import React from 'react';
import { Col, Row } from 'react-bootstrap';

function EmailTemplateBody(props) {

  const {template} = props;

  return (
    <div>
      {template.banner && <img className='banner' src={template.banner}/>}
      <Row className='m-0 p-3'>
        <Col xs={12} className='body_content_email'>
          <div dangerouslySetInnerHTML={{ __html: template.body}}/>
          {template.logo && <Row className='mb-2'>
            <Col xs={12} className='text-center'>
              <img className='logo' src={template.logo}/>
            </Col>
          </Row>}
        </Col>
      </Row>
    </div>
  )
}

export default EmailTemplateBody