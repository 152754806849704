import { connect } from 'react-redux'
import Body from '../components/Body'


const mapStateToProps = (state) => ({
  modal_show_communication_history_open: state.communication_center.modal_show_communication_history_open,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)