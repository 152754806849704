import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import { Nav } from 'react-bootstrap'

const AddTab = ({ dispatch, general_settings }) => {
  return (
    <Nav.Link className='p-0 align-self-center tabs_container' style={{width: "3%"}}>
      <div className='new-tab menu_tab text-center' style={{backgroundColor: general_settings.color_schema}}>
        <a className='section-title nav-link fa fa-plus pt-3'
           role='tab'
           aria-selected='false'
           id='player-activity-tabs-new-tab'
           onClick={() => { dispatch(displayMode(DISPLAY_MODES.TAB_FORM)) } }
           style = {{color: getComplementaryColor((general_settings.color_schema || "#FFFFFF"))  }}
        >
        </a>
      </div>
    </Nav.Link>
  )
}

AddTab.propTypes = {
  dispatch: PropTypes.func,
  general_settings: PropTypes.object
}

export default connect()(AddTab)
