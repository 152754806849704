import React, { Component } from 'react'


import BodyContainer from '../containers/BodyContainer'
import HeaderContainer from '../containers/HeaderContainer'
import DashboardActionsContainer from '../containers/DashboardActionsContainer'


class Dashboard extends Component {
  constructor(props){
    super(props)
  }
  
  componentDidMount(){
    this.props.fetchTextMessageCenterData()
  }

  render() {
    return (
      <div className="col-12 h-100 d-flex flex-column">
        <HeaderContainer />
        <DashboardActionsContainer/>
        <BodyContainer />
      </div>
    )
  }
}


export default Dashboard
