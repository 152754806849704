import { connect } from 'react-redux'
import SpecialOrdersTile from '../presentational/special_orders_tile'


const mapStateToProps = (state) => ({
  data: {
        current_orders: state.filters.specialOrders.current_orders,
          // [{
          // vendor: "Addidas", category: "Drivers", status: "Order Placed", 
          // price: "$450",
          // category_details: [["Head Type", "TSi3"], ["Lh/Rh", "Rh"], ["Shaft Type", "Smoke Black RDX"], ["Shaft Length", "-0.5(45.25)"]]
          // }],
        past_orders: state.filters.specialOrders.past_orders
          // [{
          // vendor: "Callaway", category: "Drivers", status: "Picked-Up", 
          // price: "$450",
          // category_details: [["Head Type", "TSi3"], ["Lh/Rh", "Rh"], ["Shaft Type", "Smoke Black RDX"], ["Shaft Length", "-0.5(45.25)"]]
          // }],
      },
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

// const mapDispatchToProps = (dispatch) => ({
//   switchView: view => dispatch(switchView(view)),
// })

export default connect(
  mapStateToProps,
  null
)(SpecialOrdersTile)