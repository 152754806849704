import { connect } from 'react-redux'
import FilterCategoryModal from '../components/FilterCategoryModal'
import { closeFilterModal, applyFilters } from '../actions'

const mapStateToProps = (state) => ({
  modal_filter_open: state.stock_order.modal_filter_open, 
  categories_list_for_filter: state.stock_order.categories_list_for_filter,
  filters_list: state.stock_order.filters_list
})

const mapDispatchToProps = (dispatch) => ({
  closeFilterModal: () => dispatch(closeFilterModal()),
  applyFilters: data => dispatch(applyFilters(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCategoryModal)