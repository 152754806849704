
import React from 'react';
import {useState} from 'react';
import _ from 'lodash';
import CategoryItems from './CategoryItems';

function CategoryDropdown(props) {

  const [categoryInput, setCategoryInput] = useState(null);
  const {categories, can_place_stock_orders, view_only_stock_orders, handleNewStockCategory} = props
  
  return (
    <div className="col-12 px-2 py-1 border dropdown full_width_dropdown">
      <button className={`col-12 width_fill_avaible btn btn-default dropdown-toggle text_align_left font_weight_300 font_size_14 ${view_only_stock_orders == true ? 'disabled' : ''}`} id="category_btn" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" onClick={() => categoryInput.focus()}>
        <span className='text-truncate' id='category_btn_text'>
          Select a product category..
        </span>
        <span className="float-end add-margin-top-8 caret"/>
      </button>
        
      <div className="dropdown-menu col-12 no_padding no_border_radius width_fill_avaible product_category_position_relative" id="product_category_index_table" style={{transform: "translate3d(0px, 34px, 0px)", overflow: "hidden", ariaLabelledby: "category_dropdown_btn"}}>
        <div className="dropdown-item search_category_container">
          <input ref={(input) => { setCategoryInput(input); }} name="search_category_field" className= "width_100  form-control form-control-sm" placeholder= "Search..." autoComplete= "off" id="search_category_field"/>
        </div>
        <div className="product_category_index_table ps-0 pe-1" style = {{maxHeight: "250px", overflowX: "hidden", overflowY: "auto"}}>
          {categories.map((category) => (
            <CategoryItems view_only_stock_orders = {view_only_stock_orders} category = {category} depth = '0' first= '' last= '' handleNewStockCategory={handleNewStockCategory}/>
            ))
          }
        </div>
      </div>
  </div>
)
} 
export default CategoryDropdown