import React, { Component } from 'react'

class ClubRepairContent extends Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    const {club} = this.props
    return (  
      <div className={`col-12 col-lg-${this.props.maxWidthChildren} mb-3`}>
        <div className='row item-box m-0'>
          <div className='col-6 p-0'>
            <b>Repair Type: </b>
            <span className="searchable_field">
              {club.repair_type}
            </span>
          </div>
          <div className='col-6 p-0'>
            <b>Number of Clubs: </b>
            <span className="searchable_field">
              {club.nr_of_clubs}
            </span>
          </div>
          <div className='col-6 p-0'>
            <b>Repair Details: </b>
            <br/>
            <span className="searchable_field">
              {club.repair_details || '-'}
            </span>
          </div>
          {
            club.grip_type && <div className='col-6 p-0'>
            <b>Grip Type: </b>
            <br/>
            <span className="searchable_field">
              {club.grip_type || '-'}
            </span>
          </div>
          }
          {club.type && <div className='col-6 p-0'>
            <b>Type: </b>
            <span className="searchable_field">
              {club.type}
            </span>
          </div>}
          <div className='col-6 p-0'>
            <b>{club.expected_completion_date ? "Desired Completion Date" : "Completion Date"}: </b>
            <span className="searchable_field">
              {club.expected_completion_date ? club.expected_completion_date : club.completion_date}
            </span>
          </div>
          {club.technician && <div className='col-6 p-0'>
            <b>Technician: </b>
            <span className="searchable_field">
              {club.technician}
            </span>
          </div>}
          {club.vendor && <div className='col-6 p-0'>
            <b>Vendor: </b>
            <span className="searchable_field">
              {club.vendor}
            </span>
          </div>}
        </div>
    </div>
    )
  }
}

export default ClubRepairContent