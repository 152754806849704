import { connect } from 'react-redux'
import { closeReceiveModal, updateStockOrder, uploadFile} from '../actions'
import ReceiveStockOrderModal from '../components/ReceiveStockOrderModal'

const mapStateToProps = (state) => ({
  modal_delivered_open: state.stock_order.modal_delivered_open,
  item: state.stock_order.item,
  customer_id: state.stock_order.customer_id,
  date_format: state.stock_order.date_format
})

const mapDispatchToProps = (dispatch) => ({
  closeReceiveModal: () => dispatch(closeReceiveModal()),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiveStockOrderModal)
