import { combineReducers } from 'redux'
import dashboard from './dashboard'
import automated_templates from './automated_templates'
import custom_templates from './custom_templates'
import scheduled_text_messages from './scheduled_text_messages'
import sent_text_messages_history from './sent_text_messages_history'

export default combineReducers({
  dashboard,
  automated_templates,
  custom_templates,
  scheduled_text_messages,
  sent_text_messages_history
})
