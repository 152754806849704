import React from 'react';
import GsgButton from '../../../shared/components/gsg_button';

function Footer(props) {
  return (
        
    <div className='row bottom_buttons_bar'> 
      <div className="col-12 py-2 px-3">
        <GsgButton text="Save Configuration"
          className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right"
          disabled={false}
          onClick={props.onClickHandler}/>
      </div>
    </div>
)}

export default Footer
