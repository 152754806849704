import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Column from './column'
import AddColumn from './add_column'
import { Row, Col } from 'react-bootstrap'

class ListColumns extends Component {
  constructor(props) {
    super(props)
    this.getUrl = this.getUrl.bind(this)
    this.getViewMoreUrl = this.getViewMoreUrl.bind(this)
  }
  
  flatten(input) {
    const stack = [ ...input ]
    const res = []
    while (stack.length) {
      // pop value from stack
      const next = stack.pop()
      if (Array.isArray(next)) {
        // push back array items, won't modify the original input
        stack.push(...next)
      } else {
        res.push(next)
      }
    }
    //reverse to restore input order
    return res.reverse()
  }

  getUrl(id) {
    // const _widget = this.flatten(this.props.widgets.map(section => section.pages))
    //   .find(page => page.widget_id === id)
      

    // return _widget !== undefined ? _widget.url : null
    return id ? (this.props.editMode || !this.props.member_view ? `${this.props.onlineFormsURL}/${id}?show_menu=false&no_banner=true&golf_shop_central=true` : `${this.props.onlineFormsURL}/${id}/member_order_form?no_banner=true&golf_shop_central=true`) : null
  }

  getViewMoreUrl(id) {
    const _widget = this.flatten(this.props.widgets.map(section => section.pages))
      .find(page => page.widget_id === id)

    return _widget !== undefined ? _widget.view_more : null
  }

  columnMaxWidthFromLayout(layout, left_column=false) {
    if ((layout  === '3' && left_column) || (layout  === '4' && !left_column))
      return 12
    else if (layout  === '1')
      return 4
    else 
      return 6
  }

  render() {
    return (
      <Row className="columns-list">
        <Col xs={12}>
          { this.props.data.layout === '1' ?
            <Row>
              <Col xs={12} className="column">
                { this.props.data.columns.map(column =>
                    <Column key={ column.id }
                            columns={this.props.allColumns}
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ column.options ? this.getUrl(column.options.online_form_id) : null }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            editMode={this.props.editMode}
                            demo_page={this.props.demo_page}
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id) }
                            schemaColor={this.props.schemaColor}
                            options={column.options}
                            activeColumn={this.props.activeColumn}
                            maxWidthChildren={this.columnMaxWidthFromLayout(this.props.data.layout)}
                            profile_setup={this.props.profile_setup}
                            member_profile_values={this.props.member_profile_values}
                            member_view={this.props.member_view}
                            {...column} /> ) }
              </Col>
              {this.props.editMode && <Col xs={12} className="column">
                <AddColumn size={ '12' }
                           POSITION={ null }
                           DraggedOverElement={ this.props.draggedOverElement }
                           setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                           count={ this.props.data.columns.length }
                           AddColumn={ () => this.props.addColumn(this.props.blockID, null) } />
              </Col>}
            </Row> :
            <Row>
              <Col xs={12} md={ this.props.data.left.width } className="column">
                { this.props.data.left.columns.map(column =>
                    <Column key={ column.id }
                            columns={this.props.allColumns}
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ column.options ? this.getUrl(column.options.online_form_id) : null }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            editMode={this.props.editMode}
                            demo_page={this.props.demo_page}
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id) }
                            schemaColor={this.props.schemaColor}
                            options={column.options}
                            activeColumn={this.props.activeColumn}
                            maxWidthChildren={this.columnMaxWidthFromLayout(this.props.data.layout, true)}
                            profile_setup={this.props.profile_setup}
                            member_profile_values={this.props.member_profile_values}
                            member_view={this.props.member_view}
                            {...column} /> ) }
                  {this.props.editMode && <AddColumn size={ this.props.data.left.width }
                             count={ this.props.data.left.columns.length }
                             POSITION='left'
                             DraggedOverElement={ this.props.draggedOverElement }
                             setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                             AddColumn={ () => this.props.addColumn(this.props.blockID, 'left') } />}
              </Col>
              <Col xs={12} md={ this.props.data.right.width } className="column">
                { this.props.data.right.columns.map(column =>
                    <Column key={ column.id }
                            columns={this.props.allColumns}
                            ColumnChangePosition={ _position => this.props.columnChangePosition(column.id, _position) }
                            DraggableElement={ this.props.draggableElement }
                            DraggedOverElement={ this.props.draggedOverElement }
                            DraggedOverPosition={ this.props.draggedOverPosition }
                            IframePointerOption={ this.props.iframePointerOptions }
                            IframeUrl={ column.options ? this.getUrl(column.options.online_form_id) : null }
                            onEdit={ () => this.props.editColumn(column.id) }
                            onEditProps={ () => this.props.editColumnProps(column.id) }
                            onRemove={ () => this.props.removeColumn(column.id) }
                            resizeColumn={ (height) => this.props.resizeColumn(column.id, height)}
                            setColumnDisplay={ _display => this.props.setColumnDisplay(column.id, _display) }
                            setDraggebleElement={ (_element) => this.props.setDraggebleElement(_element) }
                            setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                            setDraggedOverPosition={ _position => this.props.setDraggedOverPosition(_position) }
                            setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                            setIframeFormState={ () => this.props.setIframeFormState(null) }
                            editMode={this.props.editMode}
                            demo_page={this.props.demo_page}
                            swapColumns={ (_col1, _col2, _position) => this.props.swapColumns(_col1, _col2, _position) }
                            ViewMoreUrl={ this.getViewMoreUrl(column.widget_id) }
                            schemaColor={this.props.schemaColor}
                            options={column.options}
                            activeColumn={this.props.activeColumn}
                            maxWidthChildren={this.columnMaxWidthFromLayout(this.props.data.layout)}
                            profile_setup={this.props.profile_setup}
                            member_profile_values={this.props.member_profile_values}
                            member_view={this.props.member_view}
                            {...column} /> ) }
                  {this.props.editMode && <AddColumn size={ this.props.data.right.width }
                             count={ this.props.data.right.columns.length }
                             DraggedOverElement={ this.props.draggedOverElement }
                             POSITION='right'
                             setDraggedOverElement={ (_element) => this.props.setDraggedOverElement(_element) }
                             AddColumn={ () => this.props.addColumn(this.props.blockID, 'right') } />}
              </Col>
            </Row>
          }
        </Col>
      </Row>
    )
  }
}

ListColumns.propTypes = {
  addColumn: PropTypes.func,
  blockID: PropTypes.string,
  columnChangePosition: PropTypes.func,
  data: PropTypes.object,
  draggableElement: PropTypes.string,
  draggedOverElement: PropTypes.any,
  draggedOverPosition: PropTypes.any,
  editColumn: PropTypes.func,
  editColumnProps: PropTypes.func,
  iframePointerOptions: PropTypes.string,
  removeColumn: PropTypes.func,
  resizeColumn: PropTypes.func,
  schemaColor: PropTypes.string,
  setColumnDisplay: PropTypes.func,
  setDraggebleElement: PropTypes.func,
  setDraggedOverElement: PropTypes.func,
  setDraggedOverPosition: PropTypes.func,
  setIframePointerOptions: PropTypes.func,
  setIframeFormState: PropTypes.func,
  swapColumns: PropTypes.func,
  widgets: PropTypes.any,
}

export default ListColumns
