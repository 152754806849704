import React, {Component, useState} from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import {config_froala} from "../../../shared/froala_config";
import update from "immutability-helper";
import FroalaEditor from "react-froala-wysiwyg";

function TabForm (props) {
  const initialState = {
    title: '',
  }
  
  const [state, setState] = useState(initialState);
  
  let config = config_froala
  config["placeholderText"] = "Title"
  config["minHeight"] = 130
  config["maxHeight"] = 1000
  config["width"] = 600
  config["toolbarButtons"] = {
        'moreRich': {},
        'moreMisc': {},
        'moreText': {
          
          'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          
        },
        
        'moreParagraph': {
          
          'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          
        },
      }
  
  const setTitle = (_title) => {
    setState(update(state, {
        title: {$set: _title},
      }
    ))
  };

  return (
    <div className='tab_form col'>
      <ModalHeader >
        <h4 className="modal-title form_new_tab no-margin">{window.I18n.t('multi_widgets.tabs.add_title')}</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="row mt-3">
            <div className="col inner-content">
              <FroalaEditor tag='textarea'
                            config={{...config, ...{height: 30}}}
                            model={state.title}
                            onModelChange={setTitle}/>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={ e => { e.preventDefault(); props.cancel() } }
        >
          {window.I18n.t('multi_widgets.cancel')}
        </button>
        <button
          className="btn gems_custom_button float-end"
          style={ { backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor)) }}
          onClick={ e => { e.preventDefault(); props.createTab(state.title) } }
        >
          {window.I18n.t('multi_widgets.save')}
        </button>
      </ModalFooter>
    </div>
  )
}

TabForm.propTypes = {
  size: PropTypes.number,
  createTab: PropTypes.func,
  cancel: PropTypes.func,
}

export default TabForm
