import React, {Component, useEffect, useRef, useState} from 'react'

function HtmlTile(props){
  const divRef = useRef();
  const html = props.html_code

  useEffect(() => {
    const fragment = document.createRange().createContextualFragment(html);
    $(divRef.current).html("");

    divRef.current.append(fragment);
  }, [html]);

  return (
    <div ref={divRef} className="h-100" style={{overflowY: 'auto', overflowX: 'hidden'}}/>
  );
}

export default HtmlTile
