import React from 'react'
import {useState, useEffect, useRef} from 'react';


function ReadMoreComponent (props){
  const {isParagraph, lines, height, readMoreText, readLessText, component} = props;

  const [isOpen, setIsOpen] = useState(false)

  const [showReadMoreButton, setShowReadMoreButton] = useState(false)

  const ref = useRef(null)

  const paragraphStyles = {
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box'
  }

  const componentStyles = {
    height: height,
    display: 'flex',
    flexDirection: 'column'
  }

  useEffect(() => {
    if (ref.current && !showReadMoreButton) {
      setShowReadMoreButton(ref.current.scrollHeight !== ref.current.clientHeight)
    }
  })

  return (
    <div className='read-more-box position-relative' style={{minHeight: "80px"}}>
      <div className={`${isParagraph ? 'description' : ''} read-more-text`} style={isOpen ? (isParagraph ? null : {display: 'flex', flexDirection: 'column', height: 'auto', fontWeight: 'normal'}) : (isParagraph ? paragraphStyles : componentStyles)} ref={ref}>{component}</div>
      { showReadMoreButton && 
        <>
          {!isParagraph && !isOpen && <div className='opacity_gradient'/>}
          <div className='read-more-button' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? readLessText : readMoreText}
          </div>
        </>        
      }
    </div>
  )
}


export default ReadMoreComponent