import { connect } from 'react-redux'
import DashboardAdvancedFilter from '../components/DashboardAdvancedFilter'
import { openCategoryFilterModal, removeAllFilters, changeCalendarFilterDate, changeQuickSearchValue, changeTileLayout, search } from '../actions'

const calendar_filter_dates_options = [
  { label: "Start Ship Date", value: "start_date" },
  { label: "End Ship Date", value: "end_date" },
  { label: "Early Cancel Date", value: "early_cancel_date" },
  // {label: "Cancel Date", value: "cancellation_date"},
  { label: "Expected Ship Date", value: "expected_ship_date" },
  { label: "Expected Delivery Date", value: "expected_delivery_date" }
]

const mapStateToProps = (state) => ({
  filters_list_count: state.stock_order.filters_list.length == 0 ? "all" : state.stock_order.filters_list.length,
  filters_list: state.stock_order.filters_list,
  calendar_filter_dates_options: calendar_filter_dates_options,
  calendar_filter_date: state.stock_order.calendar_filter_date,
  view: state.stock_order.view,
  quick_search_value: state.stock_order.quick_search_value || null,
  condensed_tile_layout: state.stock_order.condensed_tile_layout || false,
  search_params: state.stock_order.search_params,
  vendors_list: state.stock_order.vendors_list,
  date_format: state.stock_order.date_format
})

const mapDispatchToProps = (dispatch) => ({
  openCategoryFilterModal: () => dispatch(openCategoryFilterModal()),
  removeAllFilters: () => dispatch(removeAllFilters()),
  changeCalendarFilterDate: filter_date => dispatch(changeCalendarFilterDate(filter_date)),
  changeQuickSearchValue: value => dispatch(changeQuickSearchValue(value)),
  changeTileLayout: value => dispatch(changeTileLayout(value)),
  search: value => dispatch(search(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdvancedFilter)