import { connect } from 'react-redux'
import CartOnlineOrder from '../components/CartOnlineOrder';
import {
  changeCartView,
  changeCustomerAuthType,
  loginMember,
  changeGenericMember,
  checkCustomerDetails,
  checkStockAndSubmitOrder,
  openErrorModal,
  setCartErrorMessage,
  changeDropShipErrorMessage,
} from "../actions";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  currencySymbol: state.general_settings.currency_symbol,
  formType: state.general_settings.form_type,
  dropShipType: state.general_settings.drop_ship_type,
  maxCredits: state.general_settings.total_credits,
  checkoutItemsLimit: state.general_settings.checkout_items_limit,

  genericForm: state.customer.generic_form,
  customer: state.customer,
  links: state.general_settings.links,

  guestForm: (state.customer.generic_form && state.customer.authentication_customer_type === 'guest') || (state.customer.customer_type === 'player' && state.customer.player && _.isEmpty(state.customer.player.member_id)),
  memberForm: state.customer.customer_details && !(state.customer.customer_type === 'player' && state.customer.player && !state.customer.player.member_id),

  dropShipEnabled: state.customer.drop_ship_enabled,
  selectedDropShip: (state.customer.drop_ship_addresses || []).filter((ds, index) => ds.selected)[0] || {},

  orderSubmitted: state.form_state.orderSubmitted,
  isCartView: state.form_state.isCartView,
  preview: state.form_state.preview,
  dropShipError: state.form_state.dropShipError,
  editMode: state.form_state.editMode,

  itemsInCart: state.cart.itemsInCart,
  totalCost: state.cart.totalCost.toFixed(2),
  cartErrorMessage: state.cart.errorMessage,
  isEnoughStockForOrders: state.cart.isEnoughStockForOrders,
})

const mapDispatchToProps = (dispatch) => ({
  changeCartView: (data) => dispatch(changeCartView(data)),
  changeCustomerAuthType: (type) => dispatch(changeCustomerAuthType(type)),
  loginMember: (fields) => dispatch(loginMember(fields)),
  changeGenericMember: () => dispatch(changeGenericMember()),
  checkCustomerDetails: (memberAuthFields, guestAuthFields) => dispatch(checkCustomerDetails(memberAuthFields, guestAuthFields)),
  checkStockAndSubmitOrder: (guestAuthFields) => dispatch(checkStockAndSubmitOrder(guestAuthFields)),
  openErrorModal: (errorHTML) => dispatch(openErrorModal(errorHTML)),
  setCartErrorMessage: (error_message) => dispatch(setCartErrorMessage(error_message)),
  changeDropShipErrorMessage: (value) => dispatch(changeDropShipErrorMessage(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartOnlineOrder)
