import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {toast, ToastContainer} from "react-toastify";


const Toast = ({message, type}) => {

  const toast_config = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  }

  if(message) {
    if (type === "success")
      toast.success(message, toast_config);
    if (type === "warning")
      toast.warning(message, toast_config);
    if (type === "error")
      toast.error(message, toast_config);
    if (type === "info")
      toast.info(message, toast_config);
  }
  const handleInputChange = (e) => {
    type = $(e.target).attr('msg-type')
    message = $(e.target).attr('msg-value')

    if(message) {
      if (type === "success")
        toast.success(message, toast_config);
      if (type === "warning")
        toast.warning(message, toast_config);
      if (type === "error")
        toast.error(message, toast_config);
      if (type === "info")
        toast.info(message, toast_config);
    }
  }

  return (<>
    <ToastContainer />
    <input type="hidden" id = "react_toast_shared_input" onClick={(e) => handleInputChange(e)}/>
  </>)
}

export default Toast