import { connect } from 'react-redux'
import PipedriveHeader from '../components/PipedriveHeader'
import {updatePipedriveSorting} from '../actions'

const mapStateToProps = (state) => ({
  to_place_sorted_by: state.stock_order.to_place_sorted_by,
  placed_sorted_by: state.stock_order.placed_sorted_by,
  delivered_sorted_by: state.stock_order.delivered_sorted_by
})

const mapDispatchToProps = (dispatch) => ({
  updatePipedriveSorting: data => dispatch(updatePipedriveSorting(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipedriveHeader)
