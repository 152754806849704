import { connect } from 'react-redux'
import MatchingCategoriesPage from '../components/MatchingCategoriesPage'
import { saveCategoriesConfiguration } from '../actions'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  saveCategoriesConfiguration: () => dispatch(saveCategoriesConfiguration()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchingCategoriesPage)
