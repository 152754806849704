import { connect } from 'react-redux'
import SpecialOrder from '../components/SpecialOrder'
import { load_component_data } from '../actions'

const mapStateToProps = (state) => ({
  components: state.dashboard.components,
  customer_id: state.dashboard.customer_id,
  categories_list_for_filter: state.dashboard.categories_list_for_filter,
  to: state.dashboard.to,
  from: state.dashboard.from,
  reference_date: state.dashboard.reference_date,
  date_format: state.dashboard.date_format,
  moduleKey: state.dashboard.module_key,
  refresh_date: state.dashboard.refresh_date,
  isLoading: state.dashboard.isLoading,
  vendors_list: state.dashboard.vendors_list,
  categories_custom_fields: state.dashboard.categories_custom_fields,
  categories_list_options: state.dashboard.categories_list_options,
  used_category_ids: state.dashboard.used_category_ids
})

const mapDispatchToProps = (dispatch) => ({
  load_component_data: data => dispatch(load_component_data(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialOrder)