import React from 'react'
import {useState, useEffect} from 'react';
import TimePeriod from './TimePeriod';
import Summary from './shared/Summary';
import BarChartComponent from './shared/BarChartComponent';
import LineTrend from './shared/LineTrend';
import VendorDeliveryPerformance from './shared/VendorDeliveryPerformance';
import PieChartComponent from './shared/PieChartComponent';
import KeyInsight from './shared/KeyInsight';

function SpecialOrder(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);
  const {} = state;

  const {customer_id, moduleKey, from, to, reference_date, date_format, refresh_date, used_category_ids} = props;
  
  const top_vendors_categories_sort_options = [
    {label: "Total Retail", value: "total_retail"}, 
    {label: "Total Wholesale", value: "total_wholesale"},
    {label: "Total Orders", value: "total_orders"},
    {label: "Total Profit Margin", value: "total_profit_margin"}]
  
  const top_vendors_filter_options = props.categories_list_for_filter

  const top_members_sort_options = [
    {label: "Total Retail", value: "total_retail"}, 
    {label: "Total Orders", value: "total_orders"}
  ]

  const include_orders_options = [
    {label: "finalized", value: "order_lines.finalized_date"}, 
    {label: "delivered", value: "order_lines.delivered_date"},
    {label: "placed", value: "orders.ordered_date"},
    {label: "entered", value: "order_lines.created_at"}
  ]

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: moduleKey, customer_id: customer_id, to: to, from: from, reference_date: reference_date, date_format: date_format})
  });


  return (
    <div className="col h-100 d-flex flex-column w-100 background_white">
      <TimePeriod to={to} 
                  from={from} 
                  reference_date={reference_date}
                  date_format={date_format}
                  refresh_date={refresh_date}
                  moduleKey={moduleKey} 
                  customer_id={customer_id}
                  load_component_data={props.load_component_data} 
                  options_for_select={include_orders_options}/> 
      <Summary data={props.components.summary["data"]} isLoading={props.components.summary["isLoading"]}/>
      <b className='main_header'>ANALYZE</b>
      <div className='row'>
        <BarChartComponent 
                title="TOP VENDORS" 
                chart_data={props.components.top_vendors["chart_data"]} 
                sort_options={top_vendors_categories_sort_options}
                sort_options_for_filter = {top_vendors_filter_options}
                componentKey="top_vendors"
                sortTitle="Sort By"
                moduleKey={moduleKey}
                filters={props.components.top_vendors["filters"]}
                used_category_ids={used_category_ids}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.top_vendors["isLoading"]}
                />
        <BarChartComponent 
                title="TOP CATEGORIES" 
                chart_data={props.components.top_categories["chart_data"]} 
                sort_options={top_vendors_categories_sort_options} 
                vendors_options = {props.vendors_list}
                componentKey="top_categories"
                moduleKey={moduleKey}
                sortTitle="Sort By" 
                filters={props.components.top_categories["filters"]}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.top_categories["isLoading"]}
                />
        <BarChartComponent 
                title="TOP MEMBERS" 
                chart_data={props.components.top_members["chart_data"]} 
                sort_options={top_members_sort_options} 
                componentKey="top_members"
                moduleKey={moduleKey}
                sortTitle="Sort By"
                filters={props.components.top_members["filters"]}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.top_members["isLoading"]}
                />
      </div>
      <div className='row'>
        <LineTrend  title="ORDER TREND" 
                    data={props.components.order_trend["chart_data"]} 
                    isLoading={props.components.summary["isLoading"]}
                    dataKey="Orders"
                    />
        <VendorDeliveryPerformance data={props.components.vendor_delivery_performance} load_component_data={props.load_component_data} isLoading={props.components.vendor_delivery_performance["isLoading"]} moduleKey="special_order"/>
      </div>
      <div className='row'>
        <PieChartComponent title='MEMBER OR GUEST' 
                      componentKey='customer_type' 
                      data={props.components.customer_types["chart_data"]}
                      isLoading={props.components.customer_types["isLoading"]}
                      />
        <PieChartComponent title='DROPSHIPPED OR SHOP' 
                      componentKey='ship_method'
                      data={props.components.ship_method["chart_data"]}
                      isLoading={props.components.customer_types["isLoading"]}
        />
        <PieChartComponent title='ORDER METHOD' 
                      componentKey='order_method'
                      data={props.components.order_method["chart_data"]}
                      isLoading={props.components.customer_types["isLoading"]}
        />
        <PieChartComponent title='RETURNED' 
                    componentKey='returned_orders'
                    data={props.components.returned_orders["chart_data"]}
                      isLoading={props.components.customer_types["isLoading"]}
        />
      </div>
      <div className='row'>
        <BarChartComponent 
                title="CATEGORY ANALYZER" 
                chart_data={props.components.category_analyzer["chart_data"]} 
                componentKey="category_analyzer" 
                sortTitle="Custom Field"
                vendors_options = {props.vendors_list}
                categories_options = {props.categories_list_options}
                sort_options = {props.categories_custom_fields}
                moduleKey={moduleKey}
                filters={props.components.category_analyzer["filters"]}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.category_analyzer["isLoading"]}
                />
        <KeyInsight
              data={props.components.key_insight} isLoading={props.components.key_insight["isLoading"]}
              />
      </div>
    </div>
  )
}
export default SpecialOrder
