import React from 'react';

function Header(props) {
  return (
    <div className="row custom_name border-bottom border-dark">
        <div className='col-4 p-0'>
          <h5 className="page-title ms-0">
            {"COMMUNICATIONS "}
            <span className="icon-record"/>
            {" EMAIL CENTER"}
          </h5>
        </div>
      </div>
    )}
export default Header
