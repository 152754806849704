import { connect } from 'react-redux'
import LoginForm from '../presentational/login_form'
import { login, setAttribute, removeInfoMessage } from '../../actions'

const mapStateToProps = (state, props) => ({
  activeTab: state.filters.active_tab,
  tabs: state.tabs,
  blocks: state.blocks,
  displayMode: state.filters.display_mode,
  member_view: state.filters.member_view,
  editMode: state.filters.editMode,
  general_settings: state.general_settings,
  state: state,
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  authentication_type: state.general_settings.authentication_type,
  website: state.general_settings.website,
  infoMessage: state.filters.infoMessage
})

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(login(data)),
  setAttribute: (attr, value) => {
    dispatch(setSettingsAttribute(attr, value))
  },
  removeInfoMessage: () => dispatch(removeInfoMessage())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)