import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import HandleDuplicatesModal from './HandleDuplicatesModal';

function MergePage(props) {
  
  const initialState = {
    roster_field: null,
    spreadsheet_field: null,
    roster_matching_fields: [],
    spreadsheet_matching_fields: [],
    method_of_handle_duplicate: "all_but_last",
    all_matched_values: null,
    roster_removal: "yes"
  }
  
  const [state, setState] = useState(initialState);
  const {customer_id, spreadsheetfile_id, roster_options, spreadsheet_options, checkMergingOptions, submitMergingOptions,
    spreadsheet_duplicates_count, roster_duplicates_count, soft_deleted_roster_duplicates_count, update_m_count, delete_m_count, new_m_count, handle_duplicates_modal_open, loading_btn, total_members, total_rows, total_deleted_members
    ,all_roster_duplicates_count, no_remove_soft_deleted_count, all_soft_deleted_roster_duplicates_count, all_spreadsheet_duplicates_count, soft_deleted_after_import} = props;
  const {roster_matching_fields, spreadsheet_matching_fields, spreadsheet_field, roster_field, method_of_handle_duplicate, roster_removal, all_matched_values} = state;
  
  
  const handleFieldsSelectChange = (value, type) => {
    setState(update(state, {
          [type]:  { $set : value }
          }))
  }

  const updateDuplicates = (data) => {
    setState(update(state, {
      all_matched_values:  
       {$set : data }}))
  }

  const handleRemoveMergingFields = (index) => {
    let u_roster_matching_field = [...roster_matching_fields]
    let u_spreadsheet_matching_fields = [...spreadsheet_matching_fields]

    u_roster_matching_field = u_roster_matching_field.filter(function(e) {
                                      return u_roster_matching_field.indexOf(e) != index
                                    })
    u_spreadsheet_matching_fields = u_spreadsheet_matching_fields.filter(function(e) {
                                      return u_spreadsheet_matching_fields.indexOf(e) != index
                                    })

    setState(update(state, {
      roster_matching_fields:  { $set : u_roster_matching_field },
      spreadsheet_matching_fields:  { $set : u_spreadsheet_matching_fields },
      }))

    let data = {roster_matching_fields : u_roster_matching_field, spreadsheet_matching_fields: u_spreadsheet_matching_fields}
    checkMergingOptions(JSON.stringify(data))
  }
  const handleAddNewField = (event) => {
    let u_roster_matching_field = [...roster_matching_fields]
    let u_spreadsheet_matching_fields = [...spreadsheet_matching_fields]

    if(state.roster_field && state.spreadsheet_field){
      u_roster_matching_field.push(state.roster_field)
      u_spreadsheet_matching_fields.push(state.spreadsheet_field)
      
      setState(update(state, {
        loading_btn: { $set: true },
        roster_matching_fields:  { $set: u_roster_matching_field },
        spreadsheet_matching_fields:  { $set: u_spreadsheet_matching_fields },
        roster_field: { $set : null },
        spreadsheet_field: { $set : null },
        method_of_handle_duplicate: { $set : "all_but_last" },
        all_matched_values: {$set: null } 
        }))

      let data = {roster_matching_fields : u_roster_matching_field, spreadsheet_matching_fields: u_spreadsheet_matching_fields}
      checkMergingOptions(JSON.stringify(data))
    }
  }
  
  const handleSubmit = (event) => {
    // let params = {...state}
    
    // let data = {roster_matching_fields : params["roster_matching_fields"], 
    //             spreadsheet_matching_fields: params["spreadsheet_matching_fields"],
    //             method_of_handle_duplicate: params["method_of_handle_duplicate"],
    //             roster_removal: params["roster_removal"],
    //             all_matched_values: params["all_matched_values"] || props.all_matched_values
    //            }
    submitMergingOptions({})
  }

  const handleRadioInputChange = (event) => {
    
    if(event.target.name == "method_of_handle_duplicate" &&  event.target.value == "manual")
    { 
      setState(update(state, {
        [event.target.name]:  { $set: event.target.value }
        }))

      if(all_matched_values)
        props.openHandleDuplicatesModal(null)
      else
      {
        let u_roster_matching_field = [...roster_matching_fields]
        let u_spreadsheet_matching_fields = [...spreadsheet_matching_fields]
    
        let data = {roster_matching_fields : u_roster_matching_field, spreadsheet_matching_fields: u_spreadsheet_matching_fields}

        props.openHandleDuplicatesModal(JSON.stringify(data))
      }
    }
    
    if(event.target.name == "method_of_handle_duplicate" &&  event.target.value == "all_but_last")
      setState(update(state, {
        [event.target.name]:  { $set: event.target.value },
        all_matched_values: {$set: null } 
        }))
    else
      setState({...state, [event.target.name]: event.target.value})
  }

  return (
    <div className="col-12 h-100 d-flex flex-column background_white">
      {handle_duplicates_modal_open && <HandleDuplicatesModal handle_duplicates_modal_open={handle_duplicates_modal_open} 
      closeHandleDuplicatesModal={props.closeHandleDuplicatesModal} 
      spreadsheet_duplicates_data={props.spreadsheet_duplicates_data} 
      spreadsheet_duplicates_rows={props.spreadsheet_duplicates_rows}
      spreadsheet_matching_fields={spreadsheet_matching_fields}
      all_matched_values={state.all_matched_values ? state.all_matched_values : props.all_matched_values}
      spreadsheet_keys={props.spreadsheet_keys}
      updateDuplicates={updateDuplicates}
      />}
      <div className='row backgroundColorGemsOrange'>
        <div className='col color_white fs-6 p-2'>
          <i>
            This page allows you to configure how to manage member merging criteria, old member removal, and duplicates and visualise the outcome of the upload process.
          </i>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          {/* <h5>Member merge & duplication configurator</h5> */}
          <p className='pb-1 pt-1'>Select one or more master roster field - spreadsheet column pairs to match spreadsheet rows to Golf Shop members</p>
        </div>
      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-3'>
          <p>Golf Shop Field(s)</p>
          <Select id = "roster_field" 
                  name="roster_field"
                  onChange={ (e) =>  handleFieldsSelectChange(e.value, "roster_field") }
                  options={roster_options}
                  isOptionDisabled={(option) => roster_matching_fields.includes(option.value)} 
                  value={roster_options.filter(function(option) {
                              return option.value === roster_field;
                            })}
                  />
          {
            roster_matching_fields.map((r, index) => (
              <p className='p-1'>{(_.find(roster_options, (ro) => ro.value == r ) || {}).label}</p>
          ))}
        </div>
        <div className='col-3'>
          <p>Spreadsheet Column(s)</p>
          <Select id = "spreadsheetOption" 
                  name="spreadsheetOption"
                  onChange={ (e) =>  handleFieldsSelectChange(e.value, "spreadsheet_field") }
                  options={spreadsheet_options}
                  isOptionDisabled={(option) => spreadsheet_matching_fields.includes(option.value)} 
                  value={spreadsheet_options.filter(function(option) {
                              return option.value === spreadsheet_field;
                            })}
                  />
          {
            spreadsheet_matching_fields.map((s, index) => (
              <p className='p-1'>{s}<span className="fa fa-close cursor_pointer color_red fs-4 float-end" onClick={(e) => {handleRemoveMergingFields(index)}}></span></p>
          ))}
        </div>
        <div className='col'>
          <button
              className="float-start btn gems_custom_button m-3" 
              aria-label="Add"
              onClick={handleAddNewField}
              disabled={loading_btn}
            >
            {loading_btn ? <> <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
            </>
            :
            <>Add</>
            }
          </button>
        </div>
      </div>
      <div className='row'>
        {/* <div className="col-8 text-center">
          <Spinner className="" animation="border" variant="warning" id="loading_spinner" />
        </div> */}
      </div>
      
      {(roster_duplicates_count != null || spreadsheet_duplicates_count != null) && <div className='row'>
        <div className='col'>
          <h5>Spreadsheet duplicates</h5>
          {((roster_duplicates_count == 0 )
          ?
          <p className='p-3 pt-0 color_green'>No duplicate group(s) found on roster having 
          {
            roster_matching_fields.map((r, index) => (
              <b> {(_.find(roster_options, (ro) => ro.value == r ) || {}).label}{index != roster_matching_fields.length - 1 ? ', ' : ' ' } </b>
          ))}
          combination
          </p>
          :
          <p className='p-3 pt-0 color_red'> {roster_duplicates_count} duplicate group(s) for a total of {all_roster_duplicates_count} (includes {all_soft_deleted_roster_duplicates_count} soft deleted) members have been found on roster having
          {
            roster_matching_fields.map((r, index) => (
              <b> {(_.find(roster_options, (ro) => ro.value == r ) || {}).label}{index != roster_matching_fields.length - 1 ? ', ' : ' ' }</b>
          ))}
          combination. Only 1 soft deleted member per group will be restored, unless the group contains active members.
          </p>
          )}
          {((spreadsheet_duplicates_count == 0) 
          ?
          <p className='p-3 pt-0 color_green'> No duplicate group(s) found on spreadsheet having 
          {
            spreadsheet_matching_fields.map((s, index) => (
              <b> {s}{index != spreadsheet_matching_fields.length - 1 ? ', ' : ' ' }</b>
          ))}
          combination
          </p>
          :
          <p className='p-3 pt-0 color_red'> {spreadsheet_duplicates_count} duplicate group(s) for a total of {all_spreadsheet_duplicates_count} members have been found on spreadsheet having 
          {
            spreadsheet_matching_fields.map((s, index) => (
              <b> {s}{index != spreadsheet_matching_fields.length - 1 ? ', ' : ' ' }</b>
          ))}
          combination
          </p>
          )}
          {spreadsheet_duplicates_count > 0 && <div className='row p-3'>
            <p className='pt-0'>Choose a method to handle duplicate group(s) in spreadsheet:</p>
            <Form.Check 
              inline
              name="method_of_handle_duplicate"
              value = "all_but_last"
              type='radio'
              id="all_but_last"
              label="Disregard all but last"
              className="col-2"
              ini={true.toString()}
              checked = {method_of_handle_duplicate == "" || method_of_handle_duplicate == undefined || method_of_handle_duplicate == "all_but_last"}
              onChange={handleRadioInputChange} 
            />
            <div className='col'>
            <Form.Check 
              inline
              type='radio'
              name="method_of_handle_duplicate"
              value = "manual"
              id="manual"
              label="Manualy select rows with same column(s)"
              className=""
              disabled= {spreadsheet_duplicates_count > 600 ? true : false}
              checked = {method_of_handle_duplicate == "manual"}
              onChange={handleRadioInputChange} 
              onClick={handleRadioInputChange}
            />
            {spreadsheet_duplicates_count > 600 &&
              <OverlayTrigger
              key="background_opacity"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-background_opacity`}>
                  Too many duplicates({spreadsheet_duplicates_count})
                </Tooltip>
                }>
              <i className="ms-2 gga-info-circle tooltip_info" />
            </OverlayTrigger>}
            </div>
          </div>}
        </div>
      </div>}
      {delete_m_count > 0 && <div className='row mt-2'>
        <div className='col'>
          <h5>Golf Shop Roster member removal</h5>
          <p className='ps-3 pt-0'><b>{delete_m_count}</b> members are present in Golf Shop but are not in the spreadsheet or without matching fields. Do you want to remove those members? (Members with existing orders / demos / club repair will be soft deleted)</p>
          <div className='row p-3 pt-0'>
            <Form.Check 
              inline
              name="roster_removal"
              value = "yes"
              type='radio'
              id="roster_removal"
              label="Yes"
              className="col-2"
              ini={true.toString()}
              checked = {roster_removal == "yes"}
              onChange={handleRadioInputChange} 
            />
            <Form.Check 
              inline
              type='radio'
              name="roster_removal"
              value = "no"
              id="no"
              label="No"
              className="col"
              checked = {roster_removal == "" || roster_removal == undefined || roster_removal == "no"}
              onChange={handleRadioInputChange} 
            />
          </div>
        </div>
      </div>}
      <form action={"/customers/"+customer_id+"/members/match_custom_fields"} accept-charset="UTF-8" method="post">
        <input name="utf8" type="hidden" value="✓"/>
        <input type="hidden" name="authenticity_token" value={props.token}/>
        <input name="all_matched_values" type="hidden" value={JSON.stringify(all_matched_values || props.all_matched_values)}/>
        <input name="all_spreadsheet_matched_rows" type="hidden" value={JSON.stringify(props.all_spreadsheet_matched_rows)}/>
        <input name="roster_matching_fields" type="hidden" value={JSON.stringify(roster_matching_fields)}/>
        <input name="spreadsheet_matching_fields" type="hidden" value={JSON.stringify(spreadsheet_matching_fields)}/>
        <input name="spreadsheetfile_id" type="hidden" value={spreadsheetfile_id}/>
        <input name="roster_removal" type="hidden" value={roster_removal}/>
        <input name="match_values_soft_deleted_ids" type="hidden" value={JSON.stringify(props.match_values_soft_deleted_ids)}/>
        <input name="from_react" type="hidden" value={true}/>
        
        <input type="submit" name="commit" value="Import Spreadsheet" className="submitSaveBtn d-none"/>
      </form>
      {new_m_count != null && <div className='row mt-2' style={{backgroundColor: 'white'}}>
        <div className='col mb-3'>
          <h5>Overview</h5>
          {/* <p className='ps-3'><b>{total_members}</b> members are present in Golf Shop Roster and <b>{total_deleted_members}</b> are soft deleted</p>
          <p className='ps-3'><b>{total_rows}</b> rows are present in Spreadsheet</p> */}
        </div>
        <div className='col-12'>
          <div className='row'>
            <div className='mt-0 mb-0 col p-0 text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "rgb(255, 132, 0)", margin: "4rem"}}><p className='fs-1'>{total_members}</p><p>current roster size</p>
            <p className='fs-5'>(includes {total_deleted_members} soft deleted)</p></div>
            <b className='col fs-2 d-flex align-items-center justify-content-center '>{"WILL BE MERGED WITH"}</b>
            <div className='mt-0 mb-0 col p-0 text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "#187534", margin: "4rem"}}><p className='fs-1'>{total_rows}</p><p>spreadsheet rows</p></div>
          </div>
        </div>
        <div className='row mb-4 mt-5rem'>
          <b className='col fs-2 text-center'>{"AFTER THE MERGING PROCESS"}</b>
        </div>
        <div className='col-12'>
          <div className='row'>
            <div className='col mt-0 mb-0 text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "rgb(255, 132, 0)", margin: "4rem"}}><p className='fs-1'>{(total_members - (roster_removal == "yes" ? delete_m_count : 0)) + new_m_count }</p><p>expected roster size</p>
            <p className='fs-5'>(includes {roster_removal === "yes" ? soft_deleted_after_import : no_remove_soft_deleted_count} soft deleted)</p></div>
          </div>
          <div className='row'>
            <div className='col mt-1  text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "limegreen", margin: "4rem"}}>
              <p className='fs-1'>{new_m_count}</p><p>new members</p>
              <p className='font_size_14'>(Members from spreadsheet without matching fields will be included in this count)</p>
            </div>
            <div className='col mt-1  text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "deepskyblue", margin: "4rem"}}><p className='fs-1'>{update_m_count}</p><p>members to update</p></div>
            <div className='col mt-1  text-center color_white fs-3' style={{height: "150px", border: "1px solid", backgroundColor: "red", margin: "4rem"}}>
              <p className='fs-1'>{roster_removal == "yes" ? delete_m_count : 0}</p><p>members to try to remove</p>
              <p className='font_size_14'>(Members from roster without matching fields will be included in this count)</p>
            </div>
          </div>
        </div>
      </div>}
      <div className='row bottom_buttons_bar'> 
          <div className="col-12 py-2 px-3">
          {roster_matching_fields.length > 0 && <button
                  className="btn gems_custom_button gems_orange_button btn-default pull-right"
                  onClick={(event) => {handleSubmit(); $('.submitSaveBtn').click()}}
                  disabled={loading_btn}
            >
            {loading_btn ? <> <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
            </>
            :
            <>Save</>
            }</button>}
            {/* <GsgButton text="Refresh Overview"
                  className="btn gems_custom_button btn-default me-3 pull-right"
                  disabled={false}
                  onClick={(event) => handleSubmitCheck()}/> */}

          </div>
        </div>
    </div>
  )
}
export default MergePage
