import React from 'react';
import DatePicker from "react-datepicker";
import {ResponsiveContainer, AreaChart, Area, ReferenceLine, Sector , LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import Select from 'react-select';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import _ from 'lodash';
import TableComponent from './TableComponent';

function VendorDeliveryPerformance(props) {
  const initialState = {
    by_vendor_value: 0
  }
  
  const [state, setState] = useState(initialState);

  const handleByVendorChange = (value) => {
    setState({...state, by_vendor_value: value });
  }

  return (
        <div className='col-2 p-20 add-margin-all-10 mb-0 text-start home_class' id="vendorDeliveryPerformance">
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <>
            <div className='row m-0 mb-20 text-start body_blue_component' style={{backgroundColor: "rgb(227, 238, 240)"}}>
              <h5 className='mt-2'><b>{props.data["avg_delivery_time"]} Days</b></h5>
              <h6>Average Delivery Time - All Vendors</h6>
            </div>
            <h6 className='text-start'>By Vendor</h6>
            <Select className="topVCMSelect order-2 flex-grow-1" 
                                menuPortalTarget={document.getElementById('vendorDeliveryPerformance')}
                                menuPosition={'fixed'} 
                                name = "vendorDeliveryPerformanceSelect"
                                options={props.data["all_vendors"]}
                                onChange={(e) => {handleByVendorChange(e.value)}}/>
            {state.by_vendor_value != 0 && <div className='m-0 row mt-1 text-start body_blue_component dark_orange' style={{backgroundColor: "rgb(255, 243, 235)"}}>
              <h5 className='mt-2'><b> {state.by_vendor_value} Days</b></h5>
              <h6>Average Delivery Time</h6>
            </div>}
            <h6 className='text-start mt-3 '>Best Average Delivery Times</h6>
            <TableComponent data={props.data["best_vendors"]} componentType='delivery_time_by_vendor' moduleKey={props.moduleKey}/>
          </>}
      </div>
    )}
export default VendorDeliveryPerformance
