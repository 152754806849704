import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  LOAD_COMPONENT_DATA: 'LOAD_COMPONENT_DATA',
  SET_IS_LOADING: 'SET_IS_LOADING'
}

export const load_component_data = (data) => (dispatch, getState) => {
  const state = getState()

  const dataURL = "/customers/"+state.dashboard.customer_id+"/shop_dashboard/"+data.key+"/load_component_data"
  let subcomponent_filters = {}

  if(data.subcomponent_key)
    subcomponent_filters[data.subcomponent_key] = data.subcomponent_filters
  else
    Object.entries(state.dashboard.components).map(key => key[1]["filters"] ? subcomponent_filters[key[0]] = key[1]["filters"] : "");

  const params = {
                  customer_id: data.customer_id,
                  id: data.key,
                  subcomponent_filters: subcomponent_filters,
                  subcomponent_key: data.subcomponent_key,
                  to: data.to || state.dashboard.to,
                  from: data.from || state.dashboard.from,
                  reference_date: data.reference_date || state.dashboard.reference_date,
                  date_format: data.date_format || state.dashboard.date_format,
                 }

  if(!state.dashboard.isLoading)
    dispatch({
      type: ActionTypes.SET_IS_LOADING,
      data: {subcomponent_key: data.subcomponent_key, isLoading: true},
    })

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json', 'Content-Type': 'applications/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.LOAD_COMPONENT_DATA,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}