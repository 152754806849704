import { connect } from 'react-redux'
import SentEmailsSection from '../components/SentEmailsSection'
import { paginateCommunicationHistory, openCommunicationTemplateModal } from '../actions'

const mapStateToProps = (state) => ({
  emails: state.sent_emails_history.emails,
  quick_search_value: state.dashboard.quick_search_value,
  isLoading: state.sent_emails_history.isLoading,
  total_pages: state.sent_emails_history.sent_emails_total_pages,
  page: state.sent_emails_history.page
})

const mapDispatchToProps = (dispatch) => ({
  paginateCommunicationHistory: () => dispatch(paginateCommunicationHistory()),
  showBulkTemplate: (data) => dispatch(openCommunicationTemplateModal(data, "sent_email")),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SentEmailsSection)