// import { PopupActionTypes } from '../../shared/actions'
// import PopupTypes from '../popup_types'
// import { openPopup } from '../../shared/actions'
// export { openPopup, closePopup } from '../../shared/actions'
import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  // ...PopupActionTypes,
  // Tab related actions
  CREATE_TAB: 'CREATE_TAB',
  EDIT_TAB: 'EDIT_TAB',
  SAVE_TABS: 'SAVE_TABS',
  UPDATE_TAB: 'UPDATE_TAB',
  REMOVE_TAB: 'REMOVE_TAB',
  DELETE_TAB: 'DELETE_TAB',
  SWAP_TABS: 'SWAP_TABS',
  DEFAULT_TAB_MODE: 'DEFAULT_TAB_MODE',
  // Block related actions
  CREATE_BLOCK: 'CREATE_BLOCK',
  EDIT_BLOCK: 'EDIT_BLOCK',
  UPDATE_BLOCK: 'UPDATE_BLOCK',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  BLOCK_REORDER: 'BLOCK_REORDER',
  // Column related actions
  CREATE_COLUMN: 'CREATE_COLUMN',
  UPDATE_COLUMN: 'UPDATE_COLUMN',
  SWAP_COLUMNS: 'SWAP_COLUMNS',
  RESIZE_COLUMN: 'RESIZE_COLUMN',
  REMOVE_COLUMN: 'REMOVE_COLUMN',
  COLUMN_DISPLAY: 'COLUMN_DISPLAY',
  COLUMN_CHANGE_POSITION: 'COLUMN_CHANGE_POSITION',
  // Filters related methods
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  SET_ACTIVE_COLUMN: 'SET_ACTIVE_COLUMN',
  SET_ACTIVE_BLOCK: 'SET_ACTIVE_BLOCK',
  SET_ACTIVE_POSITION: 'SET_ACTIVE_POSITION',
  SET_IFRAME_POINTER_OPTIONS: 'SET_IFRAME_POINTER_OPTIONS',
  SET_DRAGGABLE_ELEMENT: 'SET_DRAGGABLE_ELEMENT',
  SET_DRAGGED_OVER_ELEMENT: 'SET_DRAGGED_OVER_ELEMENT',
  SET_DRAGGED_OVER_POSITION: 'SET_DRAGGED_OVER_POSITION',
  DISPLAY_MODE: 'DISPLAY_MODE',
  SET_IFRAME_FORM_STATE: 'SET_IFRAME_FORM_STATE',
  ADD_NEW_WIDGET: 'ADD_NEW_WIDGET',
  VIEW_INVENTORY: 'VIEW_INVENTORY',
  ADD_TO_WAITING_LIST: 'ADD_TO_WAITING_LIST',
  RESERVE_DEMO: 'RESERVE_DEMO',
  GET_RESERVE_DEMO: 'GET_RESERVE_DEMO',
  CHOOSE_NOTIFICATION: 'CHOOSE_NOTIFICATION',
  SET_SETTINGS_ATTRIBUTE: 'SET_SETTINGS_ATTRIBUTE',
  //collage page related methods
  UPDATE_COLLAGE_ITEMS: 'UPDATE_COLLAGE_ITEMS',
  REQUEST_REPAIR: 'REQUEST_REPAIR',
  UPDATE_MEMBER_PROFILE: 'UPDATE_MEMBER_PROFILE',
  UPDATE_INFO_MESSSAGE: 'UPDATE_INFO_MESSSAGE',
  SAVE_GENERAL_SETTINGS: 'SAVE_GENERAL_SETTINGS'
}

let tabID = -1000
let tabINDEX = 0

export const setSettingsAttribute = (attr, value) => ({
  type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
  attribute: attr,
  value: value
})

export const setTabIndex = _index => tabINDEX = _index
export const createTab = (_title) => ({
  type: ActionTypes.CREATE_TAB,
  id: tabID++,
  title: _title,
  index: ++tabINDEX,
})

export const editTab = (_id) => ({
  type: ActionTypes.EDIT_TAB,
  id: _id,
})

export const saveTabs = (_tabs) => ({
  type: ActionTypes.SAVE_TABS,
  tabs: _tabs,
})

export const updateTab = (_id, _title, _width) => (
  {
  type: ActionTypes.UPDATE_TAB,
  id: _id,
  title: _title,
  width: _width
})

export const updateBlock = (_id, _title) => ({
  type: ActionTypes.UPDATE_BLOCK,
  id: _id,
  title: _title,
})

export const removeTab = (_id) => ({
  type: ActionTypes.REMOVE_TAB,
  id: _id,
})


export const setActiveTab = (_id) => ({
  type: ActionTypes.SET_ACTIVE_TAB,
  id: _id,
})

export const swapTabs = (_tabID1, _tabID2) => ({
  type: ActionTypes.SWAP_TABS,
  tabID1: _tabID1,
  tabID2: _tabID2,
})

export const DISPLAY_MODES = {
  DEFAULT: 'DEFAULT',
  BLOCK: 'BLOCK',
  COLUMN: 'COLUMN',
  WIDGET: 'WIDGET',
  TAB_FORM: 'TAB_FORM',
  TABS: 'TABS',
  NEW_PAGE: 'NEW_PAGE',
  EDIT_COLUMN_PROPS: 'EDIT_COLUMN_PROPS',
  BLOCK_REORDER: 'BLOCK_REORDER',
  INVENTORY: 'INVENTORY',
  NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
  RESERVE_DEMO: 'RESERVE_DEMO',
  REQUEST_REPAIR: 'REQUEST_REPAIR',
}

export const displayMode = (_mode) => ({
  type: ActionTypes.DISPLAY_MODE,
  display_mode: _mode,
})

export const setActiveColumn = (_columnId) => ({
  type: ActionTypes.SET_ACTIVE_COLUMN,
  column_id: _columnId,
})

export const setActiveBlock = (_blockId) => ({
  type: ActionTypes.SET_ACTIVE_BLOCK,
  block_id: _blockId,
})

export const setActivePosition = (_position) => ({
  type: ActionTypes.SET_ACTIVE_POSITION,
  position: _position,
})

export const setIframePointerOptions = (_option) => ({
  type: ActionTypes.SET_IFRAME_POINTER_OPTIONS,
  option: _option,
})

export const setDraggebleElement = (_element) => ({
  type: ActionTypes.SET_DRAGGABLE_ELEMENT,
  element: _element,
})

export const setDraggedOverElement = (_element) => ({
  type: ActionTypes.SET_DRAGGED_OVER_ELEMENT,
  element: _element,
})

export const setDraggedOverPosition = _position => ({
  type: ActionTypes.SET_DRAGGED_OVER_POSITION,
  position: _position,
})

export const setIframeFormState = (_status) => ({
  type: ActionTypes.SET_IFRAME_FORM_STATE,
  status: _status,
})

export const addNewWidget = (_widget) => ({
  type: ActionTypes.ADD_NEW_WIDGET,
  widget: _widget,
})


let blockID = -1000
let blockIndex = 0
export const setBlockIndex = _index => blockIndex = _index
export const createBlock = (_tabId, _title, _layout) => ({
  type: ActionTypes.CREATE_BLOCK,
  id: blockID++,
  tabId: _tabId,
  title: _title,
  layout: _layout,
  index: ++blockIndex,
})

export const editBlock = (_id) => ({
  type: ActionTypes.EDIT_BLOCK,
  id: _id,
})

export const removeBlock = (_id) => ({
  type: ActionTypes.REMOVE_BLOCK,
  id: _id,
})

export const reorderBlocks = _blocks => ({
  type: ActionTypes.BLOCK_REORDER,
  blocks: _blocks,
})

let columnID = -1000
let columnINDEX = 0
export const setColumnIndex = _index => columnINDEX = _index
export const createColumn = (_blockId, _widgetId, _position, _title, _viewMore, _options) => ({
  type: ActionTypes.CREATE_COLUMN,
  id: columnID++,
  blockId: _blockId,
  widget_id: _widgetId,
  position: _position,
  index: ++columnINDEX,
  title: _title,
  viewMore: _viewMore,
  options: _options
})

export const updateColumn = (_id, _title, _viewMore, _widgetId, _options) => ({
  type: ActionTypes.UPDATE_COLUMN,
  id: _id,
  title: _title,
  viewMore: _viewMore,
  widget_id: _widgetId,
  options: _options
})

export const resizeColumn = (_id, _height) => ({
  type: ActionTypes.RESIZE_COLUMN,
  id: _id,
  height: _height,
})

export const swapColumns = (_colID1, _colID2, _position) => ({
  type: ActionTypes.SWAP_COLUMNS,
  colID1: _colID1,
  colID2: _colID2,
  position: _position,
})

export const removeColumn = (_id) => ({
  type: ActionTypes.REMOVE_COLUMN,
  id: _id,
})

export const setColumnDisplay = (_id, _display) => ({
  type: ActionTypes.COLUMN_DISPLAY,
  id: _id,
  display: _display,
})

export const columnChangePosition = (_id, _position) => ({
  type: ActionTypes.COLUMN_CHANGE_POSITION,
  id: _id,
  position: _position,
})

//collage page
export const updateCollageItems = (colID, items) => ({
  type: ActionTypes.UPDATE_COLLAGE_ITEMS,
  column_id: colID,
  items: items
})

export const viewInventory = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/api/web/view_inventory"
  const params = {customer_id: state.filters.customer_id, member_id: state.filters.member_id}
  if(state.filters.inventory.length == 0){
    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.VIEW_INVENTORY,
          data: response,
        })
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
  else
    dispatch({
      type: ActionTypes.VIEW_INVENTORY,
      data: { inventory: state.filters.inventory,
              inventory_select: state.filters.inventory_select,
              demo_page: 'inventory'},
    })
}

export const chooseNotification = (id) => (dispatch) => {
  dispatch({
    type: ActionTypes.CHOOSE_NOTIFICATION,
    data: {selected_club_id: id},
  })
  dispatch(displayMode(DISPLAY_MODES.NOTIFICATION_TYPE))
}

export const saveGeneralSettings = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/customers/"+state.filters.customer_id+"/pages/save_general_settings"
  const params = {customer_id: state.filters.customer_id, multi_widgets_state: data}
  
  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      window.location.href = response['redirect_url']
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
  

}

export const addToWaitingList = (notification_type) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/customers/"+state.filters.customer_id+"/club_trackers/"+state.filters.selected_club_id+"/add_to_waiting_list"
  const params = {member_id: state.filters.member_id, notification_type: notification_type}

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.ADD_TO_WAITING_LIST,
        data: response,
      })
      $('#popup-element').modal('hide')
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const getReserveDemo = (id) => (dispatch, getState) => {
  const state = getState()

  if(state.filters.inventory.length == 0){
    const dataURL = "/api/web/view_inventory"
    const params = {customer_id: state.filters.customer_id, member_id: state.filters.member_id, selected_club_id: id || state.filters.selected_club_id}

    callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.GET_RESERVE_DEMO,
          data: response,
        })
        dispatch(displayMode(DISPLAY_MODES.RESERVE_DEMO))
      })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
  else
    dispatch({
      type: ActionTypes.GET_RESERVE_DEMO,
      data: {selected_club_id: id, inventory: state.filters.inventory, inventory_select: state.filters.inventory_select},
    })
    dispatch(displayMode(DISPLAY_MODES.RESERVE_DEMO))
}

export const reserveDemo = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/customers/"+state.filters.customer_id+"/demo_trackers/reserve"

  callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.RESERVE_DEMO,
        data: response,
      })
      $('#popup-element').modal('hide')
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const requestRepair = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/customers/"+state.filters.customer_id+"/club_repairs/request_repair"

  callAPI(dataURL, 'POST', data, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.REQUEST_REPAIR,
        data: response,
      })
      $('#popup-element').modal('hide')
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const updateMemberProfile = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/customers/"+state.filters.customer_id+"/members/"+state.filters.member_id+"/update_member_profile"
  const params = {data_to_update: data}

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.UPDATE_MEMBER_PROFILE,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const login = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = "/website/"+state.general_settings.website_namespace
  const params =  data
  window.history.pushState(window.location.href, 'Title', window.location.href.split('?')[0]);
  $('.bootstrap_spinner').css('display', 'block');
  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      if(response.infoMessage)
      {
        $('.bootstrap_spinner').css('display', 'none');
        dispatch({
          type: ActionTypes.UPDATE_INFO_MESSSAGE,
          data: response,
        })
      }
      else
        window.location.reload();
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const removeInfoMessage = () => (dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_INFO_MESSSAGE,
      data: {infoMessage: null},
    })
}

export const uploadFile = (data) => (dispatch, getState) => {
  const state = getState()
  const dataURL = `/customers/${state.general_settings.customer_id}/pages/upload_image`
  if(data)
  {
    document.getElementById("upload_spinner").classList.remove("d-none");
    return new Promise((resolve, reject) => {
      fetch(
        dataURL,
        {
          method: 'POST',
          body: data,
        }
      )
      .then(response => Promise.all([ response, response.json() ]))
      .then(([ response, json ]) => {
        if (response.status === 200 && _.isEmpty(json.errorMessage)) {
          resolve(json)
        } else {
          reject(json.errorMessage, json)
        }
      })
      .catch(() => {
        reject()
      })
    }).then(response => {
      if (response["type"] == "portal_banner")
      {
        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "banner_url",
          value: response["url"]
        })

        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "banner_attachment_id",
          value: response["id"]
        })

        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "banner_type",
          value: "custom"
        })
      }
      else
      {
        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "background_image_url",
          value: response["url"]
        })

        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "background_image_attachment_id",
          value: response["id"]
        })

        dispatch({
          type: ActionTypes.SET_SETTINGS_ATTRIBUTE,
          attribute: "backround_type",
          value: "image"
        })
      }
      document.getElementById("upload_spinner").classList.add("d-none");
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
  }
}
