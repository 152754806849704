import { connect } from 'react-redux'
import MatchingFieldsPage from '../components/MatchingFieldsPage'
import { saveFieldsConfiguration } from '../actions'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  saveFieldsConfiguration: () => dispatch(saveFieldsConfiguration()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchingFieldsPage)
