import { connect } from 'react-redux'
import { closeAddTabModal, saveNewTab } from '../actions'
import AddTabModal from '../components/AddTabModal'

const mapStateToProps = (state) => ({
  customer_id: state.profile_setup.customer_id,
  all_tabs: state.profile_setup.all_tabs,
  modal_add_tab_open: state.profile_setup.modal_add_tab_open,
  hidden_tabs: state.profile_setup.hidden_tabs
})

const mapDispatchToProps = (dispatch) => ({
  closeAddTabModal: () => dispatch(closeAddTabModal()),
  saveNewTab: (title, all_tabs, profile_category_id, hidden_tabs) => dispatch(saveNewTab(title, all_tabs, profile_category_id, hidden_tabs))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTabModal)