import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function DashboardAccordion(props) {

  const {items, id} = props;

  return (
    <Accordion defaultActiveKey={id}>
      {
        items.map((item, index) => (
          <Accordion.Item eventKey={index} key={index} className='mt-2 border-top rounded-0'>
            <Accordion.Header className={item.header.classes}>
              <div className='w-100'>
                {item.header.content}
                <i className='fa fa-caret-down float-end color_black'/>
                <i className='fa fa-caret-up float-end color_black'/>
              </div>
              
              
            </Accordion.Header>
            <Accordion.Body className={item.body.classes} style={{backgroundColor:"#ebebeb"}}>
              <div className='row m-0 width_100 overflow-auto' style={{maxHeight: "600px"}}>
                {item.body.content}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
     
    </Accordion>
  )
}

export default DashboardAccordion
