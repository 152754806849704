import React, { useState, useRef } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../stock_orders/components/Modal';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import View from 'react'

function EditValuesModal(props) {
  const [values, setValues] = useState(props.edited_metadatum.possible_values || ['']);
  const [valuesType, setValuesType] = useState(props.edited_metadatum.values_type);

  const handleClose = () => {
    props.closeEditValuesModal()
  }

  const handleSubmit = () => {
    let filteredValues = values.filter(value => value != '')

    if (valuesType !== "free_text" && filteredValues.length == 0){
      alert("Custom field must have at least one default option filled.")
    }
    else{
      props.setMetadatumValues(props.edited_metadatum.metadatum_id, valuesType, valuesType === "free_text" ? null : values.filter(value => value !== ''))
      handleClose()
    }
  }

  const handleValueChange = (index, value) => {
    let newValues = [...values]
    newValues[index] = value
    setValues(newValues)
  }

  const addNewOption = () => {
    setValues(oldArray => [...oldArray, ""]);
  }

  const removeOption = (index) => {
    let newValues = [...values]
    newValues.splice(index, 1)
    setValues(newValues)
  }

  return (props.modal_edit_values_open && <div id="edit_values_modal">
    <Modal isOpen={props.modal_edit_values_open} scrollable={true} className="edit_values_modal_container" dialogClassName="modal-dialog-centered">
      <ModalHeader className="header">
        <h4 className="modal-title form_new_edit m-0">Edit Field Values</h4>
      </ModalHeader>
      <ModalBody className="">
        <Form>
          <div className="row mt-3">
            <div className="col inner-content">
              <Form.Group as={Row} className="mb-3" controlId="formTabForm">
                <Row>
                  <div className="row">
                    <div className="col-12 ms-2 bold">Specify Field Type:</div>
                  </div>
                </Row>
                <Row>
                  <Col sm="4" className="form-check mt-2">
                    <label className="col-form-label ps-2 cursor_pointer" for="free_text_input">Free Text</label>
                    <input type="radio"
                      id="free_text_input"
                      name="free_text"
                      className="form-check-input mt-2"
                      checked={valuesType == "free_text"}
                      value="Free Text"
                      onClick={() => setValuesType("free_text")}
                    />
                  </Col>
                  <Col sm="4" className="form-check mt-2">
                    <label className="col-form-label ps-2 cursor_pointer" for="multiple_choice_input">Multiple Choice</label>
                    <input type="radio"
                      id="multiple_choice_input"
                      name="multiple_choice"
                      className="form-check-input mt-2"
                      checked={valuesType == "multiple_choice"}
                      onClick={() => setValuesType("multiple_choice")}
                    />
                  </Col>
                  <Col sm="4" className="form-check mt-2">
                    <label className="col-form-label ps-2 cursor_pointer" for="multiple_answers_input">Multiple Answers</label>
                    <input type="radio"
                      id="multiple_answers_input"
                      name="multiple_answers"
                      className="form-check-input mt-2"
                      checked={valuesType == "multiple_answers"}
                      onClick={() => setValuesType("multiple_answers")}
                    />
                  </Col>
              </Row>
              <Col>
                <Row>
                  <Col sm="12">
                    {valuesType !== "free_text" && values.map((value, index) => 
                      <Row className="mt-2 option_container">
                        <Col sm="3" className="mt-2 ps-4 option_label_container">
                          Option {index + 1}
                        </Col>
                        <Col sm="6">
                            <Row>
                              <Col sm="9" className="option_input_container">
                                <input type="text"
                                placeholder=""
                                className="form-control p-1"
                                value={values[index]}
                                onInput={ e => handleValueChange(index, e.target.value) }
                                />
                              </Col>
                              <Col sm="3" className="delete_option_button">
                                <div onClick={() => removeOption(index)} className="cursor_pointer color_red mt-2 font_size_large fa fa-trash"/>
                              </Col>
                            </Row>
                        </Col>
                      </Row>
                    )}
                    {valuesType !== "free_text" && <Row className="new_option_container">
                      <Col sm="3" className="new_option_label pe-0 me-0 color_green bold cursor_pointer" onClick={() => addNewOption()} >
                        New Option
                      </Col>
                      <Col sm="3" className="new_option_button" style={{width: "auto", paddingLeft: "6px"}}>
                        <div onClick={() => addNewOption()} 
                        className="w-50 add_default_option add_padding_bottom_10 color_green cursor_pointer dropdown_elements fa fa-plus-circle"
                        style={{fontSize: "14px", marginTop: "18px"}}
                        />
                      </Col>
                    </Row>}
                  </Col>
                </Row>
              </Col>
              </Form.Group>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start ms-3 btn gems_custom_button"
          style={{marginRight: "auto"}}
          aria-label="Cancel"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="btn gems_custom_button gems_orange_button me-3 float-end"
          onClick={handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  </div>);
} export default EditValuesModal