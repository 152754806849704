import { connect } from 'react-redux'
import Footer from '../components/Footer'
import {newStockOrder, uploadStockOrder} from '../actions'

const mapStateToProps = (state) => ({
  view_only_stock_orders: state.stock_order.view_only_stock_orders,
  can_place_stock_orders: state.stock_order.can_place_stock_orders
})

const mapDispatchToProps = (dispatch) => ({
  newStockOrder: () => dispatch(newStockOrder()),
  uploadStockOrder: () => dispatch(uploadStockOrder()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
