import { connect } from 'react-redux'
import EditColumns from '../presentational/edit_columns'
import {
  createColumn,
  updateColumn,
  setActiveBlock,
  setActivePosition,
  displayMode,
  DISPLAY_MODES,
  setActiveColumn,
} from '../../actions'
import _ from "lodash"

const mapStateToProps = state => ({
  id: state.filters.active_column,
  column: _.find(state.columns, (col) => col.id == state.filters.active_column ),
  widgets: state.filters.widgets,
  blockId: state.filters.active_block || _.find(state.blocks, (blk) => blk.id == _.find(state.columns, (col) => col.id === state.filters.active_column).blockId ).id,
  position: state.filters.position,
  onlineForms: state.filters.onlineForms,
  managers: state.filters.managers,
  member_profile_data: state.filters.member_profile_data,
  demo_return_interval: state.general_settings.demo_return_interval,
  demo_clubs_settings_url: state.general_settings.demo_clubs_settings_url,
  schemaColor: state.general_settings.color_schema,
  // column: state.columns.find(column => column.id === state.filters.active_column),
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(setActiveColumn(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  createColumn: (_blockID, _url, _position, _title, _viewMore, _options) => {
    dispatch(createColumn(_blockID, _url, _position, _title, _viewMore, _options))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(setActiveColumn(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  updateColumn: (_id, _title, _viewMore, _widgetId, _options) => {
    dispatch(updateColumn(_id, _title, _viewMore, _widgetId, _options))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(setActiveColumn(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  addNewPage: () => { dispatch(displayMode(DISPLAY_MODES.NEW_PAGE)) },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditColumns)
