import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col, Table } from 'react-bootstrap';
import {useState} from 'react';
import Summary from './Summary';

function FinalizeStockOrderModal(props) {

  const initialState = props.item

  const [state, setState] = useState(initialState);

  const handleClose = () => {
    props.closeFinalizeModal()
  }
  
  const handleSubmit = () => {
    let params = {...state}

    params["action_type"] = "delivered"

    props.updateStockOrder(params)
    handleClose()
  }


  const handleCheckboxInputChange = (event) => {
    if(event.target.name == "vendor_invoice_received")
      setState({...state, [event.target.name]: event.target.checked, ["vendor_invoice_number"]: "" });
    else
      setState({...state, [event.target.name]: event.target.checked });
  }

  const handleInputChange = (event) => {
    if(event.target.getAttribute("assigned") == "remove_file")
      setState({...state, [event.target.name]: "remove" });
    else
      setState({...state, [event.target.name]: event.target.value });
  }
  
  const {modal_finalize_open, customer_id} = props;
  const {received_into_inventory, vendor_invoice_received, invoice_processed, shipping_cost, vendor_invoice_number} = state;
  const {files} = props.item

  return (
    <div className="modal_finalize_stock_order_container">
      <Modal isOpen={modal_finalize_open}>
        <ModalHeader >
          <h4 className="modal-title form_new_edit no-margin">Finalize Stock Order</h4>
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="row">
              <div className="col">
                {props.finalized_fields.vendor_invoice_received && <Form.Group as={Row} className="mb-3" controlId="formVendorInvoiceReceived">
                  <Form.Check 
                    name="vendor_invoice_received"
                    value = {vendor_invoice_received || false}
                    defaultChecked = {vendor_invoice_received || false}
                    type='checkbox'
                    id="vendor_invoice_received"
                    label="Vendor Invoice Received"
                    className="col"
                    onChange={handleCheckboxInputChange} 
                  />
                </Form.Group>}
                {props.finalized_fields.vendor_invoice_received && vendor_invoice_received && <Form.Group as={Row} className="" controlId="formInvoiceNumber">
                  <Form.Label column sm="4">
                      Vendor Invoice Number
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control type="text" 
                                    name='vendor_invoice_number'
                                    value={vendor_invoice_number || ""}
                                    onChange={handleInputChange} 
                                    />
                    </Col>
                </Form.Group>}
                {props.finalized_fields.received_into_inventory && <Form.Group as={Row} className="" controlId="formReceivedIntoInventory">
                  <Form.Check 
                    name="received_into_inventory"
                    value = {received_into_inventory || false}
                    defaultChecked = {received_into_inventory || false}
                    type='checkbox'
                    id="received_into_inventory"
                    label="Received into Inventory"
                    className="col"
                    onChange={handleCheckboxInputChange} 
                  />
                </Form.Group>}
                {props.finalized_fields.invoice_processed && <Form.Group as={Row} className="mb-3" controlId="formInvoiceProcessed">
                  <Form.Check 
                    name="invoice_processed"
                    value = {invoice_processed || false}
                    defaultChecked = {invoice_processed || false}
                    type='checkbox'
                    id="invoice_processed"
                    label="Invoice Processed"
                    className="col"
                    onChange={handleCheckboxInputChange} 
                  />
                </Form.Group>}
                <Form.Group as={Row} className="" controlId="formShippingCost">
                  <Form.Label column sm="4">
                      Total Shipping Cost
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control type="text" 
                                    name='shipping_cost'
                                    value={shipping_cost || ""}
                                    onChange={handleInputChange} 
                                    />
                    </Col>
                </Form.Group> 
              </div>
              <div className="col">
                <Summary files={files}
                          handleInputChange = {handleInputChange}
                          uploadFile = {props.uploadFile}
                          customer_id = {customer_id}
                          state = {state}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
        <button
            className="float-start btn gems_custom_button "
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Finalize Stock Order
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default FinalizeStockOrderModal