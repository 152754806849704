import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import {useState, useEffect} from 'react';
import _ from 'lodash';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import AlertMessage from './alert';
function LoginForm (props) {
  const [state, setState] = useState({login_action: true});

  const handleLogin = () => {
    let params = {...state}
    props.removeInfoMessage()
    props.login(params)
  }

  const handleInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.value });
  }

  return (  
        ["ce_integration", "jonas_integration"].includes(props.authentication_type) ?
          <div className="row btn-container text-center py-3 fs-5 mb-3" 
            style = { { color: getComplementaryColor((props.backgroundColor)) }}>
            <div className='col-12 text-center mb-5'>
            We're sorry, we do not have the proper information on file for you to show this page.
            </div>
            <div className='col-12 text-center'>
              Please contact customer support at <a className="link" target="_blank" href={ props.website }>{ props.website }</a>
            </div>
          </div>
        :
        <>

          <Col xs={12} className="btn-container text-center fs-5 mb-3 py-3"
          style = { { color: getComplementaryColor((props.backgroundColor))}}>
            <span> Please enter your details in order to log in </span>
          </Col>
          {props.infoMessage && <AlertMessage message={props.infoMessage.message}/>}
          <Col xs={6} className="btn-container mx-auto mb-3">
            {(props.general_settings.member_authentication_data).map((field, index) => 
                field.selected && 
                <div className="row my-1 ps-3 mb-2" key={`${field.key}_${index}`}>
                  <div className="col ps-0 pe-1 form-floating">
                    <input type="text_field" name={`auth[${field.key}]`} className="form-control form-control-sm" autoComplete="off" onChange={handleInputChange}/>
                    <label htmlFor={field.key}>{_.startCase(_.toLower((field.name)))}</label>
                  </div>
                </div>
                
              )}
          </Col>

          <Col xs={12} className="btn-container text-center py-3 fs-5 mb-3">
          <a onClick={handleLogin} className="btn gems_custom_button text-center"
          style={{backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor))}}
          >Login</a>
          </Col>
        </>
  )
}

export default LoginForm
