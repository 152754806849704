import { connect } from 'react-redux'
import CalendarBody from '../components/CalendarBody'
import {openCommunicationTemplateModal} from '../actions'

const mapStateToProps = (state) => ({
  communication_history_list: state.communication_center.communication_history_list,
  date_format: state.communication_center.date_format
})

const mapDispatchToProps = (dispatch) => ({
  openCommunicationModal: (id, type) => dispatch(openCommunicationTemplateModal(id, type))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarBody)