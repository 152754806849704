import React from 'react'
import {useState, useEffect} from 'react';
import CartTableContainer from '../containers/CartTableContainer';
import CartListContainer from "../containers/CartListContainer";
import {validateEmail} from "../../shared/helpers";
import DropShipContainer from "../containers/DropShipContainer";
import {getShippingAddressErrorText} from "../actions";


function CartOnlineOrder (props){
  const {
    orderSubmitted,
    fontColor,
    genericForm,
    customer,
    currencySymbol,
    isCartView,
    itemsInCart,
    formType,
    dropShipType,
    dropShipEnabled,
    selectedDropShip,
    dropShipError,
    guestForm,
    memberForm,
    totalCost,
    maxCredits,
    preview,
    cartErrorMessage,
    isEnoughStockForOrders,
    checkoutItemsLimit,
    editMode,
    links,

    changeCartView,
    changeCustomerAuthType,
    loginMember,
    changeGenericMember,
    checkCustomerDetails,
    checkStockAndSubmitOrder,
    openErrorModal,
    setCartErrorMessage,
    changeDropShipErrorMessage,
  } = props;

  const initialState = {
    memberAuthFields: {},
    guestAuthFields: {},
  }

  useEffect(() => {
    setState({...state})
  }, []);

  const [state, setState] = useState(initialState);

  const handleHideCartView = () => {
    changeCartView(!isCartView)
  }

  const handleChangeGenericCustomerTypeRadioButton = (event) => {
    let type = event.target.id.split('_')[0]
    changeCustomerAuthType(type)
    if (type == 'guest')
      setState({...state, memberAuthFields: {}})
    else
      setState({...state, guestAuthFields: {}})
  }

  const handleAuthFieldsChange = (event, field_id, type) => {
    let memberFields = state.memberAuthFields
    let guestFields = state.guestAuthFields
    if (type == 'member') {
      memberFields[field_id] = event.target.value
      guestFields = {}
    } else {
      memberFields = {}
      guestFields[field_id] = event.target.value

      if (field_id == 'email' && !validateEmail(event.target.value)) {
        setCartErrorMessage('Please enter a valid email address!')
      } else {
        setCartErrorMessage(null)
      }

    }

    setState({...state, memberAuthFields: memberFields, guestAuthFields: guestFields})
  }

  const handleGenericLoginMember = () => {
    let customer_check_result = checkCustomerDetails(state.memberAuthFields, state.guestAuthFields)
    if (customer_check_result.status)
      loginMember(state.memberAuthFields)
    else
      setCartErrorMessage(customer_check_result.error)
    setState({...state, guestAuthFields: {}})
  }

  const handleChangeGenericMember = () => {
    changeGenericMember()
    setState({...state, memberAuthFields: {}, guestAuthFields: {}})
  }

  const handleSubmitOrder = () => {
    let error_message;
    let customer_check_result;

    if (itemsInCart == 0) {
      error_message = "Cart can't be empty! Please select at least one item before submitting the order!"
      setCartErrorMessage(error_message)
      return
    }

    customer_check_result = checkCustomerDetails(state.memberAuthFields, state.guestAuthFields)
    if (customer_check_result.status) {
     checkStockAndSubmitOrder(state.guestAuthFields)

    } else {
      setCartErrorMessage(customer_check_result.error)
    }
  }

  const isCustomerPresent = () => {
    var customer_present = false

    if (genericForm) {
      if (customer.authentication_customer_type == 'guest' && state.guestAuthFields["email"] && state.guestAuthFields["first_name"] && state.guestAuthFields["last_name"]) {
        customer_present = true
      } else if (customer.authentication_customer_type == 'member' && customer.customer_details && customer.customer_details["id"]) {
        customer_present = true
      }
    } else if (customer.customer_details["id"]) {
      customer_present = true
    }

    return customer_present
  }

  function shouldDisableSubmitButton() {
    let should_check_drop_ship = dropShipType !== 'ship_to_club' && genericForm && !customer.authentication_customer_type ? false : true

    if (should_check_drop_ship) {
      let is_drop_ship_filled_in = false
      let guestShippingAddressErrorText = ''
      if (genericForm && customer.authentication_customer_type === 'guest') {
        guestShippingAddressErrorText = getShippingAddressErrorText([{id: 'line_1', label: "Address Line 1"}, {id: 'city', label: "City"}, {id: 'state', label: "State"}, {id: 'zip', label: "Zip Code"}].reduce((array, field, index) => {
          array.push({label: field.label, value: ((selectedDropShip.address_fields || {})[field.id] || {}).value})
          return array;
        }, []))

        if (_.isEmpty(guestShippingAddressErrorText))
          is_drop_ship_filled_in = true
      } else {
        if (!_.isEmpty(selectedDropShip))
          is_drop_ship_filled_in = true
      }

      if ((dropShipType === 'required' || customer.drop_ship_enabled) && !is_drop_ship_filled_in) {
        let drop_ship_error_message = ''
        if ((genericForm && customer.authentication_customer_type === 'guest') || (customer.customer_type === 'player' && customer.player && !customer.player.member_id))
          drop_ship_error_message = `${dropShipType === 'required' ? 'Drop ship is required! The following fields are mandatory: ' : "You have selected shipping option for this order! The following fields are mandatory: "}${guestShippingAddressErrorText}`
        else
          drop_ship_error_message = `${dropShipType === 'required' ? 'Drop ship is required! Please select an address!' : "You have selected shipping option for this order. Please select an address!"}`
        if (drop_ship_error_message !== dropShipError)
          changeDropShipErrorMessage(drop_ship_error_message)
        return true
      } else {
        if (!_.isEmpty(dropShipError))
          changeDropShipErrorMessage('')
      }
    }


    return !(!cartErrorMessage && isCustomerPresent() && itemsInCart > 0 && isEnoughStockForOrders && (checkoutItemsLimit === 0 || checkoutItemsLimit !== 0 && itemsInCart <= checkoutItemsLimit))
  }

  return (
    <>
      <div className={`w-100`} id='cart-container'>
        {/* Cart view */}
        {
          isCartView && <>

            <div id='cart-table-container' className='desktop_view'>
              {checkoutItemsLimit !== 0 && itemsInCart > checkoutItemsLimit &&
                <div className='cart-customer-credentials-error' id='cart-products-error'>
                  You cannot order more than {checkoutItemsLimit} items.
                </div>
              }

              <div className='table-responsive'>
                <CartTableContainer/>
              </div>
            </div>

            <div id='cart-list-container' className='mobile_view'>
              {checkoutItemsLimit !== 0 && itemsInCart > checkoutItemsLimit &&
                <div className='cart-customer-credentials-error' id='cart-products-error'>
                  You cannot order more than {checkoutItemsLimit} items.
                </div>
              }
              <CartListContainer/>
            </div>


            <div id='cart-right-side'>
              <div id='cart-customer-credentials'>
                {customer.customer_details && <div className=''>
                  <div className='d-flex align-items-center' style={{gap: '10px'}}>
                    <span className='bold'
                          style={{color: `${fontColor}`}}>Welcome, {customer.customer_details.full_name}!</span>


                    {genericForm &&
                      <button
                        type='button'
                        className='btn gems_custom_button'
                        id='btn_change_member'
                        style={{
                          width: "auto",
                          backgroundColor: "white",
                          bordercolor: `${fontColor}`,
                          color: `${fontColor}`
                        }}
                        onClick={handleChangeGenericMember}
                      >
                        Change
                      </button>
                    }
                  </div>
                </div>}

                {
                  genericForm && !customer.customer_details && <>
                    {customer.customer_type == 'members_guests' &&
                      <div className='' id='cart-customer-credentials-guest-and-member'>
                      <span className='bold'>
                        Are you a member or a guest?
                      </span>

                        <div className='flex-wrap d-flex align-items-center' style={{gap: "16px"}}
                             onChange={handleChangeGenericCustomerTypeRadioButton}>
                          <div className='form-check ps-0 d-flex align-items-center'>
                            <input
                              className='form-check-input mt-0'
                              id='guest_generic_radio'
                              type='radio'
                              name='generic_customer_type'
                              checked={customer.authentication_customer_type == 'guest'}
                            />
                            <label
                              className='ps-2'
                              for='guest_generic_radio'
                            >
                              Guest
                            </label>
                          </div>
                          <div className='form-check ps-0 d-flex align-items-center'>
                            <input
                              className='form-check-input mt-0'
                              id='member_generic_radio'
                              type='radio'
                              name='generic_customer_type'
                              checked={customer.authentication_customer_type == 'member'}
                            />
                            <label
                              className='ps-2'
                              for='member_generic_radio'
                            >
                              Member
                            </label>
                          </div>
                        </div>
                      </div>}

                    {customer.authentication_customer_type == 'guest' &&
                      <div className='' id='cart-customer-credentials-guest'>
                        <div className='w-100'>
                          <p className='fst-italic text-danger bold'>
                            Fields marked with * are required.
                          </p>
                        </div>

                        {[{label: "first_name", required: true}, {label: "last_name", required: true}, {label: "email", required: true}, {label: "cell_phone", required: false}].map((guest_field) =>
                          <div className='cart-customer-credentials-row'>
                            <span style={{textTransform: "capitalize"}}>
                              <b>{guest_field.label.replaceAll('_', ' ')}</b>
                              {guest_field.required && <span className='text-danger'>*</span>}
                            </span>

                            <input
                              type='text'
                              id={`guest_${guest_field.label}`}
                              name={`guest_${guest_field.label}`}
                              autoComplete="off"
                              className={`guest_data_field form-control ${guest_field.required ? "required_field": ""}`}
                              style={{height: "35px", textAlign: 'left'}}
                              onChange={(event) => handleAuthFieldsChange(event, guest_field.label, 'guest')}
                              value={state.guestAuthFields[guest_field.label]}
                            />
                          </div>
                        )}
                      </div>}

                    {customer.authentication_customer_type == 'member' &&
                      <div className='' id='cart-customer-credentials-member'>
                        <div className='w-100'>
                          <p className='bold'>
                            Please enter your details in order to log in
                          </p>
                        </div>

                        {customer.authentication_fields.map((field, id) =>
                          <div className='cart-customer-credentials-row'>
                            <label name={`label_${field.id}`} id={`label_${field.id}`} className='bold'>{field.name}</label>

                            <input
                              name={`${field.id}`}
                              id={`${field.id}`}
                              className='form-control'
                              autoComplete='off'
                              style={{textAlign: 'left'}}
                              onChange={(event) => handleAuthFieldsChange(event, field.id, 'member')}
                              value={state.memberAuthFields[field.id]}
                            />
                          </div>
                        )}
                      </div>}


                  </>
                }

                {cartErrorMessage &&
                  <div className='cart-customer-credentials-error'>
                    {cartErrorMessage}
                  </div>
                }

              </div>

              { dropShipType !== 'ship_to_club' && (customer.customer_details || genericForm && customer.authentication_customer_type === 'guest') &&
                <DropShipContainer/>

              }

              <div id='cart-buttons'>
                {
                  genericForm && customer.authentication_customer_type == 'member' && !customer.customer_details &&
                  <button
                    type='button'
                    className={`btn gems_custom_button text-center`}
                    onClick={handleGenericLoginMember}
                    style={{backgroundColor: `${fontColor}`, bordercolor: `${fontColor}`, color: "white"}}
                    id='generic_link_authenticate_user'
                  >
                    Log In
                  </button>
                }
              </div>

              <div className='d-flex flex-column align-items-start w-100' style={{gap: "8px"}}
                   id='cart-total-container'>
                {
                  ['credit', 'points', 'credit_and_purchase'].includes(formType) && <>
                    <div className='cart-total'>
                      <div className='cart-total-header' style={{color: `${fontColor}`}}>
                        {formType.includes('credit') ? 'Credits' : 'Points'}
                      </div>

                      <hr className='w-100'/>

                      <div className='cart-total-body'>
                        <div className='cart-total-row'>
                          <b>{formType.includes('credit') ? 'Credits' : 'Points'} Used:</b>
                          <span>
                            {formType == 'points' ?
                              Math.min(totalCost, maxCredits).toFixed(2)
                              :
                              (currencySymbol == '$' ? `${currencySymbol}${Math.min(totalCost, maxCredits).toFixed(2)}` : `${Math.min(totalCost, maxCredits).toFixed(2)}${currencySymbol}`)
                            }
                          </span>
                        </div>
                        <div className='cart-total-row'>
                          <b>Remaining {formType.includes('credit') ? 'Credits' : 'Points'}:</b>
                          <span>
                            {formType == 'points' ?
                              Math.max(0, (maxCredits - totalCost)).toFixed(2)
                              :
                              (currencySymbol == '$' ? `${currencySymbol}${Math.max(0, maxCredits - totalCost).toFixed(2)}` : `${Math.max(0, maxCredits - totalCost).toFixed(2)}${currencySymbol}`)
                            }
                          </span>
                        </div>
                      </div>

                    </div>
                  </>
                }

                {
                  formType != 'no_cost' &&
                  <>
                    <div className='cart-total'>
                      <div className='cart-total-header' style={{color: `${fontColor}`}}>
                        Order Summary
                      </div>

                      <hr className='w-100'/>

                      <div className='cart-total-body'>
                        <div className='cart-total-row'>
                          <b>Subtotal:</b>
                          <span>
                            {formType == 'points' ?
                              totalCost
                              :
                              (currencySymbol == '$' ? `${currencySymbol}${totalCost}` : `${totalCost}${currencySymbol}`)
                            }
                          </span>
                        </div>
                      </div>

                    </div>

                    <div className='cart-total' style={{backgroundColor: `${fontColor}`, color: "white"}}>
                      <div className='cart-total-row'>
                        <b>Balance Due:</b>
                        <b>
                          {formType == 'purchase' ?
                            <>
                              {currencySymbol == '$' ? `${currencySymbol}${totalCost}` : `${totalCost}${currencySymbol}`}
                            </>
                            :
                            <>
                              {
                                formType == 'credit_and_purchase' ?
                                  `${currencySymbol == '$' ? `${currencySymbol}${Math.max(totalCost - maxCredits, 0).toFixed(2)}` : `${Math.max(totalCost - maxCredits, 0).toFixed(2)}${currencySymbol}`}`
                                  :
                                  `0`
                              }
                            </>
                          }

                        </b>
                      </div>
                    </div>
                  </>
                }
              </div>

              <div id='cart-buttons'>
                <button
                  className={`btn gems_custom_button text-center`}
                  onClick={handleHideCartView}
                  style={{color: `${fontColor}`, bordercolor: `${fontColor}`}}
                  id='continue_shopping_button'
                  type='button'
                >
                  Continue Shopping
                </button>


                {!preview &&
                  <button
                    type="button"
                    className={`btn gems_custom_button text-center ${shouldDisableSubmitButton() ? 'disabled' : ''}`}
                    onClick={handleSubmitOrder}
                    id={'btn_save_online_po_form'}
                    style={{backgroundColor: `${fontColor}`, color: "white", width: "auto"}}
                    data-disable-with="Saving..."
                  >
                    {orderSubmitted ? "Update Order" : "Submit Order"}
                  </button>
                }
              </div>

              <div align='justify' className='w-100' style={{fontSize: "14px", display: 'flex', justifyContent: 'center', gap: '20px', textDecoration: 'underline'}}>
                <div>
                  <a className='section-title nav-link text-center'
                     role='tab'
                     aria-selected='false'
                     id='terms-of-service-tabs-new-tab'
                     onClick={() => { window.open(links.customer_terms_of_service_url, '_blank'); }}
                  >
                    Terms of Service
                  </a>
                </div>

                <div>
                  <a className='section-title nav-link text-center'
                     role='tab'
                     aria-selected='false'
                     id='privacy-policy-tabs-new-tab'
                     onClick={() => { window.open(links.customer_privacy_policy_url, '_blank'); }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>

              { (guestForm && customer.authentication_customer_type === 'guest' || customer.customer_details) && dropShipError && <div className='cart-customer-credentials-error'>
                {dropShipError}
              </div>}
            </div>
          </>
        }
      </div>
    </>
  )
}


export default CartOnlineOrder