
import React from 'react';
import _ from 'lodash';
import { useRef, useLayoutEffect, useEffect, useState } from "react";
import ReadMoreReadLess from '../../shared/components/ReadMoreReadLess';

function GripDropdownItem(props) {
  const {grip, display_grip_retail_price, handleGripSelection, grip_selected} = props

  return (
    <div className={`col-12 mb-2 p-2 border club_repair_grip_tile bg-white ${grip_selected && grip_selected.id == grip.id ? "selected_grip_tile" : ""}`} onClick={(e) => handleGripSelection(grip)} key={`grip_dropdown_item_${grip.id}`} style={{maxWidth: `${handleGripSelection ? "" : "500px"}`}}>
      <div className='row mx-0 mb-2'>
        <div className='col p-0 bold'>
          <span className="searchable_field" style={{whiteSpace: "normal"}}>
            {grip.name}
          </span>
        </div>
        { display_grip_retail_price && 
          <div className='col pe-0 float-end' style={{maxWidth: "fit-content"}}>
            <span className="searchable_field">
              {grip.repair_price}
            </span>
          </div>
        }
      </div>

      <div className='row mx-0'>
        <div className='col p-1 border' style={{maxWidth: "100px", alignSelf: "flex-start"}}>
          <img src={grip.imageURL} style={{width: "100%", height: "90px", alignSelf: "center", objectFit: "contain"}}/>
        </div>
        <div className='col'>
          <div className='row mx-0 mb-2'>
            <ReadMoreReadLess limit={200} text={grip.description} rows={4}/>
          </div>
        </div>
        
      </div>

    </div>
  )
} 
export default GripDropdownItem