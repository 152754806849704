import { connect } from 'react-redux'
import DashboardActions from '../components/DashboardActions'
import { changeQuickSearchValue } from '../actions'

const mapStateToProps = (state) => ({
  customer_id: state.dashboard.customer_id,
  create_email_url: state.dashboard.create_email_url,
  create_template_url: state.dashboard.create_template_url,
  single_email_url: state.dashboard.single_email_url,
  email_delivery_reports_url: state.dashboard.email_delivery_reports_url,
  quick_search_value: state.dashboard.quick_search_value
})

const mapDispatchToProps = (dispatch) => ({  
  changeQuickSearchValue: value => dispatch(changeQuickSearchValue(value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardActions)