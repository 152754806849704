import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import ProfileSetupPageContainer from './ProfileSetupPageContainer'

const ProfileSetupRoot = ({customer_id, all_tabs, view_mode, current_tab, member_metadata_values, hidden_tabs, member_info, system_fields_locked, activity_log, activity_log_entities, notification_settings, all_metadata, metadata_values, metadata_dropdown_fields, profile_picture_url, preview_member_profile, member_id, date_format, manager_edit_profile}) => {
  const state = {
    profile_setup: {
      customer_id: customer_id,
      all_tabs: all_tabs,
      view_mode: view_mode,
      current_tab: current_tab,
      member_metadata_values: member_metadata_values,
      hidden_tabs: hidden_tabs,
      member_info: member_info,
      system_fields_locked: system_fields_locked,
      activity_log: activity_log,
      activity_log_entities: activity_log_entities,
      notification_settings: notification_settings,
      all_metadata: all_metadata,
      metadata_values: metadata_values,
      metadata_dropdown_fields: metadata_dropdown_fields,
      profile_picture_url: profile_picture_url,
      preview_member_profile: preview_member_profile,
      member_id: member_id,
      date_format: date_format,
      manager_edit_profile: manager_edit_profile
    },
  }
  
  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={ store }>
      <ProfileSetupPageContainer/>
    </GlobalProvider>
  )
}

ProfileSetupRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export default ProfileSetupRoot
