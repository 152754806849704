import { ActionTypes } from '../actions'

const {
  FETCH_EMAIL_CENTER_DATA_REQUEST,
  QUICK_SEARCH,
  ENABLE_DISABLE_AUTOMATED_TEMPLATE,
  UPDATE_DEMO_REMINDER_TEMPLATE_DAYS
} = ActionTypes

const initialState = {
  create_email_url: "",
  create_template_url: "",
  single_email_url: "",
  email_delivery_reports_url: ""
}
  
const dashboard = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_EMAIL_CENTER_DATA_REQUEST:
      return {
        ...state,
        create_email_url: action.data.create_email_url,
        create_template_url: action.data.create_template_url,
        single_email_url: action.data.single_email_url,
        email_delivery_reports_url: action.data.email_delivery_reports_url
      }

    case QUICK_SEARCH:
      return {
        ...state,
        quick_search_value: action.data.quick_search_value
      }

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default dashboard