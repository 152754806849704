import { ActionTypes } from '../actions'

const {
  CHECK_MERGING_OPTIONS_REQUEST,
  OPEN_HANDLE_DUPLICATES_MODAL,
  CLOSE_HANDLE_DUPLICATES_MODAL,
  SET_LOADING_BTN_ON
} = ActionTypes

  
const merge_page = (state = {handle_duplicates_modal_open: false, loading_btn: false}, action) => {
  switch (action.type) {
    case CHECK_MERGING_OPTIONS_REQUEST:
      return {
        ...state,
        roster_duplicates_count: action.data.roster_duplicates_count,
        soft_deleted_roster_duplicates_count: action.data.soft_deleted_roster_duplicates_count,
        delete_m_count: action.data.delete_m_count,
        spreadsheet_duplicates_count: action.data.spreadsheet_duplicates_count,
        all_matched_values: action.data.all_matched_values,
        new_m_count: action.data.new_m_count,
        update_m_count: action.data.update_m_count,
        spreadsheet_duplicates_rows: action.data.spreadsheet_duplicates_rows,
        spreadsheet_duplicates_data: action.data.spreadsheet_duplicates_data,
        spreadsheet_keys: action.data.spreadsheet_keys,
        all_spreadsheet_matched_rows: action.data.all_spreadsheet_matched_rows,
        loading_btn: false,
        total_members: action.data.total_members,
        total_rows: action.data.total_rows,
        total_deleted_members: action.data.total_deleted_members,
        all_roster_duplicates_count: action.data.all_roster_duplicates_count,
        no_remove_soft_deleted_count: action.data.no_remove_soft_deleted_count,
        all_spreadsheet_duplicates_count: action.data.all_spreadsheet_duplicates_count,
        all_soft_deleted_roster_duplicates_count: action.data.all_soft_deleted_roster_duplicates_count,
        soft_deleted_after_import: action.data.soft_deleted_after_import,
        match_values_soft_deleted_ids: action.data.match_values_soft_deleted_ids,
      }
    case OPEN_HANDLE_DUPLICATES_MODAL:
      return {
        ...state,
        handle_duplicates_modal_open: true,
        loading_btn: true
        }
    case CLOSE_HANDLE_DUPLICATES_MODAL:
      return {
        ...state,
        handle_duplicates_modal_open: false,
        loading_btn: false
        }
    case SET_LOADING_BTN_ON:
      {
        return {
          ...state,
          loading_btn: true,
          }
      }
    default:
      return state
  }
}
export default merge_page
