import React, { useState, useEffect, useRef } from 'react';
import {Col, Form, Row} from "react-bootstrap";
function AutoResizeInput(props) {
  // const [inputValue, setInputValue] = useState(props.value);
  const [inputWidth, setInputWidth] = useState("10px");
  const inputRef = useRef(null);

  useEffect(() => {
    // Create a temporary div element to measure text width
    const span = document.createElement("span");
    span.style.visibility = "hidden";
    span.style.whiteSpace = "nowrap";
    span.style.font = getComputedStyle(inputRef.current).font;
    span.textContent = props.value || " ";
    document.body.appendChild(span);
    setInputWidth(`${span.offsetWidth + 10}px`); // Add some padding
    document.body.removeChild(span);
  }, [props.value]);

  const handleInputChange = (event) => {
    props.handleInputChange("name", event.target.value);
  };

  const handleKeyDown = (event) => {
    props.handleKeyDown(event);
  };

  return (
    <Form.Control
          ref={inputRef}
          type="text"
          name='name'
          value={props.value}
          contenteditable="true"
          spellcheck="false"
          placeholder={"Enter Task Name"}
          autoComplete="off"
          onChange={handleInputChange}
          style={{ width: inputWidth}}
          onKeyDown={handleKeyDown}
    />
  );
}

export default AutoResizeInput;
