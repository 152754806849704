import { connect } from 'react-redux'
import ClubRepairsTile from '../presentational/club_repairs_tile'
import { setActiveColumn } from '../../actions'
import { displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = (state, props) => ({
  colId: props.colId,
  member_id: state.filters.member_id,
  enable_club_repair_request: props.options.enable_club_repair_request,
  data: {
      current_clubs: state.filters.clubRepairs.current_clubs,
      requested_repairs: state.filters.clubRepairs.requested_repairs,
      past_clubs: state.filters.clubRepairs.past_clubs,
      },
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

const mapDispatchToProps = (dispatch) => ({
  setActiveColumn: (id) => dispatch(setActiveColumn(id)),
  getRequestRepair: () => dispatch(displayMode(DISPLAY_MODES.REQUEST_REPAIR))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubRepairsTile)