import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

const EditTabs = ({ dispatch, tabs, schemaColor }) => {
  return (
    <div>
      <button type="button"
              onClick={(e) => { e.preventDefault(); dispatch(displayMode(DISPLAY_MODES.TABS)) } }
              className="manage-tabs-btn btn gems_custom_button me-1"
              style={ { backgroundColor: schemaColor, color: getComplementaryColor((schemaColor)) }}
              disabled={ tabs.length === 1 && tabs[0].title === '' }>
        { window.I18n.t('multi_widgets.manage_tabs') }
      </button>
    </div>
  )
}

const mapStateToProps = state => ({
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

EditTabs.propTypes = {
  dispatch: PropTypes.func,
  tabs: PropTypes.array,
}

export default connect(mapStateToProps)(EditTabs)
