import React from 'react';

function Header(props) {
  return (
    <div className="row custom_name border-bottom border-dark">
      <div className='col-12 p-0'>
        <h5 className="page-title ms-0">
          {" CALLAWAY CONNECT "}
          <span className="icon-record"/>
          {` ${props.action}`}
        </h5>
      </div>
    </div>
    )}
export default Header
