import React from 'react';
import { Col, Row } from 'react-bootstrap';

function BulkTextMessageDetails(props) {

  const {template} = props;

  return (
    <div>
      <Row className='mb-3'>
        {template.scheduled_communication && template.paused && <Col xs={12}>
          <b className="color_red">Text message is paused</b>
        </Col>}
        {!template.is_draft &&  <Col xs={12} >
          <b>{template.scheduled_communication ? "Number of text messages scheduled: " : "Number of text messages sent: "}</b>
          {template.text_messages_sent}
        </Col>}
        <Col xs={12} >
          <b>Recipients: </b>
          {template.recipients}
        </Col>

        {!template.is_draft &&  <Col xs={12} >
          <b>{template.scheduled_communication ? "Scheduled time: " : "Sent time: "}</b>
          <span> {template.nice_format_date} </span>
          <span className='icon-record'/>
          <span> {template.nice_format_time} </span>
          <span>{template.time_zone}</span>
          
        </Col>}

        <Col xs={12}>
          <hr/>
        </Col>
      </Row>
    </div>
  )
}

export default BulkTextMessageDetails