import { connect } from 'react-redux'
import { fetchEmailCenterData } from '../actions'
import Dashboard from '../components/Dashboard'

// const mapStateToProps = (state) => ({
// })

const mapDispatchToProps = (dispatch) => ({
  fetchEmailCenterData: () => dispatch(fetchEmailCenterData())
})

export default connect(
  null,
  mapDispatchToProps
)(Dashboard)
