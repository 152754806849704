import { ActionTypes } from '../actions'

const {
  FETCH_EMAIL_CENTER_DATA_REQUEST,
  COMMUNICATION_HISTORY_PAGINATE,
  OPEN_COMMUNICATION_TEMPLATE_MODAL,
  CLOSE_COMMUNICATION_TEMPLATE_MODAL,
} = ActionTypes

const initialState = {
  emails: [],
  isLoading: true,
  modal_show_communication_history_open: false,
  template_to_display: null,
  sent_emails_total_pages: null
}
  
const sent_emails_history = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_CENTER_DATA_REQUEST:
      return {
        ...state,
        emails: action.data.sent_emails_history,
        isLoading: false,
        sent_emails_total_pages: action.data.sent_emails_total_pages
      }

    case COMMUNICATION_HISTORY_PAGINATE:
      const emails_history_list = [...state.emails]
      const paginated_emails_history = emails_history_list.concat(action.data)
      const page_count = action.data.length > 0 ? state.page + 1 : state.page
      return {
        ...state,
        emails: paginated_emails_history,
        page: page_count,
      }

    case OPEN_COMMUNICATION_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: action.data.template,
        modal_show_communication_history_open: true
      }

    case CLOSE_COMMUNICATION_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: null,
        modal_show_communication_history_open: false
      }

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default sent_emails_history