// import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import { MdCallSplit } from 'react-icons/md'
import { FcCancel } from 'react-icons/fc'

import { add, startOf } from 'react-big-calendar/lib/utils/dates'
import { navigate } from 'react-big-calendar/lib/utils/constants'

function createCalendar(currentDate) {
  if (!currentDate) {
    currentDate = moment()
  } else {
    currentDate = moment(currentDate)
  }

  const first = currentDate.clone().startOf('month')
  const last = currentDate.clone().endOf('month')
  const weeksCount = Math.ceil((first.day() + last.date()) / 7)
  const calendar = Object.assign([], { currentDate, first, last })

  for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
    const week = []
    calendar.push(week)
    calendar.year = currentDate.year()
    calendar.month = currentDate.month()

    for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
      const date = currentDate.clone().set('date', day + 1 - first.day())
      date.calendar = calendar
      week.push(date)
    }
  }

  return calendar
}

function CalendarDate(props) {
  const edit = (event) => {props.editStockOrder(event.currentTarget.id)};
  const delete_action = (event) => {props.deleteStockOrder(event.currentTarget.id)};
  const openCancelStockOrder = (event) => {props.openCancelStockOrder(event.currentTarget.id)};
  
  const nextAction = (event) => {
    alert(event.target.getAttribute("position"))
    switch (event.target.getAttribute("position")) {
      case "orders_to_placed":
        return props.openPlaceStockOrder(event.currentTarget.id)
      case "orders_placed":
        return props.openReceiveStockOrder(event.currentTarget.id)
      case "orders_delivered":
        return props.openFinalizeStockOrder(event.currentTarget.id)
      default:
        return "#"
    }
    
  
  }
  const openSplitStockOrder = (event) => {props.openSplitStockOrder(event.currentTarget.id)}

  const { dateToRender, dateOfMonth, events } = props
  let today = ''
  let event_in = false
  let events_arr = []

  today =
    dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? ' today '
      : ''
      

  event_in = events.length > 0 ? 'haveEvent' : false


  if (dateToRender.month() < dateOfMonth.month()) {
    return (
      <button disabled={true} className="date prev-month">
        {dateToRender.date()}
      </button>
    )
  }

  if (dateToRender.month() > dateOfMonth.month()) {
    return (
      <button disabled={true} className="date next-month">
        {dateToRender.date()}
      </button>
    )
  }

  if(event_in == 'haveEvent'){
    return (
      <button className={"dropdown date in-month p-0 "}>
        <button
          className={"dropdown date in-month "+today+event_in}
          aria-expanded = "false" 
          data-bs-toggle = {(events.length > 1) ? "dropdown" : ""}
          style={{backgroundColor: ((events.length == 1) && event_in) ? events[0].resource.color: "red"}}
          id = {((events.length == 1) && event_in) ? events[0].resource.id : "dropdownContainerItemMenu_"+dateToRender.format('YYYY-MM-DD')}
          onClick = {((events.length == 1) && event_in) ? edit : ()=>{}} >
          {dateToRender.date()}
        </button>
        {(events.length > 1) && 
        <ul className="dropdown-menu calendar_year_view_dropdown" aria-labelledby = {"dropdownContainerItemMenu_"+dateToRender.format('YYYY-MM-DD')}>
          {events.map((ev, index) => (
            <div style={{borderLeft: "4px solid "+ ev.resource.color}} key={ev.resource.id}>
              <li className={" dropdown-item pt-1 pb-1"} style={{ borderBottom: "1px dashed rgba(215, 215, 215, 0.843)" }}>
                <span className="link cursor_pointer" onClick={edit} id={ev.resource.id}>
                {ev.title}
                </span>
              </li>
            </div>
          )
          )}
        </ul>}
      </button>
    )}
  else
    return (
      <button
        className={"cursor-not-allowed date"+today}>

        {dateToRender.date()}
      </button>
    )
}

class Calendar extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    calendar: undefined
  }

  componentDidMount() {
    this.setState({ calendar: createCalendar(this.props.date) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setState({ calendar: createCalendar(this.props.date) })
    }
  }

  render() {
    if (!this.state.calendar) {
      return null
    }
    const { to_place, placed, delivered, calendar_filter_date, filters_list, 
      editStockOrder, deleteStockOrder, openCancelStockOrder, openSplitStockOrder, openPlaceStockOrder,
      openReceiveStockOrder, openFinalizeStockOrder, quick_search_value, date_format} = this.props.props
    let new_to_place = []
    let new_placed = []
    let new_delivered = []

    // apply product category filters
    new_to_place = (filters_list == "all") ? 
                to_place 
                : 
                to_place.filter((a) => {
                  return _.find(a.stock_order_categories_list, function(o) 
                  { return _.find(filters_list, function(i) 
                    { return i.category_id.includes(o.category_id) })})
                })

    new_placed = (filters_list == "all") ? 
                placed 
                : 
                placed.filter((a) => {
                  return _.find(a.stock_order_categories_list, function(o) 
                  { return _.find(filters_list, function(i) 
                    { return i.category_id.includes(o.category_id) })})
                })
                
    new_delivered = (filters_list == "all") ? 
                delivered 
              : 
              delivered.filter((a) => {
                return _.find(a.stock_order_categories_list, function(o) 
                { return _.find(filters_list, function(i) 
                  { return i.category_id.includes(o.category_id) })})
              })

    // apply quick search filter
    new_to_place = (!quick_search_value) ? 
        new_to_place 
        : 
        new_to_place.filter((filter) => {
          return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
        })

    new_placed = (!quick_search_value) ? 
        new_placed 
        : 
        new_placed.filter((filter) => {
          return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
        })
            
        new_delivered = (!quick_search_value) ? 
            new_delivered 
            : 
            new_delivered.filter((filter) => {
              return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
            })
            
    let events = new_to_place.concat(new_placed).concat(new_delivered).map((so) => {
      return {
        id: so.id,
        start: new Date(moment(so[calendar_filter_date], date_format.toUpperCase())),
        end: new Date(moment(so[calendar_filter_date], date_format.toUpperCase())),
        title: so.order_no + (so.order_nickname ? " ( "+so.order_nickname+" )" : ""),
        allDay: true,
        resource: so
       } 
    })

    return (
      <div className="month">
        <div className="month-name">
          {this.state.calendar.currentDate.format('MMMM').toUpperCase()}
        </div>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <span key={index} className="day">
            {day}
          </span>
        ))}
        {this.state.calendar.map((week, index) => (
          <div key={index}>
            {week.map(date => (
              <CalendarDate
                key={date.date()}
                dateToRender={date}
                dateOfMonth={this.state.calendar.currentDate}
                events={events.filter((filter) => {
                  return (moment(filter.start).format('YYYY-MM-DD') ==  date.format('YYYY-MM-DD'))
                })}
                editStockOrder={editStockOrder}
                deleteStockOrder={deleteStockOrder}
                openCancelStockOrder={openCancelStockOrder}
                openSplitStockOrder={openSplitStockOrder}
                openPlaceStockOrder={openPlaceStockOrder}
                openReceiveStockOrder={openReceiveStockOrder}
                openFinalizeStockOrder ={openFinalizeStockOrder}
              />
            ))}
          </div>
        ))}
      </div>
    )
  }
}

class Year extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    let { date, ...props } = this.props
    let range = Year.range(date)
    const months = []
    const firstMonth = startOf(date, 'year')
    let currentDate = moment()
    let start_month = 0
    let end_month = 12

    // if(currentDate.month() > 8)
    // {
    //   start_month = 2
    //   end_month = 14
    // }

    // if(currentDate.month() > 10)
    // {
    //   start_month = 4
    //   end_month = 16
    // }
    
    for (let i = start_month; i < end_month; i++) {
      months.push(
        <Calendar props = {this.props}  key={i + 1} date={add(firstMonth, i, 'month')} />
      )
    }

    return <div className="year">{months.map(month => month)}</div>
  }
}

// Day.propTypes = {
//   date: PropTypes.instanceOf(Date).isRequired,
// }

Year.range = date => {
  return [startOf(date, 'year')]
}

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return add(date, -1, 'year')

    case navigate.NEXT:
      return add(date, 1, 'year')

    default:
      return date
  }
}

Year.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default Year
