import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import OnlineOrderPageContainer from './OnlineOrderPageContainer'

const OnlineOrderFormRoot = ({
  general_settings,
  customer,
  form_state,
  cart,
  products
}) => {
  const state = {
    general_settings: general_settings,
    cart: cart,
    customer: customer,
    form_state: form_state,
    products: products
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <OnlineOrderPageContainer/>
    </GlobalProvider>
  )
}


export default OnlineOrderFormRoot