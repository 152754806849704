import { connect } from 'react-redux'
import GeneralTab from '../components/GeneralTab'

const mapStateToProps = (state, props) => ({
  memberInfo: props.memberInfo,
  setMemberInfo: props.setMemberInfo,
  view_mode: state.profile_setup.view_mode,
  date_format: state.profile_setup.date_format || state.general_settings.date_format,
  member_id: state.filters ? state.filters.member_id : state.profile_setup.member_id,
  manager_edit_profile: state.profile_setup.manager_edit_profile || false
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralTab)