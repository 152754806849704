import React from 'react';
import { Spinner} from 'react-bootstrap';

function OrdersRepairsHome(props) {
  return (
    <div className='col p-3 add-margin-all-10 text-center home_class' >
        <h4 className='mx-3 text-start mb-0 mt-2'>{props.title}</h4>
        {props.data.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <>
          <div className='m-3 mt-20 mb-2 body_blue_component text-start'>
            {props.data.active.map((ao) => (
              <div className='d-flex ms-3 me-3 item'>
                <h3 className='order-1 m-3 mx-0'><b>{ao["value"]}</b></h3>
                <h5 className='order-2 m-3 add-line-height-35 fw-bold'>{ao["name"]}</h5>
              </div>
              ))}
          </div>
          {props.data.warnings && 
            <div className='m-3 mt-0 mb-2 body_red_component text-start d-flex'>
              <span className='m-3 me-0 gsgicon-Alert_Icon-02 fs-3 order-1'/>
              <div className='ms-0 me-3 mt-3 item order-2'>
                {props.data.warnings.map((wr) => (
                  wr["value"] > 0 && 
                  <div className='d-flex'>
                    <span class="fa fa-circle order-1 p-1" style={{fontSize: "6px", lineHeight: "20px"}}></span>
                    <h6 className='order-2 p-1'><b>{wr["value"]}</b></h6>
                    <h6 className='order-3 p-1'>{wr["name"]}</h6>
                  </div>
                ))}
              </div>
            </div>
          }
          <div className='m-3 mt-0 mb-2 button_row'>
            <div className='shop_dashboard_btn btn gems_custom_button cursor_pointer me-1'>
              <a href={props.data.dashboard_url} data-method="get">Dashboard</a>
            </div>
            <div className='shop_dashboard_btn btn gems_custom_button cursor_pointer ms-1'>
              <a href={props.data.analytics_url} data-method="get">Analytics</a>
            </div>
          </div>
          </>
        }
      </div>
    )}
export default OrdersRepairsHome
