import React, {Component, useEffect, useState, useRef, createRef} from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import AddTab from '../containers/add_tab'
import { ResizableBox } from 'react-resizable';
// import Tab from './tab'
import { TabContainer, Nav, NavItem, Navbar, Container} from 'react-bootstrap'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import Logout from './Logout'
import _ from "lodash";

const { Collapse, Toggle } = Navbar

function ListTabs(props) {
  
  let containerMaxWidth = parseInt(props.general_settings.container_width)
  
  const initialState = {
    buttonMenuClass: 'down',
  }
  // changeOnHoverStyle(e, schemaColor) {
  //   e.target.closest('.col').style.borderBottom = `2px solid ${schemaColor}`;
  //   e.target.style.color = schemaColor;
  // }

  // changeInitialStyle(e, activeTabId, tabId) {
  //   if (activeTabId != tabId) {
  //     let c = getComplementaryColor((props.general_settings.enable_background_color ? (props.general_settings.background_color || "#FFFFFF") :  "#FFFFFF"))
  //     e.target.closest('.col').style.borderBottom = `2px solid ${c}`
  //     e.target.style.color = c
  //   }
  // }
  const [state, setState] = useState(initialState);
  let  elementsRef = useRef(props.tabs.map(() => createRef()));

  if(elementsRef && (props.tabs.length > elementsRef.current.length))
  {
    elementsRef.current.push(createRef(props.tabs[-1]));

  }
  
  const updateAndResizeTabs = () => {
    let tbs = props.tabs
    tbs.map((tab, index) => {
      props.updateTab(tab.id, tab.title, `${elementsRef.current[index].current.getBoundingClientRect().width}px`)
    })
    
  };
  
  const active_tab_name = props.tabs.length > 0 && props.activeTab ? _.find(props.tabs, (tab) => tab.id == props.activeTab ).title : "No tabs"
  
  return (
      <Row onSelect={ () => { this.setState({ buttonMenuClass: 'down' }) } } id="mw-tabs" className={ ["m-0 p-0 test", props.editMode && "overflow-hidden" ] } style={{boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)"}}>
          <Navbar bg="light" expand="md" className='w-100 p-0 m-0'>
            <Container fluid className='p-0'>
              <style>{ ".navbar-light .navbar-toggler{ color: " + getComplementaryColor((props.general_settings.color_schema || "#FFFFFF")) + "}" }</style>
              <Navbar.Toggle style={ {backgroundColor: props.general_settings.color_schema || "#FFFFFF" }} aria-controls="navbarScroll" className='d-flex d-md-none'><div className='flex-grow-1' dangerouslySetInnerHTML={{__html: active_tab_name}}></div><i className="float-end fa fa-caret-down icon_dropdown_search fs-4" id="icon_dropdown_search"></i></Navbar.Toggle>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto row my-md-0 m-0 w-100"
                  style={{backgroundColor: props.general_settings.color_schema || "#FFFFFF"}}
                  navbarScroll
                >
                  <div className="d-flex p-0 tabs_container" style={{maxWidth: containerMaxWidth + "px", width: "97%"}}>
                    {
                    props.tabs.length >= 1 && props.tabs[0].title !== ''
                      ? props.tabs.map((tab, index) =>
                        props.editMode
                        ?
                        <ResizableBox axis={'x'} width={tab.width ? parseInt(tab.width) : (containerMaxWidth / props.tabs.length)} onResizeStop={(e) => updateAndResizeTabs()} minConstraints={[50, 0]} maxConstraints={[containerMaxWidth - (props.tabs.length * 50), 0]}>
                          <Nav.Link ref={elementsRef.current[index]} key={`tabs_${tab.id}`} className='' style={
                            { width: "100%",
                              height: "100%",
                              margin: "0",
                              padding: "0"
                            }}>
                            <div className={props.activeTab == tab.id ? 'active menu_tab' : 'menu_tab'}
                                  key={tab.id} eventKey={`mw-tab-${tab.id}`}
                                  onClick={() => props.setActiveTab(tab.id)}
                                  style={{backgroundColor: props.general_settings.color_schema || "#FFFFFF"}}
                            >
                              <a className='section-title nav-link text-center text-break'
                                  style={{color: getComplementaryColor((props.general_settings.color_schema || "#FFFFFF"))}}
                                  dangerouslySetInnerHTML={{__html: tab.title}}>
                              </a>
                            </div>
                          </Nav.Link>
                        </ResizableBox>
                      :
                        <Nav.Link key={`tabs_${tab.id}`} className='' style={
                          { width: `${tab.width ? (parseInt(tab.width) * 100)/(containerMaxWidth) : ((containerMaxWidth / props.tabs.length) * 100)/containerMaxWidth}%`,
                            margin: "0",
                            padding: "0"
                          }}>
                          <div className={props.activeTab == tab.id ? 'active menu_tab' : 'menu_tab'}
                               key={tab.id} eventKey={`mw-tab-${tab.id}`}
                               onClick={() => props.setActiveTab(tab.id)}
                               style={{backgroundColor: props.general_settings.color_schema || "#FFFFFF"}}
                          >
                            <a className='section-title nav-link text-center text-break'
                                style={{color: getComplementaryColor((props.general_settings.color_schema || "#FFFFFF"))}}
                                dangerouslySetInnerHTML={{__html: tab.title}}>
                            </a>
                          </div>
                        </Nav.Link>
                      )
                      : null
                    }
                  </div>
                  {props.editMode && <AddTab general_settings={props.general_settings}/>}
                  {!props.editMode && props.general_settings.authentication_type != "ce_integration" && props.general_settings.authentication_type != "jonas_integration" && <Logout general_settings={ props.general_settings } preview={ props.preview }/>}
                </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
  )
}

ListTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
}

export default ListTabs
