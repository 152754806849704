import { connect } from 'react-redux'
import { } from '../actions'
import NotificationsTab from '../components/NotificationsTab'

const mapStateToProps = (state, props) => ({
  member_id: state.filters ? state.filters.member_id : null,
  notification_settings: state.profile_setup.notification_settings,
  memberNotifications: props.memberNotifications,
  setMemberNotifications: props.setMemberNotifications,
  view_mode: state.profile_setup.view_mode
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  null
)(NotificationsTab)