import React from 'react'
import { Col } from 'react-bootstrap';
import Footer from '../components/Footer';
import MatchingFieldsTableContainer from '../containers/MatchingFieldsTableContainer';
import Header from './Header';
import HeaderMatchingFields from './HeaderMatchingFields';

function MatchingFieldsPage(props) {
  const {saveFieldsConfiguration} = props;
  return (
    <div className="col-12 h-100 d-flex flex-column">
      <Header action="MATCHING FIELDS"/>
      <HeaderMatchingFields />
      <MatchingFieldsTableContainer/>
      <Footer onClickHandler={saveFieldsConfiguration} />
    </div>
  )
}

export default MatchingFieldsPage
