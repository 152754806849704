import React from 'react';
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function ErrorModal(props) {

  const {
    show,
    errorHTML,
    fontColor
  } = props;

  const handleClose = () => {
    props.closeErrorModal()
  }

  return (
    <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" backdrop centered>
      <Modal.Header closeButton style={{border: 0}}>
        <b className='color_orange'>
          WARNING!
        </b>
      </Modal.Header>
      <hr style={{margin: "0 16px"}}/>
      <Modal.Body style={{padding: "8px 16px 16px 16px", fontSize: "1rem", lineHeight: "138%", fontWeight: 400, fontStyle: "notmal"}}>
        { errorHTML &&
          <div dangerouslySetInnerHTML={{__html: errorHTML}} className='d-flex align-items-start'/>
        }
      </Modal.Body>
    </Modal>
  )
}

export default ErrorModal
