import { ActionTypes } from '../actions'

const {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  CHANGE_SPECIAL_INSTRUCTIONS,
  UPDATE_QUANTITY,
  SET_CART_AUTH_ERROR,
  LOGIN_MEMBER,
  CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE,
} = ActionTypes

const initialState = {
}
  
const cart = (state = initialState, action) => {
  let cart_products;
  let selected_product;
  let existing_cart_product;
  let selected_configuration;
  let selected_quantity;
  let product;
  let totalCost;
  switch (action.type) {
    case CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE:
      let products = action.data.products
      let cartProducts = action.data.cartProducts

      let stockEnough = true
      cartProducts.forEach((cartProduct, index) => {
        if (products[cartProduct.id].stock && (products[cartProduct.id].stock - products[cartProduct.id].items_ordered_by_others - products[cartProduct.id].items_ordered_by_customer < 0))
          stockEnough = false
      })
      return {
        ...state,
        isEnoughStockForOrders: stockEnough
      }
    case SET_CART_AUTH_ERROR:
      return {
        ...state,
        errorMessage: action.data.error_message
      }
    case LOGIN_MEMBER:
      return {
        ...state,
        errorMessage: action.data.invalid_credentials ? "Invalid credentials! Please try again!" : "",
      }
    case ADD_PRODUCT_TO_CART:
      cart_products = state.products
      selected_product = action.data.product
      selected_configuration = Object.keys( action.data.configuration ).map((id) => action.data.configuration[id])
      selected_quantity = action.data.quantity
      existing_cart_product = Object.values(cart_products).find((p, index) => p.id == selected_product.id && _.isEqual(p.configuration, selected_configuration))

      if (existing_cart_product && !existing_cart_product.canceled_at) {
        cart_products = Object.values(cart_products).filter((p, index) => p != existing_cart_product)
        if (existing_cart_product.deleted) {
          existing_cart_product.quantity = selected_quantity
          existing_cart_product.deleted = false
        } else {
          existing_cart_product.quantity = existing_cart_product.quantity + selected_quantity
        }

        cart_products.push(existing_cart_product)
      } else {
        cart_products.push({
          id: selected_product.id,
          name: selected_product.name,
          price: selected_product.price,
          order: selected_product.order,
          image: selected_product.images[0],
          quantity: selected_quantity,
          order_line_id: null,
          order_position: "ITEMS TO ORDER",
          deleted: false,
          canceled_at: null,
          special_instructions: "",
          configuration: selected_configuration,
          insertedOrder: Math.max(Math.max(...cart_products.map(item => item.insertedOrder)), 0) + 1,
        })
      }

      totalCost = Object.values(cart_products).filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + ((p.price || 0) * p.quantity), 0)
      return {
        ...state,
        itemsInCart: Object.values(cart_products).filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0),
        products: cart_products,
        totalCost: totalCost,
      }
    case REMOVE_PRODUCT_FROM_CART:
      cart_products = state.products
      product = action.data.product
      cart_products = cart_products.filter((p, index) => p != product)

      if (product.order_line_id) {
        product.deleted = true
        cart_products.push(product)
      }
      totalCost = cart_products.filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + ((p.price || 0) * p.quantity), 0)
      return {
        ...state,
        itemsInCart: Object.values(cart_products).filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0),
        products: cart_products,
        totalCost: totalCost,
      }
    case UPDATE_QUANTITY:
      cart_products = state.products
      product = action.data.product
      cart_products = cart_products.filter((p, index) => p != product)

      product.quantity = action.data.quantity
      cart_products.push(product)

      totalCost = cart_products.filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + ((p.price || 0) * p.quantity), 0)
      return {
        ...state,
        itemsInCart: Object.values(cart_products).filter((p, index) => p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0),
        products: cart_products,
        totalCost: totalCost,
      }
    case CHANGE_SPECIAL_INSTRUCTIONS:
      cart_products = state.products
      product = action.data.product
      cart_products = cart_products.filter((p, index) => p != product)
      product.special_instructions = action.data.special_instructions
      cart_products.push(product)

      return {
        ...state,
        products: cart_products
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default cart