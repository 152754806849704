import { connect } from 'react-redux'
import Body from '../components/Body'

const mapStateToProps = (state) => ({
  modal_show_custom_template_open: state.custom_templates.modal_show_custom_template_open,
  modal_show_communication_history_open: state.sent_emails_history.modal_show_communication_history_open,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)