import moment from "moment";
import React, {useEffect, useState} from "react";
import {IoMdAddCircle} from "react-icons/io";
import { Form} from 'react-bootstrap';
import _ from "lodash";
function QuickTaskAssignTo(props) {
  const initialState = {
    assignees_list: props.assignees_list,
    assign_to_options: props.assign_to_options,
    quick_search: "",
  }

  const [state, setState] = useState(initialState);
  const {open_assign_to, handleOpenClose, handleInputChange} = props
  const {assignees_list, quick_search, assign_to_options} = state

  const Assign = (name, id) => {
    let current_list = [...assignees_list]

    let is_in = _.find(current_list, (cl) => cl.value === id )

    if(is_in)
      current_list = current_list.filter(item => item.value !== id)
    else
      current_list.push({value: id, label: name})

    setState({...state, assignees_list: current_list})
  }

  const close = () => {
    setState(initialState)
    handleOpenClose("open_assign_to", false)
  }

  const save = () => {
    let current_list = [...assignees_list]
    handleInputChange("assignees_list", current_list)
  }

  useEffect(() => {
    setState(initialState)
  }, [props.assignees_list, props.assign_to_options]);

  const QuickSearch = (key) => {
    if(key){
      let filter_assignees_list = []

      let staffs = {...props.assign_to_options[0]}
      let users = {...props.assign_to_options[1]}

      staffs.options = staffs.options.filter(item => item.label.toLowerCase().includes(key.toLowerCase()))
      users.options = users.options.filter(item => item.label.toLowerCase().includes(key.toLowerCase()))
      filter_assignees_list[0] = staffs
      filter_assignees_list[1] = users

      setState({...state, assign_to_options: filter_assignees_list, quick_search: key})
    }
    else
      setState({...state, assign_to_options: props.assign_to_options, quick_search: ""})
  }

  return (
    <div className="quick-task-form-assign-to">
      <div className="align-vertical-middle w-100 d-flex gap-5px" onClick={(e) => handleOpenClose("open_assign_to", true)}>
        <span><IoMdAddCircle className="fs-5 align-vertical-middle assingee-btn"/></span>
        <span className="align-vertical-middle">Assign To</span>
      </div>
      {open_assign_to &&
        <div className="quick-task-form-assign-to-body">
          <div className="input-group input-group-sm quick-task-form-assign-to-search-box">
            <Form.Control type="text"
                          name='quick_search'
                          placeholder='Search...'
                          value={quick_search || ""}
                          onChange={(e) => QuickSearch(e.target.value)}
            />
          </div>
          <div className={"quick-task-form-assign-to-options-body w-100"}>
            {assign_to_options.map((option, index) => (option["options"] && <>
                {option["options"].map((op, i) => (
                  <div
                    className={"quick-task-form-assign-to-item d-flex w-100 text-start"}
                    key={i}
                  >
                    <Form.Check
                      value={op["value"]}
                      name={op["label"]}
                      checked={_.find(assignees_list, (as) => as.value === op["value"])}
                      type='checkbox'
                      className="mb-1 mt-1 assing_to_checkbox"
                      onChange={(e) => Assign(e.target.name, e.target.value)}
                    />
                    <span
                      className={`${_.find(assignees_list, (as) => as.value === op["value"]) ? 'bold' : ''} align-vertical-middle assing_to_checkbox_label`}>{op["label"]}</span>
                  </div>))
                }
                {(index + 1) < assign_to_options.length &&
                  <span
                    className="quick-task-form-assign-to-item flex-column d-flex align-vertical-middle w-100 text-start quick-task-form-assign-to-item-border"></span>}
              </>
            ))}
          </div>
          <div className="quick-task-form-assign-to-footer d-flex w-100">
            <span className="align-vertical-middle quick-task-form-assign-to-footer-item"
                  onClick={(e) => close()}>Cancel</span>
            <span className="align-vertical-middle quick-task-form-assign-to-footer-item"
                  onClick={(e) => save()}>Save</span>
          </div>
        </div>}
    </div>
  )
}

export default QuickTaskAssignTo

