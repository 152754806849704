import React, {useEffect, useState} from 'react';
import { format } from "date-fns";
import { Row, Col, Form, Table } from 'react-bootstrap'
import Select from 'react-select'

function ActivityTab(props) {

  const beautifyActivityLogEntity = (entity) => {
    switch(entity) {
      case 'OrderLine':
        return 'Special Orders';
      case 'DemoTracker':
        return 'Demo Clubs'
      case 'Order':
        return 'Orders'
      case 'ClubRepair':
        return 'Club Repairs'
      case 'StockOrder':
        return 'Stock Orders'
      case 'StockOrderCategory':
        return 'Stock Order Categories'
      case 'Rental':
        return 'Rentals'
      default:
        return entity;
    }
  }

  const getAllEntities = () => {
    const entities = [{value: '', label: "Show All"}]

    props.activity_log_entities.map(entity => {
      entities.push({value: entity, label: beautifyActivityLogEntity(entity)})
    })

    return entities
  }

  const handleFilterEntityClick = (value) => {
    setFilteredValue(value)
    props.setFilteredActivityLog(value, props.member_id)
  }

  const [filteredValue, setFilteredValue] = useState('')

  useEffect(() => {
    $('.select2').select2({})
    $("#activity_entity_filter_dropdown")[0].onchange = function(){handleFilterEntityClick(this.value)}
  }, []);

  return(
    <Col className="activity_tab_container w-100" style={{backgroundColor: "#F7F7F7", padding: "10px"}}>
      <Form.Group as={Row} className="mb-3 form-row" controlId="formBlockColums">
        <Form.Label className="ps-3 pt-1" style={{fontSize: "16px", fontWeight: "bold", width: "auto"}}>
          Filter by: 
        </Form.Label>
        <select id="activity_entity_filter_dropdown" className="form-control form-control-sm select2" value={filteredValue} >
          {getAllEntities().map(entity => 
            <option value={entity.value} key={entity.value}>{entity.label}</option>
          )}
        </select>
      </Form.Group>
      <div style={{height: "600px", overflowY: "scroll"}}>
        <Table striped className="tableFixHead custom_template_communications" style={{border: "1px solid #D7D7D7", height: "50%"}}>
          <tbody>
            {props.activity_log.map((log, index) =>
              <tr key={index}>
                <td className='ps-3' style={{width: "10%"}}> 
                  {/* <Col sm="2" className="text-center ps-2">
                    <span>
                      {beautifyDate(log.created_at)[0]}
                    </span>
                    <br></br>
                    <span>
                    {beautifyDate(log.created_at)[1]}
                    </span>
                  </Col>  */}
                  <div>
                    {log.created_time}
                  </div>
                  <div>
                    {log.created_date}
                  </div>
                </td>
                <td style={{whiteSpace: "pre-line"}}> <div dangerouslySetInnerHTML={{ __html: `${log.message || '-'}`}}/> </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      { props.activity_log.length == 0 && <span>No recent activity</span>}
    </Col>    
  )
}
export default ActivityTab