import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { setSettingsAttribute, updateTab } from '../../actions'
import { connect } from 'react-redux'
import { Row, Form, Col, Table, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import UploadFile from '../../../shared/components/upload_file';
import { uploadFile } from '../../actions'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import Select from 'react-select';
import _ from "lodash";

class LayoutSettings extends Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
    $('.select2').select2({})
  }
  

  getContainerStyle(){
    let style = {}
    
    if(this.props.general_settings.enable_background_color) {
      if(!this.props.general_settings.enable_background_image){
        let comp = getComplementaryColor((this.props.general_settings.background_color))

        style["borderLeft"] = `2px solid ${comp}`
        style["borderRight"] = `2px solid ${comp}`
      }
    } else {
      style["borderLeft"] = `1px solid #424242`
      style["borderRight"] = `1px solid #424242`
    }

    return style
  }
  
  setContainerWidth(width){
    // reset tabs width
    let sum = _.sum(_.map(this.props.tabs, (t) => parseInt(t.width) || (width / this.props.tabs.length)))
    
    if(sum > width)
      this.props.tabs.map(tab => {
        this.props.updateTab(tab.id, tab.title, `${(width / this.props.tabs.length)}px`)
      })
    
    this.props.setAttribute("container_width", `${width}px`)
  }

  render(){
    return (
      <div className="background_white" id="general_settings_page_container" style={ this.getContainerStyle() }>
        <div className="row d-flex flex-row cursor_pointer m-0 dropdown_page_caret" data-bs-target="#general_settings_page" data-bs-toggle="collapse" type="button" id="general_settings_page_btn">
          <div className="col float-start">
            <h6 className="link add-line-height-35 mb-0 mt-1">
              Appearance Settings
            </h6>
          </div>
          <div className="col float-end">
            <i className="add-line-height-35 float-end fa fa-caret-up icon_dropdown_search fs-4" id="icon_dropdown_search"></i>
          </div>
        </div>
        <div className="collapse row panel custom_panel show" id="general_settings_page">
          <div className="col panel-body overflow-hidden">
            <div className="row m-1">
              <div className="col-12 ps-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row mb-2 ps-2 color_container" id="empty_color">
                      <label className="col-5 col-form-label col-form-label-sm" htmlFor="color">Color Scheme</label>
                      <div className="col-7">
                        <input name="color_schema" className='form-control' type="color"
                               onChange={(e) => this.changeColorSchema(e)}
                               style={{width: 100, height: 28, paddingLeft: "30px!important"}}
                               defaultValue={this.props.general_settings.color_schema}/>
                      </div>
                    </div>
                    <div className="row mb-2 ps-2">
                      <label className="form-check-label col-5">
                        Background
                      </label>
                      <div className="col-7 p-0">
                        <div className="row">
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.enable_background_color}
                                   className="form-check-input"
                                   id="color_background"
                                   name="selectedBackgroundType"
                                   type="checkbox"
                                   onChange={() => {
                                     this.toggleBackgroundColor()
                                   }}/>
                            <label className="form-check-label ps-2" htmlFor="default_banner">
                              Color
                            </label>
                            {this.props.general_settings.enable_background_color &&
                              <input name="background_color" className='form-control mx-4' type="color"
                                     onChange={(e) => this.props.setAttribute("background_color", e.target.value)}
                                     style={{
                                       display: "inline-block",
                                       width: 100,
                                       height: 28,
                                       paddingLeft: "30px!important"
                                     }} defaultValue={this.props.general_settings.background_color}/>
                            }
                          </div>
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.enable_background_image}
                                   className="form-check-input"
                                   id="image_background"
                                   name="selectedBackgroundType"
                                   type="checkbox"
                                   onChange={() => {
                                     this.toggleBackgroundImage()
                                   }}/>
                            <label className="form-check-label ps-2" htmlFor="customer_banner">
                              Image
                            </label>
                            <a className="link mx-2"
                               onClick={() => {
                                 this.handleBackgroundImageUpload()
                               }}
                               style={{display: `${this.props.general_settings.enable_background_image ? '' : 'none'}`}}>
                              &nbsp;
                              Edit
                            </a>
                          </div>
                          <div className="col-12 form-check">
                            <div className="text-center w-100">
                              <Spinner className="d-none" animation="border" variant="warning" id="upload_spinner"/>
                            </div>
                            <UploadFile uploadFile={this.props.uploadFile}
                                        display={'none'}
                                        customer_id={this.props.customer_id}
                                        item_id={"portal_background"}
                                        file_id={"background_file_input"}
                            />
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row mb-2 ps-2">
                      <label className="form-check-label col-5">
                        Portal Banner
                      </label>
                      <div className="col-7 p-0">
                        <div className="row">
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.banner_type === "default"}
                                   className="form-check-input" id="default_banner" name="selectedBanner" type="radio"
                                   onChange={() => {
                                     this.setDefaultBaner()
                                   }}></input>
                            <label className="form-check-label ps-2" htmlFor="default_banner">
                              Default Banner
                            </label>
                          </div>
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.banner_type === "custom"}
                                   className="form-check-input" id="custom_banner" name="selectedBanner" type="radio"
                                   onChange={() => {
                                     this.handleCustomBannerChange()
                                   }}></input>
                            <label className="form-check-label ps-2" htmlFor="customer_banner">
                              Custom Banner
                            </label>
                            <span className="link" onClick={() => {
                              this.handleCustomBannerChange()
                            }}
                                  style={{display: `${this.props.general_settings.banner_type === "custom" ? '' : 'none'}`}}>
                              &nbsp;
                              Edit
                            </span>
                          </div>
                          <div className="col-12 form-check">
                            <div className="text-center w-100">
                              <Spinner className="d-none" animation="border" variant="warning" id="upload_spinner"/>
                            </div>
                            <UploadFile uploadFile={this.props.uploadFile}
                                        display={'none'}
                                        customer_id={this.props.customer_id}
                                        item_id={"portal_banner"}
                                        file_id={"banner_file_input"}/>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {this.props.general_settings.enable_background_image &&
                      <div className="row mb-2 ps-2">
                        <div className="col-5 pe-0">
                          <label className="form-check-label">
                            Background Opacity
                          </label>
                          <OverlayTrigger
                            key="background_opacity"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-background_opacity`}>
                                Change the slidebar in order to make the background more transparent.
                              </Tooltip>
                            }
                          >
                            <i className="ms-2 gga-info-circle tooltip_info"/>
                          </OverlayTrigger>
                        </div>
                        <div className="col-7">
                          <input type="range" id="background_opacity" name="background_opacity" min="20" max="100"
                                 onChange={(e) => this.props.setAttribute("background_opacity", e.target.value)}
                                 defaultValue={this.props.general_settings.background_opacity}/>
                        </div>
                      </div>
                    }
                    
                    <div className="row mb-2 ps-2">
                      <div className="col-5 pe-0">
                        <label className="form-check-label">
                          Container Width
                        </label>
                        <OverlayTrigger
                          key="container_width"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-container_width`}>
                              Change the slidebar in order to expand the main container.
                            </Tooltip>
                          }
                        >
                          <i className="ms-2 gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div>
                      <div className="col-7 p-0">
                        <div className="row">
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.container_width === "992px"}
                                   className="form-check-input" id="md_container_width" name="contaierWidth"
                                   type="radio"
                                   onChange={(e) => this.setContainerWidth(992)}></input>
                            <label className="form-check-label ps-2" htmlFor="md_container_width">
                              Narrow (992px)
                            </label>
                          </div>
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.container_width === "1200px"}
                                   className="form-check-input" id="lg_container_width" name="contaierWidth"
                                   type="radio"
                                   onChange={(e) => this.setContainerWidth(1200)}></input>
                            <label className="form-check-label ps-2" htmlFor="lg_container_width">
                              Normal (1200px)
                            </label>
                          </div>
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.container_width === "1400px"}
                                   className="form-check-input" id="xl_container_width" name="contaierWidth"
                                   type="radio"
                                   onChange={(e) => this.setContainerWidth(1400)}></input>
                            <label className="form-check-label ps-2" htmlFor="xl_container_width">
                              Wide (1400px)
                            </label>
                          </div>
                          <div className="col-12 form-check">
                            <input checked={this.props.general_settings.container_width === "1800px"}
                                   className="form-check-input" id="xxl_container_width" name="contaierWidth"
                                   type="radio"
                                   onChange={(e) => this.setContainerWidth(1800)}></input>
                            <label className="form-check-label ps-2" htmlFor="xxl_container_width">
                              Wider (1800px)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-3">
              <div className="col ps-0 pe-0">
                <div className="row mb-2 mx-0">
                  <div className="col p-0">
                    <label htmlFor="flash_message">Flash Message:</label>
                    <textarea name="flash_message"
                              onChange={(e) => this.props.setAttribute("flash_message", e.target.value)}
                              className="form-control form-control-sm gs_flash_message_class" autoComplete="off"
                              defaultValue={this.props.general_settings.flash_message}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  changeColorSchema(e) {
    this.props.setAttribute("color_schema", e.target.value)
    let rgb = hexToRgb(this.props.general_settings.color_schema)
    this.props.setAttribute("font_color", getComplementaryColor(rgb))
  }
  
  handleCustomBannerChange() {
    this.props.setAttribute("banner_type", "custom");
    document.getElementById("banner_file_input").click();
  }
  
  toggleBackgroundImage() {
    this.props.setAttribute("enable_background_image", !this.props.general_settings.enable_background_image);
    // if(!this.props.general_settings.enable_background_image){
    //   document.getElementById("background_file_input").click()
    // }
  }

  handleBackgroundImageUpload(){
    document.getElementById("background_file_input").click()
  }

  setDefaultBaner() {
    this.props.setAttribute("banner_url", this.props.general_settings.customer_banner_url);
    this.props.setAttribute("banner_type", "default");
    this.props.setAttribute("banner_attachment_id", ""); 
  }

  toggleBackgroundColor() {
    this.props.setAttribute("enable_background_color", !this.props.general_settings.enable_background_color)
    // this.props.setAttribute("background_image_url", "");
    // this.props.setAttribute("background_image_attachment_id", "");
  }
  
}

LayoutSettings.propTypes = {
  dispatch: PropTypes.func,
  general_settings: PropTypes.object,
  tabs: PropTypes.object,
}

const mapStateToProps = state => ({
  general_settings: state.general_settings,
  tabs: state.tabs,
})

const mapDispatchToProps = dispatch => ({
  setAttribute: (attr, value) => {
    dispatch(setSettingsAttribute(attr, value))
  },
  uploadFile: (data) => dispatch(uploadFile(data)),
  updateTab: (id, title, width) => dispatch(updateTab(id, title, width)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSettings)

