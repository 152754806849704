import { connect } from 'react-redux'
import Footer from '../components/Footer'
import { saveChanges, switchView, close } from '../actions'

const mapStateToProps = (state, props) => ({
  all_tabs: state.profile_setup.all_tabs,
  view_mode: state.profile_setup.view_mode,
  current_tab: state.profile_setup.current_tab,
  all_metadata: state.profile_setup.all_metadata,
  preview_member_profile: state.profile_setup.preview_member_profile,
  member_roster_url: state.profile_setup.member_roster_url,
  setMemberInfo: props.setMemberInfo,
  memberInfo: props.memberInfo,
})

const mapDispatchToProps = (dispatch) => ({
  saveChanges: (all_tabs, all_metadata, member_info) => dispatch(saveChanges(all_tabs, all_metadata, member_info)),
  switchView: (view_mode) => dispatch(switchView(view_mode)),
  close: (current_tab_id) => dispatch(close(current_tab_id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)