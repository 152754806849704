import React from 'react';
import DatePicker from "react-datepicker";
import {ResponsiveContainer, AreaChart, Area, ReferenceLine, Sector , LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import Select from 'react-select';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import _ from 'lodash';
import TableComponent from '../shared/TableComponent';

function VendorDeliveryPerformance(props) {
  const initialState = {
    by_vendor_value: 0
  }
  
  const [state, setState] = useState(initialState);

  const handleByVendorChange = (value) => {
    setState({...state, by_vendor_value: value });
  }
  const sort_options = 
  [
    {label: "start ship date", value: "start_date"}, 
    {label: "end ship date", value: "end_date"},
    {label: "expected ship date", value: "expected_ship_date"},
    {label: "expected delivery date", value: "expected_delivery_date"},
  ]
  return (
        <div className='col p-20 add-margin-all-10 mb-0 text-start home_class' id="vendorDeliveryPerformance">
        <h4 className='text-start header'>VENDOR DELIVERY PERFORMANCE</h4>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <>
            <div className='col-12 p-2 d-flex' id="VDPSO" style={{backgroundColor: "#FCF3E3", marginBottom: "44px", borderRadius: "7px"}}>
              <b className='text-start add-line-height-35'>Actual Delivery Time compared to:</b>
              <Select className="VDPSO ps-3 flex-grow-1" 
                                  menuPortalTarget={document.getElementById('VDPSO')}
                                  menuPosition={'fixed'} 
                                  name = "vendorDeliveryPerformanceSelect"
                                  options={sort_options}
                                  defaultValue={sort_options.filter(function(option) {
                                      return option.value === props.data.filters["filter_by"];
                                    })}
                                    onChange={(e) => {props.load_component_data({key: props.moduleKey, customer_id: props.customer_id, subcomponent_key: props.componentKey, subcomponent_filters: {filter_by: e.value}})}}/>
            </div>
            <TableComponent data={props.data["best_vendors"]} componentType='delivery_time_stocks_by_vendor' moduleKey={props.moduleKey}/>
          </>
          }
      </div>
    )}
export default VendorDeliveryPerformance
