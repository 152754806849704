import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormCheck } from 'react-bootstrap'
import Select from 'react-select'

function CustomTab(props) {

  const [createNewField, setCreateNewField] = useState([] )
  const [newFieldName, setNewFieldName] = useState({})

  const handleCreateNewFieldClick = (currentBlockId) => {
    if (!newFieldName[currentBlockId] || !newFieldName[currentBlockId].replace(/\s/g, '').length) {
      alert("Field name cannot be empty!")
    }
    else {
      props.addNewField(newFieldName[currentBlockId], props.current_tab.member_profile_category_id, currentBlockId, props.current_tab.id, JSON.stringify(props.all_tabs))
      setCreateNewField(createNewField.filter(blockId => blockId !== currentBlockId))
      setNewFieldName({...newFieldName, [currentBlockId]: ''})
    }
  }

  const handleCancelCreateNewFieldClick = (currentBlockId) => {
    setCreateNewField(createNewField.filter(blockId => blockId !== currentBlockId))
    setNewFieldName({...newFieldName, [currentBlockId]: ''})
  }


  {/* BLOCKS SECTION START */}
  const handleDeleteBlockClick = (block_id, tab_id) => {
    if ( window.confirm( "Are you sure you want to delete this block?"))
      props.deleteBlock(block_id, tab_id)
  }

  const handleEditBlockTitleClick = (block_id, tab_id) => {
    props.openEditBlockTitleModal(block_id, tab_id)
  }
  {/* BLOCKS SECTION END */}

  {/* FIELDS DISPLAY/HANDLING SECTION START */}
  const getDisplayedSingleFieldMetadata = (metadata_ids) => {    
    let displayedMetadata = []

    Object.keys(props.all_metadata).map(key => {
      props.all_metadata[key].map((metadatum) => {
        if (Object.keys(metadata_ids).includes(metadatum.metadatum_id) && (metadatum.values_type !== "multiple_answers" || props.view_mode === "manager"))
          displayedMetadata.push(metadatum)
      })
    })

    // displayedMetadata.sort((a,b) => a.values_type === "multiple_answers" ? 1 : (b.values_type === "multiple_answers" ? -1 : (metadata_ids[a.metadatum_id] < metadata_ids[b.metadatum_id] ? -1 : 1)))
    displayedMetadata.sort((a,b) => metadata_ids[a.metadatum_id] < metadata_ids[b.metadatum_id] ? -1 : 1)

    return displayedMetadata
  }

  const getDisplayedMultipleAnswersMetadata = (metadata_ids) => {    
    let displayedMetadata = []

    Object.keys(props.all_metadata).map(key => {
      props.all_metadata[key].map((metadatum) => {
        if (Object.keys(metadata_ids).includes(metadatum.metadatum_id) && metadatum.values_type === "multiple_answers")
          displayedMetadata.push(metadatum)
      })
    })

    displayedMetadata.sort((a,b) => metadata_ids[a.metadatum_id] < metadata_ids[b.metadatum_id] ? -1 : 1)

    return displayedMetadata
  }

  const getFieldOptions = (block) => {
    const originalFields = props.metadata_dropdown_fields
    const fieldOptions = []
    let pairOptions = []

    originalFields.sort((a, b) => a.label === props.current_tab.member_profile_category_title ? -1 : 1)

    originalFields.map(pair => {
      pairOptions = pair.options.filter(field => !Object.keys(block.metadata_ids).includes(field.value))

      if (pairOptions.length > 0) {
        fieldOptions.push({label: pair.label, options: pairOptions})
      }
    })

    return fieldOptions
  }

  const handleSelectFieldClick = (value, block_id) => {
    if (value){
      props.showField(value, block_id, props.current_tab.id, JSON.stringify(props.all_tabs))
    }
  }

  const isOptionSelected = (optionsArray, value) => {
    if (!optionsArray || !value){
      return false
    }

    const options = optionsArray.split(', ')

    return options.includes(value)
  }

  const handleEditMetadatumValuesClick = (tab_id, block_id, metadatum_id) =>{
    props.openEditValuesModal(tab_id, block_id, metadatum_id)
  }

  const handleHideMetadatumClick = (metadatum_id, block_id, tab_id) =>{
    props.hideMetadatum(metadatum_id, block_id, tab_id)
  }
  {/* FIELDS DISPLAY/HANDLING SECTION END */}

  {/* MEMBER METADATA DISPLAY/HANDLING SECTION START */}
  // const formatMemberMetadatum = (metadatum, value) => {
  //   let valueToDisplay = '-'
  //   if (metadatum.values_type === "multiple_choice") {
  //     getMetadatumDropdownValues(metadatum.metadatum_id).map(entity => {
  //       if (entity.value === value)
  //         valueToDisplay = value
  //     })
  //   }

  //   return metadatum.values_type === "multiple_choice" ? valueToDisplay : value
  // }

  const getMetadatumDropdownValues = (metadatum_id) => {
    const possibleValues = []
    let originialValueMatched = false
    let newValueMatched = false
    props.metadata_values[metadatum_id].map(value => {
      if (props.originalMemberMetadata[metadatum_id] === value)
        originialValueMatched = true

      if (props.memberMetadata[metadatum_id] === value)
        newValueMatched = true

      possibleValues.push({label: value, value: value, selected: props.memberMetadata[metadatum_id] ? props.memberMetadata[metadatum_id] === value : false})
    })

    if (!originialValueMatched && props.originalMemberMetadata[metadatum_id])
      possibleValues.push({label: props.originalMemberMetadata[metadatum_id], value: props.originalMemberMetadata[metadatum_id], selected: !newValueMatched})

    return possibleValues
  }

  const handleMemberMetadataInput = (key, value, values_type) => {
    if (values_type === "dropdown") {
      const selectedOptions = []
      const list = checkbox.options
      for (let option of list) {
        if (option.selected) {
          selectedOptions.push(option.value)
        }
      }

      props.setMemberMetadata(prevHash=> ({...prevHash, [key]: selectedOptions.join(', ')}))
    }
    else if (values_type === "multiple_answers") {
      if (props.memberMetadata[key]) {
        let newValues = props.memberMetadata[key].split(', ')
        let valueIndex = newValues.indexOf(value)

        if (valueIndex !== -1) {
          newValues.splice(valueIndex, 1)
        }
        else {
          newValues.push(value)
        }
        props.setMemberMetadata(prevHash=> ({...prevHash, [key]: newValues.join(', ')}))
      }
      else {
        props.setMemberMetadata(prevHash=> ({...prevHash, [key]: value}))
      }
    }
    else if (values_type === "multiple_choice") {
      props.setMemberMetadata(prevHash=> ({...prevHash, [key]: value === "Select value..." ? null : value}))
    }
    else if (values_type === "free_text") {
      props.setMemberMetadata(prevHash=> ({...prevHash, [key]: value}))
    }

    if (props.updatedMemberMetadata.indexOf(key.toString()) == -1) {
      props.setUpdatedMemberMetadata(prevArray=> [...prevArray, key.toString()])
    }
  }

  useEffect(() => {
    $('.select2').select2({})
    if (props.view_mode === "manager"){
      const dropdown_elements = $(".select_field_dropdown")
      for (let element of dropdown_elements) {
        element.onchange = function(){handleSelectFieldClick(this.value, this.getAttribute("block_id"))}
      }
    }
    else if (props.view_mode === "member_edit"){
      const dropdown_elements = $(".selected_value_metadatum")
      for (let element of dropdown_elements) {
        element.onchange = function(){handleMemberMetadataInput(this.getAttribute("metadatum_id"), this.value || "", "multiple_choice")}
      }
    }
  }, [props.view_mode, props.current_tab, props.all_tabs, props.metadata_dropdown_fields]);
  {/* MEMBER METADATA DISPLAY/HANDLING SECTION END */}

  {/* DRAGGABLE ITEMS SECTION START */}
  const [draggedElementId, setDraggedElementId] = useState(null)
  const [hoveredElementId, setHoveredElementId] = useState(null)
  const [newPositionPlacement, setNewPositionPlacement] = useState(null)
  const [draggedElementType, setDraggedElementType] = useState(null)

  const [draggedMetadatumBlockId, setDraggedMetadatumBlockId] = useState(null)

  const onDragStart = (e, elementId, type) => {
    console.log(`start`, e.pageY)
    setDraggedElementId(elementId)
    setDraggedElementType(type)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  const onDragOver = (e, elementId, type, blockId = null) => {
    if (type !== draggedElementType) {
      return
    }

    if (type === "metadatum") {
      // if (draggedElementId === elementId)
      //   return
      // props.swapOrder(draggedElementId, elementId, type, blockId, newPositionPlacement)
      const metadatumContainer = $(e.target).closest('.metadatum')
      let placeholderPosition = null
      if ( e.pageY <= (metadatumContainer.offset().top + (metadatumContainer.height() / 2)) ) {
        placeholderPosition = 'beforebegin'
      } else {
        placeholderPosition = 'afterend'
      }

      if (draggedElementId === elementId){
        $( '.dnd-placeholder' ).remove()
        if (newPositionPlacement !== null || hoveredElementId !== null) {
          setNewPositionPlacement(null)
          setHoveredElementId(null)
          setDraggedMetadatumBlockId(null)
        }
        return
      }

      if (newPositionPlacement !== placeholderPosition) {
        setNewPositionPlacement(placeholderPosition)
      } else {
        if (elementId === hoveredElementId) {
          return
        }
      }

      setHoveredElementId(elementId)
      setDraggedMetadatumBlockId(blockId)
      $( '.dnd-placeholder' ).remove()
      // Placeholder element
      e.target.closest('.metadatum').insertAdjacentHTML(placeholderPosition, `<div class="col-xs-12 dnd-placeholder dnd-placeholder-metadatum">DROP HERE</div>`)
    }
    else {
      const blockContainer = $(e.target).closest('.block')
      let placeholderPosition = null
      if ( e.pageY <= (blockContainer.offset().top + (blockContainer.height() / 2)) ) {
        placeholderPosition = 'beforebegin'
      } else {
        placeholderPosition = 'afterend'
      }

      if (draggedElementId === elementId){
        $( '.dnd-placeholder' ).remove()
        if (newPositionPlacement !== null || hoveredElementId !== null) {
          setNewPositionPlacement(null)
          setHoveredElementId(null)
        }
        return
      }

      if (newPositionPlacement !== placeholderPosition) {
        setNewPositionPlacement(placeholderPosition)
      } else {
        if (elementId === hoveredElementId) {
          return
        }
      }

      setHoveredElementId(elementId)
      $( '.dnd-placeholder' ).remove()
      // Placeholder element
      e.target.closest('.block').insertAdjacentHTML(placeholderPosition, `<div class="col-xs-12 dnd-placeholder">DROP HERE</div>`)
      return
    }
    return
  }

  const onDragEnd = () => {
    if (draggedElementType === 'block')
      props.swapOrder(draggedElementId, hoveredElementId, 'block', null, newPositionPlacement)
    else if (draggedElementType === 'metadatum')
      props.swapOrder(draggedElementId, hoveredElementId, 'metadatum', draggedMetadatumBlockId, newPositionPlacement)

    setNewPositionPlacement(null)
    setDraggedElementId(null)
    setHoveredElementId(null)
    setDraggedElementType(null)
    $( '.dnd-placeholder' ).remove()
  }
  {/* DRAGGABLE ITEMS SECTION END */}

  return (
    <Fragment>
      {props.current_tab.blocks.sort( (a, b) => a.order <= b.order ? -1 : 1 ).map(block =>
        <div className='block mb-4' key={block.id} onDragOver={(e) => onDragOver(e, block.id, "block")}>
          <div className="block-header row">
            <Col>
              { props.view_mode == "manager" && <div onClick={() => handleDeleteBlockClick(block.id, props.current_tab.id)}
                data-toggle="tooltip"
                data-placement="right"
                className="float-end cursor_pointer ms-2 color_red">
                <i className="fa fa-trash fa-fw"
                  block_id={block.id}
                  tab_id={props.current_tab.id}
                ></i>
              </div>}
              { props.view_mode == "manager" && <div onClick={() => handleEditBlockTitleClick(block.id, props.current_tab.id)}
                data-toggle="tooltip"
                data-placement="right"
                className="float-end cursor_pointer">
                <i className="fa fa-pencil fa-fw"
                  block_id={block.id}
                  tab_id={props.current_tab.id}
                ></i>
              </div>}
              <Row style={{paddingLeft: "12px"}}>
                {props.view_mode == "manager" && <Col xs={2} md={1} ms="1"
                  className="block-drag-icon movable-icon cursor_pointer p-0 m-0"
                  style={{width: "fit-content", marginTop: "1px!important"}}
                  draggable
                  onDragStart={ (e) => onDragStart(e, block.id, "block") }
                  onDragEnd={() => onDragEnd()}
                >
                  <i className="fa fa-bars"></i>
                </Col>}
                <Col className="ps-2">
                  <h6 className='fw-bold'>{block.title}</h6>
                </Col>
              </Row>
            </Col>
          </div>
          <div className="flex-column">
            <div className="block-body d-flex">
              { getDisplayedSingleFieldMetadata(block.metadata_ids).map(metadatum =>
                <div className={ `metadatum ${props.view_mode} ${metadatum.values_type}`} key={metadatum.metadatum_id} onDragOver={(e) => onDragOver(e, metadatum.metadatum_id, "metadatum", block.id)}>
                  <Row style={{paddingLeft: "15px"}}>
                    {props.view_mode == "manager" && <Col xs={2} md={1} ms="1"
                      className="movable-icon pt-0 cursor_pointer p-0 m-0"
                      style={{width: "fit-content"}}
                      draggable
                      onDragStart={ (e) => onDragStart(e, metadatum.metadatum_id, "metadatum") }
                      onDragEnd={() => onDragEnd()}
                    >
                      <i className="fa fa-bars"></i>
                    </Col>}
                    <Col className={props.view_mode === "manager" ? "ps-2" : "ps-0"}>
                      <h6 >{metadatum.name}:</h6>
                    </Col>
                  </Row>
                  { props.view_mode == "manager" && 
                      <Row className="pt-2" style={{paddingLeft: "30px"}}>
                        <div className="fs-7 text-center action_buttons" style={{color: "#3F92E6", cursor: "pointer"}}
                          metadatum_id={metadatum.metadatum_id}
                          block_id={block.id}
                          tab_id={props.current_tab.id}
                          onClick={() => handleEditMetadatumValuesClick(props.current_tab.id, block.id, metadatum.metadatum_id)}>
                          Edit Values
                        </div>
                        <div className="fa fa-circle font_size_4 ps-0 pe-0 pt-2" style={{width: "fit-content"}}/>
                        <div className="fs-7 text-center action_buttons" style={{color: "red", cursor: "pointer"}}
                          onClick={() => handleHideMetadatumClick(metadatum.metadatum_id, block.id, props.current_tab.id)}>
                          Remove
                        </div>
                      </Row>
                  }

                  {/* MEMBER VIEW FIELDS START*/}
                  { props.view_mode == "member_edit" && metadatum.values_type === "free_text" && <Col>
                    <input type="text"
                      placeholder=""
                      className="form-control p-1"
                      metadatum_id={metadatum.metadatum_id}
                      value={ props.memberMetadata[metadatum.metadatum_id] ? props.memberMetadata[metadatum.metadatum_id] : "" }
                      onInput={ e => handleMemberMetadataInput(metadatum.metadatum_id, e.target.value, "free_text") }
                      />
                  </Col>
                  }
                  
                  { props.view_mode == "member_edit" && metadatum.values_type === "multiple_choice" && <Col>
                    <select className="selected_value_metadatum form-control form-control-sm select2" metadatum_id={metadatum.metadatum_id}>
                      <option value={null} key="Select value...">Select value...</option>
                      {getMetadatumDropdownValues(metadatum.metadatum_id).map(entity =>
                        <option value={entity.value} key={entity.label} selected={entity.selected}>{entity.label}</option>
                      )}
                    </select>
                  </Col>
                  }

                  { (props.view_mode == "preview" || props.view_mode == "member_preview") && 
                      <Col className='fw-bold fs-5'>
                        { props.memberMetadata[metadatum.metadatum_id] || "-" }
                      </Col>
                  }
                  {/* MEMBER VIEW FIELDS END */}

                  {/* { !metadatum.locked && <Col className="p-0" style={{color: "#3F92E6", cursor: "pointer"}}
                      metadatum_id={metadatum.metadatum_id}
                      block_id={block.id}
                      tab_id={props.current_tab.id}
                      onClick={handleLockMetadatunClick}>
                    Lock
                  </Col>}
                  { metadatum.locked && <Col className="p-0" style={{color: "#3F92E6", cursor: "pointer"}}
                      metadatum_id={metadatum.metadatum_id}
                      block_id={block.id}
                      tab_id={props.current_tab.id}
                      onClick={handleUnlockMetadatunClick}>
                    Unlock
                  </Col>} */}
                  {/* <Col className="p-0" style={{color: "#EB4207", cursor: "pointer"}}
                      metadatum_id={metadatum.metadatum_id}
                      block_id={block.id}
                      tab_id={props.current_tab.id}
                      onClick={handleDeleteMetadatumClick}>
                    Delete
                  </Col> */}
                </div>
              )}

              { props.view_mode == "manager" && <div className="metadatum">
                <div className="col">
                  <span>Add New Field</span>
                </div>
                <div className="flex-row new_field_group_container" style={{display: createNewField.length > 0 && createNewField.indexOf(block.id) !== -1 ? "flex" : "none"}}>
                  <Col sm="2" className="pe-2 pt-2 mt-1" style={{width: "fit-content", fontSize: "14px"}} id="new_field_label">
                    Name:
                  </Col>
                  <Col sm="2" className="ps-0 new_field_input_container" style={{width: "40%"}}>
                    <input type="text"
                    placeholder=""
                    className="form-control mt-2 pt-1 h-75"
                    value={ newFieldName[block.id] }
                    onInput={ e => setNewFieldName({...newFieldName, [block.id]: e.target.value}) }
                    autoFocus />
                  </Col>
                  <Col xs={2} md={1} sm="1" className="p-0 pt-2" style={{width: "fit-content"}} onClick={ () => handleCreateNewFieldClick(block.id)}>
                    <i className="fs-6 fa fa-check fa-fw cursor_pointer pt-1 ms-2" style={{color: "green"}}></i>
                  </Col>
                  <Col xs={2} md={1} sm="1" className="pt-2" style={{width: "fit-content"}} onClick={ () => handleCancelCreateNewFieldClick(block.id)}>
                    <i className="fs-6 fa fa-remove cursor_pointer pt-1 ms-2" style={{color: "red"}}></i>
                  </Col>
                </div>
                <div className="flex-row existing_field_group_container" style={{display: createNewField.length > 0 && createNewField.indexOf(block.id) !== -1 ? "none" : "flex"}}>
                  <Col sm="10" className="p-0">
                    <select className="select_field_dropdown form-control form-control-sm select2" block_id={block.id}>
                      <option value={null} key="Select field...">Select existing field...</option>
                      {getFieldOptions(block).map(pair =>
                        <optgroup label={pair.label}>
                          {pair.options.map(entity =>
                            <option value={entity.value} key={entity.label} disabled={(entity.value === null && entity.label !== "Select field...") ? true : false}>{entity.label}</option>
                          )}
                        </optgroup>
                      )}
                    </select>
                  </Col>
                  <Col sm="2" className="add_new_field_button_container" style={{display: "flex", justifyContent: "center"}}>
                    <div className="fa fa-plus-circle cursor_pointer" style={{paddingTop: "5px", fontSize: "20px", color: "green"}} onClick={() => setCreateNewField(createNewField =>[...createNewField, block.id])}/>
                  </Col>
                </div>
              </div>}
            </div>

            { (props.view_mode === "member_edit" || props.view_mode === "preview" || props.view_mode === "member_preview") && <div className="block-body d-flex" style={{marginTop: "10px"}}>
              { getDisplayedMultipleAnswersMetadata(block.metadata_ids).map(metadatum =>
                <div className={ `metadatum ${props.view_mode} ${metadatum.values_type}`} key={metadatum.metadatum_id} onDragOver={(e) => onDragOver(e, metadatum.metadatum_id, "metadatum", block.id)}>
                  {/* MULTIPLE ANSWERS FIELDS START*/}
                  <Row>
                    <Col style={{marginBottom: "5px", fontSize: "14px"}}>
                      {metadatum.name}
                    </Col>
                  </Row>

                  { props.view_mode == "manager" && 
                      <Row>
                        <Col className="col-4 fs-7 text-center action_buttons" style={{color: "#3F92E6", cursor: "pointer"}}
                          metadatum_id={metadatum.metadatum_id}
                          block_id={block.id}
                          tab_id={props.current_tab.id}
                          onClick={() => handleEditMetadatumValuesClick(props.current_tab.id, block.id, metadatum.metadatum_id)}>
                          Edit Values
                        </Col>
                        <Col className="col-4 fs-7 text-center" style={{color: "red", cursor: "pointer"}}
                          onClick={() => handleHideMetadatumClick(metadatum.metadatum_id, block.id, props.current_tab.id)}>
                          Remove
                        </Col>
                      </Row>
                  }

                  { (props.view_mode === "member_edit" || props.view_mode === "preview" || props.view_mode === "member_preview") && <Row>
                    {metadatum.possible_values.map((value, index) => 
                      <Form.Check 
                        name={"option_" + index + "_" + metadatum.metadatum_id}
                        value = { props.memberMetadata[metadatum.metadatum_id] && isOptionSelected(props.memberMetadata[metadatum.metadatum_id], value.toString()) }
                        defaultChecked = {props.memberMetadata[metadatum.metadatum_id] !== null && isOptionSelected(props.memberMetadata[metadatum.metadatum_id], value.toString()) }
                        type='checkbox'
                        id={"option_" + index + "_" + metadatum.metadatum_id}
                        label={value}
                        // disabled={props.view_mode !== "member_edit"}
                        // onClick={() => {return}}
                        className={`col-6 multiple_answers_checkboxes ${props.view_mode}`}
                        style={{fontSize: "16px", fontWeight: props.memberMetadata[metadatum.metadatum_id] && isOptionSelected(props.memberMetadata[metadatum.metadatum_id], value.toString()) ? "bold" : "normal"}}
                        onChange={() => handleMemberMetadataInput(metadatum.metadatum_id, value, "multiple_answers")} 
                      />
                    )
                  }</Row>}
                  {/* MULTIPLE ANSWERS FIELDS END */}
                </div>
              )}
            </div>}
          </div>
        </div>
      )}
    </Fragment>
  )

}
export default CustomTab