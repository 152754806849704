import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../../stock_orders/components/Modal';
import BulkEmailDetails from '../../email_center/components/BulkEmailDetails';
import EmailTemplateBody from '../../email_center/components/EmailTemplateBody';
import BulkTextMessageDetails from '../../text_message_center/components/BulkTextMessageDetails.jsx';
import TextMessageTemplateBody from '../../text_message_center/components/TextMessageTemplateBody';

function CommunicationHistoryModal(props) {

  const {modal_open, communication, communication_type, calendar_view} = props;

  const handleClose = () => {
    props.closeCommunicationTemplateModal()
  }

  const handleUnscheduleCommunicationClick = () => {
    if(window.confirm(`Are you sure you want to cancel this scheduled ${communication_type == "email" ? "email" : "text message"}?`)){
      props.unscheduleCommunication(communication.id, communication_type)
    }
    handleClose()
  }

  const handlePauseScheduledCommunicationClick = () => {
    if(window.confirm(`Are you sure you want to pause this scheduled ${communication_type == "email" ? "email" : "text message"}?`)){
      props.pauseScheduledCommunication(communication.id, communication_type)
    }
    handleClose()
  }

  const handleResumeScheduledCommunicationClick = () => {
    if(window.confirm(`Are you sure you want to resume this paused scheduled ${communication_type == "email" ? "email" : "text message"}?`)){
      props.resumeScheduledCommunication(communication.id, communication_type)
    }
    handleClose()
  }

  const get_modal_communication_body = (communication) => {
    return <div className='template_content'>
      <Col className={`${communication_type == 'email' ? 'p-2' : 'col-6 offset-3'}`} style={{maxHeight: "55vh", overflowY: "auto"}}>
        {communication_type == 'email' && <EmailTemplateBody template={communication}/>}
        {communication_type == 'text_message' && <TextMessageTemplateBody template={communication}/>}
      </Col>
    </div>
  }

  const get_modal_communication_details = (communication) => {
    return <div className='template_content'>
      <Col className='p-2'>
        {communication_type == 'email' && <BulkEmailDetails template={communication}/>}
        {communication_type == 'text_message' && <BulkTextMessageDetails template={communication}/>}
      </Col>
    </div>
  }

  return (
    <div className="modal_show_communication_modal">
      <Modal isOpen={modal_open} className='template_show_modal' modalClasses="modal-lg">
        <ModalHeader>
          <h5 className="modal-title no-margin bold">{communication.subject}</h5>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button" onClick={handleClose}></button>
        </ModalHeader>
        <ModalBody>
          {get_modal_communication_details(communication)}

          {get_modal_communication_body(communication)}         
        </ModalBody>
        <ModalFooter>
          <div className='w-100'>
            <button
              className="float-start ms-2 btn gems_custom_button"
              aria-label="Close"
              onClick={handleClose}
            >
              Close
            </button>
            {communication.scheduled_communication && calendar_view && <a 
              className="float-end me-2 btn gems_custom_button gems_orange_button"
              href={communication.communication_template_edit_link}
              data-method="GET"
            >
              Edit

            </a>}
            {communication.scheduled_communication && calendar_view && <span
              className="float-end me-2 btn gems_custom_button gems_orange_button"
              onClick={handleUnscheduleCommunicationClick} 
            >
              Delete

            </span>}
            {communication.paused && communication.scheduled_communication && calendar_view && <span
              className="float-end me-2 btn gems_custom_button gems_orange_button"
              onClick={handleResumeScheduledCommunicationClick} 
            >
              Resume

            </span>}
            {!communication.paused && communication.scheduled_communication && calendar_view && <span
              className="float-end me-2 btn gems_custom_button gems_orange_button"
              onClick={handlePauseScheduledCommunicationClick} 
            >
              Pause

            </span>}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CommunicationHistoryModal
