import { connect } from 'react-redux'
import { closeModal, createStockOrder, updateStockOrder, editStockOrder, uploadFile, openLogosModal, closeLogosModal} from '../actions'
import DetailedEntry from '../components/DetailedEntry'
                     
const mapStateToProps = (state, props) => ({
  ...props,
  modal_open: state.stock_order.modal_open,
  vendors_list: state.stock_order.vendors_list,
  carrier_options: state.stock_order.carrier_options,
  categories_list: state.stock_order.categories_list,
  categories_attributes: state.stock_order.categories_attributes,
  categories_by_id: state.stock_order.categories_by_id,
  sizing_table: state.stock_order.sizing_table,
  view_only_stock_orders: state.stock_order.view_only_stock_orders,
  can_place_stock_orders: state.stock_order.can_place_stock_orders,
  modal_logos_open: state.stock_order.modal_logos_open
})

const mapDispatchToProps = (dispatch) => ({
  openLogosModal: (item, category_id) => dispatch(openLogosModal(item, category_id)),
  closeLogosModal: () => dispatch(closeLogosModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedEntry)
