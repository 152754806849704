import React from "react";
import { Col, Tooltip, OverlayTrigger, Table } from "react-bootstrap";
import TemplateTableRow from "./TemplateTableRow";

function TemplateTable(props) {
	const { template_category, settings, notification_channel } = props;

	return (
		<Table
			striped
			className="tableFixHead background_white align-middle p-0 m-0 automated_templates_table"
		>
			<thead className="background_black_grey white">
				<tr>
					<th style={{ width: "600px" }}>NAME</th>
					{template_category.key != "golf_shop_central" && (
						<th className="text-center">ENABLED</th>
					)}
					<th>RECIPIENT</th>
					<th />
				</tr>
			</thead>

			<tbody>
				{Object.keys(template_category.templates).map((template_key) => (
					<TemplateTableRow
						notification_channel={notification_channel}
						template={template_category.templates[template_key]}
						template_key={template_key}
						template_category={template_category.key}
						settings={settings}
						key={`template_table_${
							template_category.key
						}_row_${template_key}_enabled_${
							template_category.templates[template_key].enabled
								? "true"
								: "false"
						}`}
						changeTemplateEnableValue={props.changeTemplateEnableValue}
						changeTemplateDemoRemindersDaysValue={
							props.changeTemplateDemoRemindersDaysValue
						}
						changeTemplateAttributeValue={props.changeTemplateAttributeValue}
					/>
				))}
			</tbody>
		</Table>
	);
}

export default TemplateTable;
