import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import TimePeriod from './TimePeriod';
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import BarChartComponent from './shared/BarChartComponent';
import OrderTrend from './shared/LineTrend';
import Summary from './shared/Summary';
import PieChartComponent from './shared/PieChartComponent';
import LineTrend from './shared/LineTrend';

function DemoClub(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);
  const {customer_id, moduleKey, from, to, reference_date, date_format, refresh_date} = props;
  const {} = state;

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "demo_club", customer_id: customer_id, to: to, from: from, reference_date: reference_date, date_format: date_format})
  });

  const include_demos_options = [
    {label: "returned", value: "demo_trackers.returned_date"}, 
    {label: "issued", value: "demo_trackers.issued_date"}, 
  ]
  
  return (
    <div className="col h-100 d-flex flex-column w-100 background_white">
      <TimePeriod to={to} 
                  from={from} 
                  reference_date={reference_date}
                  date_format={date_format}
                  refresh_date={refresh_date}
                  moduleKey={moduleKey} 
                  customer_id={customer_id}
                  load_component_data={props.load_component_data} 
                  options_for_select={include_demos_options}/> 
      <Summary data={props.components.summary["data"]} isLoading={props.components.summary["isLoading"]}/>
      <b className='main_header'>ANALYZE</b>
      <div className='row'>
        <BarChartComponent 
                title="TOP CLUBS" 
                chart_data={props.components.top_clubs["chart_data"]}
                isLoading={props.components.top_clubs["isLoading"]}
                sort_options={[]}
                filters={props.components.top_clubs["filters"]}
                componentKey="top_clubs" 
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                />
        <BarChartComponent 
                title="TOP MEMBERS" 
                chart_data={props.components.top_members["chart_data"]}
                isLoading={props.components.top_members["isLoading"]}
                sort_options={[]} 
                filters={props.components.top_members["filters"]}
                componentKey="top_members" 
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                />
      </div>
      <div className='row'>
      <PieChartComponent title='DEMO RESULT' 
                      componentKey='demo_results' 
                      data={props.components.demo_results["chart_data"]}
                      isLoading={props.components.demo_results["isLoading"]}
                      />
      <PieChartComponent title='COMPONENT OR FIXED DEMO' 
                    componentKey='demo_club_type'
                    data={props.components.demo_club_type["chart_data"]}
                      isLoading={props.components.demo_club_type["isLoading"]}
                    />
      </div>
      <div className='row'>
        <LineTrend 
                    title="DEMO TREND" data={props.components.demo_trend["chart_data"]}
                    isLoading={props.components.demo_trend["isLoading"]}
                    dataKey="Demos"
                      />

        <BarChartComponent 
                title="Club Details Analyzer" 
                chart_data={props.components.demo_clubs_analyzer["chart_data"]}
                isLoading={props.components.demo_clubs_analyzer["isLoading"]}
                sort_options={props.custom_fields_list}
                filters={props.components.demo_clubs_analyzer["filters"]}
                sortTitle="Select Custom Field"
                componentKey="demo_clubs_analyzer" 
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                moduleKey={moduleKey}
                />
      </div>
    </div>
  )
}
export default DemoClub
