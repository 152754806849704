import { ActionTypes } from '../actions'

const {
  FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST,
  PAGINATE_UNSENT_TEXT_MESSAGES,
  UNSCHEDULE_TEXT_MESSAGE,
  DELETE_DRAFT_TEXT_MESSAGE,
  PAUSE_SCHEDULED_TEXT_MESSAGE,
  RESUME_SCHEDULED_TEXT_MESSAGE
} = ActionTypes

const initialState = {
  text_messages: [],
  isLoading: true,
  scheduled_text_messages_total_pages: null
}

let text_messages_list = []
let index_id = null
  
const scheduled_text_messages = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST:
      return {
        ...state,
        text_messages: action.data.scheduled_text_messages,
        isLoading: false,
        scheduled_text_messages_total_pages: action.data.scheduled_text_messages_total_pages
      }

    case UNSCHEDULE_TEXT_MESSAGE:
      const updated_text_messages_list = [...state.text_messages].filter(function(text_message) { return text_message.id !== action.data })
      return {
        ...state,
        text_messages: updated_text_messages_list,
      }

      case DELETE_DRAFT_TEXT_MESSAGE:
        const new_text_messages_list = [...state.text_messages].filter(function(text_message) { return text_message.id !== action.data })
        return {
          ...state,
          text_messages: new_text_messages_list,
        }
  
    case PAUSE_SCHEDULED_TEXT_MESSAGE:
      text_messages_list = [...state.text_messages];
      index_id = null

      text_messages_list.map((text_message, index) => {
        if (text_message.id.toString() == action.data.id) 
        index_id = index;
      })

      text_messages_list[index_id] = action.data

      return {
        ...state,
        text_messages: text_messages_list,
      }

    case RESUME_SCHEDULED_TEXT_MESSAGE:
      text_messages_list = [...state.text_messages];
      index_id = null

      text_messages_list.map((text_message, index) => {
        if (text_message.id.toString() == action.data.id) 
        index_id = index;
      })

      text_messages_list[index_id] = action.data

      return {
        ...state,
        text_messages: text_messages_list,
      }
    
    case PAGINATE_UNSENT_TEXT_MESSAGES:
      const scheduled_text_messages_list = [...state.text_messages]
      const paginated_scheduled_text_messages = scheduled_text_messages_list.concat(action.data)
      const page_count = action.data.length > 0 ? state.page + 1 : state.page
      return {
        ...state,
        text_messages: paginated_scheduled_text_messages,
        page: page_count,
      }
  
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default scheduled_text_messages