import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import StockOrdersPageContainer from './StockOrdersPageContainer'

const StockOrdersRoot = ({customer_id, carrier_options, categories_by_id, stock_order_id, can_manage_stock_orders, view_only_stock_orders, can_place_stock_orders, condensed_tile_layout, logos_list, date_format, cdn_assets_urls}) => {
  const state = {
    stock_order: {
      customer_id: customer_id,
      date_format: date_format,
      carrier_options: carrier_options,
      categories_by_id: categories_by_id,
      can_manage_stock_orders: can_manage_stock_orders,
      view_only_stock_orders: view_only_stock_orders,
      can_place_stock_orders: can_place_stock_orders,
      stock_order_id: stock_order_id,
      logos_list: logos_list,
      condensed_tile_layout: condensed_tile_layout,
      cdn_assets_urls: cdn_assets_urls
    },
  }
  
  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={ store }>
      <StockOrdersPageContainer/>
    </GlobalProvider>
  )
}

StockOrdersRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export default StockOrdersRoot
