import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class BlockReorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      draggedItem: null,
      blocks: this.props.blocks,
    }
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.swapBlocks = this.swapBlocks.bind(this)
  }

  swapBlocks(block1Id, block2Id) {
    const block1 = this.state.blocks.filter(block => block.id === block1Id)[0]
    const block2 = this.state.blocks.filter(block => block.id === block2Id)[0]

    this.setState({
      blocks: this.state.blocks.map(block =>
        (block.id === block1Id)
          ? {...block, index: block2.index}
          : block)
        .map(block =>
          (block.id === block2Id)
            ? {...block, index: block1.index}
            : block) })
  }

  onDragStart(e, blockId) {
    this.setState({
      draggedItem: blockId,
    })
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  onDragOver(blockId) {
    if (this.state.draggedItem === blockId) {
      return
    }
    this.swapBlocks(blockId, this.state.draggedItem)
    return
  }

  onDragEnd() {
    this.setState({
      draggedItem: null,
    })
  }

  render() {
    return (
      <div className='manage-tabs col'>
        <ModalHeader >
          <h4 className="modal-title form_new_tab no-margin">Reorder Blocks</h4>
        </ModalHeader>
        <ModalBody>
          <div className="col inner-content mt-3 mb-3">
            { this.state.blocks.sort( (a, b) => a.index <= b.index ? -1 : 1 ).map(block =>
                <div key={block.id} className="row tab" onDragOver={() => this.onDragOver(block.id)}>
                  <Col xs={2} md={1}
                      className="movable-icon"
                      draggable
                      onDragStart={ (e) => this.onDragStart(e, block.id) }
                      onDragEnd={ this.onDragEnd } >
                    <i className="fa fa-bars"></i>
                  </Col>
                  <Col xs={6} md={9}>
                    <span>
                      { block.title !== ''
                          ? `${block.title}, ${window.I18n.t('multi_widgets.tiles')}: ${this.props.columns.filter(c => c.blockId === block.id).length}`
                          : ` - , ${window.I18n.t('multi_widgets.tiles')}: ${this.props.columns.filter(c => c.blockId === block.id).length}` }
                    </span>
                  </Col>
              </div> )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={ e => { e.preventDefault(); this.props.cancel() } } >
              { window.I18n.t('multi_widgets.cancel') }
          </button>
          <button
            className="btn gems_custom_button float-end"
            style={ { backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor)) }}
            onClick={ e => { e.preventDefault(); this.props.reorderBlocks(this.state.blocks); this.props.cancel(); return false } } >
              { window.I18n.t('multi_widgets.save') }
          </button>
        </ModalFooter>
      </div>
    )
  }
}

BlockReorder.propTypes = {
  blocks: PropTypes.array,
  columns: PropTypes.array,
  reorderBlocks: PropTypes.func,
  cancel: PropTypes.func,
}

export default BlockReorder
