import React from 'react'
import ReactDOM from 'react-dom'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import YearContainer from '../containers/YearContainer'
import { Col, Row } from 'react-bootstrap'


const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'

class CalendarBody extends React.Component {
  constructor(props) {
    super(props);

    this.getOverviewStockOrders = this.getOverviewStockOrders.bind(this);
    this.getAllEvents = this.getAllEvents.bind(this);

    this.state = {
      allEvents: [],
      overviewStockOrders: [],
      currentDate: new Date(),
      currentView: "year"
    };
  }

  getOverviewStockOrders(events, selectedDate, view = this.state.currentView) {
    let overviewHash = {}
    let currentMonthEvents = []

    if (view === "week"){
      let startOfWeek = moment(selectedDate).startOf('week')
      let endOfWeek = moment(selectedDate).endOf('week')
      currentMonthEvents = events.filter(e => e.start >= startOfWeek._d && e.end <= endOfWeek._d)
    }
    else{
      currentMonthEvents = events.filter(e => e.start.getMonth() === selectedDate.getMonth() && e.start.getFullYear() === selectedDate.getFullYear())
    }
    
    currentMonthEvents.map(event => {
      event.resource.stock_order_categories_list.map(category => {
        if (overviewHash[category.category_id]){
          overviewHash[category.category_id].vendors[event.resource.vendor] = overviewHash[category.category_id].vendors[event.resource.vendor] ? overviewHash[category.category_id].vendors[event.resource.vendor] + category.qty : category.qty
          overviewHash[category.category_id].total_quantity += category.qty
        }
        else{
          overviewHash[category.category_id] = {
            name: category.name,
            color: category.category_color,
            total_quantity: category.qty,
            vendors: {}
          }
          overviewHash[category.category_id].vendors[event.resource.vendor] = category.qty
        }
      })
    })

    let sortedVendorQuantities = {};
    let vendorNames = []
    Object.keys(overviewHash).map(categoryId => {
      sortedVendorQuantities = {}
      vendorNames = Object.keys(overviewHash[categoryId].vendors);
      vendorNames.sort(function(a, b) {
        return overviewHash[categoryId].vendors[b] - overviewHash[categoryId].vendors[a]
      }).forEach(function(k) {
        sortedVendorQuantities[k] = overviewHash[categoryId].vendors[k];
      });
      overviewHash[categoryId].vendors = sortedVendorQuantities
    })

    return overviewHash
  }

  getAllEvents() {
    const { to_place, placed, delivered, calendar_filter_date, filters_list, quick_search_value} = this.props
    let new_to_place = []
    let new_placed = []
    let new_delivered = []

    // apply product category filters
    new_to_place = (filters_list == "all") ? 
                to_place 
                : 
                to_place.filter((a) => {
                  return _.find(a.stock_order_categories_list, function(o) 
                  { return _.find(filters_list, function(i) 
                    { return i.category_id.includes(o.category_id) })})
                })

    new_placed = (filters_list == "all") ? 
                placed 
                : 
                placed.filter((a) => {
                  return _.find(a.stock_order_categories_list, function(o) 
                  { return _.find(filters_list, function(i) 
                    { return i.category_id.includes(o.category_id) })})
                })
                
    new_delivered = (filters_list == "all") ? 
                delivered 
              : 
              delivered.filter((a) => {
                return _.find(a.stock_order_categories_list, function(o) 
                { return _.find(filters_list, function(i) 
                  { return i.category_id.includes(o.category_id) })})
              })

    // apply quick search filter
    new_to_place = (!quick_search_value) ? 
                new_to_place 
                : 
                new_to_place.filter((filter) => {
                  return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
                })

    new_placed = (!quick_search_value) ? 
                new_placed 
                : 
                new_placed.filter((filter) => {
                  return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
                })
                
    new_delivered = (!quick_search_value) ? 
                new_delivered 
                : 
                new_delivered.filter((filter) => {
                  return (filter.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1)
                })
    
    let date_format = this.props.date_format.toUpperCase()
    let allEvents = new_to_place.concat(new_placed).concat(new_delivered).map((so) => {
      
      return {
        id: so.id,
        start: new Date(moment(so[calendar_filter_date], date_format)),
        end: new Date(moment(so[calendar_filter_date], date_format)),
        title: so.order_no + (so.order_nickname ? " ( "+so.order_nickname+" )" : ""),
        allDay: true,
        color: so.color,
        resource: so
       } 
    })

    return allEvents
  }

  componentDidMount() {
    let allEvents = this.getAllEvents()
    this.setState({allEvents: allEvents, overviewStockOrders: this.getOverviewStockOrders(allEvents, this.state.currentDate)})
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.calendar_filter_date !== this.props.calendar_filter_date || previousProps.filters_list !== this.props.filters_list ||  previousProps.quick_search_value !== this.props.quick_search_value){
      let allEvents = this.getAllEvents()
      this.setState({allEvents: allEvents, overviewStockOrders: this.getOverviewStockOrders(allEvents, this.state.currentDate)})
    }
  }

  render() {
    return (
      <div className={`h-100 ${this.state.currentView === "year" ? "" : "overviewCalendarContainer"}`}>
        <div className={`m-0 bigCalendarStyle h-100 ${Object.keys(this.state.overviewStockOrders).length === 0 ? "w-100" : ""} ${this.state.currentView === "week" && Object.keys(this.state.overviewStockOrders).length > 0 ? "weeklyOverviewCalendar" : ""}`}>
          <Calendar
            localizer={localizer}
            events={ this.state.allEvents }
            toolbar={true}
            popup
            views={{
              week: true,
              month: true,
              year: YearContainer
            }}
            defaultView='year'
            messages={{ year: 'Year' }}
            onSelectEvent = { (v,e) => { this.props.editStockOrder(v.resource.id)} }
            style={{ height: "100%" }}
            eventPropGetter={(event) => {
                  const backgroundColor = event.color ? event.color : '#FF6633';
                  return { style: { backgroundColor } }
                }}
            onNavigate={date => {
              this.setState({overviewStockOrders: this.getOverviewStockOrders(this.state.allEvents, date), currentDate: date});
            }}
            onView={(view) => this.setState({currentView: view, overviewStockOrders: this.getOverviewStockOrders(this.state.allEvents, this.state.currentDate, view)})}
            />
        </div>
        <div id="ordersOverview" className={`m-0 h-100 ${Object.keys(this.state.overviewStockOrders).length === 0 ? "d-none" : ""}`} style={{display: this.state.currentView === "year" ? "none" : "block"}}>
          <div className="bold ps-2 pt-1 pb-2"> Overview</div>
          <div id="overviewContentContainer">
            {Object.keys(this.state.overviewStockOrders).map(category_id =>
              <div className="ps-2 pe-2 categoryContainer"> 
                <div className="w-100" style={{backgroundColor: this.state.overviewStockOrders[category_id].color || "#4B90E2", borderRadius: "4px", color: "white"}} >
                  <div className="bold pt-1 pb-1" style={{marginLeft: "2.5%", marginRight: "2.5%"}}> {this.state.overviewStockOrders[category_id].name} </div>
                  <div style={{borderBottom: "1px solid white", width: "95%", marginLeft: "2.5%"}}/>
                  <div className="bold pt-1 pb-1" style={{marginLeft: "2.5%", marginRight: "2.5%"}}> Total: {this.state.overviewStockOrders[category_id].total_quantity} </div>
                </div>
                <div className="pt-2 pb-2">
                  {Object.keys(this.state.overviewStockOrders[category_id].vendors).map(vendor =>
                    <div className="w-100 d-flex flex-row"> 
                      <div className="ps-2" style={{width: "85%"}}>
                        {vendor}
                      </div>
                      <div className="bold float-end" style={{width: "fit-content"}}>
                        {this.state.overviewStockOrders[category_id].vendors[vendor]}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}


export default CalendarBody
