import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import _ from 'lodash';
import Select from 'react-select';
import { SketchPicker } from 'react-color'; //import the desired picker
import UploadFile from './UploadFile';

//stock_order_categories: [StockOrderCategory.stock_order_items[StockOrderItem{ :stock_order_quantities, :stock_order_metadata }]])]
//categories_list: { [Category] } (nested)
//categories_by_id: { [id] : Category(id, name, parent_id) } 
//categories_attributes : { [category_id]: { [metadatum_id]: metadatum_name }}
//sizing_table: { [id, name, values] }
function CancelDetailedTable(props) {
  const { stock_order_categories, categories_attributes, categories_by_id, sizing_table} = props;

  const sizing_is_present = _.find(stock_order_categories, (soc) => soc.stock_order_sizing_table_id)


  let max_qty_values = 1
  stock_order_categories.map((soc) => {
    if(sizing_table[soc.stock_order_sizing_table_id])
      max_qty_values = (max_qty_values < sizing_table[soc.stock_order_sizing_table_id].values.length) ? sizing_table[soc.stock_order_sizing_table_id].values.length : max_qty_values
  })
  
  return (
      <Table responsive bordered className="tableFixHead">
        <tbody>
        {
          stock_order_categories.map((soc, index_soc) => (
            <>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.06)"}}>
                <td rowSpan="1" colSpan={3+max_qty_values}>
                  <b>{ soc.category_name }</b>
                </td>
              </tr>
              <tr style={{backgroundColor: "rgba(0, 0, 0, 0.03)"}}>
                <td className='text-center'><b>Item Description</b></td>
                <td></td>
                  {
                  sizing_table[soc.stock_order_sizing_table_id] ?
                  (sizing_table[soc.stock_order_sizing_table_id]).values.map((br) => (
                    <td className='text-center width_60px'><b>{br}</b></td>
                  )) 
                  :
                  <td className='text-center width_60px'><b>Quantity</b></td>
                  }
                  {(max_qty_values > (sizing_table[soc.stock_order_sizing_table_id] ? sizing_table[soc.stock_order_sizing_table_id].values.length : 1)) && <td colSpan={max_qty_values - (sizing_table[soc.stock_order_sizing_table_id] ? sizing_table[soc.stock_order_sizing_table_id].values.length : 1)}></td>}
                <td className='text-center'><b>Actions</b></td>
              </tr>
              {
                soc.stock_order_items.map((soi, index_soi) => (
                  <>
                  <tr key={soi.id}>
                    <td rowSpan="2" style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}} className='width_60px text-center'>{ soi.sku }, { soi.description }</td>
                    <td className='text-center' style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}}>
                        <b>Current</b>
                    </td>
                      {
                        sizing_table[soc.stock_order_sizing_table_id]
                        ?
                          sizing_table[soc.stock_order_sizing_table_id].values.map((br) => (
                            <td className='text-center' style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}}>
                            {(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).qty || '-'}
                            </td>
                          ))
                        : 
                          <td className='text-center' style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}}>
                            {soi.qty}
                          </td>
                      }
                      {<>
                        {(max_qty_values > (sizing_table[soc.stock_order_sizing_table_id] ? sizing_table[soc.stock_order_sizing_table_id].values.length : 1)) && <td style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}} colSpan={max_qty_values - (sizing_table[soc.stock_order_sizing_table_id] ? sizing_table[soc.stock_order_sizing_table_id].values.length : 1)}></td>}
                        {<td rowSpan="2" className='text-center' style={{borderTop: index_soi > 0 ? "1px solid rgba(0, 0, 0, 0.3)" : ""}}>
                          <a className='link p-1 color_red' onClick={(e) => props.markCancelled(soc.category_id, soi.id)}>
                          Mark All Items to be cancelled
                          </a>
                          <br></br>
                        </td>}
                        </>}
                  </tr>
                  <tr key={soi.id}>
                    <td className='text-center'>
                      <b>To be Cancelled</b>
                    </td>
                      {
                        sizing_table[soc.stock_order_sizing_table_id]
                        ?
                          
                          sizing_table[soc.stock_order_sizing_table_id].values.map((br) => (
                            <td className='text-center width_60px'>
                            {(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).updated_qty > 0 
                            ?
                              <Form.Control type="number" 
                                name='nr_of_items_to_move'
                                className="text-center"
                                value={(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).nr_of_items_to_move || 0}
                                placeholder='0'
                                min={0}
                                max={(_.find(soi.stock_order_quantities, (soq) => soq.size == br )) && (_.find(soi.stock_order_quantities, (soq) => soq.size == br )).updated_qty}
                                onChange={ (e) => props.handleQuantity(soc.category_id, soi.id, br, e.target.value)} 
                                />
                              :
                              '-'
                            }
                            </td>
                          ))
                        : 
                          <td className='width_60px'>
                          <Form.Control type="number" 
                              name='nr_of_items_to_move'
                              className="text-center"
                              value={soi.nr_of_items_to_move}
                              placeholder='0'
                              onChange={(e) => props.handleQuantity(soc.category_id, soi.id, null, e.target.value)}
                              />
                          </td>
                      }
                  </tr>
                  </>
                ))
              }
            </>
          )) 
        }
        </tbody>
      </Table>
  )
} 


let sizing_table_options = (sizing_table) => {
  let sizing_table_options_arr = [{label: "One size", value: ""}]

  Object.values(sizing_table).map((st) => {
    sizing_table_options_arr.push({label: st.name, value: st.id})
  })

  return sizing_table_options_arr
}

export default CancelDetailedTable