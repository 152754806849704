import React, { PureComponent } from 'react'
import SortingDropdown from '../components/SortingDropdown'
import PropTypes from 'prop-types'
import { MdCallSplit } from 'react-icons/md'
import { FcCancel } from 'react-icons/fc'
import { Col, Table, Spinner, Row } from 'react-bootstrap';

function ListBody(props) {

  const handleUpdateListViewSorting = (col, dir) => {
    let data = {
      sort_column: col,
      sort_direction: dir
    }
    props.updateListViewSorting(data)
  }

  const handleEditStockOrder = (event) => {
    const stock_order_id = event.currentTarget.id
    props.editStockOrder(stock_order_id)
  }

  const handleNextStockOrder = (event, stock_order) => {
    const stock_order_id = event.currentTarget.id

    switch (stock_order["status"]) {
      case "placed":
        props.openReceiveStockOrder(stock_order_id)
        break
      case "delivered":
        props.openFinalizeStockOrder(stock_order_id)
        break
      default:
        props.openPlaceStockOrder(stock_order_id)
        break
    }
  }

  const handleSplitStockOrder = (event) => {
    const stock_order_id = event.currentTarget.id
    props.openSplitStockOrder(stock_order_id)
  }

  const handleDeleteStockOrder = (event) => {
    const stock_order_id = event.currentTarget.id
    if (window.confirm('Are you sure?'))
      props.deleteStockOrder(stock_order_id)
  }

  const handleCancelStockOrder = (event) => {
    const stock_order_id = event.currentTarget.id
    props.openCancelStockOrder(stock_order_id)
  }

  const handleOpenOptionalFields = (event) => {
    const stock_order_id = event.currentTarget.id
    props.openListViewOptionalFieldsModal(stock_order_id)
  }

  const getStatusFormatted = (stock_order) => {
    if (stock_order["status"] == "cancelled")
      return "Cancelled"
    else if (stock_order["status"] == "to_place")
      return "Orders to place"
    else if (stock_order["status"] == "placed")
      return "Order placed"
    else if (stock_order["status"] == "delivered")
      return "Delivered"
    else
      return "Deleted"
  }

  const { list_view_stock_orders, sort_column, sort_direction, view_only_stock_orders, in_fields, openCancelStockOrder, quick_search_value, filters_list } = props

  let list_view_stock_orders_sorted = list_view_stock_orders.length > 0 ? sort_direction == 'desc' ? _.sortBy(list_view_stock_orders, [function (o) { return o[sort_column]; }]).reverse() : _.sortBy(list_view_stock_orders, [function (o) { return o[sort_column]; }]) : list_view_stock_orders

  const tableHeadColumns = in_fields.map((in_field, index) =>
    <th key={index} className={sort_column == in_field ? sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort"} style={{ whiteSpace: "nowrap", paddingLeft: "15px", cursor: "pointer" }} onClick={() => handleUpdateListViewSorting(in_field, sort_direction == "asc" ? "desc" : "asc")}>{in_field.replace(/_/g, ' ').toUpperCase()}</th>
  );

  return (
    <div className='col-12 h-100 p-0'>
      <div className="table-responsive tableFixHead h-100">
        <Table striped className="table fields_table align-middle background_white table-striped">
          <thead className="table-lightgrey">
            <tr className="color_black">
              <th className="text_align_center ps-4">
                <div className="btn btn-actions container_fields_btn background_white" style={{ paddingBottom: "2px", border: '1px solid rgb(176,177,180)' }}>
                  <a className="icon-th-list" onClick={handleOpenOptionalFields} id={list_view_stock_orders_sorted[0] || null}></a>
                </div>
              </th>
              {tableHeadColumns}
            </tr>
          </thead>

          <tbody>
            {list_view_stock_orders_sorted.map((stock_order, index) =>
              (!quick_search_value || (quick_search_value && (stock_order.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) && ((filters_list == "all") || _.find(stock_order.stock_order_categories_list, function (o) { return _.find(filters_list, function (i) { return i.category_id.includes(o.category_id) }) })) &&
              <tr key={index}>
                <td className="text_align_center background_white ps-4">
                  <div className="dropdown">
                    <button className="btn dropdown-toggle lh-1 fs-5" style={{ paddingBottom: "0px", paddingTop: "0px", paddingLeft: "3px", paddingRight: "3px", border: '1px solid rgb(176,177,180)' }} aria-expanded="false" data-bs-toggle="dropdown" type="button" id={"dropdownContainerItemMenu_" + stock_order.id} />
                    <ul className="dropdown-menu" aria-labelledby={"dropdownContainerItemMenu_" + stock_order.id}>
                      {!view_only_stock_orders && <li className="dropdown-item">
                        <a className="color_black dropdown-item" onClick={handleEditStockOrder} id={stock_order.id}>
                          <i className="fa fa-pencil" />
                          <span className="border-bottom">
                            Edit
                          </span>
                        </a>
                      </li>}
                      {view_only_stock_orders && <li className="dropdown-item">
                        <a className="color_black dropdown-item" onClick={handleEditStockOrder} id={stock_order.id}>
                          <i className="fa fa-eye" />
                          <span className="border-bottom">
                            View
                          </span>
                        </a>
                      </li>}
                      {!stock_order.vendor_cancel_date && !view_only_stock_orders && <li className="dropdown-item">
                        <a className="color_black dropdown-item" onClick={handleSplitStockOrder} id={stock_order.id}>
                          <i><MdCallSplit /></i>
                          <span className="border-bottom">
                            Split
                          </span>
                        </a>
                      </li>}
                      {!view_only_stock_orders && <li className="dropdown-item">
                        <a className="color_red dropdown-item" onClick={handleDeleteStockOrder} id={stock_order.id}>
                          <i className="fa fa-trash" />
                          <span className="border-bottom">
                            Delete
                          </span>
                        </a>
                      </li>}
                      {openCancelStockOrder && !view_only_stock_orders && !stock_order.vendor_cancel_date && <li className="dropdown-item">
                        <a className="color_red dropdown-item" onClick={handleCancelStockOrder} id={stock_order.id}>
                          <i><FcCancel /></i>
                          <span className="border-bottom">
                            Cancel Order/Items
                          </span>
                        </a>
                      </li>}
                    </ul>
                  </div>
                </td>
                {in_fields.map((in_field, index) =>
                  <td key={index} style={{ whiteSpace: "nowrap", paddingLeft: "15px", color: `${in_field == 'status' ? '#8F8F8F' : ''}` }}>
                    {in_field == "order_number" &&
                      <a className="link" onClick={handleEditStockOrder} id={stock_order["id"]}>
                        {stock_order[in_field]}
                      </a>
                    }
                    {in_field == "status" && getStatusFormatted(stock_order)}
                    {in_field == "status" &&
                      <a className="btn_icons_datatable padding_forward link next pull-right standard" onClick={(e) => handleNextStockOrder(e, stock_order)} id={stock_order["id"]} data-toggle="tooltip" title="" data-bs-original-title="Next" aria-label="Next">
                        <i className="font_size_larger fa fa-forward"></i>
                      </a>
                    }
                    {in_field != "order_number" && in_field != "status" && (stock_order[in_field] || '-')}
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
} export default ListBody
