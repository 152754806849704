import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { Form, Col, Row } from 'react-bootstrap';
import update from 'immutability-helper';
import moment from 'moment';

function DashboardAdvancedFilter(props) {


  const initialState = { open_search: false, new_quick_search_value: "", search_params: props.search_params, date_type_id: 0 }

  const { vendors_list } = props;

  const [state, setState] = useState(initialState);

  const status_options = [
    { value: '', label: 'All' },
    { value: 'to place', label: 'Orders to place' },
    { value: 'placed', label: 'Orders placed' },
    { value: 'delivered', label: 'Orders delivered' }
  ]

  const placed_options = [
    { value: 'upcoming early cancel date', label: 'Upcoming Early Cancel Date' },
    { value: 'order shipped', label: 'Order shipped' },
    { value: 'order not shipped', label: 'Order not shipped' },
    { value: 'shipping overdue', label: 'Shipping overdue' },
    { value: 'past start date', label: 'Past Start Date' },
    { value: 'past end date', label: 'Past End Date' },
    { value: 'past expected delivery date', label: 'Past Expected Delivery Date' },
    { value: 'vendor email not opened', label: 'Vendor Email Not Opened'}
  ]

  const delivered_options = [
    { value: 'invoice not received', label: 'Invoice Not Received' },
    { value: 'inventory not received', label: 'Not received into inventory' },
    { value: 'invoice not processed', label: 'Invoice not processed' },
  ]

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip">
      {(props.filters_list_count != "all") && props.filters_list.map((f, index) => (
        <div key={f.category_id}>
          <span>{(parseInt(index) + 1) + ". "}</span>
          <span>{f.category_name}</span>
          <br></br>
        </div>
      ))}
    </Tooltip>
  );

  //setup before functions
  let typingTimer;                //timer identifier
  let doneTypingInterval = 500;  //time in ms, 5 second for example

  const handleQuickSeachKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(QuickSeach, doneTypingInterval);
  }

  const handleQuickSeachKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const QuickSeach = () => {
    props.changeQuickSearchValue(state.new_quick_search_value)
  }
  const handleChangeQuickSearchValue = (event) => {
    setState({ ...state, new_quick_search_value: event.target.value });
  }
  const handleQuickSeachReset = () => {
    setState({ ...state, new_quick_search_value: "" });
    props.changeQuickSearchValue(null)
  }

  const handleCategorySelectChange = (event) => {
    props.changeCalendarFilterDate(event.value)
  }

  const handleTileLayoutChange = () => {
    let new_condensed_tile_layout = !props.condensed_tile_layout
    props.changeTileLayout(new_condensed_tile_layout)
  }

  const getMoreOptions = () => {
    if (state['search_params'])
      switch (state['search_params']['status']) {
        case 'placed':
          return placed_options
        case 'delivered':
          return delivered_options
        default:
          return placed_options.concat(delivered_options)
      }
  }

  const handleStatusSelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['status']: { $set: event.value },
        ['more_status']: { $set: null }
      }
    }))
  }

  const handleMoreStatusSelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['more_status']: { $set: event.value }
      }
    }))
  }

  const handleVendorSelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['vendor_id']: { $set: event.value }
      }
    }))
  }

  const handleCategorySelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['category_id']: { $set: event.value }
      }
    }))
  }

  const handleDateTypeSelectFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['date_type_id']: { $set: event.value }
      }
    }))
  }

  const handleStartDateFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['start_date']: { $set: event }
      }
    }))

  }

  const handleEndDateFilterChange = (event) => {
    setState(update(state, {
      search_params: {
        ['end_date']: { $set: event }
      }
    }))
  }

  const handleSearchKeywordChange = (event) => {
    setState(update(state, {
      search_params: {
        ['search_keyword']: { $set: event.target.value }
      }
    }))
  }

  const handleSeach = () => {
    let search_params = { ...state }

    search_params.search_params = JSON.stringify(search_params.search_params)

    props.search(search_params)
  }

  const handleResetSeach = () => {
    setState(update(state, {
      search_params: {
        ["finalized"]: { $set: false },
        ["canceled"]: { $set: false },
        ["deleted"]: { $set: false },
        ["status"]: { $set: '' },
        ["more_status"]: { $set: null },
        ["vendor_id"]: { $set: null },
        ["category_id"]: { $set: null },
        ["date_type_id"]: { $set: null },
        ["start_date"]: { $set: null },
        ["end_date"]: { $set: null },
        ["search_keyword"]: { $set: null }
      }
    }))

    props.search()
  }

  const handleOpenSearch = () => {
    setState({ ...state, open_search: !state.open_search });
  }

  const handleCheckboxInputChange = (event) => {

    setState(update(state, {
      search_params: {
        [event.target.name]: { $set: event.target.checked }
      }
    }))
  }

  const { filters_list_count, openCategoryFilterModal, removeAllFilters, calendar_filter_dates_options, view, quick_search_value, condensed_tile_layout } = props
  const { calendar_filter_date, search_params, open_search } = state

  const more_options = getMoreOptions()

  return (
    <div className="row">
      <div className="col-12 quick-search-dropdown py-2">
        <div className="row">
          <div className={(view == "calendar" ? "col-lg-2" : "col-lg-4") + " link col add-line-height-35"} onClick={handleOpenSearch} style={{ cursor: 'pointer' }}>
            Advanced Filter
          </div>

          {view == "calendar" &&
            <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 300 }}>
              <Select id="calandar_filter_date"
                name='calandar_filter_date'
                options={calendar_filter_dates_options}
                value={calendar_filter_dates_options.filter(function (option) {
                  return option.value === (calendar_filter_date || props.calendar_filter_date);
                })}
                onChange={handleCategorySelectChange}
              />

            </div>
          }
          <div className="col add-line-height-35">
            {filters_list_count != "all" ?
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(props)}
              >
                <b>Showing {filters_list_count > 1 ? filters_list_count + " categories" : "category: " + props.filters_list[0].category_name}</b>
              </OverlayTrigger> :
              <b>Showing all categories</b>
            }
            <a className="fa fa-pencil mx-2" title="Edit filter" onClick={openCategoryFilterModal} />
            {(filters_list_count != "all") && <a className="color_red fa fa-trash" title="Remove all filters" onClick={removeAllFilters} />}
          </div>
          <Col onClick={handleTileLayoutChange} className={(view == "pipedrive" ? "" : "d-none") + " col col-lg-2 p-0 condensed_expanded_class d-flex justify-content-end"}>
            <input type="checkbox"
              data-toggle="toggle"
              data-on="Condensed"
              data-off="Expanded"
              data-style="rounded"
              defaultChecked={condensed_tile_layout}
              className="toggle btn-primary btn-sm rounded"
            />
          </Col>
          <div className="col col-md-4 col-lg-3 col-xl-2">
            <div className="row m-0">
              <div className="col-10 p-0">
                <div className="input-group input-group-sm">
                  <Form.Control type="text"
                    name='quick_search'
                    placeholder='Search...'
                    value={state.new_quick_search_value || ""}
                    onChange={handleChangeQuickSearchValue}
                    onKeyUp={handleQuickSeachKeyUp}
                    onKeyDown={handleQuickSeachKeyDown}
                  />
                  <div className="input-group-text clear_quick_search fa fa-eraser" onClick={handleQuickSeachReset}>
                  </div>
                </div>
              </div>
              <div className="col-1 add-line-height-35 fs-4 text-end">
                {!open_search ? <TiArrowSortedDown className="cursor_pointer" onClick={handleOpenSearch} /> : <TiArrowSortedUp className="cursor_pointer" onClick={handleOpenSearch} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {open_search && <div className="row quick-search-dropdown search_container_for_stock_orders m-0">
        <div className="row">
          <div className="col-12 p-0">
            <Form.Group as={Row} className="" controlId="formSearch" style={{ zIndex: 200, paddingTop: 10 }}>

              <Form.Label column sm="2">
                Status
              </Form.Label>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 200 }}>
                <Select id="status"
                  name='status'
                  value={status_options.filter(function (option) {
                    return option.value == search_params.status
                  })}
                  options={status_options}
                  onChange={handleStatusSelectFilterChange} />
              </div>

              {search_params.status != 'to place' && <Form.Label column sm="2">
                More
              </Form.Label>}

              {search_params.status != 'to place' && <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 200 }}>
                <Select id="more_status"
                  name='more_status'
                  value={more_options.filter(function (option) {
                    return option.value === search_params.more_status;
                  })}
                  options={more_options}
                  onChange={handleMoreStatusSelectFilterChange} />
              </div>}

              <Form.Label column sm="2">
                Vendors
              </Form.Label>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 200 }}>
                <Select id="vendor_id"
                  name='vendor_id'
                  value={vendors_list.filter(function (option) {
                    return option.value === search_params.vendor_id;
                  })}
                  options={vendors_list}
                  onChange={handleVendorSelectFilterChange} />
              </div>

            </Form.Group>

            <Form.Group as={Row} className="" controlId="formSearch" style={{ zIndex: 100, paddingTop: 10, paddingBottom: 10 }}>

              <Form.Label column sm="2">
                Search Keyword
              </Form.Label>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 50 }}>
                <Form.Control type="text"
                  name='search_keyword'
                  obj_type="original"
                  value={search_params.search_keyword || ""}
                  onChange={handleSearchKeywordChange} />
              </div>

              <Form.Label column sm="2">
                Date Range
              </Form.Label>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 100 }}>
                <Select id="date_type_id"
                  name='date_type_id'
                  options={calendar_filter_dates_options}
                  value={calendar_filter_dates_options.filter(function (option) {
                    return option.value === search_params.date_type_id;
                  })}
                  onChange={handleDateTypeSelectFilterChange} />
              </div>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 100 }}>
                <DatePicker className="form-control"
                  name='start_date'
                  id='start_date'
                  selected = {Date.parse(moment(search_params.start_date, props.date_format.toUpperCase()))}
                  value =  {Date.parse(moment(search_params.start_date, props.date_format.toUpperCase()))}
                  dateFormat={ props.date_format.replaceAll('m', 'M')}
                  onChange={handleStartDateFilterChange}
                  />
              </div>

              <div className="col col-lg-2 calendar-datetype-selector" style={{ zIndex: 100 }}>
                <DatePicker className="form-control"
                  name='end_date'
                  id='end_date'
                  selected = {Date.parse(moment(search_params.end_date, props.date_format.toUpperCase()))}
                  value =  {Date.parse(moment(search_params.end_date, props.date_format.toUpperCase()))}
                  dateFormat={ props.date_format.replaceAll('m', 'M')}
                  onChange={handleEndDateFilterChange}
                  />
              </div>

            </Form.Group>

            <Form.Group as={Row} className="" controlId="formSearch">
              <Form.Label column sm="2">
                SHOW STOCK ORDERS
              </Form.Label>
              <Form.Check
                name="finalized"
                checked={search_params.finalized || false}
                type='checkbox'
                id="finalized"
                label="Finalized"
                className="col"
                onChange={handleCheckboxInputChange}
              />
              <Form.Check
                name="canceled"
                checked={search_params.canceled || false}
                type='checkbox'
                id="canceled"
                label="Canceled"
                className="col"
                onChange={handleCheckboxInputChange}
              />
              <Form.Check
                name="deleted"
                checked={search_params.deleted || false}
                type='checkbox'
                id="deleted"
                label="Deleted"
                className="col"
                onChange={handleCheckboxInputChange}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row m-0">
          <div className="col p-0">
            <a className="btn gems_custom_button float-end order-2" onClick={handleSeach}>
              Search
            </a>
            <a className="btn gems_custom_button float-end me-2 order-1" onClick={handleResetSeach}>
              Reset
            </a>
          </div>
        </div>
      </div >}
    </div >
  )
}


export default DashboardAdvancedFilter
