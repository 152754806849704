import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Toast } from 'react-bootstrap'
import { DISPLAY_MODES } from '../../actions'
import EditTabs from '../containers/edit_tabs'
import DisplayTabs from '../containers/display_tabs'
import AddBlock from '../containers/add_block'
import DisplayBlocks from '../containers/display_blocks'
import CreateBlock from '../containers/create_block'
import ManageTabs from '../containers/manage_tabs'
import ManageColumns from '../containers/manage_columns'
import CreateTab from '../containers/create_tab'
import ManageWidget from '../containers/manage_widgets'
import NewPageCreate from '../containers/new_page_create'
import EditBlock from '../containers/edit_block'
import ReorderBlocks from '../containers/reoreder_blocks'
import BlocksReorderButton from '../containers/blocks_reorder_button'
import Popup from '../presentational/popup'
import Inventory from '../containers/Inventory'
import Notification from '../containers/Notification'
import ReserveDemo from '../containers/ReserveDemo'
import GeneralSettings from './general_settings'
import rootReducer from '../../reducers'
import configureStore from '../../../shared/configure_store'
import RequestRepair from '../containers/RequestRepair'
import FlashMessage from './flash_message'
import { hexToRgb, getComplementaryColor} from '../../../shared/helpers'
import { connect } from 'react-redux'
import { setSettingsAttribute } from '../../actions'
import Login from '../containers/Login'
import LayoutSettings from "./layout_settings";



class Page extends Component {
  constructor(props) {
    super(props)
  }

  getContainerStyle(){
    let style = {}
    
    if(this.props.general_settings.enable_background_color) {
      style["backgroundColor"] = this.props.general_settings.background_color

      if(!this.props.general_settings.enable_background_image){
        let comp = getComplementaryColor((style["backgroundColor"]))

        style["borderLeft"] = `2px solid ${comp}`
        style["borderRight"] = `2px solid ${comp}`
        style["borderBottom"] = `2px solid ${comp}`
      }
    } else {
      style["backgroundColor"] = "#ffffff"
      style["borderLeft"] = `1px solid #424242`
      style["borderRight"] = `1px solid #424242`
      style["borderBottom"] = `1px solid #424242`
    }
    
    style["backgroundColor"] += this.props.general_settings.background_opacity != '100' ? this.props.general_settings.background_opacity : ''

    return style
  }

  getOutterPageStyles(){
    let style = {}

    if(this.props.general_settings.enable_background_image){
      style = {backgroundImage: `url(${this.props.general_settings.background_image_url})`, backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundSize: "cover"}
    } else if(this.props.general_settings.enable_background_color){
      style = {backgroundColor: this.props.general_settings.background_color}
    } else {
      style["backgroundColor"] = "#ffffff"
    }

    return style
  }
  
  saveSettings() {
    if (this.props.general_settings.website_namespaces.some(item => item == this.props.general_settings.website_namespace))
      alert("This name already exists. Please choose a different name.")
    else {
      let multi_widgets_state = JSON.stringify(this.props.state)
      this.props.saveGeneralSettings(multi_widgets_state)
      
    }
  }
  render() {
    let render
    switch (this.props.displayMode) {
      case DISPLAY_MODES.BLOCK: {
        render = <Row>
          <CreateBlock />
        </Row>
        break
      }
      case DISPLAY_MODES.EDIT_BLOCK: {
        render = <Row>
          <EditBlock />
        </Row>
        break
      }
      case DISPLAY_MODES.COLUMN: {
        render = <Row>
          <ManageColumns />
        </Row>
        break
      }
      case DISPLAY_MODES.TABS: {
        render = <Row>
          <ManageTabs />
        </Row>
        break
      }
      case DISPLAY_MODES.TAB_FORM: {
        render = <Row>
          <CreateTab />
        </Row>
        break
      }
      case DISPLAY_MODES.WIDGET: {
        render = <Row>
          <ManageWidget />
        </Row>
        break
      }
      case DISPLAY_MODES.NEW_PAGE: {
        render = <Row>
          <NewPageCreate />
        </Row>
        break
      }
      case DISPLAY_MODES.EDIT_COLUMN_PROPS: {
        render = <Row>
          <ManageColumns />
        </Row>
        break
      }
      case DISPLAY_MODES.BLOCK_REORDER: {
        render = <Row>
          <ReorderBlocks />
        </Row>
        break
      }
      case DISPLAY_MODES.INVENTORY: {
        render = <Row>
          <Inventory/>
        </Row>
        break
      }
      case DISPLAY_MODES.NOTIFICATION_TYPE: {
        render = <Row>
          <Notification/>
        </Row>
        break
      }
      case DISPLAY_MODES.RESERVE_DEMO: {
        render = <Row>
          <ReserveDemo/>
        </Row>
        break
      }
      case DISPLAY_MODES.REQUEST_REPAIR: {
        render = <Row>
          <RequestRepair/>
        </Row>
        break
      }
      case DISPLAY_MODES.DEFAULT: {
        render = <Row>
        </Row>
        break
      }
    }

    return (
      <div className="col-12 h-100 p-0" style={ this.getOutterPageStyles() }>
          <style>{`\
          ::-webkit-scrollbar-thumb{\
            background-color: ${this.props.general_settings.color_schema  || "#FF6633" }!important;\
          }\
          *:hover::-webkit-scrollbar-thumb{\
            background-color: ${this.props.general_settings.color_schema  || "#FF6633" }!important;\
          }\
        `}</style>
        <div className="mx-auto h-100 p-0 d-flex flex-column" style={{maxWidth: `${this.props.general_settings.container_width}`}}>
        {/* , opacity: this.props.general_settings.background_opacity / 100   */}
          <div className='h-100 p-0 d-flex flex-column' style={{minHeight: "90vh"}}>
            <div style={{marginBottom: -15}}>
              <img src={this.props.general_settings.banner_url} style={{width: "100%", marginBottom: 15}}/>
            </div>
            {(!this.props.have_settings || this.props.editSettings) && !this.props.member_view  && <GeneralSettings style={this.getContainerStyle()}/>}
            {((this.props.have_settings && !this.props.editSettings) || this.props.member_view)  && <>
              {!(!this.props.general_settings.member_authenticated && this.props.member_view) && this.props.infoMessage &&
                <FlashMessage removeInfoMessage={this.props.removeInfoMessage} schemaColor={this.props.schemaColor}
                              flashMessage={this.props.infoMessage}
                />}
              {this.props.editMode &&
                <LayoutSettings />}
              {/* opacity affects modals, style={{ opacity: this.props.general_settings.background_opacity / 100 }} */}
              {
                ((this.props.general_settings.member_authenticated && this.props.member_view) || this.props.editMode != null) &&
                <div id='multi-widget-admin' className=''>
                  <Row className="main-page-row m-0" style={this.getContainerStyle()}>
                    <DisplayTabs/>
                    {this.props.general_settings.flash_message && !this.props.editMode &&
                      <marquee className='flash_message' direction="left"
                               style={{
                                 color: getComplementaryColor((this.props.general_settings.enable_background_color ? (this.props.general_settings.background_color || "#FFFFFF") : "#FFFFFF")),
                                 borderBottom: `1px solid ${getComplementaryColor((this.props.general_settings.enable_background_color ? (this.props.general_settings.background_color || "#FFFFFF") : "#FFFFFF"))}`
                               }}
                      >{this.props.general_settings.flash_message}</marquee>
                    }
                    <Col xs={12} className="btn-container">
                      {this.props.editMode && <Row className='m-0'>
                        {
                          this.props.blocks.filter(b => {
                            return (this.props.tabs.length === 0 || b.tabId === this.props.activeTab)
                          }).length === 0 &&
                          <div className="left-container flex-start col mb-5">
                            <AddBlock activeTab={this.props.activeTab}/>
                          </div>
                        }
                        <div className="right-container manage_tabs_button flex-end col">
                          <EditTabs tabs={this.props.tabs}/>
                          <BlocksReorderButton
                            blocks={this.props.blocks.filter(block => block.tabId === this.props.activeTab)}/>
                        </div>
                      </Row>}
                      <DisplayBlocks/>
                      {this.props.editMode &&
                        this.props.blocks.filter(b => {
                          return (this.props.tabs.length === 0 || b.tabId === this.props.activeTab)
                        }).length > 0 &&
                        <Col className="left-container flex-start mb-5">
                          <AddBlock activeTab={this.props.activeTab}/>
                        </Col>
                      }
                    </Col>
                  </Row>
                  <Popup CONTENT={render} displayMode={this.props.displayMode}
                         general_settings={this.props.general_settings}/>
                </div>
              }
              {((!this.props.general_settings.member_authenticated && this.props.member_view)) &&
                <Row className="main-page-row m-0" style={this.getContainerStyle()}>
                  <Login/>
                </Row>
              }
            </>}
            {!this.props.preview && this.props.have_settings && (!this.props.member_view || this.props.editMode != null || this.props.editSettings) &&
              <div className="row bottom_buttons_bar mx-auto"
                   style={{maxWidth: `${this.props.general_settings.container_width}`}}>
                <div className="col-12 py-2 px-3">
                  {this.props.editMode && <Fragment>
                      <input type="submit" value="Save" onClick={() => this.submitForm()}
                             className="btn gems_custom_button gems_orange_button float-end"/>
                      <a
                        onClick={() => window.location.href = `/customers/${this.props.general_settings.customer_id}/pages`}
                        className="btn gems_custom_button float-start">Cancel</a>
                    </Fragment>
                  }
                </div>
              </div>
            }
            
            {((this.props.new_template || this.props.copy_template) && !this.props.have_settings) &&
              <div className="row bottom_buttons_bar mx-auto"
                   style={{maxWidth: `${this.props.general_settings.container_width}`}}>
                <div className="col-12 py-2 px-3">
                  {this.props.editMode && <Fragment>
                    <input type="submit" value="Save" onClick={() => this.saveSettings()}
                           className="btn gems_custom_button gems_orange_button float-end"/>
                    <a
                      onClick={() => window.location.href = `/customers/${this.props.general_settings.customer_id}/pages`}
                      className="btn gems_custom_button float-start">Cancel</a>
                  </Fragment>
                  }
                </div>
              </div>
            }
            {this.props.preview && !this.props.member_view &&
              <div className="row bottom_buttons_bar mx-auto"
                   style={{maxWidth: `${this.props.general_settings.container_width}`}}>
                <div className="col-12 py-2 px-3">
                  {!this.props.editMode && <Fragment>
                    <input type="submit" value={this.props.customer_template ? "Copy" : "Add to my Pages"} onClick={() => this.submitForm()}
                           className="btn gems_custom_button gems_orange_button float-end"/>
                    <a
                      onClick={() => window.location.href = `/customers/${this.props.general_settings.customer_id}/pages`}
                      className="btn gems_custom_button float-start">Cancel</a>
                  </Fragment>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
  
  submitForm() {
    if (this.props.new_template || this.props.copy_template) {
      $.ajax({
        type: 'POST',
        url: `/customers/${this.props.general_settings.customer_id}/pages/${this.props.general_settings.page_id}`,
        data: { template_id: this.props.general_settings.page_id, title: this.props.general_settings.title, multi_widgets_state: JSON.stringify(this.props.state), existing_template: this.props.edit_template == true}
      }).done(function() {
        window.location.reload();
      });
    }
    else {
      if (this.props.preview && !this.props.member_view) {
        window.location.href = `/customers/${this.props.general_settings.customer_id}/pages/${this.props.general_settings.page_id}/copy_page?customer_template=${this.props.customer_template}`
      }
      else
        {
          if ((this.props.general_settings.authentication_type == "member_fields") && (this.props.general_settings.website_namespaces.some(item => item == this.props.general_settings.website_namespace)))
            alert("This name already exists. Please choose a different name.")
          else
            $.ajax({
              type: 'PATCH',
              url: `/customers/${this.props.general_settings.customer_id}/pages/${this.props.general_settings.page_id}`,
              data: { multi_widgets_state: JSON.stringify(this.props.state)}
            }).done(function() {
              window.location.reload();
            });
        }
    }
  }
}

Page.propTypes = {
  activeTab: PropTypes.string,
  displayMode: PropTypes.string,
  tabs: PropTypes.array,
  blocks: PropTypes.array,
  general_settings: PropTypes.object
}

const mapStateToProps = state => ({
  general_settings: state.general_settings,
})

const mapDispatchToProps = dispatch => ({
  setAttribute: (attr, value) => {
    dispatch(setSettingsAttribute(attr, value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Page)
