import React, {Component, useState} from 'react'
import {Col, Form, Row} from "react-bootstrap";
import moment from 'moment-timezone';
import { PiCircleLight } from "react-icons/pi";
import { IoMdAddCircle } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
import {BsStar, BsStarFill} from "react-icons/bs";
import _ from "lodash";
import QuickTaskAssignTo from "./QuickTaskAssignTo";
import QuickTaskDueDate from "./QuickTaskDueDate";
import AutoResizeInput from "./AutoResizeInput";
import { IoMdStar } from "react-icons/io";
function QuickTaskForm(props) {
  const initialState = {
    name: "",
    assigner_user_id: props.user_id,
    open_date_picker: false,
    open_assign_to: false,
    assignees_list: [],
    is_important: false,
    due_date: "today",
    isCustomDate: false
  }

  const [state, setState] = useState(initialState);

  const { name, due_date, member_id, description, user_id, recurring, cadence, end_date, assigner_user_id,
    open_date_picker, open_assign_to, assignees_list, is_important, isCustomDate} = state;
  const {assign_to_options, btn_name, newTask, due_date_options, default_due_time, createTask, dateTimeFormat, timezone} = props

  const handleInputChange = (name, value) => {
    if(name === "due_date")
      if(["today", "tomorrow", "next_week"].includes(value))
        setState({...state, due_date: value, open_date_picker: false, isCustomDate: false});
    else
        setState({...state, due_date: value, open_date_picker: false});
    else
      if(name === "assignees_list")
        setState({...state, assignees_list: value, open_assign_to: false});
      else
        setState({...state, [name]: value });

  }

  const removeAssignee = (id) => {
    let current_list = [...assignees_list]
    current_list = current_list.filter(item => item.value !== id)

    setState({...state, assignees_list: current_list})
  }

  const handleOpenClose = (name, value) => {
    if (name === "open_assign_to")
      setState({ ...state, open_assign_to: value, open_date_picker: false });
    else if (name === "open_date_picker")
      setState({ ...state, open_date_picker: value, open_assign_to: false });
    else
      setState({ ...state, [name]: value });
  }

  const handleSubmit = () => {
    let params = {}
    params["name"] = name
    params["due_date"] = due_date
    params["is_important"] = is_important
    params["assigner_user_id"] = assigner_user_id
    params["assignees_list"] = JSON.stringify(assignees_list)

    //reset state
    resetState()

    createTask(params, false)
  }
  const resetState = () => {
    setState({...state,
      name: "",
      assigner_user_id: props.user_id,
      open_date_picker: false,
      open_assign_to: false,
      assignees_list: [],
      is_important: false,
      due_date: "today",
      isCustomDate: false,
      resetDateTimePiker: false
    });
  }

  const handleMoreOptions = () => {
    let params = {}
    params["name"] = name
    params["due_date"] = due_date
    params["is_important"] = is_important
    params["assigner_user_id"] = assigner_user_id
    params["assignees_list"] = assignees_list


    //reset state
    resetState()

    newTask(params)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if(name)
        handleSubmit()
    }
  }

  const date_format = dateTimeFormat.replaceAll('m', 'M').split(' ')[0]
  const time_format = dateTimeFormat.replaceAll('M', 'm').split(' ')[1] + (dateTimeFormat.split(' ')[2] ? " a" : "")

  const display_due_date = due_date_options.length > 0 ?
    ["today", "tomorrow", "next_week"].includes(due_date ? due_date : "today")
      ?
      _.find(due_date_options, (d) => (d["value"] === (due_date || "today")))["label"] + " " + (moment(_.find(due_date_options, (d) => (d["value"] === (due_date || "today")))["real_value"]).tz(timezone).format(time_format))
      :
        _.find(due_date_options, (d) => (moment(d["real_value"]).format(date_format.toUpperCase()) === moment(due_date).tz(timezone).format(date_format.toUpperCase())))
        ?
        _.find(due_date_options, (d) => (moment(d["real_value"]).format(date_format.toUpperCase()) === moment(due_date).tz(timezone).format(date_format.toUpperCase())))["label"]+ " " + (moment(due_date).tz(timezone).format(time_format))
        :
        moment(due_date).tz(timezone).format(date_format.toUpperCase() + " " + time_format).toUpperCase()
    :
    "Today"

  return (
    <div className="add-quick-task-form-container align-vertical-middle">
      <div className="quick-task-form-body-first">
        <div className="quick-task-form-name" >
          <span className="align-vertical-middle">Name:</span>
          <AutoResizeInput value={name}
                       handleInputChange={handleInputChange}
                       handleKeyDown={handleKeyDown}
          />
        </div>
        <QuickTaskDueDate
          display_due_date={display_due_date}
          handleOpenClose={handleOpenClose}
          open_date_picker={open_date_picker}
          due_date_options={due_date_options}
          due_date={display_due_date.includes("Today") ? "today" : display_due_date.includes("Tomorrow") ? "tomorrow" : display_due_date.includes("Next Week") ? "next_week" : "custom" }
          real_due_date={due_date === "today" ? due_date_options.length > 0 ? _.find(due_date_options, (d) => (d["value"] === "today"))["real_value"] : moment().tz(timezone) : due_date}
          date_format={date_format}
          time_format={time_format}
          timezone={timezone}
          handleInputChange={handleInputChange}
          default_due_time={default_due_time}
          isCustomDate={isCustomDate}
        />
        <div className="quick-task-form-assignees-container">
          {assignees_list.map((as, ii) => (
            <span className="assignee-item align-vertical-middle">
              <span className="assignee-label">{as.label}</span>
              <span className="assignee-remove-btn">
                <IoMdCloseCircle className="fs-5 align-vertical-middle color_red cursor-pointer"
                              onClick={(e) => removeAssignee(as.value)}/>
              </span>
            </span>
          ))}
        </div>
        <QuickTaskAssignTo
          key={assignees_list}
          handleOpenClose={handleOpenClose}
          open_assign_to={open_assign_to}
          assign_to_options={assign_to_options}
          handleInputChange={handleInputChange}
          assignees_list={assignees_list}
        />

        <div className="quick-task-form-more-options link align-vertical-middle"
          onClick={(e) => handleMoreOptions()}
          >
          More Options
        </div>
      </div>
      <div className="quick-task-form-body-last">
        {is_important ?
          <IoMdStar className="align-vertical-middle fs-3 cursor_pointer" onClick={(e) => handleInputChange("is_important", !is_important)}/>
          :
          <IoMdStar fill="white"
                      stroke="black"
                      stroke-width="3" className="align-vertical-middle fs-3 cursor_pointer" onClick={(e) => handleInputChange("is_important", !is_important)}/>}
        <a
          className={`${(name && name.trim() && due_date) ? '' : 'disabled'} btn gems_custom_button float-end color_green cursor_pointer`}
          style={{borderColor: 'green'}}
          onClick={(event) => handleSubmit()}
        >
          <IoMdAddCircle className="fs-5 color_green me-1"/>
          <span>{btn_name.split(" ")[0]}</span>
          <span className="save_task_btn_last_name"> {btn_name.split(" ")[1]}</span>
        </a>
    </div>
  </div>
)
}

export default QuickTaskForm
