import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

function FlashMessage(props) {
  return (
    <Toast bg='basic' 
                  key={"toast"} 
                  id={"toast"}
                  autohide={true}
                  delay={7000} 
                  onClose={()=>{props.removeInfoMessage()}}
                  style={{position: 'absolute', right: '0', bottom: '1vh', color: getComplementaryColor((props.schemaColor)), padding: '0'}}>
      <Toast.Header style={{ backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor))}}>
        <strong className="me-auto" >{props.flashMessage.title}</strong>
      </Toast.Header>
      <Toast.Body style={{color: 'black'}}>
        {props.flashMessage.message}
      </Toast.Body>
    </Toast>
  )
}

export default FlashMessage
