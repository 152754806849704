import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import EmailCenterPageContainer from './EmailCenterPageContainer'

const EmailCenterRoot = ({customer_id}) => {
  const state = {
    dashboard: {
      customer_id: customer_id
    },
    automated_templates: {
      isLoading: true
    },
    custom_templates: {
      isLoading: true
    },
    scheduled_emails: {
      isLoading: true,
      page: 1
    },
    sent_emails_history: {
      isLoading: true,
      page: 1
    },
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <EmailCenterPageContainer/>
    </GlobalProvider>
  )
}

EmailCenterRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}


export default EmailCenterRoot