import { connect } from 'react-redux'
import { closeCancelModal, cancelStockOrder, uploadFile} from '../actions'
import CancelStockOrderModal from '../components/CancelStockOrderModal'


const mapStateToProps = (state) => ({
  modal_cancel_open: state.stock_order.modal_cancel_open,
  item: state.stock_order.item,
  customer_id: state.stock_order.customer_id,
  sizing_table: state.stock_order.sizing_table
})

const mapDispatchToProps = (dispatch) => ({
  closeCancelModal: () => dispatch(closeCancelModal()),
  cancelStockOrder: data => dispatch(cancelStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelStockOrderModal)
