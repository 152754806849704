import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import { fetchProfileSetupData } from '../actions'

const mapStateToProps = (state, props) => ({
  member_info: props.member_info || state.profile_setup.member_info
})

const mapDispatchToProps = (dispatch) => ({
  fetchProfileSetupData: () => dispatch(fetchProfileSetupData())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
