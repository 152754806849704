import React, { PureComponent } from 'react'
import SortingDropdown from '../components/SortingDropdown'
import PropTypes from 'prop-types'

function PipedriveHeader(props) {
    
  const handleUpdateSorting = (type, col, dir) => {  
    let data = {
        to_place_sorted_by: {...props.to_place_sorted_by},
        placed_sorted_by: {...props.placed_sorted_by},
        delivered_sorted_by: {...props.delivered_sorted_by}
      }

    data[type].sort_column = col
    data[type].sort_direction = dir
    props.updatePipedriveSorting(data)
  }

  return (
      <div className='row m-0'>
        <div className='col-12 background_black_grey position_header_titles header'>
          <div className="row">
            <div className="col-4 text-center p-0">
              <i className="basic_arrow right_arrow hidden-xs hidden-sm"></i>
              <div className="add_padding_top_6 add_padding_bottom_5 dropdown cursor_pointer dropdown_for_default_sort d-flex justify-content-center">
                <span className="white">ORDERS TO PLACE</span>
                <SortingDropdown props={props} handleUpdateSorting={handleUpdateSorting} type="to_place_sorted_by"/>
              </div>
            </div>
            <div className="col-4 text-center p-0">
              <i className="basic_arrow right_arrow hidden-xs hidden-sm"></i>
              <div className="add_padding_top_6 add_padding_bottom_5 dropdown cursor_pointer dropdown_for_default_sort d-flex justify-content-center">
                <span className="white">ORDERS PLACED</span>
                <SortingDropdown props={props} handleUpdateSorting={handleUpdateSorting} type="placed_sorted_by"/>
              </div>
            </div>
            <div className="col-4 text-center p-0">
              <div className="add_padding_top_6 add_padding_bottom_5 dropdown cursor_pointer dropdown_for_default_sort d-flex justify-content-center">
                <span className="white">DELIVERED</span>
                <SortingDropdown props={props} handleUpdateSorting={handleUpdateSorting} type="delivered_sorted_by"/>
              </div>
              </div>
          </div>
        </div>
      </div>
  )
} export default PipedriveHeader
