import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import {useState, useEffect} from 'react';
import _ from 'lodash';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import moment from 'moment';

function ReserveDemoForm(props) {

  const initial_return_date = new Date();
  initial_return_date.setDate(initial_return_date.getDate()+ parseInt(props.demo_return_interval));
  const {selected_club_id, inventory, inventory_select} = props
  
  const selected_club = _.find(inventory, function(i) 
  { return i.id == selected_club_id })

  const getFormattedDateForBackend = (date) => {
    const offset = date.getTimezoneOffset()
    const formattedDate = new Date(date.getTime() - (offset*60*1000))

    return formattedDate.toISOString().split('T')[0].replaceAll('-', '/')
  }

  const initialState = {demo_type: selected_club ? selected_club.kind == "Full Club" ? "glued" : 'multi-component' : null,
                        club_tracker_id: selected_club_id, 
                        head_club_id: _.find(inventory, (ii) => ii.status == 'club' && ii.kind == 'Head' && ii.id == selected_club_id ) ? selected_club_id : null, 
                        shaft_club_id: _.find(inventory, (ii) => ii.status == 'club' && ii.kind == 'Shaft' && ii.id == selected_club_id ) ? selected_club_id : null,
                        member_id: props.member_id, 
                        customer_id: props.customer_id,
                        type_demo_tracker: "member",
                        expected_date: getFormattedDateForBackend(initial_return_date),
                        reserve_date: getFormattedDateForBackend(new Date()),
                        note: null,
                        managers_reserve_notifications: JSON.stringify(props.column.options.managers_reserve_notifications)}

  const [state, setState] = useState(initialState);

  const { demo_type,
          club_tracker_id, 
          head_club_id, 
          shaft_club_id, 
          member_id, customer_id,
          type_demo_tracker,
          expected_date,
          reserve_date,
          note} = state
  
  const handleDemoTypeSelectChange = (event) => {
    setState({...state, demo_type: event.value});
  }

  const handleClubSelectChange = (event) => {
    setState({...state, club_tracker_id: event.value});
  }
  const handleHeadSelectChange = (event) => {
    setState({...state, head_club_id: event.value});
  }
  const handleShaftSelectChange = (event) => {
    setState({...state, shaft_club_id: event.value});
  }

  const handleInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.value });
  }

  const handleDatesChange = (event) => {
    let actual_date = event.target.getAttribute("actual_date")
    const return_date = new Date(actual_date);
    return_date.setDate(return_date.getDate() + parseInt(props.demo_return_interval));

    setState({...state, expected_date: getFormattedDateForBackend(return_date), reserve_date: getFormattedDateForBackend(new Date(actual_date)) });
  }

  const handleSubmit = (event) => {
    let params = {...state}
  
    props.reserveDemo(params)

    event.preventDefault();
    props.cancel();
  }

  const getDates = () => {
    let i = 1
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const dates = []
    let days_in_advances

    if(props.column && props.column.options && props.column.options.days_in_advances)
      days_in_advances = props.column.options.days_in_advances
    else
      days_in_advances = 3

    while (i <= parseInt(days_in_advances)) {
      const date = new Date();
      date.setDate(date.getDate()+i);
      const month = months[date.getMonth()];
      const day = date.getDate()
      const dayName = days[date.getDay()]
      dates.push([dayName+'-'+month+day, date.toLocaleDateString("en-US")])
      i += 1 
    }
    return dates
  }

  const club_kind_options = [{value: "glued", label: "Full Club"}, {value: "multi-component", label: "Components"}]
  
  const full_clubs = inventory.filter((ii) => 
                    { return ii.status == 'club' && ii.kind == 'Full Club'})
  const head_clubs = inventory.filter((ii) => 
                      { return ii.status == 'club' && ii.kind == 'Head'})
  const shaft_clubs = inventory.filter((ii) => 
                      { return ii.status == 'club' && ii.kind == 'Shaft'})
  
  let club_additional_details = []
  let head_additional_details = []
  let shaft_additional_details = []

  if(demo_type == 'glued')
  {
    club_additional_details =  inventory.filter((ii) => 
    { return ii.id == club_tracker_id })[0]
    club_additional_details = club_additional_details ? club_additional_details.details : []
  }
  else
  {
    head_additional_details =  inventory.filter((ii) => 
    { return ii.id == head_club_id })[0]
    head_additional_details = head_additional_details ? head_additional_details.details : []

    shaft_additional_details =  inventory.filter((ii) => 
    { return ii.id == shaft_club_id })[0]
    shaft_additional_details = shaft_additional_details ? shaft_additional_details.details : []
  }
  const full_clubs_options = []
  const head_clubs_options = []
  const shaft_clubs_options = []
  
  full_clubs.map((club, index) => (
    full_clubs_options.push({value: club.id, label: club.tracking_id + '('+club.short_description+')'})
  ))
  head_clubs.map((club, index) => (
    head_clubs_options.push({value: club.id, label: club.tracking_id + '('+club.short_description+')'})
  ))
  shaft_clubs.map((club, index) => (
    shaft_clubs_options.push({value: club.id, label: club.tracking_id + '('+club.short_description+')'})
  ))
  return (  
    <div className='col'>
    <ModalHeader >
      <h4 className="modal-title form_new_block no-margin">Reserve Demo</h4>
    </ModalHeader>
    <ModalBody>
    <Form>
      <div className="row m-0 mt-3">
        <div className="col inner-content">
        <Form.Group as={Row} className="mb-3 form-row" controlId="formPickUpDate">
            <Form.Label column sm="4">
              Pick-up Date
            </Form.Label>
            <Col sm="8">
              <div className='row'>
                {getDates().map((date, index) => (
                  <div className='w-25 pe-0' key={`${date}_${index}`}>
                    <span className={index == 0 ? 'item-box p-1 mb-1 cursor_pointer pick_up_dates  active' : 'mb-1 p-1 item-box cursor_pointer pick_up_dates'} 
                    style={{backgroundColor: (index == 0 ? props.schemaColor : "#ffffff"), color: (index == 0 ? getComplementaryColor((props.schemaColor)) : "#000000")}}
                    actual_date={date[1]}
                    onClick={handleDatesChange}>
                      {date[0]}
                    </span>
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 form-row" controlId="formReturnDate">
            <Form.Label column sm="4">
              Return Date
            </Form.Label>
            <Col sm="8">
              <div className='row'>
                <span className="">
                  {moment(expected_date).format(props.date_format.toUpperCase())}
                </span>
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 form-row" controlId="formSelectType">
            <Form.Label column sm="4">
              Club Kind
            </Form.Label>
            <Col sm="8">
              <Select id = "demo_type" 
                          name="demo_type"
                          value={club_kind_options.filter(function(option) {
                                return option.value === demo_type;
                              })}
                          options={club_kind_options}
                          onChange={handleDemoTypeSelectChange}
                          />
            </Col>
          </Form.Group>
          {demo_type == 'glued' && <Form.Group as={Row} className="mb-3 form-row" controlId="formSelect">
            <Form.Label column sm="4">
              Club
            </Form.Label>
            <Col sm="8">
              <Select id = "demoClubSelect" 
                          name="demoClubSelect"
                          value={full_clubs_options.filter(function(option) {
                                return option.value === club_tracker_id;
                              })}
                          options={full_clubs_options}
                          onChange={handleClubSelectChange}
                          />
            </Col>
            {club_additional_details.length > 0 && <div className="row item-box custom_fields_reserve_demo p-0 pt-2 panel custom_panel" style={{opacity: '0.5'}}>
              {club_additional_details.map((cat, index) => (
                <div className='col-6 '>
                  <b>{cat[0]}: </b>
                  <span className="searchable_field">
                    {cat[1]}
                  </span>
                </div>
              ))}
            </div>}
          </Form.Group>}
          {demo_type == 'multi-component' && 
            <>
            <Form.Group as={Row} className="mb-3 form-row" controlId="formSelect">
              <Form.Label column sm="4">
                Head Club
              </Form.Label>
              <Col sm="8">
                <Select id = "demoClubSelect" 
                            name="demoClubSelect"
                            value={head_clubs_options.filter(function(option) {
                                  return option.value === head_club_id;
                                })}
                            options={head_clubs_options}
                            onChange={handleHeadSelectChange}
                            />
              </Col>
              {head_additional_details.length > 0 && <div className="row item-box p-0 pt-2 panel custom_panel" style={{opacity: '0.5'}}>
              {head_additional_details.map((cat, index) => (
                <div className='col-6'>
                  <b>{cat[0]}: </b>
                  <span className="searchable_field">
                    {cat[1]}
                  </span>
                </div>
              ))}
            </div>}
          </Form.Group>
          <Form.Group as={Row} className="mb-3 form-row" controlId="formSelect">
            <Form.Label column sm="4">
                Shaft Club
              </Form.Label>
              <Col sm="8">
                <Select id = "demoClubSelect" 
                            name="demoClubSelect"
                            value={shaft_clubs_options.filter(function(option) {
                                  return option.value === shaft_club_id;
                                })}
                            options={shaft_clubs_options}
                            onChange={handleShaftSelectChange}
                            />
              </Col>
              {shaft_additional_details.length > 0 && <div className="row item-box p-0 pt-2 panel custom_panel" style={{opacity: '0.5'}}>
              {shaft_additional_details.map((cat, index) => (
                <div className='col-6'>
                  <b>{cat[0]}: </b>
                  <span className="searchable_field">
                    {cat[1]}
                  </span>
                </div>
              ))}
            </div>}
            </Form.Group>
          </>
          }
          <Form.Group as={Row} className="mb-3 form-row" controlId="formNote">
            <Form.Label column sm="4">
            Note
            </Form.Label>
            <Col sm="8">
              <Form.Control as="textarea" rows={3} 
                            name='note'
                            value={note || ""}
                            onChange={handleInputChange}/>
            </Col>
          </Form.Group>
        </div>
      </div>
    </Form>
    </ModalBody>
    <ModalFooter>
        <a
          className="float-start btn gems_custom_button"
          aria-label="Back"
          onClick={ e => { e.preventDefault(); props.cancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </a>
        <a
          className={ `btn gems_custom_button float-end ${!props.member_id || (!(state.demo_type == "glued" && state.club_tracker_id) && !(state.demo_type == "multi-component" && (state.head_club_id || state.shaft_club_id)))
           ? 'disabled' : ''}` }
          style={{backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor))}}
          onClick={props.member_id ? handleSubmit : ()=>{}} 
          >
          Reserve
        </a>

        { (!(state.demo_type == "glued" && state.club_tracker_id) && !(state.demo_type == "multi-component" && (state.head_club_id || state.shaft_club_id))) &&
          <OverlayTrigger
            key="managers_notification"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-managers_notification`}>
                {state.demo_type == "multi-component" ? "For a demo that contains a club of components, it is mandatory to have at least one Head or one Shaft." : "Please select Club"}
              </Tooltip>
            }
          >
            <i className=" align-middle gga-info-circle tooltip_info float-end py-1 fs-6"/>
          </OverlayTrigger>
        }
      </ModalFooter>
    </div>
  )
}

export default ReserveDemoForm
