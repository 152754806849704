import { connect } from 'react-redux'
import { closePlaceModal, updateStockOrder, uploadFile} from '../actions'
import PlaceStockOrderModal from '../components/PlaceStockOrderModal'

const mapStateToProps = (state) => ({
  modal_place_open: state.stock_order.modal_place_open,
  customer_id: state.stock_order.customer_id,
  item: state.stock_order.item,
  carrier_options: state.stock_order.carrier_options || [],
  date_format: state.stock_order.date_format
})

const mapDispatchToProps = (dispatch) => ({
  closePlaceModal: () => dispatch(closePlaceModal()),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceStockOrderModal)
