import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { setSettingsAttribute } from '../../actions'
import { connect } from 'react-redux'
import { Row, Form, Col, Table, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
import UploadFile from '../../../shared/components/upload_file';
import { uploadFile } from '../../actions'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import Select from 'react-select';

class GeneralSettings extends Component {
  constructor(props) {
    super(props)
  }
  
  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
    $('.select2').select2({})
  }
  
  handleSelect(e){
    this.props.setAttribute("ce_metadatum_id", e.value)
  }
  
  handleSelectJonas(e){
    this.props.setAttribute("jonas_metadatum_id", e.value)
  }
  
  render(){
    let ce_maf_options = []
    let jonas_maf_options = []
    
    this.props.general_settings.all_metadata.map((x, index) =>
      {ce_maf_options.push({value: x.id, label: x.name})
        jonas_maf_options.push({value: x.id, label: x.name})}
    )
    
    let mf_maf_options = []
    
    this.props.general_settings.member_authentication_data.map((x, index) =>
      {mf_maf_options.push({value: x.key, label: x.name, isdisabled: x.selected || !x.category})}
    )
    
    const {ce_metadatum_id} = this.props.general_settings
    const {jonas_metadatum_id} = this.props.general_settings
    
    return (
      <div className="background_white h-100" id="general_settings_page" style={this.props.style}>
        <div className="row m-3">
          {<div className="col-12 ps-0">
            <div className="row mb-2 ps-2">
              <div className="col-6 ps-0 pe-1 form-floating">
                <input type="text_field" onChange={(e) => this.props.setAttribute("title", e.target.value)} name="title" value={this.props.general_settings.title} className="form-control form-control-sm" autoComplete="off" />
                <label htmlFor="page_title">Title</label>
              </div>
            </div>
            <div className="row mb-2 ps-2">
              <label className="form-check-label col-12 ps-0">
                Choose an authentication method for members:
              </label>
              <div className="col-4 form-check">
                <input checked={this.props.general_settings.authentication_type === "ce_integration"} className="form-check-input" id="ce_integration_authentication" name="selectedAuthenticationType" type="radio" onChange={() => { this.setCEAuthentication() }}></input>
                <label className="form-check-label ps-2" htmlFor="ce_integration_authentication">
                  Clubessential integration
                </label>
                <OverlayTrigger
                  key="ce_integration"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-jonas_integration`}>
                      TM Roster sync is needed to obtain CE ID. CE TM Integration is a TM Premium Feature. To Learn more about the Single Sign On Integration for Member Portals please contact support.
                    </Tooltip>
                  }
                >
                  <i className="ms-2 gga-info-circle tooltip_info" />
                </OverlayTrigger>
              </div>
              {
                <div className="col-4 form-check">
                  <input checked={this.props.general_settings.authentication_type === "jonas_integration"} disabled={this.props.general_settings.is_jonas_enabled === false} className="form-check-input" id="jonas_integration_authentication" name="selectedAuthenticationType" type="radio" onChange={() => { this.setJonasAuthentication() }}></input>
                  <label className="form-check-label ps-2" htmlFor="jonas_integration_authentication">
                    Jonas integration
                  </label>
                  <OverlayTrigger
                    key="jonas_integration"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-jonas_integration`}>
                        To Enable Jonas Single Sign On with Member Portals, please contact support.
                      </Tooltip>
                    }
                  >
                    <i className="ms-2 gga-info-circle tooltip_info" hidden={this.props.general_settings.is_jonas_enabled !== false}/>
                  </OverlayTrigger>
                </div>
              }
              <div className="col-4 form-check">
                <input checked={this.props.general_settings.authentication_type === "member_fields"} className="form-check-input" id="member_fields_authentication" name="selectedAuthenticationType" type="radio" onChange={() => { this.setMemberFieldsAuthenticatio() }}></input>
                <label className="form-check-label ps-2" htmlFor="member_fields_authentication">
                  Member Fields
                </label>
              </div>
            </div>
            { this.props.general_settings.authentication_type === "ce_integration" &&
              <div className="row ps-2">
                <div className="col-6 mb-2 ps-0">
                  <label htmlFor="ce_maf">Member Authentication Field:</label>
                  <Select id = "ce_maf"
                          name = 'ce_maf'
                          value={ce_maf_options.filter(function(option) {
                            return option.value === ce_metadatum_id;
                          })}
                          options={ce_maf_options}
                          onChange={(e) => this.handleSelect(e)}/>
                </div>
                <div className='col-12'></div>
                <div className="col-6 ps-0 mb-2 form-floating">
                  <input type="text_field" name="website_url" onChange={(e) => this.props.setAttribute("website", e.target.value)} value={this.props.general_settings.website} className="form-control form-control-sm" autoComplete="off" />
                  <label htmlFor="website_url">Club Website</label>
                </div>
              </div>
            }
            
            { this.props.general_settings.authentication_type === "jonas_integration" &&
              <div className="row ps-2">
                <div className="col-6 mb-2 ps-0">
                  <label htmlFor="jonas_maf">Jonas Authentication Field:</label>
                  <Select id = "jonas_maf"
                          name = 'jonas_maf'
                          value={jonas_maf_options.filter(function(option) {
                            return option.value === jonas_metadatum_id;
                          })}
                          options={jonas_maf_options}
                          onChange={(e) => this.handleSelectJonas(e)}/>
                </div>
              </div>
            }
            
            { this.props.general_settings.authentication_type === "jonas_integration" &&
              <div className="row mb-2 ps-2">
                {this.props.general_settings.page_id && <div className="col ps-0 pe-1 form-floating">
                  <input type="text_field" name="external_link" className="form-control form-control-sm external_link" autoComplete="off" disabled={true} value={"customers/"+this.props.general_settings.customer_id+"/pages/"+this.props.general_settings.page_id} />
                  <a onClick={() => {navigator.clipboard.writeText("customers/"+this.props.general_settings.customer_id+"/pages/"+this.props.general_settings.page_id)}}><span className="fa fa-copy"></span></a>
                  <label htmlFor="external_link">Member Portals Route</label>
                </div>}
                {!this.props.general_settings.page_id &&
                  <><label htmlFor="external_link_info" className="p-0">Member Portals Route:</label>
                    <p type="text_field" name="external_link_info"
                       className="">Please create the page first and then the link will be available.</p>
                  </>
                }
              </div>
            }
            
            { this.props.general_settings.authentication_type === "member_fields" &&
              <div className="row mb-2" >
                <label className="form-check-label col-12 ps-2 mb-2">
                  Select fields which members have to answer to authenticate:
                </label>
                <div className="col-6 mb-2 ps-2 pe-1 form-floating">
                  <label htmlFor="mf_maf">Member Authentication Field</label>
                  <Select id = "mf_maf"
                          name = 'mf_maf'
                          options={mf_maf_options}
                          isOptionDisabled={(option) => option.isdisabled}
                          onChange={(e) => this.hanndleSelectMemberAuthenticationField(e.value)}/>
                </div>
                <div className="col-12 mb-2 pe-1 ps-0">
                  {(this.props.general_settings.member_authentication_data).map((field, index) =>
                    field.selected &&
                    <div className="col-12 my-1 px-3" key={`auth_by_${field.key}`}>
                      <span> Authentication by: <b>{field.name}</b> </span>
                      <i className="fa fa-trash color_red" data-key={field.key} onClick={(e) => {this.hanndleRemoveMemberAuthenticationField(e)}}/>
                    </div>
                  )}
                </div>
              </div>
            }
            { this.props.general_settings.authentication_type === "member_fields" &&
              <div className="row mb-2 ps-2">
                <div className="col-6 ps-0 pe-1 form-floating">
                  <input type="text_field" onChange={(e) => this.props.setAttribute("website_namespace", e.target.value)} name="Url Name" defaultValue={this.props.general_settings.website_namespace || this.props.general_settings.title.replace(/\s/g, '').toLowerCase()} className="form-control form-control-sm" autoComplete="off" />
                  <label htmlFor="page_title">Golf Shop Url Name</label>
                </div>
              </div>
            }
            {
              this.props.general_settings.authentication_type !== "jonas_integration" &&
              <div className="row mb-2 ps-2">
                {(this.props.general_settings.page_id || this.props.general_settings.authentication_type == "member_fields") && <div className="col-12 ps-0 pe-1 form-floating">
                  <input type="text_field" name="external_link" className="form-control form-control-sm external_link" autoComplete="off" disabled={true} value={this.golfshop_url_formula()} />
                  <a onClick={() => {navigator.clipboard.writeText(this.golfshop_url_formula())}}><span className="fa fa-copy"></span></a>
                  <label htmlFor="external_link">Member Portals Website URL</label>
                </div>}
                {!this.props.general_settings.page_id && this.props.general_settings.authentication_type != "member_fields" &&
                  <><label htmlFor="external_link_info" className="p-0">Member Portals Website URL:</label>
                    <p type="text_field" name="external_link_info"
                       className="">Please create the page first and then the link will be available.</p>
                  </>
                }
              </div>
            }
          </div>}
        </div>
      </div>
  )
  }
  
  golfshop_url_formula() {
    if(this.props.general_settings.authentication_type === "member_fields") {
      return `${this.props.general_settings.frontend_protocol_app}://${this.props.general_settings.frontend_host_app}/website/${this.props.general_settings.website_namespace || this.props.general_settings.title.replace(/\s/g, '').toLowerCase()}`
    } else {
      if (this.props.general_settings.page_id)
        return `${this.props.general_settings.website}/default.aspx?p=GURL&interface=1417&ssid=335763&vnf=1&redirect=customers/${this.props.general_settings.customer_id}/pages/${this.props.general_settings.page_id}`
    }
    
  }
  
  setCEAuthentication() {
    this.props.setAttribute("authentication_type", "ce_integration");
    this.props.setAttribute("jonas_metadatum_id", null)
    // $("#mf_maf").select2('destroy');
    // to do: reset member_authentication_data
  }
  
  setMemberFieldsAuthenticatio() {
    this.props.setAttribute("authentication_type", "member_fields");
    this.props.setAttribute("ce_metadatum_id", null)
    this.props.setAttribute("jonas_metadatum_id", null)
  }
  
  setJonasAuthentication() {
    this.props.setAttribute("authentication_type", "jonas_integration");
    this.props.setAttribute("ce_metadatum_id", null)
  }
  
  hanndleSelectMemberAuthenticationField(key) {
    let member_authentication_data = []
    this.props.general_settings.member_authentication_data.map(data => {
      member_authentication_data.push({...data})
    })
    
    let index =  member_authentication_data.findIndex(mf => mf.key === key)
    let field = member_authentication_data.filter((ii) =>
    { return ii.key == key})[0]
    member_authentication_data[index].selected = true
    
    this.props.setAttribute("member_authentication_data", member_authentication_data);
    
  }
  
  hanndleRemoveMemberAuthenticationField(event) {
    let key = event.target.getAttribute('data-key')
    let member_authentication_data = []
    this.props.general_settings.member_authentication_data.map(data => {
      member_authentication_data.push({...data})
    })
    
    let index =  member_authentication_data.findIndex(mf => mf.key === key)
    let field = member_authentication_data.filter((ii) =>
    { return ii.key == key})[0]
    member_authentication_data[index].selected = false
    
    this.props.setAttribute("member_authentication_data", member_authentication_data);
    
  }
}

GeneralSettings.propTypes = {
  dispatch: PropTypes.func,
  general_settings: PropTypes.object,
}

const mapStateToProps = state => ({
  general_settings: state.general_settings,
})

const mapDispatchToProps = dispatch => ({
  setAttribute: (attr, value) => {
    dispatch(setSettingsAttribute(attr, value))
  },
  uploadFile: (data) => dispatch(uploadFile(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings)

