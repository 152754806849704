/*
 * You should not import this file directly, it's used by the onConfirm helper.
 * Inspired from react-confirm-alert
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { render, unmountComponentAtNode } from 'react-dom'
import GlgPopup from './components/glg_popup'

class ConfirmComponent extends Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.state = {
      show: true,
    }
  }

  handleClose() {
    this.setState({
      show: false,
    })
  }

  handleConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm()
    }
  }

  render() {
    return (
      <GlgPopup title={ this.props.message }
                saveButtonText="OK"
                showCloseButton={ this.props.showCloseButton }
                closeButtonText="Cancel"
                show={ this.state.show }
                onClose={ this.handleClose }
                modalClass="glg-confirm"
                onSave={ this.handleConfirm }
                onHidden={ destroyConfirmWrapper }></GlgPopup>
    )
  }
}

ConfirmComponent.propTypes = {
  onConfirm: PropTypes.func,
  message: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]).isRequired,
  showCloseButton: PropTypes.bool,
}

ConfirmComponent.defaultValues = {
  showCloseButton: true,
}

const destroyConfirmWrapper = () => {
  const confirmWrapper = document.getElementById('glg-confirm')
  unmountComponentAtNode(confirmWrapper)
  confirmWrapper.parentNode.removeChild(confirmWrapper)
}

/*
 * message: string or functional component
 * options: object with props to pass to the popup component (e.g. showCloseButton)
 */
const confirm = (message, callback, options) => {
  const confirmWrapper = document.createElement('div')
  confirmWrapper.id = 'glg-confirm'
  document.body.appendChild(confirmWrapper)
  const props = {
    message,
    onConfirm: callback,
    ...options,
  }
  render(<ConfirmComponent {...props} />, confirmWrapper)
}

export default confirm
