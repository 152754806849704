import { connect } from 'react-redux'
import InventoryView from '../presentational/inventory_view'
import {chooseNotification, getReserveDemo, displayMode, DISPLAY_MODES, setActiveColumn } from '../../actions'

const mapStateToProps = (state, props) => ({
  colId: props.colId,
  height: props.height,
  inventory: state.filters.inventory,
  enable_reservation: props.options.enable_reservation,
  enable_availability_notification: props.options.enable_availability_notification,
  days_in_advances: props.options.days_in_advances,
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  
  chooseNotification: (id) => dispatch(chooseNotification(id)),
  getReserveDemo: (id) => dispatch(getReserveDemo(id)),
  setActiveColumn: (id) => dispatch(setActiveColumn(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryView)
