import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'
import MatchingCategoriesPageContainer from './MatchingCategoriesPageContainer'


const CallawayConnectMatchCategoriesRoot = ({customer_id, admin_side, callaway_categories, gs_categories, matched_categories, unmatched_categories}) => {
  const state = {
    callaway_connect: {
      customer_id: customer_id,
      admin_side: admin_side,
      callaway_categories: callaway_categories,
      gs_categories: gs_categories,
      matched_categories: matched_categories,
      unmatched_categories: unmatched_categories,
      categories_to_create: [],
    }
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <MatchingCategoriesPageContainer/>
    </GlobalProvider>
  )
}



export default CallawayConnectMatchCategoriesRoot