import React from 'react'
import {useState} from 'react';
import Product from './Product';

function ProductsOnlineOrder (props){
  const {
    fontColor,
    formTitle,
    formDescription,
    formType,
    maxCredits,
    checkoutItemsLimit,
    isCartView,
    itemsInCart,
    totalCost,
    genericForm,
    products,

    addProductToCart,
    openErrorModal
  } = props;

  const initialState = {
  }

  const [state, setState] = useState(initialState);


  return (
    <>
      {/* Online Container list */}
      { !isCartView &&
        <div className='w-100' id="form-container">
          <div className='desktop_view w-100' style={{color: `${fontColor}`, textAlign: 'start'}}>
            <b className='form-title'>{formTitle}</b>
          </div>

          { formDescription && <div className='w-100' style={{color: `${fontColor}`}}>
            <div id='form-description' dangerouslySetInnerHTML={{__html: formDescription}}/>
          </div>}

          <div className={`w-100 d-flex justify-content-start`} id='form-products'>
            <div className='w-100' id='online_shop_items_list_preview'>
              {products.filter(product => product.deleted == false).sort((a, b) => a.order - b.order).map((product, index) =>
                <Product
                  key={product.id}
                  version={"desktop"}
                  product={product}
                  formType={formType}
                  fontColor={fontColor}
                  totalCost={totalCost}
                  maxCredits={maxCredits}
                  itemsInCart={itemsInCart}
                  checkoutItemsLimit={checkoutItemsLimit}
                  addProductToCart={addProductToCart}
                  openErrorModal={openErrorModal}
                />
              )}

              {products.filter(product => product.deleted == false).sort((a, b) => a.order - b.order).map((product, index) =>
                <Product
                  key={product.id}
                  version={"mobile"}
                  product={product}
                  formType={formType}
                  fontColor={fontColor}
                  totalCost={totalCost}
                  maxCredits={maxCredits}
                  itemsInCart={itemsInCart}
                  checkoutItemsLimit={checkoutItemsLimit}
                  addProductToCart={addProductToCart}
                  openErrorModal={openErrorModal}
                />
              )}
            </div>
          </div>
        </div>
      } 
    </>
  )
}


export default ProductsOnlineOrder