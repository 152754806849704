import React, {Component, useState} from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import Select from 'react-select';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import update from "immutability-helper";
import {config_froala} from "../../../shared/froala_config";
import FroalaEditor from "react-froala-wysiwyg";

function BlockForm(props){
  const initialState = {
    title: '',
    layout: '1',
  }
  
  const [state, setState] = useState(initialState);
  
  let config = config_froala
  config["placeholderText"] = "Title"
  config["minHeight"] = 130
  config["maxHeight"] = 1000
  config["width"] = 600
  config["toolbarButtons"]= {
    'moreRich': {},
    'moreMisc': {},
    'moreText': {
      
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      
    },
    
    'moreParagraph': {
      
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      
    }}
  const setTitle = (_title) => {
    setState(update(state, {
        title: {$set: _title},
      }
    ))
  };
  
  const setLayout = (_layout) => {
    setState(update(state, {
      layout: {$set: _layout},
      }
    ))
  };
  

  const handleSubmit = () => {
    props.createBlock(
      props.activeTab,
      state.title,
      state.layout,
    )
  }
  
  const nr_of_columns_arr = [{value: '1', label: window.I18n.t('multi_widgets.block_form.1_column')},
                            {value: '2', label: window.I18n.t('multi_widgets.block_form.2_column')}]
  return (
    <div className='manage-blocks col'>
      <ModalHeader >
        <h4 className="modal-title form_new_block no-margin">{window.I18n.t('multi_widgets.block_form.title')}</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="row m-0 mt-3 ms-0 me-0">
            <div className="col inner-content">
              <Form.Group as={Row} className="mb-3 form-row top-row ms-0 me-0" controlId="formTabForm">
                <Col sm="12">
                  <FroalaEditor tag='textarea'
                                config={{...config, ...{height: 30}}}
                                model={state.title}
                                onModelChange={setTitle}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3 form-row ms-0 me-0" controlId="formBlockColums">
                <Form.Label column sm="4">
                  {window.I18n.t('multi_widgets.block_form.no_columns')}
                </Form.Label>
                <Col sm="6">
                  <Select id = "columnsCount" 
                              name="columnsCount"
                              onChange={ e => setLayout(e.value) }
                              options={nr_of_columns_arr}
                              />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formBlockType" className={ `mb-3 ms-0 me-0 form-row ${state.layout !== '1' ? '' : 'd-none'}` }>
                <Form.Label column sm="4">
                  {window.I18n.t('multi_widgets.block_form.column_split')}
                </Form.Label>
                <Col sm="6">
                  <Row>
                    <Col sm="4">
                      <label onChange={ e => setLayout(e.target.value) } >
                        <input type="radio" name="layout" value="2" hidden defaultChecked />
                        <div className={ `layout-symbols equal ${state.layout !== '2' ? 'passive' : 'active'}` }></div>
                        <div className={ `layout-symbols equal ${state.layout !== '2' ? 'passive' : 'active'}` }></div>
                      </label>
                    </Col>

                    <Col sm="4">
                      <label onChange={ e => setLayout(e.target.value) }>
                        <input type="radio" name="layout" value="3" hidden />
                        <div className={ `layout-symbols one-third ${state.layout !== '3' ? 'passive' : 'active'}` }></div>
                        <div className={ `layout-symbols two-thirds ${state.layout !== '3' ? 'passive' : 'active'}` }></div>
                      </label>
                    </Col>

                    <Col sm="4">
                      <label onChange={ e => setLayout(e.target.value) }>
                        <input type="radio" name="layout" value="4" hidden />
                        <div className={ `layout-symbols two-thirds ${state.layout !== '4' ? 'passive' : 'active'}` }></div>
                        <div className={ `layout-symbols one-third ${state.layout !== '4' ? 'passive' : 'active'}` }></div>
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={ e => { e.preventDefault(); props.cancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </button>
        <button
          className="btn gems_custom_button float-end"
          style={ { backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor)) }}
          onClick={ e => { e.preventDefault(); handleSubmit() } } >
          {window.I18n.t('multi_widgets.save')}
        </button>
      </ModalFooter>
    </div>
    )
}

BlockForm.propTypes = {
  activeTab: PropTypes.string,
  colCount: PropTypes.string,
  setColumnCount: PropTypes.func,
  createBlock: PropTypes.func,
  cancel: PropTypes.func,
}

export default BlockForm
