import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useState} from 'react';
import CartOnlineOrderContainer from '../containers/CartOnlineOrderContainer';
import ProductsOnlineOrderContainer from '../containers/ProductsOnlineOrderContainer';
import SubmittedOnlineOrderContainer from '../containers/SubmittedOnlineOrderContainer';
import ErrorModal from './ErrorModal';
import HeaderContainer from "../containers/HeaderContainer";
import MobileCartConfirmation from "./MobileCartConfirmation";

function OnlineOrderForm (props){
  const {
    fontColor,
    formDisplayBanner,
    formBanner,
    formType,
    currencySymbol,
    isCartView,
    itemsInCart,
    newItemAddedToCart,
    cartProducts,
    totalCost,
    links,

    errorHTML,
    orderSubmitted,
    editMode,
    formClosed,
    preview,

    goToCartPopover,
    closeCartPopover,
    removeProductFromCart,
    closeErrorModal,
    showErrorModal,
  } = props;

  const initialState = {
  }

  const [state, setState] = useState(initialState);

  return (
    <>
      <style>{`\
        ::-webkit-scrollbar-thumb{\
          background-color: ${fontColor}!important;\
        }\
        *:hover::-webkit-scrollbar-thumb{\
          background-color: ${fontColor}!important;\
        }\
      `}</style>

      <div className={`h-100 w-100 bg-white p-0 ${(orderSubmitted && !editMode) || formClosed ? 'online-form-submitted overflow-auto' : ''}`}>
        {
          !preview && ((orderSubmitted && !editMode) || formClosed) ?
            <SubmittedOnlineOrderContainer/>
            :
            <div className={'h-100 d-flex flex-column align-items-start align-self-stretch'} style={{gap: '4px'}}>
              <ErrorModal errorHTML={errorHTML} show={showErrorModal} closeErrorModal={closeErrorModal}/>
              <HeaderContainer/>

              <div className={'d-flex flex-column align-items-start align-self-stretch overflow-auto flex-grow-1'} style={{gap: '32px', paddingBottom: "50px"}}>
                {!isCartView && formDisplayBanner !== 'remove' &&
                  <div id='online-form-banner' className={'w-100'}>
                    <img src={formBanner} alt="banner"/>
                  </div>
                }

                { newItemAddedToCart &&
                  <MobileCartConfirmation
                    fontColor={fontColor}
                    formType={formType}
                    currencySymbol={currencySymbol}
                    itemsInCart={itemsInCart}
                    cartProducts={cartProducts}
                    totalCost={totalCost}
                    goToCartPopover={goToCartPopover}
                    closeCartPopover={closeCartPopover}
                    removeProductFromCart={removeProductFromCart}
                  />
                }

                <ProductsOnlineOrderContainer/>
                <CartOnlineOrderContainer/>
              </div>
            </div>
        }
      </div>
    </>
  )
}


export default OnlineOrderForm