import { ActionTypes } from '../actions'

const {
  FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST,
  CLONE_DEFAULT_TEMPLATE,
  DELETE_TEMPLATE,
  OPEN_CUSTOM_TEMPLATE_MODAL,
  CLOSE_CUSTOM_TEMPLATE_MODAL,
} = ActionTypes

const initialState = {
  custom_text_message_templates: [],
  quick_search_value: "",
  isLoading: true,
  modal_show_custom_template_open: false,
  template_to_display: null,
}
  
const custom_templates = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST:
      return {
        ...state,
        custom_text_message_templates: action.data.custom_text_message_templates,
        default_text_message_templates: action.data.default_text_message_templates,
        isLoading: false,
      }

    case CLONE_DEFAULT_TEMPLATE:
      var custom_text_message_templates_list = [...state.custom_text_message_templates];
      custom_text_message_templates_list.push(action.data)
      return {
        ...state,
        custom_text_message_templates: custom_text_message_templates_list
      }

    case DELETE_TEMPLATE:
      var custom_text_message_templates_list = [...state.custom_text_message_templates];
      custom_text_message_templates_list = custom_text_message_templates_list.filter((item) => item.id != action.data.id)
      return {
        ...state,
        custom_text_message_templates: custom_text_message_templates_list
      }

    case OPEN_CUSTOM_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: action.data.template,
        modal_show_custom_template_open: true
      }

    case CLOSE_CUSTOM_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: null,
        modal_show_custom_template_open: false
      }

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default custom_templates