import React from 'react'
import { Col } from 'react-bootstrap';
import AutomatedTemplatesContainer from '../containers/AutomatedTemplatesContainer';
import BulkTextMessageShowModalContainer from '../containers/BulkTextMessageShowModalContainer';
import CustomTemplatesContainer from '../containers/CustomTemplatesContainer';
import CustomTemplateShowModalContainer from '../containers/CustomTemplateShowModalContainer';
import UnsentTextMessagesContainer from '../containers/UnsentTextMessagesContainer';
import SentTextMessagesContainer from '../containers/SentTextMessagesContainer';


function Body(props) {
  const {modal_show_custom_template_open, modal_show_communication_history_open} = props;

  return (
    <div className="row flex-grow-1" id="container_text_message_center_dashboard">
      <Col xs={12} xl={8} className="offset-xl-2 pe-4" style={{overflowX: "hidden", overflowY: "auto"}}>
        <SentTextMessagesContainer />
        <UnsentTextMessagesContainer />
        <CustomTemplatesContainer />
        <AutomatedTemplatesContainer />

        {modal_show_custom_template_open && <CustomTemplateShowModalContainer/>}
        {modal_show_communication_history_open && <BulkTextMessageShowModalContainer/>}
      </Col>
    </div>
  )
}

export default Body
