import { ActionTypes } from '../actions'

const {
  FETCH_EMAIL_CENTER_DATA_REQUEST,
  PAGINATE_UNSENT_EMAILS,
  UNSCHEDULE_EMAIL,
  DELETE_DRAFT_EMAIL,
  PAUSE_SCHEDULED_EMAIL,
  RESUME_SCHEDULED_EMAIL
} = ActionTypes

const initialState = {
  emails: [],
  isLoading: true,
  scheduled_emails_total_pages: null
}

let emails_list = []
let index_id = null
  
const scheduled_emails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_CENTER_DATA_REQUEST:
      return {
        ...state,
        emails: action.data.scheduled_emails,
        isLoading: false,
        scheduled_emails_total_pages: action.data.scheduled_emails_total_pages
      }

    case UNSCHEDULE_EMAIL:
      const updated_emails_list = [...state.emails].filter(function(email) { return email.id !== action.data })
      return {
        ...state,
        emails: updated_emails_list,
      }

    case DELETE_DRAFT_EMAIL:
      const new_emails_list = [...state.emails].filter(function(email) { return email.id !== action.data })
      return {
        ...state,
        emails: new_emails_list,
      }

    case PAUSE_SCHEDULED_EMAIL:
      emails_list = [...state.emails];
      index_id = null

      emails_list.map((email, index) => {
        if (email.id.toString() == action.data.id) 
        index_id = index;
      })

      emails_list[index_id] = action.data

      return {
        ...state,
        emails: emails_list,
      }

    case RESUME_SCHEDULED_EMAIL:
      emails_list = [...state.emails];
      index_id = null

      emails_list.map((email, index) => {
        if (email.id.toString() == action.data.id) 
        index_id = index;
      })

      emails_list[index_id] = action.data

      return {
        ...state,
        emails: emails_list,
      }
    
    case PAGINATE_UNSENT_EMAILS:
      const scheduled_emails_list = [...state.emails]
      const paginated_scheduled_emails = scheduled_emails_list.concat(action.data)
      const page_count = action.data.length > 0 ? state.page + 1 : state.page
      return {
        ...state,
        emails: paginated_scheduled_emails,
        page: page_count,
      }
  
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default scheduled_emails