import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import TimePeriod from './TimePeriod';
import Summary from './shared/Summary';
import OnOffSite from './club_repair/OnOffSite';
import PieChartComponent from './shared/PieChartComponent';
import BarChartComponent from './shared/BarChartComponent';
import LineTrend from './shared/LineTrend';

function ClubRepair(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);
  const {customer_id, moduleKey, from, to, reference_date, date_format, refresh_date} = props;
  const {} = state;
  
    
  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "club_repair", customer_id: customer_id, to: to, from: from, reference_date: reference_date, date_format: date_format})
  });
  
  const include_clubs_options = [
    {label: "finalized", value: "club_repairs.finalized_date"},
    {label: "entered", value: "club_repairs.created_at"} 
  ]

  return (
    <div className="col h-100 d-flex flex-column w-100 background_white">
      <TimePeriod to={to} 
                  from={from} 
                  reference_date={reference_date}
                  date_format={date_format}
                  refresh_date={refresh_date}
                  moduleKey={moduleKey} 
                  customer_id={customer_id} 
                  load_component_data={props.load_component_data} 
                  options_for_select={include_clubs_options}/> 
      <Summary data={props.components.summary["data"]} isLoading={props.components.summary["isLoading"]}/>
      <b className='main_header'>ANALYZE</b>
      <div className='row'>
        <OnOffSite data={props.components.on_site_repairs["data"]} isLoading={props.components.on_site_repairs["isLoading"]} title="ON-SITE REPAIRS" componentKey="on_site_repairs"/>
        <OnOffSite data={props.components.off_site_repairs["data"]} isLoading={props.components.off_site_repairs["isLoading"]} title="OFF-SITE REPAIRS" componentKey="off_site_repairs"/>
      </div>
      <div className='row'>
        <PieChartComponent title='REPAIR TYPE' 
                      componentKey='' 
                      data={props.components.repair_type["chart_data"]}
                      isLoading={props.components.repair_type["isLoading"]}
                      />
        <BarChartComponent 
                title="TOP GRIPS" 
                chart_data={props.components.top_grips["chart_data"]} 
                sort_options={[]} 
                componentKey="top_grips"
                moduleKey={moduleKey}
                filters={{}}
                load_component_data= {props.load_component_data}
                customer_id= {customer_id}
                isLoading={props.components.top_grips["isLoading"]}
                />
      </div>
      <div className='row'>
        <LineTrend  title="REPAIR TREND" 
                    data={props.components.repair_trend["chart_data"]} 
                    isLoading={props.components.repair_trend["isLoading"]} 
                    dataKey="Repairs"/>
      </div>
    </div>
  )
}
export default ClubRepair
