import React from 'react';
import { Col } from 'react-bootstrap';
import Select from 'react-select';

function MatchingFieldsTable(props) {
  const {matched_fields, unmatched_fields, fields_to_create, gs_fields, admin_side, setMatchFieldDropdown, createNewFieldDropdown, ignoreFieldDropdown, matchField, unmatchField, ignored_fields} = props;

  const handleMatchFieldDropdown = (event, field) => {
    if (event.value == 'new')
      createNewFieldDropdown(field.code)
    else
      if(event.value == 'ignore')
        ignoreFieldDropdown(field.code)
      else
        setMatchFieldDropdown(field.code, {id: event.value, name: event.label})
  }

  if(admin_side)
    gs_fields[0] = {label: 'Do not match', options: [{label: "No matching field", value:"skip"}]}

  return (
        
    <div className='row pt-4 flex-grow-1 overflow-auto add-margin-bottom-50' key="matching_fields"> 
      <Col md={{span: 8, offset: 2}} className="h-100 text-center">
        <div className='table-responsive'>
          <table className='table table-bordered matcher no-select tables_v2 with_striping table-striped'>
            <thead/>
            <tbody>
              <tr>
                <th className='text-center'>Callaway Field</th>
                <th className='text-center'>Golf Shop Field</th>
                <th className='text-center'>Actions</th>
              </tr>
              { !admin_side && <tr className='header march_fields'>
                <th className='text-center' colSpan={3}>
                  <div className='unmatched_header'>
                    New Fields
                  </div>
                </th>
              </tr>}

              {!admin_side && fields_to_create.map((field, index) => (
                <tr key={`tr_${field.code}`}>
                  <td className='text-start ps-4'>{field.name} ({field.code})</td>
                  <td>{field.name}</td>
                  <td>
                    <span className="background_trasparent cursor_pointer custom_vertical_align_middle delete_btn link matched_field_option no_border"
                      data-cc-field-code={field.code}
                      id={`delete_match_btn_${field.code}`}
                      onClick={() => unmatchField(field.code, 'new')}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}


              <tr className='header march_fields'>
                <th className='text-center' colSpan={3}>
                  <div className='matched_header'>
                    Matched Fields
                  </div>
                </th>
              </tr>

              {matched_fields.sort((a, b) => a.name > b.name ? 1 : -1).map((field, index) => (
                <tr key={`tr_${field.code}`}>
                  <td className='text-start ps-4'>{field.name} ({field.code})</td>
                  <td>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <span>{field.matched_field.name}</span>
                      </div>
                      {field.matched_field.global_match && <div>
                          <i className="align-middle font_size_small white color_green fa fa-asterisk tooltip_info" data-toggle="tooltip" data-html="true" data-container="body" title="This field has been matched by the Golf Genius Team."/>
                        </div>}
                    </div>
                  </td>
                  <td>
                    <span className="background_trasparent cursor_pointer custom_vertical_align_middle delete_btn link matched_field_option no_border"
                      data-cc-field-id={field.code}
                      id={`delete_match_btn_${field.code}`}
                      onClick={() => unmatchField(field.code, 'matched')}
                    >
                      Unmatch
                    </span>
                  </td>
                </tr>
              ))}

              {!admin_side && <>
                <tr className='header march_fields'>
                  <th className='text-center' colSpan={3}>
                    <div className='unmatched_header'>
                      Ignored Fields
                    </div>
                  </th>
                </tr>

                {ignored_fields.sort((a, b) => a.name > b.name ? 1 : -1).map((field, index) => (
                  <tr key={`tr_${field.code}`}>
                    <td className='text-start ps-4'>{field.name} ({field.code})</td>
                    <td>
                      {field.name}
                    </td>
                    <td>
                      <span className="background_trasparent cursor_pointer custom_vertical_align_middle delete_btn link matched_field_option no_border"
                        data-cc-field-code={field.code}
                        id={`delete_match_btn_${field.code}`}
                        onClick={() => unmatchField(field.code, 'ignored')}
                      >
                        Delete
                      </span>
                      
                    </td>
                  </tr>
                ))}
              </>}

              <tr className='header march_fields'>
                <th className='text-center' colSpan={3}>
                  <div className='unmatched_header'>
                    Unmatched Fields
                  </div>
                </th>
              </tr>

              {unmatched_fields.sort((a, b) => a.name > b.name ? 1 : -1).map((field, index) => (
                <tr key={`tr_${field.code}`}>
                  <td className='text-start ps-4'>{field.name} ({field.code})</td>
                  <td>
                    <Select id={`${field.code}_field`} 
                      name = {`${field.code}_field`}
                      isMulti={false}
                      options={gs_fields}
                      onChange={(event) => event.value != "skip" ? handleMatchFieldDropdown(event, field) : {}}
                      placeholder="No matching field"
                      />
                  </td>
                  <td>
                    { field.matched_field &&
                      <span 
                        className="background_trasparent color_red cursor_pointer custom_vertical_align_middle link match_btn no_border unmatched_field_option"
                        data-cc-field-code={field.code}
                        id={`new_match_btn_${field.code}`}
                        onClick={() => matchField(field.code)}
                      >
                        Match
                      </span>
                    }
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </div>
)}

export default MatchingFieldsTable