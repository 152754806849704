import React from 'react'
import { Col, Table, Spinner, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';

function UnsentTextMessagesSection(props) {

  const {text_messages, quick_search_value, page, total_pages} = props;

  const handlePagination = (event) => {
    props.paginateUnsentTextMessages()
  }

  const handleShowBulkTextMessageModalClick = (event) => {
    const template_id = event.target.getAttribute('data-template-id')
    props.showBulkTemplate(template_id)
  }

  const handleUnscheduleTextMessageClick = (event) => {
    if(window.confirm('Are you sure you want to cancel this scheduled text message?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.unscheduleTextMessage(communication_history_id)
    }
  }

  const handleDeleteDraftTextMessageClick = (event) => {
    if(window.confirm('Are you sure you want to delete this draft text message?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.deleteDraftTextMessage(communication_history_id)
    }
  }
  const handlePauseTextMessageClick = (event) => {
    if(window.confirm('Are you sure you want to pause this scheduled text message?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.pauseScheduledTextMessage(communication_history_id)
    }
  }

  const handleResumeTextMessageClick = (event) => {
    if(window.confirm('Are you sure you want to resume this paused scheduled text message?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.resumeScheduledTextMessage(communication_history_id)
    }
  }

  return (
      <div className='row section_container mb-4'>
        <Col xs={12} className="mb-3 p-0 communication_center_header_section">
          UNSENT TEXT MESSAGES
        </Col>
        { props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <Col xs={12} className="p-0 actions-dropdown-container">
            <Row>
              <Col xs={12} className="communication_table_container">
                <Table striped className="tableFixHead custom_template_communications mb-2">
                  <thead>
                    <tr>
                      <th className='text-center' style={{width: "105px"}}>Date</th>
                      <th className='text-center' style={{width: "105px"}}>Time</th>
                      <th>Type</th>
                      <th>Recipients</th>
                      <th className='text-center' style={{width: "75px"}}>Status</th>
                      <th className='text-center' style={{width: "75px"}}>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {text_messages.map((text_message, index) => 
                      (!quick_search_value || (quick_search_value && (text_message.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                      <tr key={index}>
                        <td className='text-center'> {text_message.sent_date} </td>
                        <td className='text-center'> {text_message.nice_format_time} </td>
                        {text_message.entity_preview_link && <td> {<div dangerouslySetInnerHTML={{ __html: text_message.subject + ` - <a class="link" href="${text_message.entity_preview_link}" data-method="get">${text_message.entity_type == "OnlinePoForm" ? "View Online Form" : "View Survey"}</a>`}}/>} </td>}
                        {!text_message.entity_preview_link && text_message.entity_deleted && <td> 
                            <span>{text_message.subject}</span>
                            <OverlayTrigger
                              key="entity_deleted_tooltip"
                              placement="top"
                              overlay={
                                <Tooltip id={`entity_deleted_tooltip`}>
                                  {(text_message.entity_type === 'OnlinePoForm' ? 'Online Form' : 'Survey') + " associated with text message this has been deleted."}
                                </Tooltip>
                                }>
                              <i className="ms-2 gga-info-circle tooltip_info color_red" />
                            </OverlayTrigger>
                            </td>
                          }
                        {!text_message.entity_preview_link && !text_message.entity_deleted && <td> {text_message.subject} </td>}
                        <td> 
                          {text_message.recipients}
                        </td>
                        {text_message.scheduled_communication && !text_message.paused && <td className='text-center color_green'> 
                            Scheduled
                          </td>}
                        {text_message.scheduled_communication && text_message.paused && <td className='text-center color_red'> 
                          Paused
                        </td>}
                        {text_message.is_draft && <td className='text-center'> 
                          Draft
                        </td>}
                        {text_messages.is_draft && <td className='text-center color_red'> 
                          Draft
                        </td>}
                        <td className='text-center actions-dropdown-cell'>
                          <div className="dropdown actions-dropdown">
                            <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {`dropdownContainerItemMenu_${text_message.id}`} />
                            <ul className="dropdown-menu dropdown-menu-right" aria-labelledby = {`dropdownContainerItemMenu_${text_message.id}`} >
                              <li className="dropdown-item"> 
                                <span 
                                  className='link'
                                  style={{fontSize: "14px"}}
                                  onClick={handleShowBulkTextMessageModalClick} 
                                  data-template-id={text_message.id}
                                > 
                                  Preview 
                                </span>
                              </li>
                              <li className="dropdown-item"> 
                                <a 
                                  className="link"
                                  style={{fontSize: "14px"}}
                                  key={index}
                                  href={text_message.communication_template_edit_link}
                                  data-method="GET"
                                >
                                  Edit
                                </a>
                              </li>
                              {text_message.scheduled_communication && text_message.paused && text_message.can_resume && <li className="dropdown-item"> 
                                <span 
                                  className='link'
                                  style={{fontSize: "14px"}}
                                  data-template-id={text_message.id}
                                  onClick={handleResumeTextMessageClick} 
                                > 
                                  Resume
                                </span>
                              </li>}
                              {text_message.scheduled_communication && text_message.paused && !text_message.can_resume && text_message.entity_type == "OnlinePoForm" && <li className="dropdown-item"> 
                                <OverlayTrigger
                                  key="resume_text_message"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-resume_text_message`}>
                                      Cannot resume a text message that is scheduled outside an online form's open/close time period.
                                    </Tooltip>
                                  }
                                >
                                  <span
                                  className="cursor_pointer color_gray disabled"
                                  style={{fontSize: "14px"}}
                                  >
                                    Resume 
                                  </span>
                                </OverlayTrigger>
                              </li>}
                              {text_message.scheduled_communication && text_message.paused && !text_message.can_resume && text_message.entity_type == "Survey" && <li className="dropdown-item"> 
                                <OverlayTrigger
                                  key="resume_text_message"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-resume_text_message`}>
                                      Cannot resume a text message that is scheduled for a closed survey.
                                    </Tooltip>
                                  }
                                >
                                  <span
                                  className="cursor_pointer color_gray disabled"
                                  style={{fontSize: "14px"}}
                                  >
                                    Resume 
                                  </span>
                                </OverlayTrigger>
                              </li>}
                              {text_message.scheduled_communication && !text_message.paused && <li className="dropdown-item"> 
                                <span 
                                  className='link'
                                  style={{fontSize: "14px"}}
                                  data-template-id={text_message.id}
                                  onClick={handlePauseTextMessageClick} 
                                > 
                                  Pause
                                </span>
                              </li>}
                              {text_message.scheduled_communication && <li className="dropdown-item"> 
                                <span 
                                  className='link color_red'
                                  style={{fontSize: "14px"}}
                                  data-template-id={text_message.id}
                                  onClick={handleUnscheduleTextMessageClick} 
                                > 
                                  Delete
                                </span>
                              </li>}
                              {text_message.is_draft && <li className="dropdown-item"> 
                                <span 
                                  className='link color_red'
                                  style={{fontSize: "14px"}}
                                  data-template-id={text_message.id}
                                  onClick={handleDeleteDraftTextMessageClick} 
                                > 
                                  Delete
                                </span>
                              </li>}
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  
                </Table>
                </Col>
              {text_messages.length > 0 && page < total_pages && <Col xs={12}>
                <span 
                  className='link'
                  onClick={handlePagination}
                >
                  View More
                </span>
              </Col>}
              {text_messages.length <= 0 && <Col xs={12}>
                <span>
                  No scheduled text messages
                </span>
              </Col>}
            </Row>
          </Col>
        }
      </div>
  )
}

export default UnsentTextMessagesSection
