import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function TemplateTile(props) {

  const {template, thumbnail_template_body, default_template, } = props;

  const handleCloneTemplateClick = (event) => {
    const default_template_id = event.target.getAttribute('data-template-id')
    const template_type = event.target.getAttribute('template-type')
    props.cloneTemplate(default_template_id, template_type)
  }

  const handleDeleteTemplateClick = (event) => {
    if (!confirm("Are you sure?"))
      return
    const template_id = event.target.getAttribute('data-template-id')
    props.deleteTemplate(template_id)
  }

  const handleShowTemplateClick = (event) => {
    const template_id = event.target.getAttribute('data-template-id')
    const template_type = event.target.getAttribute('data-template-type')
    props.showTemplate({
      template_id: template_id,
      template_type: template_type
    })
  }

  return (
    <div style={{width: "250px"}} key={`template_tile_${template.id}`}>
      <div className='template_tile_container'>
        <div className='title_container'>
          <div className='title'>
            <OverlayTrigger
              key="background_opacity"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-background_opacity`}>
                  {template.title.toUpperCase()}
                </Tooltip>
              }
            >
              <div>
                {!default_template && <div className="d-flex flex row">
                  <span className="col-10" style={{textOverflow: "ellipsis", display: "block", overflow: "hidden", whiteSpace: "no-wrap"}}> {template.title.toUpperCase()} </span>
                  {!default_template && <i data-template-id={template.id} onClick={handleDeleteTemplateClick} className="fa fa-trash mt-2 col-2 cursor_pointer" />}
                </div>}
                {default_template && <span> {template.title.toUpperCase()} </span>}
              </div>
            </OverlayTrigger>
          </div>
        </div>

        <div className='body'>
          {thumbnail_template_body}

          <div className='menu_container'>
            {!default_template && template.action_buttons.map((action_button, index) => (
                action_button.title.toLowerCase() != "copy" ?
                <a 
                  key={index}
                  className={`btsp_orange_button ${action_button.classes}`}
                  href={action_button.url} 
                  data-method={action_button.request_type}
                  data-confirm={action_button.data_confirm}
                  rel={action_button.rel}
                >
                  {action_button.title}
                </a>  
                : 
                <span
                  key={index}
                  className={`btsp_orange_button`}
                  data-template-id={template.id}
                  template-type={"custom"}
                  onClick={handleCloneTemplateClick}
                >
                  Copy
                </span>   
              ))}

            {default_template && 
              <span
                className={`btsp_orange_button`}
                data-template-id={template.id}
                template-type={"default"}
                onClick={handleCloneTemplateClick}
              >
                Add to my templates
              </span>
            }
          </div>
        </div>

        <div className='stats'>
          <i className='fa fa-fw fa-search cursor_pointer' onClick={handleShowTemplateClick} data-template-id={template.id} data-template-type={default_template ? 'default' : 'custom'}/>
        </div>
      </div>
    </div>
  )
}

export default TemplateTile
