import { connect } from 'react-redux'
import ShowTemplateModal from '../../shared/components/ShowTemplateModal'
import { closeCustomTemplateModal } from '../actions'
                     
const mapStateToProps = (state) => ({
  modal_open: state.custom_templates.modal_show_custom_template_open,
  template: state.custom_templates.template_to_display,
  template_type: 'text_message',
})

const mapDispatchToProps = (dispatch) => ({
  closeCustomTemplateModal: () => dispatch(closeCustomTemplateModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowTemplateModal)
