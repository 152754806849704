import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
class Header extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    
    return (
      <div className="row m-0 custom_name border-bottom border-dark position-sticky">
        <div className='col-4 p-0'>
          <h5 className="page-title ms-0">
            {" PROFILE SETUP "}
            <span className="icon-record"/>
            {" DASHBOARD "}
          </h5>
        </div>
      </div>
      
    )
  }
}

export default Header
