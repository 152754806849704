import { showErrorNotification, callAPI } from 'shared/helpers'
import React from 'react'

export const ActionTypes = {
  MATCH_CATEGORY: 'MATCH_CATEGORY',
  UNMATCH_CATEGORY: 'UNMATCH_CATEGORY',
  UNDO_NEW_CATEGORY: 'UNDO_NEW_CATEGORY',
  MATCH_CATEGORY_DROPDOWN: 'MATCH_CATEGORY_DROPDOWN',
  MATCH_NEW_CATEGORY_DROPDOWN: 'MATCH_NEW_CATEGORY_DROPDOWN',
  CREATE_CATEGORY_DROPDOWN: 'CREATE_CATEGORY_DROPDOWN',
  SET_PARENT_NEW_CATEGORY_DROPDOWN: 'SET_PARENT_NEW_CATEGORY_DROPDOWN',
  MATCH_FIELD: 'MATCH_FIELD',
  UNMATCH_FIELD: 'UNMATCH_FIELD',
  MATCH_FIELD_DROPDOWN: 'MATCH_FIELD_DROPDOWN',
  CREATE_FIELD_DROPDOWN: 'CREATE_FIELD_DROPDOWN',
  IGNORE_FIELD_DROPDOWN: 'IGNORE_FIELD_DROPDOWN'
}

export const matchCategory = (cc_category_id) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MATCH_CATEGORY,
    data: {
      cc_category_id: cc_category_id,
    },
  })
}

export const unmatchCategory = (cc_category_id, matching_action) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UNMATCH_CATEGORY,
    data: {
      cc_category_id: cc_category_id,
      matching_action: matching_action,
    },
  })
}

export const undoNewCategory = (cc_category_id) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UNDO_NEW_CATEGORY,
    data: {
      cc_category_id: cc_category_id,
    },
  })
}

export const setMatchCategoryDropdown = (cc_category_id, category) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MATCH_CATEGORY_DROPDOWN,
    data: {
      cc_category_id: cc_category_id,
      category: category,
    },
  })
}



export const matchNewCategoryDropdown = (cc_category_id) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MATCH_NEW_CATEGORY_DROPDOWN,
    data: {
      cc_category_id: cc_category_id,
    },
  })
}

export const createNewCategoryDropdown = (cc_category_id) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CREATE_CATEGORY_DROPDOWN,
    data: {
      cc_category_id: cc_category_id,
    },
  })
}

export const setParentNewCategoryDropdown = (cc_category_id, parent_category) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_PARENT_NEW_CATEGORY_DROPDOWN,
    data: {
      cc_category_id: cc_category_id,
      parent_category: parent_category,
    },
  })
}

export const matchField = (cc_field_code) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MATCH_FIELD,
    data: {
      cc_field_code: cc_field_code,
    },
  })
}

export const unmatchField = (cc_field_code, matching_action) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UNMATCH_FIELD,
    data: {
      cc_field_code: cc_field_code,
      matching_action: matching_action,
    },
  })
}

export const setMatchFieldDropdown = (cc_field_code, field) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MATCH_FIELD_DROPDOWN,
    data: {
      cc_field_code: cc_field_code,
      field: field,
    },
  })
}

export const createNewFieldDropdown = (cc_field_code) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CREATE_FIELD_DROPDOWN,
    data: {
      cc_field_code: cc_field_code,
    },
  })
}

export const ignoreFieldDropdown = (cc_field_code) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.IGNORE_FIELD_DROPDOWN,
    data: {
      cc_field_code: cc_field_code,
    },
  })
}

export const saveCategoriesConfiguration = () => (dispatch, getState) => {
  const state = getState()
  var customer_id = state.callaway_connect.customer_id
  const admin_side = state.callaway_connect.admin_side
  var matched_categories_hash = {}
  state.callaway_connect.matched_categories.map((category) => matched_categories_hash[category.code] = category.matched_category.id)

  var to_create_hash = {}
  state.callaway_connect.categories_to_create.map((category) => to_create_hash[category.code] = category.parent_category_id)

  var unmatched_list = []
  state.callaway_connect.unmatched_categories.map((category) => unmatched_list.push(category.code))
  
  if (admin_side)
    customer_id = 437

  const dataURL = admin_side ? "/admin/callaway_connect/set_admin_matched_categories" : `/customers/${customer_id}/callaway_connect/set_matched_categories`
  const params = {
    customer_id: customer_id,
    matched: JSON.stringify(matched_categories_hash),
    unmatched: JSON.stringify(unmatched_list),
    to_create: JSON.stringify(to_create_hash),
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      if (admin_side)
        window.location.href = `match_categories`;
      else
        window.location.href = `/customers/${customer_id}/callaway_connect/match_categories`;
    })
    
    .catch(() => {
      showErrorNotification('Error while updating data!')
    })
}

export const saveFieldsConfiguration = () => (dispatch, getState) => {
  const state = getState()
  var customer_id = state.callaway_connect.customer_id
  const admin_side = state.callaway_connect.admin_side
  var matched_fields_hash = {}
  state.callaway_connect.matched_fields.map((field) => matched_fields_hash[field.code] = field.matched_field.id)

  var to_create_list = []
  state.callaway_connect.fields_to_create.map((field) => to_create_list.push(field.code))

  var unmatched_list = []
  state.callaway_connect.unmatched_fields.map((field) => unmatched_list.push(field.code))

  var ignored_list = []
  state.callaway_connect.ignored_fields.map((field) => ignored_list.push(field.code))
  
  if (admin_side)
    customer_id = 437

  const dataURL = admin_side ? "/admin/callaway_connect/set_admin_matched_fields" : `/customers/${customer_id}/callaway_connect/set_matched_fields`
  const params = {
    customer_id: customer_id,
    matched: JSON.stringify(matched_fields_hash),
    unmatched: JSON.stringify(unmatched_list),
    to_create: JSON.stringify(to_create_list),
    ignored: JSON.stringify(ignored_list),
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      if (admin_side)
        window.location.href = `match_fields`;
      else
        window.location.href = `/customers/${customer_id}/callaway_connect/match_fields`;
    })
    
    .catch(() => {
      showErrorNotification('Error while updating data!')
    })
}