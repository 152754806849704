import React from 'react'
import PropTypes from 'prop-types'
import rootReducer from '../../reducers'
import configureStore from '../../../shared/configure_store'
import { GlobalProvider } from '../../../shared/global_context'
import FilterWrapper from './filter_wrapper'
import { DISPLAY_MODES, setBlockIndex, setColumnIndex, setTabIndex } from '../../actions'
import moment from 'moment';

const Root = ({
  initialState = null
}) => {
  // Setup state from controller
  const newState = JSON.parse(initialState)

  // Default state
  let initialSpecialOrders = {
    current_orders:
      [{
      vendor: "Addidas", category: "Drivers", status: "Order Placed", 
      price: "$450", quantity: "3",
      category_details: [["Head Type", "TSi3"], ["Lh/Rh", "Rh"], ["Shaft Type", "Smoke Black RDX"], ["Shaft Length", "-0.5(45.25)"]]
      }],
    past_orders: [{
      vendor: "Callaway", category: "Drivers", status: "Picked-Up", 
      price: "$450", quantity: "5",
      category_details: [["Head Type", "TSi3"], ["Lh/Rh", "Rh"], ["Shaft Type", "Smoke Black RDX"], ["Shaft Length", "-0.5(45.25)"]]
      }],
  }
  let initialClubRepairs = 
    {
      current_clubs:
        [{
        repair_type: "Re-Gripping", nr_of_clubs: "5", repair_details: "", 
        price: "25$", type: "Offsite", desired_completion_date: moment("01/22/2022").format(newState.general_settings.date_format.toUpperCase()), technician: "John Smith",
        }],
      requested_repairs:
        [{
          repair_type: "Re-Gripping", nr_of_clubs: "5", repair_details: "Replace with Pride Grips", 
          desired_completion_date: moment("01/22/2022").format(newState.general_settings.date_format.toUpperCase()), grip_id: null
        }],
      past_clubs:
          [{
          repair_type: "Re-Gripping", nr_of_clubs: "5", repair_details: "", 
          price: "25$", type: "Offsite", completion_date: moment("11/22/2022").format(newState.general_settings.date_format.toUpperCase()), technician: "John Smith",
          }],
    }
  let initialDemoClubs = {
    current_demos:
      [{id: '1',
      type: "Full Club", tracking_id: "WDEMO051", expected_return_date: moment("01/22/2022").format(newState.general_settings.date_format.toUpperCase()), 
      details: [["Type", "Demo Wedge"], ["Make", "Callaway"], ["Model", "JAWS MDS"], ["Lie", "Standard"],
      ["Loft", "60 degrees"], ["Bounce", "10S"], ["Dexterity", "RH"]]
      }],
    reserved_demos:
      [{ id: '2',
        type: "Full Club", tracking_id: "WDEMO051", reserve_date: moment("11/22/2022").format(newState.general_settings.date_format.toUpperCase()), 
        details: [["Type", "Demo Wedge"], ["Make", "Callaway"], ["Model", "JAWS MDS"], ["Lie", "Standard"],
        ["Loft", "60 degrees"], ["Bounce", "10S"], ["Dexterity", "RH"]]
        }],
    past_demos: [{ id: '3',
      type: "Full Club", tracking_id: "WDEMO051", return_date: moment("11/22/2022").format(newState.general_settings.date_format.toUpperCase()), 
      details: [["Type", "Demo Wedge"], ["Make", "Callaway"], ["Model", "JAWS MDS"], ["Lie", "Standard"],
      ["Loft", "60 degrees"], ["Bounce", "10S"], ["Dexterity", "RH"]]
      }],
  }

  let state = {
    tabs: [],
    blocks: [],
    columns: [],
    filters: {
      editMode: false,
      specialOrders: initialSpecialOrders,
      clubRepairs: initialClubRepairs,
      demoClubs: initialDemoClubs,
      active_tab: -1000,
      onlineForms: [],
      onlineFormsURL: "",
      display_mode: DISPLAY_MODES.DEFAULT,
      widgets: [],
      active_column: null,
      active_block: null,
      position: null,
      iframe_pointer_options: 'auto',
      draggable_element: null,
      dragged_over_element: null,
      dragged_over_position: null,
      new_page_path: null,
      form_submitted: null,
      customer_id: null,
      currency_symbol: "$",
      inventory: [],
      member_id: null,
      selected_club_id: null,
      inventory_select: [],
      demo_page: null,
      managers: [],
      member_view: null,
      repairTypes: [],
      grips: [],
      display_grip_retail_price: true,
      member_profile_data: [],
      member_profile_values: {},
      infoMessage: null,
      preview: false,
      new_template: false,
      copy_template: false,
      have_settings: false,
      customer_template: false,
      edit_settings: false
    },
    profile_setup: {
      all_tabs: [],
      current_tab: null,
      hidden_tabs: [],
      view_mode: "member_preview",
      customer_id: null,
      activity_log: [],
      activity_log_entities: [],
      all_metadata: [],
      metadata_values: {},
      notification_settings: {},
      profile_picture_url: null,
      preview_member_profile: false
      },
    general_settings: {},
  }

  // Setup state from controller  
  if (newState !== null) {
    state = { ...state, filters: { ...state.filters, member_profile_data: newState.member_profile_data}}
    state = { ...state, filters: { ...state.filters, member_profile_values: newState.member_profile_values}}
    state = { ...state, filters: { ...state.filters, member_view: newState.member_view}}
    state = { ...state, filters: { ...state.filters, repairTypes: newState.repairTypes}}
    state = { ...state, filters: { ...state.filters, grips: newState.grips}}
    state = { ...state, filters: { ...state.filters, display_grip_retail_price: newState.display_grip_retail_price}}
    state = { ...state, filters: { ...state.filters, managers: newState.managers}}
    state = { ...state, filters: { ...state.filters, member_id: newState.member_id}}
    state = { ...state, filters: { ...state.filters, currency_symbol: newState.currency_symbol}}
    state = { ...state, filters: { ...state.filters, editMode: newState.editMode}}
    state = { ...state, filters: { ...state.filters, onlineForms: newState.onlineForms}}
    state = { ...state, filters: { ...state.filters, onlineFormsURL: newState.onlineFormsURL}}
    state = { ...state, filters: { ...state.filters, customer_id: newState.customer_id}}
    state = { ...state, filters: { ...state.filters, preview: newState.preview}}
    state = { ...state, filters: { ...state.filters, new_template: newState.new_template}}
    state = { ...state, filters: { ...state.filters, copy_template: newState.copy_template}}
    state = { ...state, filters: { ...state.filters, have_settings: newState.have_settings}}
    state = { ...state, filters: { ...state.filters, customer_template: newState.customer_template}}
    state = { ...state, filters: { ...state.filters, edit_settings: newState.edit_settings}}
    

    if(newState.flashMessage)
      state = { ...state, filters: { ...state.filters, flashMessage: newState.flashMessage}}
    
    if (newState.specialOrders) {
      state = { ...state, filters: { ...state.filters, specialOrders: newState.specialOrders }}
    }
    if (newState.clubRepairs) {
      state = { ...state, filters: { ...state.filters, clubRepairs: newState.clubRepairs }}
    }
    if (newState.demoClubs) {
      state = { ...state, filters: { ...state.filters, demoClubs: newState.demoClubs }}
    }
    if (newState.tabs && newState.tabs.length !== 0) {
      const colPosition = newState.columns.length !== 0 ? newState.columns.sort( (a, b) => a.index < b.index ? 1 : -1 )[0].index : 0
      setColumnIndex(colPosition)
      setTabIndex(newState.tabs.sort( (a, b) => a.index < b.index ? -1 : 1 )[0].position)
      state = { ...state, filters: { ...state.filters, active_tab: newState.tabs[0].id, widgets: newState.sections}}
      newState.tabs.map(tab => {
        state = { ...state, tabs: [ ...state.tabs, { id: tab.id, title: tab.title, editing: false, deleting: false, index: tab.position, width:  tab.width} ] }
      })
      let blockPosition = 0
      newState.blocks.sort( (a, b) => a.position < b.position ? -1 : 1 ).map(block => {
        state = { ...state, blocks: [ ...state.blocks, { id: block.id, title: block.title, layout: `${block.layout}`, tabId: block.tab_id, index: blockPosition++ } ] }
      })
      setBlockIndex(blockPosition)
      newState.columns.map(column => {
        state = { ...state, columns: [ ...state.columns, { id: column.id, position: (column.position === 0 ? 'left' : 'right'), height: column.height, blockId: column.block_id, index: column.index, title: column.title, view_more: column.view_more, widget_id: column.widget_id, options: column.options, display: true } ] }
      })
    } else {
      state = { ...state, filters: { ...state.filters, widgets: newState.sections}}
    }

    state = { ...state, general_settings: newState.general_settings, profile_setup: { all_tabs: newState.all_tabs, current_tab: newState.current_tab, hidden_tabs: newState.hidden_tabs, view_mode: "member_preview", customer_id: newState.customer_id, activity_log: newState.activity_log, activity_log_entities: newState.activity_log_entities, all_metadata: newState.all_metadata, metadata_values: newState.metadata_values, notification_settings: newState.notification_settings, profile_picture_url: newState.profile_picture_url, preview_member_profile: newState.preview_member_profile } }
  }

  const store = configureStore(rootReducer, state)
  const globalData = {}

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider data={ globalData } store={ store }>
      <FilterWrapper />
    </GlobalProvider>
  )
}

Root.propTypes = {
  initialState: PropTypes.any
}

export default Root
