import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import DatePicker from "react-datepicker";
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import {useState} from 'react';
import _, { keys } from 'lodash';
import update from 'immutability-helper';

function HandleDuplicatesModal(props) {
  
  const initialState = {all_matched_values:  props.all_matched_values}

  const [state, setState] = useState(initialState);

  const handleClose = () => {
    props.closeHandleDuplicatesModal()
  }
  
  const handleSubmit = () => {
    let params = {...state.all_matched_values}
    
    props.updateDuplicates(params)
    handleClose()
  }
  
  const handleRadioInputChange = (event) => {
    setState(update(state, {
      all_matched_values:  
      { [event.target.name]: {$set : event.target.value }}
      }))
  }
  
  const {handle_duplicates_modal_open, customer_id} = props;

  const {all_matched_values} = state;
  let index_arr = keys(props.spreadsheet_duplicates_rows)
  return (
    <div className="modal_handle_duplicates_modal_container">
      <Modal isOpen={handle_duplicates_modal_open}>
        <ModalHeader >
          <h4 className="modal-title form_new_edit no-margin">Handle Spreadsheet Duplicates</h4>
        </ModalHeader>
        <ModalBody>
          <Form>
          {/* matched rows */}
          {index_arr.map((k) => 
            (<div className='border_all_dashed p-3 mb-3'><div className="row pb-2">{
              props.spreadsheet_duplicates_rows[k].map((kk, index) => 
                (<div className="row">{
                  props.spreadsheet_duplicates_data[parseInt(kk)].map((kkk) => (
                    ((index == 0) && props.spreadsheet_matching_fields.includes(kkk['key'])) 
                    ?
                    <div className='col'>
                      <h4>{kkk['key']}: {kkk['value']}</h4>
                    </div>
                    :
                    <></>
                  ))
                }</div>)
              )
            }</div>
            <div className="row pb-2">
              
                <Table responsive bordered>
                <thead><tr><th></th>
                {
                  props.spreadsheet_keys.map((kkk) => (
                    (!props.spreadsheet_matching_fields.includes(kkk)) && kkk != "GGS_ID" ?              
                        <th>{kkk}</th>
                    :
                    <></>
                  ))
                }</tr></thead>
                <tbody>
                {props.spreadsheet_duplicates_rows[k].map((kk, index) => 
                <tr>
                  <td>
                    <Form.Check 
                        inline
                        type='radio'
                        name={k}
                        value = {kk}
                        id={k}
                        label=""
                        className="col"
                        checked = {all_matched_values[k] == kk}
                        onChange={handleRadioInputChange}
                      />
                  </td>
                  {props.spreadsheet_keys.map((kkk) => (
                    (!props.spreadsheet_matching_fields.includes(kkk)) && kkk != "GGS_ID" ?  
                        _.find(props.spreadsheet_duplicates_data[parseInt(kk)], (kkkk) => kkkk['key'] == kkk)
                        ?
                        <td style={{minWidth: "70px"}}>{_.find(props.spreadsheet_duplicates_data[parseInt(kk)], (kkkk) => kkkk['key'] == kkk)['value']}</td>
                        :
                        <td></td>
                    :
                    <></>
                    ))
                }
                </tr>)}
                </tbody>
                </Table>
            </div></div>))}
            
          </Form>
        </ModalBody>
        <ModalFooter>
        <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default HandleDuplicatesModal