import { connect } from 'react-redux'
import NotificationForm from '../presentational/notification_form'
import {addToWaitingList, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  member_id: state.filters.member_id,
  inventory: state.filters.inventory,
  selected_club_id: state.filters.selected_club_id,
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  
  addToWaitingList: (id) => dispatch(addToWaitingList(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationForm)
