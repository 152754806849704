import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
function TaskDashboardHeader(props) {
    return (
      <div className="row custom_name border-bottom border-dark">
        <div className='col-4 p-0'>
          <h5 className="page-title ms-0">
            {" STAFF "}
            <span className="icon-record"/>
            {" TASKS "}
            <span className="icon-record"/>
            {" DASHBOARD "}
          </h5>
        </div>
      </div>

    )
}

export default TaskDashboardHeader
