import { ActionTypes } from '../actions'

const {
  FETCH_COMMUNICATION_CENTER_DATA_REQUEST,
  OPEN_COMMUNICATION_TEMPLATE_MODAL,
  CLOSE_COMMUNICATION_TEMPLATE_MODAL,
  UNSCHEDULE_COMMUNICATION,
  PAUSE_SCHEDULED_COMMUNICATION,
  RESUME_SCHEDULED_COMMUNICATION
} = ActionTypes

const initialState = {
  communication_history_list: [],
  modal_show_communication_history_open: false,
  template_to_display: null,
  template_type_to_display: null
}

let communications_list = []
let index_id = null
  
const communication_center = (state = initialState, action) => {

  switch (action.type) {
    case FETCH_COMMUNICATION_CENTER_DATA_REQUEST:
      return {
        ...state,
        communication_history_list: action.data.communication_history_list
      }

    case OPEN_COMMUNICATION_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: action.data.template,
        template_type_to_display: action.data.template_type,
        modal_show_communication_history_open: true
      }

    case CLOSE_COMMUNICATION_TEMPLATE_MODAL:
      return {
        ...state,
        template_to_display: null,
        template_type_to_display: null,
        modal_show_communication_history_open: false
      }

    case UNSCHEDULE_COMMUNICATION:
      const updated_communication_history_list = [...state.communication_history_list].filter(function(communication) { return communication.id !== action.data })
      return {
        ...state,
        communication_history_list: updated_communication_history_list,
      }
    
    case PAUSE_SCHEDULED_COMMUNICATION:
      communications_list = [...state.communication_history_list];
      index_id = null

      communications_list.map((communication, index) => {
        if (communication.id.toString() == action.data.id) 
        index_id = index;
      })

      communications_list[index_id] = action.data

      return {
        ...state,
        communication_history_list: communications_list,
      }

    case RESUME_SCHEDULED_COMMUNICATION:
      communications_list = [...state.communication_history_list];
      index_id = null

      communications_list.map((communication, index) => {
        if (communication.id.toString() == action.data.id) 
        index_id = index;
      })

      communications_list[index_id] = action.data

      return {
        ...state,
        communication_history_list: communications_list,
      }
        
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default communication_center