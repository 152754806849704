import React from 'react';
import { Table } from 'react-bootstrap';

function TableComponent(props) {
  let header = []
  switch(props.componentType) {
    case 'delivery_time_by_vendor':
      header = ["Vendor", "Average"];
      break;
    case 'on_site_repairs':
      header = ["Technician", "Assigned Repairs", "Total Clubs Repaired", "Total Commission", "Avg Repair Duration"]
      break;
    case 'off_site_repairs':
      header = ["Vendor", "Assigned Repairs", "Total Clubs Repaired", "Total Repair Price", "Avg Repair Duration"]
      break;
    case 'delivery_time_stocks_by_vendor':
      header = ["Vendor", "Average Delivery"]
      break;
  }

  return (
    <Table className={props.moduleKey == "club_repair" || props.moduleKey == "stock_order" ? 'orange_table' : 'blue_table'}>
      <thead>
        <tr>
        {header.map((h) => (
          <th className={props.moduleKey == "club_repair" || props.moduleKey == "stock_order" ? 'p-3' : 'ps-3'}>{h}</th>
        ))}
        </tr>
      </thead>
      <tbody>
        {(props.data.length > 0) && 
          props.data.map((dt) => (
            <tr >
              {dt.map((v, index) => (
              <td className={props.moduleKey == "club_repair" || props.moduleKey == "stock_order" ? 'p-3' : 'ps-3'} style={{color: props.moduleKey == "stock_order" ? index == 1 && v != 0 ? v < 0 ? 'red' : 'green' : 'black' : 'black'}}>{(index == 1 && (props.moduleKey == "stock_order" || props.moduleKey == "special_order")) ? (props.moduleKey == "special_order" || (v == 0 || v < 0)) ? `${v} days` : `+${v} days` : v}</td>
              ))}
            </tr>
          ))
        }
      </tbody>
    </Table>
    )}
export default TableComponent