import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import SpecialOrderContent from './special_order_content'

class SpecialOrdersTile extends Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    return (  
        <div className="special_orders_tile h-100" style={{overflowY: 'auto', overflowX: 'hidden'}}>
          <div className='row current_orders'>            
              <div className={`col-12 col-lg-${this.props.maxWidthChildren}`}>
              <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
              <span>CURRENT ORDERS</span>
              </div>
              {this.props.data.current_orders.map((order, index) => (
              <SpecialOrderContent key={`${order.id}_${index}`} order={order} schemaColor={this.props.schemaColor}/>
              ))}
              </div>

              <div className={`col-12 col-lg-${this.props.maxWidthChildren}`} >
              <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
              <span>PAST ORDERS</span>
              </div>
              {this.props.data.past_orders.map((order, index) => (
              <SpecialOrderContent key={`${order.id}_${index}`} order={order} schemaColor={this.props.schemaColor}/>
              ))}
              </div>
        </div>
      </div>
    )
  }
}

export default SpecialOrdersTile
