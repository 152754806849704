import { connect } from 'react-redux'
import { deleteBlock, hideMetadatum, deleteMetadatum, showField,  unlockMetadatum, lockMetadatum, addNewField, openEditValuesModal, openEditBlockTitleModal, swapOrder } from '../actions'
import CustomTab from '../components/CustomTab'

const mapStateToProps = (state, props) => ({
  all_tabs: state.profile_setup.all_tabs,
  current_tab: state.profile_setup.current_tab,
  view_mode: state.profile_setup.view_mode,
  setMemberMetadata: props.setMemberMetadata,
  memberMetadata: props.memberMetadata,
  updatedMemberMetadata: props.updatedMemberMetadata,
  setUpdatedMemberMetadata: props.setUpdatedMemberMetadata,
  all_metadata: state.profile_setup.all_metadata,
  metadata_values: state.profile_setup.metadata_values,
  metadata_dropdown_fields: state.profile_setup.metadata_dropdown_fields,
  originalMemberMetadata: props.originalMemberMetadata
})

const mapDispatchToProps = (dispatch) => ({
  hideMetadatum: (metadatum_id, block_id, tab_id) => dispatch(hideMetadatum(metadatum_id, block_id, tab_id)),
  showField: (metadatum_id, block_id, tab_id, all_tabs) => dispatch(showField(metadatum_id, block_id, tab_id, all_tabs)),
  openEditValuesModal: (tab_id, block_id, metadatum_id) => dispatch(openEditValuesModal(tab_id, block_id, metadatum_id)),
  lockMetadatum: (metadatum_id, block_id, tab_id, all_tabs) => dispatch(lockMetadatum(metadatum_id, block_id, tab_id, all_tabs)),
  unlockMetadatum: (metadatum_id, block_id, tab_id, all_tabs) => dispatch(unlockMetadatum(metadatum_id, block_id, tab_id, all_tabs)),
  deleteMetadatum: (metadatum_id, block_id, tab_id, all_tabs) => dispatch(deleteMetadatum(metadatum_id, block_id, tab_id, all_tabs)),
  deleteBlock: (block_id, tab_id) => dispatch(deleteBlock(block_id, tab_id)),
  openEditBlockTitleModal: (block_id, tab_id) => dispatch(openEditBlockTitleModal(block_id, tab_id)),
  swapOrder: (draggedId, hoveredId, type, blockId, placement) => dispatch(swapOrder(draggedId, hoveredId, type, blockId, placement)),
  addNewField: (title, member_profile_category_id, block_id, tab_id, all_tabs) => dispatch(addNewField(title, member_profile_category_id, block_id, tab_id, all_tabs)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomTab)