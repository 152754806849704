import { ActionTypes } from '../actions'

const {
  LOAD_COMPONENT_DATA,
  SET_IS_LOADING
} = ActionTypes

  
const dashboard = (state = {}, action) => {
  switch (action.type) {
    case LOAD_COMPONENT_DATA:
      if(action.data.subcomponent_key)
      {
        let components = {...state.components}
        components[action.data.subcomponent_key] = action.data.subcomponent_data
        
        return {
          ...state,
          components: components
        }
      }
      else
        return {
          ...state,
          components: action.data.components,
          from: action.data.from,
          to: action.data.to,
          reference_date: action.data.reference_date,
          date_format: action.data.date_format,
          refresh_date: action.data.refresh_date,
          isLoading: action.data.isLoading,
          custom_fields_list: action.data.custom_fields_list,
          used_category_ids: action.data.used_category_ids
        }
    case SET_IS_LOADING:
      let components = {...state.components}
    
      if(action.data.subcomponent_key)
        components[action.data.subcomponent_key].isLoading = action.data.isLoading
      else
        Object.values(components).map(key => key['isLoading'] = action.data.isLoading);

      return {
        ...state,
        components: components
      }
    default:
      return state
  }
}
export default dashboard
