import React from 'react';
import {Cell, ResponsiveContainer, Tooltip, PieChart, Pie, Legend } from 'recharts';
import { Spinner} from 'react-bootstrap';

function PieChartComponent(props) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={13} fontWeight='bold'>
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };
  
  let colors = []
  colors = ["#224D8D", "#F3B029", "#F24C00", "#12A9BD", "#04323E", "#D62246", "#0D1321", "#DAB6C4", "#2D3047", "#D5F9DE", "#38023B", "#A28497", "#721121"]

  return (
    <div className='col p-20 add-margin-all-10 mb-0 text-center piechart_container'>
      <h4 className='text-start header'>{props.title}</h4>
      {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
        <ResponsiveContainer height={450}>
            <PieChart>
              <Pie dataKey="value" 
                    data={props.data} 
                    innerRadius={78} 
                    outerRadius={133} 
                    label={renderCustomizedLabel}
                    labelLine={false}
                    fill="#f24c00" >
                      {props.data.map(
                            (item, index) => (
                              <Cell fill={colors[index % colors.length]}/>
                            )
                          )}
                    </Pie>
              <Legend
                layout='vertical' 
                verticalAlign='bottom'
                iconSize={15}
                payload={
                  props.data.map(
                    (item, index) => ({
                      id: item.name,
                      type: "square",
                      value: `${item.name}: ${item.value}`,
                      color: colors[index % colors.length],
                    })
                  )
                }
              />
            </PieChart>
          </ResponsiveContainer>
      }
    </div>
    )}
export default PieChartComponent
