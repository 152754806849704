import React from 'react'
import {useState, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import CartTableElement from './CartTableElement';
import CartListElement from "./CartListElement";


function CartList (props){
  const { 
    fontColor,
    formType,
    displaySpecialInstructions,
    currencySymbol,
    formClosed,
    cartProducts,
    products,
    itemsInCart,
    checkoutItemsLimit,
    maxCredits,
    totalCost,

    changeSpecialInstructions,
    removeProductFromCart,
    updateQuantity,
    openErrorModal,
  } = props;


  return (
    <>
      {
        cartProducts.sort((a, b) => a.insertedOrder - b.insertedOrder).map((product, index) => !product.deleted && !product.canceled_at && <>
          <CartListElement
            key={index}
            cartProduct={product}
            fontColor={fontColor}
            formType={formType}
            displaySpecialInstructions={displaySpecialInstructions}
            currencySymbol={currencySymbol}
            formClosed={formClosed}
            products={products}
            checkoutItemsLimit={checkoutItemsLimit}
            itemsInCart={itemsInCart}
            totalCost={totalCost}
            maxCredits={maxCredits}

            changeSpecialInstructions={changeSpecialInstructions}
            removeProductFromCart={removeProductFromCart}
            updateQuantity={updateQuantity}
            openErrorModal={openErrorModal}
          />

          { index !== cartProducts.length - 1 && <hr style={{height: '2px', margin: '0 20px', backgroundColor: '#D9D9D9'}}/>}
        </>)
      }
      { itemsInCart == 0 &&
        <div className='cart-row-section' style={{padding: '16px 20px'}}>
          <i className='fa fa-shopping-cart me-2'/>
          <span>Cart is empty</span>
        </div>
      }
    </>
    
  )
}


export default CartList