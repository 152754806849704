import { connect } from 'react-redux'
import {
	changeCartView,
  goToCartPopover,
  closeCartPopover,
  removeProductFromCart,
} from "../actions";
import Header from "../components/Header";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  formTitle: state.general_settings.title,
  formDisplayBanner: state.general_settings.display_banner,
  currencySymbol: state.general_settings.currency_symbol,
  formBanner: state.general_settings.banner,
  formType: state.general_settings.form_type,

  isCartView: state.form_state.isCartView,
  newItemAddedToCart: state.form_state.newItemAddedToCart,
  preview: state.form_state.preview,

  itemsInCart: state.cart.itemsInCart,
  cartProducts: state.cart.products,
  totalCost: state.cart.totalCost.toFixed(2),

  cartCurrentCredit: Math.max(0, state.general_settings.total_credits - state.cart.totalCost).toFixed(2),
})

const mapDispatchToProps = (dispatch) => ({
  changeCartView: (data) => dispatch(changeCartView(data)),
  goToCartPopover: () => dispatch(goToCartPopover()),
  closeCartPopover: () => dispatch(closeCartPopover()),
  removeProductFromCart: (product) => dispatch(removeProductFromCart(product)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
