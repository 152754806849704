import React from 'react'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import MergePageContainer from './MergePageContainer'

const MasterRosterSpreadsheetUpdateRoot = ({data}) => {

  const state = {
    merge_page: {
      customer_id: data.customer_id,
      spreadsheetfile_id: data.spreadsheetfile_id,
      roster_options: data.roster_options,
      spreadsheet_options: data.spreadsheet_options,
      token: data.token,
      roster_duplicates_count: null, 
      delete_m_count: null,
      update_m_count: null,
      spreadsheet_duplicates_count: null,
      new_m_count: null,
      update_m_count: null,
      all_spreadsheet_matched_rows: [],
      total_members: 0,
      total_rows: 0,
      total_deleted_members: 0
    }

  }
  
  const store = configureStore(rootReducer, state)
  return (
    <GlobalProvider store={ store }>
      <MergePageContainer/>
    </GlobalProvider>
  )
}


export default MasterRosterSpreadsheetUpdateRoot
