import { connect } from 'react-redux'
import ListTabs from '../presentational/list_tabs'
import { setActiveTab, editTab, updateTab, removeTab } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs.sort( (a, b) => a.index <= b.index ? -1 : 1 ),
  activeTab: state.filters.active_tab,
  editMode: state.filters.editMode,
  general_settings: state.general_settings,
  backgroundColor: state.general_settings.background_color,
  preview: state.filters.preview
})

const mapDispatchToProps = dispatch => ({
  setActiveTab: id => dispatch(setActiveTab(id)),
  editTab: (id) => dispatch(editTab(id)),
  updateTab: (id, title, width) => dispatch(updateTab(id, title, width)),
  removeTab: id => dispatch(removeTab(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListTabs)
