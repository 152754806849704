import { connect } from 'react-redux'
import RequestRepairForm from '../presentational/request_repair_form'
import {requestRepair, displayMode, DISPLAY_MODES } from '../../actions'


const mapStateToProps = state => ({
  member_id: state.filters.member_id,
  customer_id: state.filters.customer_id,
  currency_symbol: state.filters.currency_symbol,
  display_grip_retail_price: state.filters.display_grip_retail_price,
  repairTypes: state.filters.repairTypes,
  grips: state.filters.grips,
  column: state.columns.filter((filter) => {
    return filter.id == state.filters.active_column})[0],
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  date_format: state.general_settings.date_format
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  
  requestRepair: (data) => dispatch(requestRepair(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestRepairForm)
