import React from 'react';
import { Spinner} from 'react-bootstrap';
import TableComponent from '../shared/TableComponent';

function OnOffSite(props) {
  return (
      <div className='col p-20 add-margin-all-10 mb-0 text-center home_class'>
        <h4 className='text-start header'>{props.title}</h4>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <TableComponent data={props.data} componentType = {props.componentKey} moduleKey="club_repair"/>
        }
      </div>
    )}
export default OnOffSite
