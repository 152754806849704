import { connect } from 'react-redux'
import { closeOptionalFieldsModal, updateStockOrdersListViewOptionalFields} from '../actions'
import ListViewOptionalFieldsModal from '../components/ListViewOptionalFieldsModal'
                          
const mapStateToProps = (state) => ({
  modal_list_view_optional_fields: state.stock_order.modal_list_view_optional_fields,
  fields: state.stock_order.fields,
  in_fields: state.stock_order.in_fields,
  mandatory_fields: state.stock_order.mandatory_fields
})

const mapDispatchToProps = (dispatch) => ({
  closeOptionalFieldsModal: () => dispatch(closeOptionalFieldsModal()),
  updateStockOrdersListViewOptionalFields: data => dispatch(updateStockOrdersListViewOptionalFields(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListViewOptionalFieldsModal)
