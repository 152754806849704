import { connect } from 'react-redux'
import ProductsOnlineOrder from '../components/ProductsOnlineOrder';
import {
	addProductToCart,
  openErrorModal,
} from "../actions";


const mapStateToProps = (state) => ({
  formType: state.general_settings.form_type,
  fontColor: state.general_settings.font_color,
  formTitle: state.general_settings.title,
  formDescription: state.general_settings.description,
  maxCredits: state.general_settings.total_credits,
  checkoutItemsLimit: state.general_settings.checkout_items_limit,

  isCartView: state.form_state.isCartView,

  totalCost: state.cart.totalCost.toFixed(2),
  itemsInCart: state.cart.itemsInCart,

  genericForm: state.customer.generic_form,

  products: Object.values(state.products),
})

const mapDispatchToProps = (dispatch) => ({
  addProductToCart: (product, configuration, quantity) => dispatch(addProductToCart(product, configuration, quantity)),
  openErrorModal: (errorHTML) => dispatch(openErrorModal(errorHTML)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsOnlineOrder)
