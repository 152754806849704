import { connect } from 'react-redux'
import BlockForm from '../presentational/block_form'
import { createBlock, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs,
  activeTab: state.filters.active_tab,
  schemaColor: state.general_settings.color_schema,
})

const mapDispatchToProps = dispatch => ({
  createBlock: (tabId, title, layout) => {
    dispatch(createBlock(tabId, title, layout))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BlockForm)
