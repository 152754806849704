import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import DisplayColumns from '../containers/display_columns'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class Block extends Component {
  constructor(props) {
    super(props)

    this.onRemoveBlock = this.onRemoveBlock.bind(this)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  onRemoveBlock() {
    const result = window.confirm(window.I18n.t('multi_widgets.block.confirm_delete')) // eslint-disable-line no-alert
    if (result) {
      this.props.onRemove()
    }
  }

  render() {
    return (
      <Col xs={12} className="block-container">
        { (this.props.editMode || this.props.title) &&
          <Row top="xs" className="block-menubar themed-box mx-0" style={{backgroundColor: this.props.schemaColor}}>
            <Col xs={9} md={10} className="title" style={{color: getComplementaryColor((this.props.schemaColor))}} dangerouslySetInnerHTML={{__html: this.props.title}}>
              {/*{ `${this.props.title || '\u00A0'} ` }*/}
            </Col>
            {this.props.editMode && <Col xs={3} md={2} className="right-container">
              <span onClick={this.props.onEdit} className="wrapper" data-toggle="tooltip" data-placement="left"
                    data-original-title={window.I18n.t('multi_widgets.block.edit')}>
                <i className="fa fa-pencil fa-fw" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
              </span>
              <span onClick={() => this.onRemoveBlock()} className="wrapper right" data-toggle="tooltip"
                    data-placement="left" data-original-title={window.I18n.t('multi_widgets.block.remove')}>
                <i className="fa fa-trash" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
              </span>
            </Col>}
          </Row>
        }
        
        <Col className="block-content px-3">
          <DisplayColumns blockID={this.props.id} LAYOUT={this.props.layout}/>
        </Col>
      </Col>
    )
  }
}

Block.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  id: PropTypes.string,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  layout: PropTypes.string,
}

export default Block
