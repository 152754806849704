import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import SpecialOrders from '../containers/SpecialOrders'
import DemoClubs from '../containers/DemoClubs'
import ClubRepairs from '../containers/ClubRepairs'
import MemberProfile from '../containers/MemberProfile'
import CollagePage from "../containers/CollagePage";
import HtmlTile from './html_tile';
import BodyContainer from '../../../profile_setup/containers/BodyContainer'

class ColumnContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      HEIGHT: null,
      startY: null,
      memberInfo: this.getEnhancedProfileInfo()[1]
    };
    this.finishedLoading = this.finishedLoading.bind(this);
    this.resizeCol = this.resizeCol.bind(this);
    this.startPosition = this.startPosition.bind(this);

    
    this.handleMousemove = this.handleMousemove.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousemove", (e) => this.handleMousemove(e));
    document.addEventListener("mouseup", () => {
      this.setState({ startY: null });
      this.props.setIframePointerOptions("auto");
    });
  }

  conponentWillUnmount() {
    document.removeEventListener("mousemove", true);
    document.removeEventListener("mouseup", true);
  }

  handleMousemove(e) {
    if (this.state.startY !== null) {
      this.resizeCol(e);
    }
  }

  finishedLoading() {
    setTimeout(() => {
      this.setState({
        loaded: true,
      });

      this.props.setIframeFormState();
    }, 1000);
  }

  startPosition(_val) {
    this.setState({
      startY: _val,
    });
  }

  resizeCol(e) {
    const newHeight = this.state.HEIGHT + (e.clientY - this.state.startY);
    this.props.resizeColumn(newHeight > 262 ? newHeight : 262);
  }

  getEnhancedProfileInfo() {
    const memberInfo = {}
    const memberMetadata = {}

    Object.keys(this.props.member_profile_values).map((key) => {
      if (isNaN(+key)){
        memberInfo[key] = this.props.member_profile_values[key]
      }
      else{
        memberMetadata[key] = this.props.member_profile_values[key]
      }
    })

    return [memberMetadata, memberInfo]
  }

  render() {
    return (
      <Row className="column-content p-2" style={{ height: `${this.props.HEIGHT}px`}}>        
        { this.props.URL == null ? 
            (this.props.tile_type == "special_orders" ?
            <SpecialOrders maxWidthChildren={this.props.maxWidthChildren}/>
            : this.props.tile_type == "demo_clubs" ?
            <DemoClubs options={this.props.options} colId={this.props.colId} maxWidthChildren={this.props.maxWidthChildren}/>
            : this.props.tile_type == "club_repairs" ?
            <ClubRepairs options={this.props.options} colId={this.props.colId} maxWidthChildren={this.props.maxWidthChildren}/> 
            : this.props.tile_type == "collage" ?
            <CollagePage colId ={this.props.colId} blockId={this.props.blockId} options= {this.props.options} displayActions={false}/>
            :this.props.tile_type == "member_profile" ?
            <MemberProfile options={this.props.options} colId={this.props.colId}/>
            :this.props.tile_type == "html" ?
            <HtmlTile html_code={this.props.options.html_code} colId={this.props.colId}/>
            :this.props.tile_type == "enhanced_member_profile" ?
            <BodyContainer all_tabs={this.props.profile_setup.all_tabs} view_mode={"member_preview"} current_tab={this.props.profile_setup.current_tab} modal_manage_tabs_open={false} modal_add_tab_open={false} member_metadata_values={this.getEnhancedProfileInfo()[0]} originalMemberInfo={this.getEnhancedProfileInfo()[1]} colId={this.props.colId} member_view={this.props.member_view} centralPage={true}
            />
            :
            ""
          )
        : (
          ""
        )}
        { !this.state.loaded && this.props.URL &&
          <div className="custom_high_z_index" style={{ width: 100, height: 100, marginLeft: '47%', marginTop: 100 }}>
            <div className="spinner-border" role="status" style={{ color: this.props.schemaColor || "#FFFFFF" }}></div>
          </div>
        }
        {this.props.URL !== null && (
          <iframe
            width="100%"
            height="100%"
            src={this.props.URL}
            frameBorder="0"
            onLoad={() => this.finishedLoading()}
            style={{
              pointerEvents: this.props.IframePointerOption,
              visibility: this.state.loaded ? "visible" : "hidden",
            }}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        { this.props.editMode && (
          <div
            className="resize-handle"
            onMouseDown={(e) => {
              this.startPosition(e.clientY);
              this.setState({ HEIGHT: this.props.HEIGHT });
              this.props.setIframePointerOptions("none");
            }}
            onMouseUp={() => {
              this.startPosition(null);
              this.setState({ HEIGHT: null });
            }}
          >
            <div className="hotcorner-left"></div>
            <div className="hotcorner-right"></div>
          </div>
        )}
      </Row>
    );
  }
}

ColumnContent.propTypes = {
  URL: PropTypes.string,
  HEIGHT: PropTypes.number,
  IframePointerOption: PropTypes.string,
  setIframePointerOptions: PropTypes.func,
  setIframeFormState: PropTypes.func,
  onEdit: PropTypes.func,
  resizeColumn: PropTypes.func,
};

export default ColumnContent;
