import { ActionTypes } from '../actions'

const {
  SUBMIT,
  EDIT_SUBMITTED_ORDER,
  CLOSE_ERROR_MODAL,
  OPEN_ERROR_MODAL,
  CHANGE_CART_VIEW,
  SHOULD_DISPLAY_NEW_ITEM_POPOVER,
  REMOVE_PRODUCT_FROM_CART,
  CHANGE_DROP_SHIP_ERROR,
} = ActionTypes

const initialState = {
}
  
const form_state = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CART_VIEW:
      return {
        ...state,
        isCartView: action.data.value,
        newItemAddedToCart: false,
      }
    case SUBMIT:
      return {
        ...state,
        orderSubmitted: true,
        editMode: false,
        newItemAddedToCart: false
      }
    case REMOVE_PRODUCT_FROM_CART:
      return {
        ...state,
        newItemAddedToCart: false
      }
    case EDIT_SUBMITTED_ORDER:
      return {
        ...state,
        editMode: true,
        isCartView: false,
        showErrorModal: false,
        errorHTML: '',
        newItemAddedToCart: false
      }
    case CLOSE_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: false,
        errorHTML: '',
        newItemAddedToCart: false,
      }
    case SHOULD_DISPLAY_NEW_ITEM_POPOVER:
      return {
        ...state,
        newItemAddedToCart: action.data.value,
      }
    case OPEN_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: true,
        errorHTML: action.data.errorHTML,
        newItemAddedToCart: false,
      }
    case CHANGE_DROP_SHIP_ERROR:
      return {
        ...state,
        dropShipError: action.data.value,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default form_state