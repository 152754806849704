
import React from 'react';
import _ from 'lodash';
import CategoryItems from './CategoryItems';
import {useState} from 'react';

function CategoryDropdown(props) {
  
  const {category_id, dropdown_identifier, disable_dropdown, categories, is_matching_page, is_create_category, is_admin_side, setMatchCategory, createNewCategory, setSelectedCategory} = props

  const initialState = {
    search_value: "",
    selected_category: props.selected_category || {
      id: null,
      name:  is_create_category ? "Top Level Category" : "Select a product category..",
      full_name: null,
      color: "transparent",
      order: 0,
      parent_id:"",
      children: []
    },
    open_dropdown: false,
  }

  const [state, setState] = useState(initialState);

  const handleDropdownCloseOpen = () => {
    setState({...state, open_dropdown: !state.open_dropdown})
  }

  const handleClickCategory = (category) => {
    setState({...state, selected_category: category, open_dropdown: false})
    if(category)
      setState({...state, selected_category: category, open_dropdown: false})
    else
    {
      const category_elements = document.getElementById(`${dropdown_identifier}_category_dropdown`).getElementsByClassName('category_container')
      Array.from(category_elements).forEach((el) => el.classList.remove('active_category'))
      setState({...state, selected_category: {
        id: null,
        name:  "Select a product category..",
        full_name: null,
        color: "transparent",
        order: 0,
        parent_id:"",
        children: []
      }, open_dropdown: false})
    }
    if (is_matching_page)
      if (category && category.id == 'new')
        createNewCategory(dropdown_identifier)
      else
        setMatchCategory(dropdown_identifier, category)
    else
      setSelectedCategory(dropdown_identifier, category)
  }

  const handleClickTopCategory = () => {
    const category_elements = document.getElementById(`${dropdown_identifier}_category_dropdown`).getElementsByClassName('category_container')
    Array.from(category_elements).forEach((el) => el.classList.remove('active_category'))
    document.getElementById(`${dropdown_identifier}_top_category_category_item`).classList.add('active_category')
    setState({...state, selected_category: {
      id: null,
      name:  "Top Level Category",
      full_name: null,
      color: "transparent",
      order: 0,
      parent_id:"",
      children: []
    }, open_dropdown: false})

    setSelectedCategory(dropdown_identifier, null)
  }

  //setup before functions
  let typingTimer;                //timer identifier
  let doneTypingInterval = 500;  //time in ms, 5 second for example

  const handleQuickSeachKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(QuickSeach, doneTypingInterval);
  }

  const handleQuickSeachKeyDown = () => {
    clearTimeout(typingTimer);
  }

  const showHiddenCategory = (category_id) => {
    const category_element = document.getElementById(`${dropdown_identifier}_${category_id}_category_item`)
    category_element.classList.remove('d-none')
    const parent_id = category_element.getAttribute('data-parent-id')
    if (parent_id != undefined && parent_id != null && parent_id != '')
      showHiddenCategory(parent_id)
    return
  }

  const QuickSeach = () => {
    const dropdown = document.getElementById(`${dropdown_identifier}_category_dropdown`)
    Array.from(dropdown.getElementsByClassName('category_container')).forEach((el) => el.classList.add('d-none'))

    Array.from(dropdown.getElementsByClassName('searchable_field_for_categoy')).forEach((el) => {
      const current_value = el.firstChild.getAttribute('data-full-name').toLowerCase()
      const words = state.search_value.split(' ')
      const is_a_match = words.every((word) => current_value.includes(word))

      if (is_a_match)
        showHiddenCategory(el.firstChild.getAttribute('data-id'))
      
    })
  }
  const handleChangeQuickSearchValue = (event) => {
    setState({...state, search_value: event.target.value});
  }

  return (
    <div className={`col-12 border bg-white position-relative category_dropdown_react ${disable_dropdown == true ? 'disabled' : ''}`} id={`${dropdown_identifier}_category_dropdown`}>
      <div className='text-start font-weight-light font_size_14 w-100 px-2 py-1' style={{height: "32px", cursor: "pointer", verticalAlign: "middle"}} onClick={handleDropdownCloseOpen}>
        <span className='text-truncate'>
          {state.selected_category.full_name || state.selected_category.name}
        </span>
        <span className="float-end py-1 fa fa-caret-down"/>
      </div>

      <div className={`text-start border-top position-absolute w-100 backgroundColorGemsWhite ${state.open_dropdown ? '' : 'd-none'}`} style={{top: "34px", zIndex: "1000"}}>
        <div className='dropdown-item m-0 px-1 py-1 '>
          <input
            className="width_100  form-control form-control-sm"
            placeholder="Search..."
            autoComplete="off"
            id={`${dropdown_identifier}_search_field`}
            value={state.search_value || ""}
            onChange={handleChangeQuickSearchValue}
            onKeyUp={handleQuickSeachKeyUp} 
            onKeyDown={handleQuickSeachKeyDown} 
          />
        </div>

        {is_matching_page && <ul className='m-0 p-0' style={{listStyle: "none", maxHeight: "250px", overflowX: "hidden", overflowY: "auto"}}>
          <li>
            <strong className="" style={{cursor: "default", display: "block", padding: "6px"}} onClick={null}>Do not match</strong>
            <ul className='m-0 p-0' style={{listStyle: "none"}}>
              <li style={{padding: "6px"}} className="ps-3 first_level font_size_first_category" onClick={(e) => handleClickCategory(null)}>
                No matching category
              </li>
            </ul>
          </li>

          { !is_admin_side && <li>
            <strong className="" style={{cursor: "default", display: "block", padding: "6px"}} onClick={null}>New Category</strong>
            <ul className='m-0 p-0' style={{listStyle: "none"}}>
              <li style={{padding: "6px"}} className="ps-3 first_level font_size_first_category" onClick={(e) => handleClickCategory({id: 'new'})}>
                Create Category
              </li>
            </ul>
          </li>}

          <li>
            <strong className="" style={{cursor: "default", display: "block", padding: "6px"}} onClick={null}>Match to Category</strong>
            <ul className='m-0 p-0' style={{listStyle: "none"}}>
              
              {categories.map((category) => (
                <CategoryItems
                  category = {category}
                  depth='0'
                  first=''
                  last=''
                  handleClickCategory={handleClickCategory}
                  dropdown_identifier={dropdown_identifier}
                  key={`${dropdown_identifier}_${category.id}_category_item`}
                />
                ))
              }
              
            </ul>
          </li>
        </ul>}

        {!is_matching_page && <ul className='m-0 p-0' style={{listStyle: "none", maxHeight: "250px", overflowX: "hidden", overflowY: "auto"}}>
          {is_create_category &&
            <li className={`dropdown-item category_container p-0 mb-2 ${state.selected_category.id ? '' : 'active_category'}`} id={`${dropdown_identifier}_top_category_category_item`}>
              <ul className='m-0 p-0' style={{listStyle: "none"}}>
                <li style={{padding: "6px"}} className="ps-3 first_level font_size_first_category" onClick={(e) => handleClickTopCategory()}>
                  <a className='first_level font_size_first_category add-line-height-30 searchable_field_for_categoy d-flex'>
                    Top Level Category
                  </a>
                </li>
              </ul>
            </li>
          }
          <li>
            <ul className='m-0 p-0' style={{listStyle: "none"}}>
              
              {categories.map((category) => (
                <CategoryItems
                  category = {category}
                  depth='0'
                  first=''
                  last=''
                  handleClickCategory={handleClickCategory}
                  dropdown_identifier={dropdown_identifier}
                  key={`${dropdown_identifier}_${category.id}_category_item`}
                />
                ))
              }
            </ul>
          </li>
        </ul>}
      </div>
    </div>
    
)
} 
export default CategoryDropdown