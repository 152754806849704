import React from 'react';
import DatePicker from "react-datepicker";
import { Spinner} from 'react-bootstrap';

function Summary(props) {
  const css_class = (name) => {
    if(name == "Total Ship Cost" || name == "Total Commission")
      return "row m-0 p-18 mb-1 text-center body_red_component no_border"
    else
      if(name == "Total Profit Margin" || name == "Total Repair Price")
       return "row m-0 p-18 mb-1 text-center body_green_component no_border"
      else
       return "row m-0 p-18 mb-1 text-center body_blue_component"
  } 
  const css_color_class = (name) => {
    if(name == "Total Ship Cost")
      return "row m-0 mb-1 text-center dark_red"
    else
      if(name == "Total Profit Margin")
       return "row m-0 mb-1 text-center dark_green"
      else
       return "row m-0 mb-1 text-center dark_blue"
  } 
  return (
    <div className='row mt-3'>
      <b className='main_header'>SUMMARY</b>
      <div className='summary_component col'>
        <div className='row'>
          {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          props.data.map((sm) => (
            <div className='col p-12 m-0 text-center'>
              <div className={css_class(sm["name"])}>
                <h3 className='mt-2 font_size_20px'><b>{sm["value"]}</b></h3>
                <h5 className='font_size_20px'>{sm["name"]}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )}
export default Summary