import { connect } from 'react-redux'
import { cloneTemplate, deleteTemplate, openCustomTemplateModal } from '../actions'
import CustomTemplatesSection from '../components/CustomTemplatesSection'

const mapStateToProps = (state) => ({
  custom_text_message_templates: state.custom_templates.custom_text_message_templates,
  default_text_message_templates: state.custom_templates.default_text_message_templates,
  quick_search_value: state.dashboard.quick_search_value,
  isLoading: state.custom_templates.isLoading,
  customer_id: state.dashboard.customer_id,
})

const mapDispatchToProps = (dispatch) => ({
  cloneTemplate: (id, type) => dispatch(cloneTemplate(id, type)),
  deleteTemplate: data => dispatch(deleteTemplate(data)),
  showTemplate: data => dispatch(openCustomTemplateModal(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomTemplatesSection)