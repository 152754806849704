import React from 'react'
import CartTableContainer from '../containers/CartTableContainer';
import CartListContainer from "../containers/CartListContainer";

function SubmittedOnlineOrder (props){
  const {
    fontColor,
    formLogo,
    formDisplayBanner,
    formBanner,
    orderSubmitted,
    formClosed,
    genericForm,
    cartProducts,

    editOnlineOrder
  } = props;

  const handleEditOnlineOrder = () => {
    editOnlineOrder()
  }

  return (
    <div className={`w-100 background_white d-flex flex-column align-items-center ${formClosed ? 'closed_form' : ''}`} style={{gap: "32px", paddingBottom: "50px"}}>
      { formDisplayBanner != 'remove' && <div className='p-0 banner w-100'>
        <img src={formBanner} alt="banner" className={`${formDisplayBanner == 'existing' ? 'existing_banner_image' : 'customer_banner_image'}`}/>
      </div>}

      <div className='d-flex flex-column align-items-center' style={{padding: "16px", gap: "32px"}}>
        { formClosed ?
          <>
            <div className='closed text-center'>
                <span>
                Online Form is now closed. Ordering new items or modifying your order is no longer possible.
                </span>
            </div>

            { (cartProducts.length > 0) &&
              <div className='d-flex flex-column align-items-center' style={{gap: "32px"}} id='cart-container'>
                <b style={{fontSize: "18px"}} className='mb-2'>Your Order</b>
                {!genericForm && <>
                  <div id='cart-table-container' className='desktop_view'>
                    <div className='table-responsive'>
                      <CartTableContainer/>
                    </div>
                  </div>

                  <div id='cart-list-container' className='mobile_view'>
                    <CartListContainer/>
                  </div>
                </>}
              </div>
            }
          </>
          :
          <>
            {orderSubmitted &&
              <div className='sent text-center'>
                  <span>
                    Thank you for your order. Your order has been submitted.
                  </span>
              </div>
            }
            <div className='text-center bottom_buttons_bar_answer_survey no_padding add_padding_top_30'>
              { genericForm ?
                <button onClick={() => location.reload()} className='gems_custom_button font_size_18 sent_order_button' type='button' style={{backgroundColor: fontColor, color: "white"}}>
                  Submit another order
                </button>
                :
                <button className='btn gems_custom_button font_size_18 sent_order_button' onClick={handleEditOnlineOrder} type='button' style={{backgroundColor: fontColor, color: "white"}}>
                  Edit order
                </button>
              }
            </div>
          </>
        }


      </div>

      { formLogo && <div className='add-margin-bottom-50'>
        <div className='text-center'>
          <img className='logo_shop' src={formLogo}/>
        </div>
      </div>}
    </div>
  )
}


export default SubmittedOnlineOrder