import React, { useState } from "react";
import Select from "react-select";
import TemplateInfoIcon from "./TemplateInfoIcon";

function TemplateTableRow(props) {
	const {
		notification_channel,
		template,
		template_key,
		template_category,
		settings,
	} = props;

	//setup before functions
	let typingTimer; //timer identifier
	let doneTypingInterval = 500; //time in ms, 5 second for example

	const handleChangeEnableValue = (event) => {
		const template_category = event.target.attributes.getNamedItem(
			"data-template-category"
		).value;
		const template_key =
			event.target.attributes.getNamedItem("data-template-key").value;
		const enabled = event.target.checked;

		props.changeTemplateEnableValue({
			template_category: template_category,
			template_key: template_key,
			enabled: enabled,
		});
	};

	const handleChangeDemoDaysValueKeyUp = (event) => {
		clearTimeout(typingTimer);
		const template_category = event.target.attributes.getNamedItem(
			"data-template-category"
		).value;
		const template_key =
			event.target.attributes.getNamedItem("data-template-key").value;
		const days = event.target.value;

		typingTimer = setTimeout(
			() => handleChangeDemoDaysValue(template_category, template_key, days),
			doneTypingInterval
		);
	};

	const handleChangeDemoDaysValueKeyDown = () => {
		clearTimeout(typingTimer);
	};

	const handleChangeDemoDaysValue = (template_category, template_key, days) => {
		props.changeTemplateDemoRemindersDaysValue({
			template_category: template_category,
			template_key: template_key,
			days: days,
		});
	};

	const handleChangeDaysValue = (template_category, template_key, days) => {
		props.changeTemplateAttributeValue({
			template_category: template_category,
			template_key: template_key,
			value: days,
			attribute_type: "days",
		});
	};

	const handleChangeHoursValue = (template_category, template_key, hours) => {
		props.changeTemplateAttributeValue({
			template_category: template_category,
			template_key: template_key,
			value: hours,
			attribute_type: "hours",
		});
	};

	const handleChangeDaysValueKeyUp = (event) => {
		clearTimeout(typingTimer);
		const template_category = event.target.attributes.getNamedItem(
			"data-template-category"
		).value;
		const template_key =
			event.target.attributes.getNamedItem("data-template-key").value;
		const days = event.target.value;

		typingTimer = setTimeout(
			() => handleChangeDaysValue(template_category, template_key, days),
			doneTypingInterval
		);
	};

	const handleChangeHoursValueKeyUp = (event) => {
		clearTimeout(typingTimer);
		const template_category = event.target.attributes.getNamedItem(
			"data-template-category"
		).value;
		const template_key =
			event.target.attributes.getNamedItem("data-template-key").value;
		const hours = event.target.value;

		typingTimer = setTimeout(
			() => handleChangeHoursValue(template_category, template_key, hours),
			doneTypingInterval
		);
	};

	const handleChangeDaysValueKeyDown = () => {
		clearTimeout(typingTimer);
	};

	const getInputDefaultValue = (type) => {
		type = type ? type : "days"
		if (template_key.toString() == "member_club_reminder_after")
			return settings.demo_tracker_reminder_after_days;
		else if (template_key.toString() == "member_club_reminder_before")
			return settings.demo_tracker_reminder_before_days;
		else
			return (
				(((settings.notification_options || {})[template_key.toString()] || {})[
					notification_channel
				] || {})[type] || 0
			);
	};

	const getTriggerDefaultValue = () => {
		return (
			(((settings.notification_options || {})[template_key.toString()] || {})[
				notification_channel
			] || {})["trigger"] || null
		);
	};

	const handleTemplateTriggerSelectChange = (
		event,
		template_key,
		template_category
	) => {
		const trigger = event.value;

		props.changeTemplateAttributeValue({
			template_category: template_category,
			template_key: template_key,
			value: trigger,
			attribute_type: "trigger",
		});

		setState({ ...state, trigger: trigger });
	};

	const initialState = { trigger: getTriggerDefaultValue() };

	const [state, setState] = useState(initialState);

	return (
		<tr>
			<td style={{ width: "600px" }}>
				{template.custom_field && (
					<div className="d-flex align-items-center">
						{template_key.toString().includes("shop_task_assignee_reminder_") && <><input
							type="text"
							data-id={template.id}
							data-template-key={template_key.toString()}
							data-template-category={template_category.toString()}
							defaultValue={getInputDefaultValue()}
							className={`member_club_reminder_before_reminder_days text-center mx-1`}
							onKeyUp={
								handleChangeDaysValueKeyUp
							}
							onKeyDown={
								handleChangeDaysValueKeyDown
							}
						/>
						</>}
						<a href={template.edit_url} data-method="get" className="link">
							{template.name_first}
						</a>
						<input
							type="text"
							data-id={template.id}
							data-template-key={template_key.toString()}
							data-template-category={template_category.toString()}
							defaultValue={template_key.toString().includes("shop_task_assignee_reminder_") ? getInputDefaultValue("hours") : getInputDefaultValue()}
							className={`member_club_reminder_before_reminder_days text-center mx-1`}
							onKeyUp={
								template_key.toString() == "member_club_reminder_after" ||
								template_key.toString() == "member_club_reminder_before"
									? handleChangeDemoDaysValueKeyUp
									:
									template_key.toString().includes("shop_task_assignee_reminder_")
									?
										handleChangeHoursValueKeyUp
									:
										handleChangeDaysValueKeyUp
							}
							onKeyDown={
								template_key.toString() == "member_club_reminder_after" ||
								template_key.toString() == "member_club_reminder_before"
									? handleChangeDemoDaysValueKeyUp
									:
									template_key.toString().includes("shop_task_assignee_reminder_")
										?
										handleChangeHoursValueKeyUp
										:
										handleChangeDaysValueKeyUp
							}
						/>
						<a href={template.edit_url} data-method="get" className="link">
							{template.name_last}
						</a>

						{template.custom_triggers && (
							<div className="ms-2" style={{width: "150px"}}>
								<Select
									id="templateTriggerSelect"
									name="templateTriggerSelect"
									menuPlacement="top"
									data-id={template.id}
									data-template-key={template_key.toString()}
									data-template-category={template_category.toString()}
									value={template.custom_triggers.filter(function (option) {
										return option.value === state.trigger;
									})}
									options={template.custom_triggers}
									onChange={(e) =>
										handleTemplateTriggerSelectChange(
											e,
											template_key.toString(),
											template_category.toString()
										)
									}
								/>
							</div>
						)}
					</div>
				)}

				{!template.custom_field && (
					<a href={template.edit_url} data-method="get" className="link">
						{template.name}
					</a>
				)}
			</td>
			{template_category != "golf_shop_central" && (
				<td className="text-center">
					{[
						"vendor_stock_order",
						"vendor_order",
						"vendor_order_drop_ship",
					].includes(template_key.toString()) && (
						<TemplateInfoIcon
							text={
								"The content of this template will prefill the email body when you chose to email an order to a vendor."
							}
						/>
					)}

					{template_key.toString() == "vendor_cancel_stock_order" && (
						<TemplateInfoIcon
							text={
								"The content of this template will pre fill the email body when you choose to cancel an stock order and send an email to vendor."
							}
						/>
					)}

					{![
						"vendor_stock_order",
						"vendor_cancel_stock_order",
						"vendor_order",
						"vendor_order_drop_ship",
					].includes(template_key.toString()) && (
						<div className="form-check form-switch d-flex justify-content-center m-0 p-0">
							<input
								type="checkbox"
								defaultChecked={template.enabled}
								data-id={template.id}
								data-template-key={template_key.toString()}
								data-template-category={template_category.toString()}
								className="form-check-input font_size_1_25_rem email_notification_toggle"
								onChange={handleChangeEnableValue}
							/>
						</div>
					)}
				</td>
			)}
			<td>{template.recipient}</td>
			<td className="text-center">
				<a href={template.edit_url} data-method="get" className="link">
					Edit
				</a>
			</td>
		</tr>
	);
}

export default TemplateTableRow;
