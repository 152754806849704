import { connect } from 'react-redux'
import UpdateBlockForm from '../presentational/update_block_form'
import { updateBlock, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  title: state.blocks.filter( block => block.id === state.filters.active_block )[0].title,
  id: state.filters.active_block,
  schemaColor: state.general_settings.color_schema,
})

const mapDispatchToProps = dispatch => ({
  onUpdate: (blockID, title) => {
    dispatch(updateBlock(blockID, title))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  onCancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateBlockForm)
