import { connect } from 'react-redux'
import Dashboard from '../components/Dashboard'
import { fetchStockOrdersData } from '../actions'

// const mapStateToProps = (state) => ({
// })

const mapDispatchToProps = (dispatch) => ({
  fetchStockOrdersData: () => dispatch(fetchStockOrdersData())
})

export default connect(
  null,
  mapDispatchToProps
)(Dashboard)
