import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import CommunicationCenterPageContainer from './CommunicationCenterPageContainer'

const CommunicationCenterRoot = ({customer_id, date_format}) => {
  const state = {
    communication_center: {
      customer_id: customer_id,
      date_format
    }
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <CommunicationCenterPageContainer />
    </GlobalProvider>
  )
}

CommunicationCenterRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}

export default CommunicationCenterRoot