import React, { useState, useRef } from 'react';
import Select from 'react-select'
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../stock_orders/components/Modal';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import View from 'react'

function AddTabModal(props) {

  const [title, setTitle] = useState('');
  const [profileCategoryId, setProfileCategoryId] = useState('')

  const handleClose = () => {
    setProfileCategoryId('')
    props.closeAddTabModal()
  }

  const handleSubmit = () => {
    props.saveNewTab(title || "Custom Tab", JSON.stringify(props.all_tabs), profileCategoryId, JSON.stringify(props.hidden_tabs))
    handleClose()
  }

  const getHiddenTabs = () => {
    const tab_options_hash = [{value: '0', label: <div dangerouslySetInnerHTML={{ __html: `<span class="color-grey">No profile category</span>`}}/>}]

    Object.keys(props.hidden_tabs).map(key => {
      tab_options_hash.push({value: key, label: props.hidden_tabs[key]})
    })

    return tab_options_hash
  }

  const handleSelectTabClick = (field) => {
    if (field.value == '0'){
      setTitle('')
      setProfileCategoryId('')
    }
    else{
      setTitle(field.label)
      setProfileCategoryId(field.value)
    }
  }

  return (props.modal_add_tab_open && <div id="addTabModalDiv">
    <Modal isOpen={props.modal_add_tab_open} scrollable={true} className="" dialogClassName="modal-dialog-centered">
      <ModalHeader className="header">
        <h4 className="modal-title form_new_edit m-0">Add Tab</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="row">
            <div className="col-12 inner-content">
              <div class="alert alert-info" role="alert">
                {"The custom fields contained in the specified category will be readily available in this tab if a profile category is chosen. All other custom fields will be listed below them. To create a new profile category go to Club Details -> Profile Categories."}
              </div>
            </div>
            <div className="col-12 inner-content">
              <Form.Group as={Row} className="" controlId="formTabForm">
                <Form.Label column sm="4">
                  <div className="row">
                    <div className="col-12">Associated Profile Category</div>
                  </div>
                </Form.Label>
                <div className="col-sm-8">
                  <Select id="selected_tab_option" options={getHiddenTabs()} onChange={(field) => handleSelectTabClick(field)}/>
                </div>
              </Form.Group>
              <Form.Group as={Row} className="mt-3" controlId="formTabForm">
                <Form.Label column sm="4">
                  <div className="row">
                    <div className="col-12">Title</div>
                  </div>
                </Form.Label>
                <Col sm="8">
                  <input type="text"
                    placeholder=""
                    className="form-control"
                    value={ title }
                    onInput={ e => setTitle(e.target.value) }
                    autoFocus />
                </Col>
              </Form.Group>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button "
          aria-label="Cancel"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="btn gems_custom_button gems_orange_button float-end"
          onClick={handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  </div>);
} export default AddTabModal