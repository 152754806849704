import React from 'react'
import {useState, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import missingImage from '../../../../assets/images/missing_product_image.png'


function CartTableElement (props){
  const { 
    fontColor,
    formType,
    displaySpecialInstructions,
    currencySymbol,
    formClosed,
    cartProduct,
    products,
    checkoutItemsLimit,
    itemsInCart,
    maxCredits,
    totalCost,

    changeSpecialInstructions,
    removeProductFromCart,
    updateQuantity,
  } = props;

  const initialState = {
    quantity: cartProduct.quantity,
  }

  const [state, setState] = useState(initialState)

  useEffect(() => {
    let specialInstructionsInputs = document.getElementsByClassName('special_instructions_input')
    Array.from(specialInstructionsInputs).forEach(element => {
      textAreaAdjust(element)
    });
  }, []);

  const textAreaAdjust = (element) => {
    element.style.height = '1px'
    element.style.height = 14 + element.scrollHeight + 'px'
  }

  const handleChangeSpecialInstructions = (event, cartProduct) => {
    changeSpecialInstructions(cartProduct, event.target.value)
    textAreaAdjust(event.target)
  }

  const handleRemoveProductFromCart = (cartProduct) => {
    removeProductFromCart(cartProduct)
  }

  const handleUpdateQuantity = (cartProduct, old_quantity, quantity) => {
    if (isNaN(Number(quantity))) {
      setState({...state, quantity: quantity})
      return
    }

    if (Number(quantity) === 0) {
      if (confirm("Are you sure you want to remove this product?")) {
        removeProductFromCart(cartProduct)
      }
    } else {
      // validate products limit
      // set maximum limit if overflow
      if (checkoutItemsLimit !== 0 && itemsInCart - old_quantity + quantity > checkoutItemsLimit) {
        props.openErrorModal(`<p>You cannot order more than ${checkoutItemsLimit} items.</p>`)
        // quantity = Math.max(0, checkoutItemsLimit - (itemsInCart - old_quantity))
        updateQuantity(cartProduct, old_quantity, quantity)
      }

      // validate credit limit
      let totalCostWithoutCurrentItem = totalCost - cartProduct.price*old_quantity
      if (['credit', 'points'].includes(formType) && cartProduct.price*quantity > Math.max(0, (maxCredits - totalCostWithoutCurrentItem))) {
        props.openErrorModal(`<p>You do not have enough ${formType === 'credit' ? 'credits' : 'points'} to purchase this item.</p>`)
        // let remainingCredits = maxCredits - totalCostWithoutCurrentItem
        // quantity = parseInt(remainingCredits / cartProduct.price)
      }
      if (products[cartProduct.id].stock && (products[cartProduct.id].stock - products[cartProduct.id].items_ordered_by_others - (products[cartProduct.id].items_ordered_by_customer - old_quantity + quantity) < 0)) {
        props.openErrorModal("<p>Some items that you have ordered have exceeded the current stock and have been highlighted. <br> Please check the updated available quantities.</p>")
      }


      if (formType === 'credit_and_purchase' && cartProduct.price*quantity > Math.max(0, (maxCredits - totalCostWithoutCurrentItem))) {
        props.openErrorModal(`<p>You do not have enough credits to purchase this item. <br> You can optionally apply your remaining credits (if any) to this item and pay the remaining balance.</p>`)
      }

      updateQuantity(cartProduct, old_quantity, quantity)
      setState({...state, quantity: quantity})
    }

  }


  return (
    <tr className={`cart-row border-top ${products[cartProduct.id].stock && (products[cartProduct.id].stock - products[cartProduct.id].items_ordered_by_others - products[cartProduct.id].items_ordered_by_customer < 0) ? 'cart-element-quantity-exceeded' : ''}`}>
      <td className='cart-row-section'>
        <img src={cartProduct.image ? cartProduct.image : missingImage} style = {{height: "100px", width: "100px", objectFit: "contain"}} />
      </td>
      <td className='cart-row-section'>
        <div className='d-flex justify-content-center align-items-start align-self-stretch flex-column' style={{gap: "10px", flex: "1 0 0"}}>
          <div className='bold flex-start' style={{color: `${fontColor}`}}>
            {cartProduct.name}
          </div>
          <div>
            {
              cartProduct.configuration.map((field, index) => <>
                <span className='bold'>{field.name}:</span> {field.value}
                <br/>
              </>)
            }
          </div>
        </div>
        
      </td>
      {
        formType != 'no_cost' && <td  className='cart-row-section bold' style={{color: `${fontColor}`}}>
          { formType == 'points' ?
            `${cartProduct.price.toFixed(2)} Points`
          :
            currencySymbol == '$' ? `${currencySymbol}${cartProduct.price.toFixed(2)}` : `${cartProduct.price.toFixed(2)}${currencySymbol}`
          }
          
        </td>
      }
      <td className='cart-row-section'>
        {
          formClosed ?
            <>{cartProduct.quantity}</>
          :
            <div className='d-flex justify-content-center align-items-center align-self-stretch flex-row' style={{gap: "10px", flex: "1 0 0"}}>
              <i className="fa fa-minus" aria-hidden="true" onClick={() => handleUpdateQuantity(cartProduct, cartProduct.quantity, Math.max(0, cartProduct.quantity-1))}/>
              <input
                type="number"
                className='form-control font_size_14 cart-input-quantity'
                style={{width: "60px"}}
                value={cartProduct.quantity}
                min={1}
                max={products[cartProduct.id].stock ? (products[cartProduct.id].stock - products[cartProduct.id].items_ordered_by_others) : ""}
                onChange={(event) => handleUpdateQuantity(cartProduct, cartProduct.quantity, Number(event.target.value))}
              />
              <i className="fa fa-plus" aria-hidden="true" onClick={() => handleUpdateQuantity(cartProduct, cartProduct.quantity, cartProduct.quantity+1)}/>
            </div>
        }
        
      </td>
      {
        formType != 'no_cost' && <td className='cart-row-section bold' style={{color: `${fontColor}`}}>
          { formType == 'points' ?
            `${(cartProduct.price * cartProduct.quantity).toFixed(2)} Points`
            :
            currencySymbol == '$' ? `${currencySymbol}${(cartProduct.price * cartProduct.quantity).toFixed(2)}` : `${(cartProduct.price * cartProduct.quantity).toFixed(2)}${currencySymbol}`
          }
          
        </td>
      }
      { displaySpecialInstructions && 
          <td className='cart-row-section'>
            { formClosed ? 
                <span>{cartProduct.special_instructions ? cartProduct.special_instructions : "-"}</span>
              :
                <textarea
                  name={`special_instructions_${cartProduct.id}`}
                  id={`special_instructions_${cartProduct.id}`}
                  className="form-control font_size_14 special_instructions_input"
                  autoComplete="off"
                  onKeyUp={(event) => textAreaAdjust(event.target)}
                  style={{height: "38px", resize: "none"}}
                  value={cartProduct.special_instructions}
                  onChange={(event) => handleChangeSpecialInstructions(event, cartProduct)}
                />
            }
          </td>
        }
        <td>
          {
            cartProduct.order_position == "ITEMS TO ORDER" ? <>
              { formClosed ?
                  <i className='icon-truck-1 fs-6 float-end' data-toggle={'tooltip'} placement={'top'} title={'Order placed'}/>
                :
                  <i className='fa p-1 fa-trash fs-6 color_btn_red cursor_pointer  float-end' onClick={() => handleRemoveProductFromCart(cartProduct)}/>
              }
            </> : <>
              { cartProduct.order_position == "SENT TO VENDOR" ? 
                  <i className='icon-truck-1 fs-6  float-end' data-toggle={'tooltip'} placement={'top'} title={'Order in transit'}/>
                : 
                  <i className='icon-truck-1 fs-6  float-end' data-toggle={'tooltip'} placement={'top'} title={'Order delivered'}/>
              }
            </>
          }
        </td>
    </tr>

  )
}


export default CartTableElement