import { showErrorNotification, callAPI } from "shared/helpers";
import React from "react";
import { validateEmail } from "../../shared/helpers";

export const ActionTypes = {
  CHANGE_CART_VIEW: 'CHANGE_CART_VIEW',
  ADD_PRODUCT_TO_CART: 'ADD_PRODUCT_TO_CART',
  REMOVE_PRODUCT_FROM_CART: 'REMOVE_PRODUCT_FROM_CART',
  CHANGE_CUSTOMER_AUTH_TYPE: 'CHANGE_CUSTOMER_AUTH_TYPE',
  LOGIN_MEMBER: 'LOGIN_MEMBER',
  CHANGE_GENERIC_MEMBER: 'CHANGE_GENERIC_MEMBER',
  SUBMIT: 'SUBMIT',
  EDIT_SUBMITTED_ORDER: 'EDIT_SUBMITTED_ORDER',
  CHANGE_SPECIAL_INSTRUCTIONS: 'CHANGE_SPECIAL_INSTRUCTIONS',
  CLOSE_ERROR_MODAL: 'CLOSE_ERROR_MODAL',
  OPEN_ERROR_MODAL: 'OPEN_ERROR_MODAL',
  ADJUST_PRODUCTS_STOCK_ORDER: 'ADJUST_PRODUCTS_STOCK_ORDER',
  SHOULD_DISPLAY_NEW_ITEM_POPOVER: 'SHOULD_DISPLAY_NEW_ITEM_POPOVER',
  UPDATE_QUANTITY: 'UPDATE_QUANTITY',
  SET_CART_AUTH_ERROR: 'SET_CART_AUTH_ERROR',
  CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE: 'CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE',
  CHANGE_SELECTED_DROP_SHIP: 'CHANGE_SELECTED_DROP_SHIP',
  MARK_DROP_SHIP_DELETED: 'MARK_DROP_SHIP_DELETED',
  SET_DROP_SHIP_EDIT_MODE: 'SET_DROP_SHIP_EDIT_MODE',
  CANCEL_DROP_SHIP_EDIT: 'CANCEL_DROP_SHIP_EDIT',
  SAVE_DROP_SHIP_EDIT: 'SAVE_DROP_SHIP_EDIT',
  EDIT_DROP_SHIP_FIELD: 'EDIT_DROP_SHIP_FIELD',
  SWITCH_DROP_SHIP_ENABLED: 'SWITCH_DROP_SHIP_ENABLED',
  SAVE_NEW_DROP_SHIP_ADDRESS: 'SAVE_NEW_DROP_SHIP_ADDRESS',
  CHANGE_DROP_SHIP_ERROR: 'CHANGE_DROP_SHIP_ERROR',
}

export const changeCartView = (value) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CHANGE_CART_VIEW,
		data: { value: value },
	});
};

export const closeCartPopover = () => (dispatch, getState) => {
  dispatch({
		type: ActionTypes.SHOULD_DISPLAY_NEW_ITEM_POPOVER,
		data: { 
      value: false
    },
	});
};

export const goToCartPopover = () => (dispatch, getState) => {
  dispatch({
		type: ActionTypes.SHOULD_DISPLAY_NEW_ITEM_POPOVER,
		data: { 
      value: false
    },
	});

	dispatch({
		type: ActionTypes.CHANGE_CART_VIEW,
		data: { value: true },
	});
};

export const addProductToCart = (product, configuration, quantity) => (dispatch, getState) => {
  const state = getState()
  dispatch({
		type: ActionTypes.SHOULD_DISPLAY_NEW_ITEM_POPOVER,
		data: { 
      value: true
    },
	});
  dispatch({
    type: ActionTypes.ADD_PRODUCT_TO_CART,
    data: {
      product: product,
      configuration: configuration,
      quantity: quantity,
      totalProductQuantityOrdered: state.cart.products.filter((p, index) => p.id == product.id && p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0) + quantity,
    },
  })

  dispatch({
    type: ActionTypes.CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE,
    data: {
      products: state.products,
      cartProducts: state.cart.products
    },
  });

  setTimeout(() => {
    dispatch({
      type: ActionTypes.SHOULD_DISPLAY_NEW_ITEM_POPOVER,
      data: { 
        value: false
      },
    });
  }, "5000");





};

export const removeProductFromCart = (product) => (dispatch, getState) => {
  const state = getState()
	dispatch({
		type: ActionTypes.REMOVE_PRODUCT_FROM_CART,
		data: { 
      product: product,
      totalProductQuantityOrdered: Math.max(0, state.cart.products.filter((p, index) => p.id == product.id && p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0) - product.quantity),
    },
	});

  dispatch({
    type: ActionTypes.CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE,
    data: {
      products: state.products,
      cartProducts: state.cart.products
    },
  });
};

export const updateQuantity = (product, old_quantity, quantity) => (dispatch, getState) => {
  const state = getState()

	dispatch({
		type: ActionTypes.UPDATE_QUANTITY,
		data: { 
      product: product,
      quantity: quantity,
      totalProductQuantityOrdered: state.cart.products.filter((p, index) => p.id == product.id && p.deleted != true && !p.canceled_at).reduce((partialSum, p) => partialSum + (p.quantity || 0), 0) - old_quantity + quantity,
    },
	});

  dispatch({
    type: ActionTypes.CHECK_IS_ENOUGH_STOCK_ORDER_VARIABLE,
    data: {
      products: state.products,
      cartProducts: state.cart.products
    },
  });
};

export const changeCustomerAuthType = (type) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CHANGE_CUSTOMER_AUTH_TYPE,
		data: { 
      type: type,
    },
	});
};

export const loginMember = (fields) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/online_forms/generic_form_authenticate_member'
  const params = {
    customer_id: state.general_settings.customer_id,
    online_form_id: state.general_settings.online_form_id,
    member_credentials: fields,
  }

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.LOGIN_MEMBER,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
};

export const setCartErrorMessage = (error_message) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_CART_AUTH_ERROR,
    data: {
      error_message: error_message
    },
  })
}

export const checkCustomerDetails = (memberAuthFields, guestAuthFields) => (dispatch, getState) => {
  const state = getState()
  if (state.customer.generic_form) {
    if (!state.customer.authentication_customer_type)
      return {
        status: false,
        error: 'Please choose whether you are ordering as a member or as a guest.'
      }

    if (state.customer.authentication_customer_type == 'member') {
      let filled_in = true
      state.customer.authentication_fields.forEach((field, index) => {
        if (!memberAuthFields[field.id])
          filled_in = false
      })

      if (!filled_in)
        return {
          status: false,
          error: 'Please enter required credentials in order to login.'
        }

    }
    if (state.customer.authentication_customer_type == 'guest') {
      var filled_required_credentials = true
      if (!guestAuthFields.first_name || !guestAuthFields.last_name || !guestAuthFields.email)
        filled_required_credentials = false
      
      if (!filled_required_credentials)
        return {
          status: false,
          error: 'Please fill all required fields marked with *.'
        }

      if ( !validateEmail(guestAuthFields.email) )
        return {
          status: false,
          error: 'Please enter a valid email address!'
        }

      return {
        status: true,
        error: ''
      }
      
    }
  } else {
    if (state.customer.customer_details && state.customer.customer_details.id)
      return {
        status: true,
        error: ''
      }
    
    return {
      status: false,
      error: 'Invalid credentials!'
    }
  }

  return {
    status: true,
    error: ''
  }

};



export const isStockEnough = (product) => {
  return (product.stock && (product.stock - product.items_ordered_by_others - product.items_ordered_by_customer < 0))
}

export const checkStockAndSubmitOrder = (guestAuthFields) => (dispatch, getState) => {
	const state = getState()
  let dataURL = '/api/web/online_forms/refresh_products_stock_data'
  let params = {
    customer_id: state.general_settings.customer_id,
    id: state.general_settings.online_form_id,
    member_id: state.customer.generic_form ? null : (state.customer.customer_type == 'member' ? state.customer.customer_details.id : null),
    player_id: state.customer.generic_form ? null : (state.customer.customer_type == 'player' ? state.customer.customer_details.id : null)
  }

  return callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.ADJUST_PRODUCTS_STOCK_ORDER,
        data: response,
      })
    })
    .then(() => {
      let products = Object.values(state.products)
      let stock_enough = true
      products.forEach((product) => {
        if (isStockEnough(product))
          stock_enough = false
      })

      return stock_enough
    }).then((stock_enough) => {
      if ( stock_enough ) {
        dataURL = '/api/web/online_forms/place_online_order'
        params = {
          customer_id: state.general_settings.customer_id,
          online_form_id: state.general_settings.online_form_id,
          cart: JSON.stringify(state.cart),
          customer: JSON.stringify(state.customer),
          guest_auth: JSON.stringify(guestAuthFields),
        }

        callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
          .then(response => {
            if (state.customer.generic_form) {
              dispatch({
                type: ActionTypes.SUBMIT,
                data: {},
              })
            }
            else {
              window.location.reload()
            }

            return true
          })
          .catch(() => {
            showErrorNotification('Error while fetching data!')
            return false
          })
      } else {
        dispatch({
          type: ActionTypes.OPEN_ERROR_MODAL,
          data: {
            errorHTML: "<p>Some items that you have ordered have exceeded the current stock and have been highlighted. <br> Please check the updated available quantities.</p>"
          },
        })
        return false
      }
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
      return false
    })
};

export const changeGenericMember = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CHANGE_GENERIC_MEMBER,
		data: {},
	});
};

export const editOnlineOrder = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.EDIT_SUBMITTED_ORDER,
		data: {},
	});
};

export const changeSpecialInstructions = (product, special_instructions) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CHANGE_SPECIAL_INSTRUCTIONS,
		data: {
      product: product,
      special_instructions: special_instructions,
    },
	});
};

export const closeErrorModal = () => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.CLOSE_ERROR_MODAL,
		data: {},
	});
};

export const openErrorModal = (errorHTML) => (dispatch, getState) => {
	dispatch({
		type: ActionTypes.OPEN_ERROR_MODAL,
		data: {
      errorHTML: errorHTML
    },
	});
};

export const changeSelectedDropShip = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_SELECTED_DROP_SHIP,
    data: {
      drop_ship: drop_ship
    },
  });
};

export const markDropShipAsDeleted = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.MARK_DROP_SHIP_DELETED,
    data: {
      drop_ship: drop_ship
    },
  });
};


export const setDropShipEditMode = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SET_DROP_SHIP_EDIT_MODE,
    data: {
      drop_ship: drop_ship
    },
  });
};

export const cancelDropShipEdit = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CANCEL_DROP_SHIP_EDIT,
    data: {
      drop_ship: drop_ship
    },
  });
};

export const editDropShipField = (drop_ship, field_id, value) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.EDIT_DROP_SHIP_FIELD,
    data: {
      drop_ship: drop_ship,
      field_id: field_id,
      value: value
    },
  });
};

export const saveDropShipEdit = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SAVE_DROP_SHIP_EDIT,
    data: {
      drop_ship: drop_ship
    },
  });
};

export const changeDropShipEnabled = ()=> (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SWITCH_DROP_SHIP_ENABLED,
    data: {},
  });
};

export const saveNewDropShip = (drop_ship) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SAVE_NEW_DROP_SHIP_ADDRESS,
    data: {
      drop_ship: drop_ship
    },
  });
};


export const changeDropShipErrorMessage = (value) => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CHANGE_DROP_SHIP_ERROR,
    data: {
      value: value
    },
  });
};

export const getShippingAddressErrorText = (shipping_fields_to_validate) => {
  let shipping_valid = true
  let error_text = ''
  let invalid_fields = []
  shipping_fields_to_validate.forEach((field, index) => {
    if (_.isEmpty(field.value)) {
      shipping_valid = false
      invalid_fields.push(field.label)
    }
  })
  if (invalid_fields.length > 0) {
    error_text = invalid_fields.filter(text => text).join(', ')
  }

  return error_text
}
