import React from 'react';
import DatePicker from "react-datepicker";
import {ResponsiveContainer, AreaChart, Area, ReferenceLine, Sector , LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import Select from 'react-select';
import { Spinner} from 'react-bootstrap';

function LineTrend(props) {
  return (
      <div className='col p-20 add-margin-all-10 mb-0 text-center home_class col-offset-1 flex-grow-1'>
        <h4 className='text-start header'>{props.title}</h4>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <ResponsiveContainer height={450}>
            <AreaChart data={props.data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="rgb(242, 76, 0)" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="rgb(242, 76, 0)" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <XAxis dataKey="name" tick={{stroke: '#30303f', strokeWidth: 1}} stroke="#30303f" axisLine={false}/>
              <YAxis tick={{stroke: '#30303f', strokeWidth: 1}} stroke="#30303f" axisLine={false}/>
              <CartesianGrid strokeDasharray="1 0 1" vertical={false} stroke="#005279"/>
              <Tooltip />
              <Area type="monotone" dataKey={props.dataKey} stroke="rgb(242, 76, 0)" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        }
      </div>
    )}
export default LineTrend
