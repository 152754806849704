import { ActionTypes } from '../actions'

const {
  FETCH_PROFILE_SETUP_DATA_REQUEST,
  SET_CURRENT_TAB,
  OPEN_MANAGE_TABS_MODAL,
  CLOSE_MANAGE_TABS_MODAL,
  HIDE_FIELD,
  DELETE_METADATUM,
  SHOW_FIELD,
  OPEN_ADD_TAB_MODAL,
  CLOSE_ADD_TAB_MODAL,
  SAVE_NEW_TAB,
  DELETE_TAB,
  START_EDIT_TAB,
  END_EDIT_TAB,
  SAVE_TAB_TITLE,
  OPEN_ADD_BLOCK_MODAL,
  CLOSE_ADD_BLOCK_MODAL,
  SAVE_NEW_BLOCK,
  LOCK_METADATUM,
  UNLOCK_METADATUM,
  ADD_NEW_FIELD,
  DELETE_BLOCK,
  SAVE_CHANGES,
  SWITCH_VIEW,
  CLOSE,
  OPEN_EDIT_BLOCK_TITLE_MODAL,
  CLOSE_EDIT_BLOCK_TITLE_MODAL,
  SAVE_BLOCK_TITLE,
  OPEN_EDIT_VALUES_MODAL,
  CLOSE_EDIT_VALUES_MODAL,
  SAVE_MEMBER_CHANGES,
  SET_FILTERED_ACTIVITY_LOG,
  SET_METADATUM_VALUES,
  UPLOAD_PROFILE_PICTURE,
  CLEAR_PHOTO,
  SWAP_ORDER
} = ActionTypes

const initialState = {
  edit_mode: false,
  current_tab: {},
  blocks: [],
  all_tabs: [],
  modal_manage_tabs_open: false,
  modal_add_tab_open: false,
  edited_block: null,
  edited_tab: null,
  modal_edit_block_title_open: false,
  edited_metadatum: null,
  modal_edit_values_open: false,
  displayed_tabs_ids: [],
  activity_log: [],
  all_metadata: [],
  member_roster_url: ""
}

let allTabs
let allMetadata
let tabIndex
let blockIndex
let newBlocks
let currentTab
let memberProfileCategoryId
let tabTitle
let newTab
let blockMetadata
let blockMetadataIds
let editedBlock
let editedMetadatum
let metadatumIndex

const profile_setup = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_SETUP_DATA_REQUEST:
      return {
        ...state,
        current_tab: action.data.current_tab,
        blocks: action.data.blocks,
        member_roster_url: action.data.member_roster_url
      }
    case SET_CURRENT_TAB:
      allTabs = [...state.all_tabs]
      allTabs.map(tab => {
        if (tab.id == action.data){
          currentTab = tab
        }
      })
      return {
        ...state,
        current_tab: currentTab
      }
    case OPEN_MANAGE_TABS_MODAL:
      return {
        ...state,
        modal_manage_tabs_open: true
      }
    case CLOSE_MANAGE_TABS_MODAL:
      return {
        ...state,
        modal_manage_tabs_open: false
      }
    case OPEN_ADD_TAB_MODAL:
      return {
        ...state,
        modal_add_tab_open: true
      }
    case CLOSE_ADD_TAB_MODAL:
      return {
        ...state,
        modal_add_tab_open: false
      }
    case SAVE_NEW_TAB:
      return {
        ...state,
        all_tabs: action.data.all_tabs,
        hidden_tabs: action.data.hidden_tabs,
        current_tab: action.data.all_tabs[action.data.all_tabs.length - 1]
      }
    case DELETE_TAB:
      allTabs = [...state.all_tabs]

      allTabs.map(tab => {
        if (tab.id == action.data.id){
          if (action.data.is_profile_category){
            memberProfileCategoryId = tab.member_profile_category_id
            tabTitle = tab.title
          }
        }
      })
      allTabs = allTabs.filter((tab) => tab.id !== action.data.id)

      let hiddenTabs
      if (action.data.is_profile_category) {
        hiddenTabs = {...state.hidden_tabs, [memberProfileCategoryId]: tabTitle}
      }
      else {
        hiddenTabs = {...state.hidden_tabs}
      }

      return {
        ...state,
        all_tabs: allTabs,
        hidden_tabs: hiddenTabs,
        current_tab: state.current_tab.id === action.data.id ? allTabs[0] : state.current_tab
      }
    case START_EDIT_TAB:
      return {
        ...state,
        edited_tab: action.data.toString()
      }
    case END_EDIT_TAB:
      return {
        ...state,
        edited_tab: null
      }
    case SAVE_TAB_TITLE:
      allTabs = [...state.all_tabs]
      allTabs.map((tab, index) => {
        if (tab.id == action.data.tab_id){
          tabIndex = index
          newTab = tab
        }
      })
      newTab.title = action.data.title
      allTabs[tabIndex] = newTab

      return {
        ...state,
        all_tabs: allTabs,
        edited_tab: null
      }
    case OPEN_ADD_BLOCK_MODAL:
      return {
        ...state,
        modal_add_block_open: true
      }
    case CLOSE_ADD_BLOCK_MODAL:
      return {
        ...state,
        modal_add_block_open: false
      }
    case SAVE_NEW_BLOCK:
      allTabs = [...state.all_tabs]
      allTabs.map((tab, index) => {
        if (tab.id == action.data.tab_id){
          let newBlockOrder = 0
          tab.blocks.map(block => {
            newBlockOrder = Math.max(newBlockOrder, block.order)
          })
          tabIndex = index
          newBlocks = tab.blocks.concat({id: tab.blocks.length > 0 ? tab.blocks[tab.blocks.length - 1].id + 1 : 1, title: action.data.title, order: newBlockOrder + 1, metadata_ids: {}, metadata: []})
        }
      })
      allTabs[tabIndex].blocks = newBlocks

      return {
        ...state,
        current_tab: allTabs[tabIndex],
        all_tabs: allTabs
      }
    case OPEN_EDIT_BLOCK_TITLE_MODAL:
      editedBlock = null
      allTabs = [...state.all_tabs]
      allTabs.map(tab => {
        if (tab.id == action.data.tab_id){
          tab.blocks.map(block => {
            if (block.id == action.data.block_id){
              editedBlock = block
            }
          })
        }
      })
      return {
        ...state,
        edited_block: editedBlock,
        modal_edit_block_title_open: true
      }
    case CLOSE_EDIT_BLOCK_TITLE_MODAL:
      return {
        ...state,
        modal_edit_block_title_open: false
      }
    case OPEN_EDIT_VALUES_MODAL:
      editedBlock = null
      editedMetadatum = null

      allTabs = [...state.all_tabs]
      allTabs.map(tab => {
        if (tab.id == action.data.tab_id){
          tab.blocks.map(block => {
            if (block.id == action.data.block_id){
              editedBlock = block
            }
          })
        }
      })

      allMetadata = {...state.all_metadata}
      Object.keys(allMetadata).map(key =>
        allMetadata[key].map(metadatum => {
          if (metadatum.metadatum_id == action.data.metadatum_id){
            editedMetadatum = metadatum
          }
        })
      )

      return {
        ...state,
        edited_metadatum: editedMetadatum,
        edited_block: editedBlock,
        modal_edit_values_open: true
      }
    case CLOSE_EDIT_VALUES_MODAL:
      return {
        ...state,
        modal_edit_values_open: false
      }
    case SET_METADATUM_VALUES:
      let metadatumCategoryId = null
      allMetadata = {...state.all_metadata}

      Object.keys(allMetadata).map(memberProfileCategoryId => 
        allMetadata[memberProfileCategoryId].map((metadatum, index) => {
          if (metadatum.metadatum_id == action.data.metadatum_id){
            editedMetadatum = metadatum
            metadatumIndex = index
            metadatumCategoryId = memberProfileCategoryId
          }
        })
      )

      editedMetadatum.possible_values = action.data.possible_values
      editedMetadatum.values_type = action.data.values_type

      allMetadata[metadatumCategoryId][metadatumIndex] = editedMetadatum

      return{
        ...state,
        all_metadata: allMetadata
      }

    case SAVE_BLOCK_TITLE:
      allTabs = [...state.all_tabs]
      allTabs.map((tab, tIndex) => {
        if (tab.id == action.data.tab_id){
          tabIndex = tIndex
          tab.blocks.map((block, bIndex) => {
            if (block.id == action.data.block_id){
              blockIndex = bIndex
              newBlocks = block
            }
          })
        }
      })
      newBlocks.title = action.data.title
      allTabs[tabIndex].blocks[blockIndex] = newBlocks

      return {
        ...state,
        current_tab: allTabs[tabIndex],
        all_tabs: allTabs
      }
    case HIDE_FIELD:
      allTabs = [...state.all_tabs]
      allTabs.map((tab, tIndex) => {
        if (tab.id == action.data.tab_id){
          tabIndex = tIndex
          tab.blocks.map((block, bIndex) =>{
            if (block.id == action.data.block_id){
              blockIndex = bIndex
              newBlocks = block
            }
          })
        }
      })

      delete newBlocks.metadata[action.data.metadatum_id.toString()]
      delete newBlocks.metadata_ids[action.data.metadatum_id.toString()]
      allTabs[tabIndex].blocks[blockIndex] = newBlocks

      return {
        ...state,
        current_tab: allTabs[tabIndex],
        all_tabs: allTabs
      }
    case DELETE_METADATUM:
      return {
        ...state,
        current_tab: action.data.current_tab,
        all_tabs: action.data.all_tabs
      }
    case SHOW_FIELD:
      return {
        ...state,
        current_tab: action.data.current_tab,
        all_tabs: action.data.all_tabs
      }
    case LOCK_METADATUM: 
      return {
        ...state, 
        current_tab: action.data.current_tab,
        all_tabs: action.data.all_tabs
      }
    case UNLOCK_METADATUM:
      return {
        ...state,
        current_tab: action.data.current_tab,
        all_tabs: action.data.all_tabs
      }
    case ADD_NEW_FIELD:
      const dropdownMetadataCopy = [...state.metadata_dropdown_fields]
      const allMetadataCopy = {...state.all_metadata}
      let profileCategoryIndex = 0

      dropdownMetadataCopy.map((mpc, index) => {
        if (mpc.label === action.data.member_profile_category_name){
          profileCategoryIndex = index
        }
      })
      dropdownMetadataCopy[profileCategoryIndex].options.push({label: action.data.metadatum_name, value: action.data.metadatum_id})
      allMetadataCopy[action.data.member_profile_category_id || "other"].push({metadatum_id: action.data.metadatum_id, name: action.data.metadatum_name, possible_values: null, values_type: "free_text"})
      
      return {
        ...state,
        metadata_dropdown_fields: dropdownMetadataCopy,
        all_metadata: allMetadataCopy,
        current_tab: action.data.current_tab,
        all_tabs: action.data.all_tabs
      }
    case DELETE_BLOCK:
      allTabs = [...state.all_tabs]

      allTabs.map((tab, index) => {
        if (tab.id == action.data.tab_id){
          tabIndex = index
          newBlocks = tab.blocks.filter((block) => block.id !== action.data.block_id)
        }
      })
      allTabs[tabIndex].blocks = newBlocks

      return {
        ...state,
        current_tab: allTabs[tabIndex],
        all_tabs: allTabs
      }
    case SAVE_CHANGES:
      return {
        ...state,
        all_tabs: action.data,
        view_mode: "preview"
      }
    case SWITCH_VIEW:
      return {
        ...state,
        view_mode: action.data
      }
    case CLOSE:
      return {
        ...state,
        view_mode: "preview",
        all_tabs: action.data.all_tabs,
        current_tab: action.data.current_tab
      }
    case SAVE_MEMBER_CHANGES:
      if (action.data.central_page){
        return {
          ...state,
          view_mode: "member_preview",
          member_metadata_values: action.data.updated_member_metadata
        }
      }
      else {
        return {
          ...state,
          view_mode: "member_preview",
          member_info: action.data.updated_member_info,
          member_metadata_values: action.data.updated_member_metadata
        }
      }
    case SET_FILTERED_ACTIVITY_LOG:
      return {
        ...state,
        activity_log: action.data
      }
    case UPLOAD_PROFILE_PICTURE:
      if (action.data.valid_extension) {
        return {
          ...state,
          profile_picture_url: action.data.profile_picture
        }
      }
      else {
        window.alert("Selected file format is not allowed!");
        return {
          ...state
        }
      }
    case CLEAR_PHOTO:
      return {
        ...state,
        profile_picture_url: null
      }
    case SWAP_ORDER:
      const draggedId = action.data.draggedId
      const hoveredId = action.data.hoveredId
      const type = action.data.type

      let draggedIndex = null
      let hoveredIndex = null

      let aux = null
      let currentBlockIndex = null

      let swapDirection = 1
      let initialOrder = null
      let finalOrder = null

      if (type === "metadatum"){
        allTabs = [...state.all_tabs]
        allTabs.map((tab, index) => {
          if (tab.id == state.current_tab.id){
            tabIndex = index
            tab.blocks.map((block, index) => {
              if (block.id === action.data.blockId){
                currentBlockIndex = index
                Object.keys(block.metadata_ids).map(key => {
                  if (key === draggedId)
                    initialOrder = block.metadata_ids[key]
                  else if (key === hoveredId)
                    finalOrder = block.metadata_ids[key]
                })
              }
            })
          }
        })

        if (action.data.placement === 'beforebegin' && initialOrder < finalOrder) {
          finalOrder = finalOrder - 1
        }
        else if (action.data.placement === 'afterend' && initialOrder > finalOrder) {
          finalOrder = finalOrder + 1
        }

        if (finalOrder === initialOrder) {
          return {
            ...state,
            current_tab: allTabs[tabIndex],
            all_tabs: allTabs
          }
        }

        if (finalOrder > initialOrder){
          swapDirection = -1
        }

        let currentBlock = allTabs[tabIndex].blocks[currentBlockIndex]

        Object.keys(currentBlock.metadata_ids).map(key => {
          if ((initialOrder < currentBlock.metadata_ids[key] && currentBlock.metadata_ids[key] < finalOrder) || (finalOrder < currentBlock.metadata_ids[key] && currentBlock.metadata_ids[key] < initialOrder) || currentBlock.metadata_ids[key] === finalOrder) {
            currentBlock.metadata_ids[key] = currentBlock.metadata_ids[key] + swapDirection
          }
        })
        currentBlock.metadata_ids[draggedId] = finalOrder

        allTabs[tabIndex].blocks[currentBlockIndex] = currentBlock
      }
      else if (type === "block"){

        allTabs = [...state.all_tabs]
        allTabs.map((tab, index) => {
          if (tab.id == state.current_tab.id){
            tabIndex = index
            tab.blocks.map((block, index) => {
              if (block.id === draggedId){
                draggedIndex = index
                initialOrder = block.order
              }
              if (block.id === hoveredId){
                hoveredIndex = index
                finalOrder = block.order
              }
            })
          }
        })

        if (action.data.placement === 'beforebegin' && initialOrder < finalOrder) {
          finalOrder = finalOrder - 1
        }
        else if (action.data.placement === 'afterend' && initialOrder > finalOrder) {
          finalOrder = finalOrder + 1
        }

        if (finalOrder === initialOrder) {
          return {
            ...state,
            current_tab: allTabs[tabIndex],
            all_tabs: allTabs
          }
        }

        if (finalOrder > initialOrder)
          swapDirection = -1

        allTabs[tabIndex].blocks.map((block, index) => {
          if ((initialOrder < block.order && block.order < finalOrder) || (finalOrder < block.order && block.order < initialOrder) || block.order === finalOrder) {
            allTabs[tabIndex].blocks[index].order = allTabs[tabIndex].blocks[index].order + swapDirection
          }
        })

        aux = allTabs[tabIndex].blocks[draggedIndex].order
        allTabs[tabIndex].blocks[draggedIndex].order = finalOrder
      }
      else if (type === "tab"){
        allTabs = [...state.all_tabs]
        allTabs.map((tab, index) => {
          if (tab.id === draggedId){
            draggedIndex = index
          }
          if (tab.id === hoveredId){
            hoveredIndex = index
          }
          if (tab.id === state.current_tab.id){
            tabIndex = index
          }
        })
        aux = allTabs[draggedIndex].order
        allTabs[draggedIndex].order = allTabs[hoveredIndex].order
        allTabs[hoveredIndex].order = aux
      }
      return {
        ...state,
        current_tab: allTabs[tabIndex],
        all_tabs: allTabs
      }
    default: // need this for default case
      return state 
    }
}

export default profile_setup