import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../../stock_orders/components/Modal';
import EmailTemplateBody from '../../email_center/components/EmailTemplateBody';
import TextMessageTemplateBody from '../../text_message_center/components/TextMessageTemplateBody';

function ShowTemplateModal(props) {

  const {modal_open, template, template_type} = props;

  const handleClose = () => {
    props.closeCustomTemplateModal()
  }

  const get_modal_template_body = (template) => {
    return <div className='template_content'>
      <Col className={`${template_type == 'email' ? 'p-2' : 'col-6 offset-3'}`}>
        {template_type == 'email' && <EmailTemplateBody template={template}/>}
        {template_type == 'text_message' && <TextMessageTemplateBody template={template}/>}
      </Col>
    </div>
  }

  return (
    <div className="modal_show_template_modal">
      <Modal isOpen={modal_open} className='template_show_modal' modalClasses="modal-lg">
        <ModalHeader>
          <h5 className="modal-title no-margin bold">{template.title}</h5>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button" onClick={handleClose}></button>
        </ModalHeader>
        <ModalBody>
          {get_modal_template_body(template)}         
        </ModalBody>
        <ModalFooter>
          <div className='w-100'>
            <button
              className="ms-2 float-start btn gems_custom_button"
              aria-label="Close"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ShowTemplateModal
