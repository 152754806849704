import { connect } from 'react-redux'
import { closeUploadModal, uploadStockOrderFile, retryUploadStockOrder } from '../actions'
import UploadStockOrderModal from '../components/UploadStockOrderModal'
                     
const mapStateToProps = (state) => ({
  modal_upload_open: state.stock_order.modal_upload_open,
  title: state.stock_order.title,
  customer_id: state.stock_order.customer_id,
  upload_finished_with_error: state.stock_order.upload_finished_with_error,
  upload_error_description: state.stock_order.upload_error_description,
  vendors_list: state.stock_order.vendors_list,
  cdn_assets_urls: state.stock_order.cdn_assets_urls,
  upload_stock_order_job_id: state.stock_order.upload_stock_order_job_id
})

const mapDispatchToProps = (dispatch) => ({
  closeUploadModal: () => dispatch(closeUploadModal()),
  uploadStockOrderFile: data => dispatch(uploadStockOrderFile(data)),
  retryUploadStockOrder: (jobId) => dispatch(retryUploadStockOrder(jobId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadStockOrderModal)
