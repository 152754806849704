import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  FETCH_COMMUNICATION_CENTER_DATA_REQUEST: 'FETCH_COMMUNICATION_CENTER_DATA_REQUEST',
  OPEN_COMMUNICATION_TEMPLATE_MODAL: 'OPEN_COMMUNICATION_TEMPLATE_MODAL',
  CLOSE_COMMUNICATION_TEMPLATE_MODAL: 'CLOSE_COMMUNICATION_TEMPLATE_MODAL',
  UNSCHEDULE_COMMUNICATION: 'UNSCHEDULE_COMMUNICATION',
  PAUSE_SCHEDULED_COMMUNICATION: 'PAUSE_SCHEDULED_COMMUNICATION',
  RESUME_SCHEDULED_COMMUNICATION: 'RESUME_SCHEDULED_COMMUNICATION'
}

export const fetchCommunicationCenterData = () => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/communication_center'
  const params = {customer_id: state.communication_center.customer_id}

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_COMMUNICATION_CENTER_DATA_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const openCommunicationTemplateModal = (data) => (dispatch, getState) => {
  const state = getState()
  const selected_template = state.communication_center.communication_history_list.filter((t) => t.id == data)[0]
  const template_type = selected_template.communication_type

  dispatch({
    type: ActionTypes.OPEN_COMMUNICATION_TEMPLATE_MODAL,
    data: {
      template: selected_template,
      template_type: template_type
    },
  })
}

export const closeCommunicationTemplateModal = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CLOSE_COMMUNICATION_TEMPLATE_MODAL
  })
}

export const unscheduleCommunication = (communication_history_id, template_type) => (dispatch, getState) => {
  const state = getState()
  const dataURL = template_type == "email" ? '/api/web/email_center/unschedule_email' : '/api/web/text_message_center/unschedule_text_message'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.UNSCHEDULE_COMMUNICATION,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const pauseScheduledCommunication = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/communication_center/pause_scheduled_communication'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.PAUSE_SCHEDULED_COMMUNICATION,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

export const resumeScheduledCommunication = (communication_history_id) => (dispatch, getState) => {
  const state = getState()
  const dataURL = '/api/web/communication_center/resume_scheduled_communication'
  const params = {
    customer_id: state.dashboard.customer_id,
    communication_history_id: communication_history_id
  }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.RESUME_SCHEDULED_COMMUNICATION,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
  })
}

