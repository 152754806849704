import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col  } from 'react-bootstrap';
import Select from 'react-select';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class InventoryView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      new_quick_search_value: "",
      inventory: this.props.inventory,
      kind_select: "",
    };
  };

  handlechooseNotification = (event) => {
    let club_id = event.target.getAttribute("club_id")
    this.props.chooseNotification(club_id)

  }

  handleReserveDemo = (event) => {
    let club_id = event.target.getAttribute("club_id")
    this.props.setActiveColumn(this.props.colId)
    this.props.getReserveDemo(club_id)
  }

  handleChangeQuickSearchValue = (event) => {
    const inventory_filtered = (!event.target.value) ? 
      this.props.inventory
      :
      this.state.inventory.filter(club => club.searchable_fields.includes(event.target.value.toLowerCase()))
    this.setState({
      new_quick_search_value: event.target.value,
      inventory: inventory_filtered
    });
  }
  handleQuickSeachReset = () => {
    this.setState({
      new_quick_search_value: '',
      inventory: this.props.inventory
    });
  }

  handleKindSelectChange = (option) => {
    let inventory_filtered = this.props.inventory
    if (option.value)
      inventory_filtered = inventory_filtered.filter(club => club.kind == option.label)
    if (this.state.new_quick_search_value)
      inventory_filtered = inventory_filtered.filter(club => club.searchable_fields.includes(this.state.new_quick_search_value))
    this.setState({
      kind_select: option.value,
      inventory: inventory_filtered
    });
  }

  render() {
    return (  
      <div className='column-content px-2 py-1 row'>
      {/* <ModalHeader >
        <h4 className="modal-title form_new_block no-margin">Inventory</h4>
      </ModalHeader>
      <ModalBody> */}
      <div className='col p-0'>
        <a
          className="float-start btn gems_custom_button"
          aria-label="Back"
          onClick={ e => { e.preventDefault(); this.props.cancel() } } >
          Back
        </a>
      </div>
      <div className='col col-md-4 col-lg-4 col-xxl-2 p-0' style={{minWidth: "150px"}}>
        <Form.Group as={Row} className="mb-1" controlId="formInventoryKind">
          <Form.Label column sm="2" className='me-1'>Kind:</Form.Label>
          <Col sm="8">
            <Select id = "kind" 
                name = 'kind'
                options={[
                  { value: '', label: 'All'},
                  { value: 'full club', label: 'Full Club' },
                  { value: 'head', label: 'Head' },
                  { value: 'shaft', label: 'Shaft' }
                ]}
                onChange={(e) => {this.handleKindSelectChange(e)}}/>
          </Col>
        </Form.Group>
      </div>
      <div className='col col-md-4 col-lg-4 col-xxl-2 p-0' style={{minWidth: "150px"}}>
        <div className="input-group input-group-sm float-end">
          <Form.Control type="text" 
            name='quick_search'
            value={this.state.new_quick_search_value || ""}
            onChange={(e) => {this.handleChangeQuickSearchValue(e)}}
            onKeyUp={(e) => {this.handleChangeQuickSearchValue(e)}}
            onKeyDown={(e) => {this.handleChangeQuickSearchValue(e)}}

          />
          <div className="input-group-text clear_quick_search fa fa-eraser" onClick={(e) => {this.handleQuickSeachReset(e)}}>
          </div>
        </div>
      </div>
      <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
        <span>INVENTORY</span>
      </div>
      <div className='row'  style={{height: this.props.height, overflow: 'auto'}}>
      {this.state.inventory.map((club, index) => (
        <div className={`col-12 col-lg-${this.props.maxWidthChildren} m-0 mt-2 mb-2`} key={`${club.id}_${index}`}>
          <div className="m-0 p-0 cursor_pointer row item-box d-flex flex-row cursor_pointer dropdown_page_caret" data-bs-target= {`#club_`+club.id} data-bs-toggle= "collapse" type = "button" id= {`club_`+club.id+'_btn'}>
            <div className={`col-4 white inventory_club_kind ${ club.kind == "Full Club" ? "glued_demo_club" : ( club.kind == "Shaft" ? "shaft_demo_club" : "head_demo_club")}`}>
            {club.kind}
            <br/>
            #{club.tracking_id}
            </div>
            <div className='col-7 link searchable_field'>
              {club.short_description}
            </div>
            <div className='col-1 ps-0'>
              <i className="add-line-height-35 float-end fs-4 fa fa-caret-down icon_dropdown_search"></i>
            </div>
          </div>
          <div className="row item-box p-0 pt-2 m-0 panel custom_panel collapse" id= {`club_`+club.id} >
            {club.details.map((cat, index) => (
              <div className='col-6' key={`${club.id}_${cat[0]}_${index}`}>
                <b>{cat[0]}: </b>
                <span className="searchable_field">
                  {cat[1]}
                </span>
              </div>
            ))}
            {club.status == "club" && this.props.enable_reservation &&
              <a
              className="btn gems_custom_button float-end m-2 col-4"
              style={ { backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor)) }}
              onClick={this.handleReserveDemo} club_id={club.id}>
              Reserve Demo
              </a>}
            {this.props.enable_availability_notification && club.status == "demo" && club.notified_on && 
            <div className='row color_orange p-2'>
            <b>You will be notified when it is available</b></div>}
            {this.props.enable_availability_notification && club.status == "demo" && !club.notified_on &&
              <a
              className="btn gems_custom_button float-end m-2 col-7"
              style={ { backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor)) }}
              onClick={ this.handlechooseNotification } club_id={club.id}>
              Get Notified when available
              </a>}
            {!this.props.enable_availability_notification && club.status == "demo" && 
              <div className='row p-2'>
                <div className='col'>
                  <span className="golf_shop_central_label background_label_not_charged">
                    Item unavailable
                  </span>
                </div>
              </div>}
          </div>
        </div>
      ))}
      </div>
      {/* </ModalBody>
      <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Back"
            onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </button>
        </ModalFooter> */}
      </div>
    )
  }
}

export default InventoryView
