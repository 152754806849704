import React, { Fragment, useState } from 'react';
import { format } from "date-fns";
import { Row, Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import AddBlockModalContainer from '../containers/AddBlockModalContainer';
import EditBlockTitleModalContainer from '../containers/EditBlockTitleModalContainer';
import EditValuesModalContainer from '../containers/EditValuesModalContainer';
import ActivityTabContainer from '../containers/ActivityTabContainer';
import GeneralTabContainer from '../containers/GeneralTabContainer';
import CustomTabContainer from '../containers/CustomTabContainer';
import NotificationsTabContainer from '../containers/NotificationsTabContainer';

function ProfileSetupBlocks(props) {

  const handleAddBlockClick = () => {
    props.openAddBlockModal()
  }

  {/* UNUSED FUNCTIONALITIES - KEEP FOR POTENTIAL FUTURE USE */}
  // const handleCreateFieldClick = (event) => {
  //   const block_id = event.target.getAttribute("block_id")
  //   const tab_id = event.target.getAttribute("tab_id")
  //   props.addNewField(block_id, tab_id, "Mock Field", JSON.stringify(props.all_tabs))
  // }

  // const getDisplayedFields = (metadata) => {
  //   const displayedFields = []

  //   metadata.map(metadatum => {
  //     if (metadatum.hidden == false) {
  //       displayedFields.push(metadatum)
  //     }
  //   })

  //   return displayedFields
  // }

  // const handleDeleteMetadatumClick = (event) =>{
  //   if(window.confirm(`Are you sure you want to delete this field?`)){
  //     props.deleteMetadatum(event.target.getAttribute("metadatum_id"), event.target.getAttribute("block_id"), event.target.getAttribute("tab_id"), JSON.stringify(props.all_tabs))
  //   }
  // }

  // const handleLockMetadatunClick = (event) => {
  //   const metadatum_id = event.target.getAttribute("metadatum_id")
  //   const block_id = event.target.getAttribute("block_id")
  //   const tab_id = event.target.getAttribute("tab_id")
  //   props.lockMetadatum(metadatum_id, block_id, tab_id, JSON.stringify(props.all_tabs))

  // }

  // const handleUnlockMetadatunClick = (event) => {
  //   const metadatum_id = event.target.getAttribute("metadatum_id")
  //   const block_id = event.target.getAttribute("block_id")
  //   const tab_id = event.target.getAttribute("tab_id")
  //   props.unlockMetadatum(metadatum_id, block_id, tab_id, JSON.stringify(props.all_tabs))
  // }

  const [createNewField, setCreateNewField] = useState(false)
  const [newFieldName, setNewFieldName] = useState('')

  const handleCreateNewFieldClick = () => {
    if (!newFieldName || !newFieldName.replace(/\s/g, '').length) {
      alert("Field name cannot be empty!")
    }
    else {
      props.addNewField(newFieldName, props.current_tab.member_profile_category_id)
      setCreateNewField(false)
      setNewFieldName('')
    }
  }

  const handleCancelCreateNewFieldClick = () => {
    setCreateNewField(false)
    setNewFieldName('')
  }

  return (
    <Fragment className={props.current_tab.title === "Activity" ? "activity_tab_fragment" : ""}>
      {/* Default Tabs */}
      { props.current_tab.locked && <div className='block-body general d-flex' key={props.current_tab.title + "_block"}>
        {/* General Tab Content */}
        { props.current_tab.title == "General" && <GeneralTabContainer setMemberInfo={props.setMemberInfo} memberInfo={props.memberInfo || props.original_member_info} /> }

        {/* Activity Tab Content */}
        { props.current_tab.title == "Activity" && <ActivityTabContainer/> }

        {/* Notifications Tab Content */}
        { props.current_tab.title == "Notification Settings" && <NotificationsTabContainer memberNotifications={props.memberNotifications} setMemberNotifications={props.setMemberNotifications}/> }
      </div>}

      {/* Custom tabs */}
      { !props.current_tab.locked && <CustomTabContainer updatedMemberMetadata={props.updatedMemberMetadata} setUpdatedMemberMetadata={props.setUpdatedMemberMetadata} memberMetadata={props.memberMetadata} setMemberMetadata={props.setMemberMetadata} originalMemberMetadata={props.originalMemberMetadata}/> }

      {/* Add Block */}
      <div className="d-flex flex-row" id="create_action_buttons_container">
        { !props.current_tab.locked && props.view_mode == "manager" && !createNewField && <div className="pe-2">
          <button
            id="add_block_button"
            className="me-2 mt-2 btn gems_custom_button d-flex flex-row"
            aria-label="manage_tabs"
            onClick={handleAddBlockClick}
          >
            <div className="fa fa-plus-circle pe-1" style={{paddingTop: "3px", fontSzie: "14px"}}/>
            <div>
              Add Block
            </div>
          </button>
        </div>}
      </div>
      {props.modal_add_block_open && <AddBlockModalContainer/>}
      {props.modal_edit_block_title_open && <EditBlockTitleModalContainer/>}
      {props.modal_edit_values_open && <EditValuesModalContainer/>}
    </Fragment>
  )
}
export default ProfileSetupBlocks
