import React, { Component } from 'react'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class SpecialOrderContent extends Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    const {order} = this.props
    return (  
      <div className='row item-box m-0 mt-1 mb-1'>
        <div className='col p-0'>
          <div className='row m-0'>
            <div className='col p-0'>
              <span className={"searchable_field golf_shop_central_label"} style={{backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor))}}>
                {order.status}
              </span>
            </div>
          </div>
          
          <div className='row m-0'>
            <div className='col p-0'>
              <b>Vendor: </b>
              <span className="searchable_field">
                {order.vendor}
              </span>
              <br/>
              <b>Category: </b>
              <span className="searchable_field">
                {order.category}
              </span>
              <br/>
              {
              <>
              <b>Price: </b>
              <span className="searchable_field">
                {order.price}
              </span>
              <br/>
              </>}
              {
              <>
              <b>Qty: </b>
              <span className="searchable_field">
                {order.quantity}
              </span>
              <br/>
              </>}
              {order.returned_date && <><b>Returned Date: </b>
              <span className="searchable_field">
                {order.returned_date}
              </span>
              <br/></>}
              {order.ordered_date && <><b>Sent to Vendor Date: </b>
              <span className="searchable_field">
                {order.ordered_date}
              </span>
              <br/></>}
              {order.created_at && <><b>Ordered Date: </b>
              <span className="searchable_field">
                {order.created_at}
              </span>
              <br/></>}
              {order.received_date && <><b>Ready to Pick-Up Date: </b>
              <span className="searchable_field">
                {order.received_date}
              </span>
              <br/></>}
              {order.delivered_date && <><b>Pick-Up Date: </b>
              <span className="searchable_field">
                {order.delivered_date}
              </span>
              <br/></>}
              {order.item_sku && <><b>SKU: </b>
              <span className="searchable_field">
                {order.item_sku}
              </span>
              <br/></>}
              {(order.shipping_company && order.shipping_company != "0") && <><b>Shipping Company: </b>
              <span className="searchable_field">
                {order.shipping_company}
              </span>
              <br/></>}
              {order.tracking_number && <><b>Tracking Number: </b>
                {order.tracking_url ? 
                <a href={order.tracking_url} target='_blank' className='link'>
                  {order.tracking_number}
                </a>
                :
                <span className="searchable_field">
                  {order.tracking_number}
                </span>}
              <br/></>}
            </div>
            <div className='col'>
              {order.category_details.map((cat, index) => (
                <div key={`${order.id}_${cat[0]}_${index}`}>
                <b>{cat[0]}: </b>
                <span className="searchable_field">
                  {cat[1]}
                </span>
                <br/>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default SpecialOrderContent