import { connect } from 'react-redux'
import { closeEditBlockTitleModal, saveBlockTitle } from '../actions'
import EditBlockTitleModal from '../components/EditBlockTitleModal'

const mapStateToProps = (state) => ({
  customer_id: state.profile_setup.customer_id,
  current_tab: state.profile_setup.current_tab,
  modal_edit_block_title_open: state.profile_setup.modal_edit_block_title_open,
  edited_block: state.profile_setup.edited_block,
  all_tabs: state.profile_setup.all_tabs
})

const mapDispatchToProps = (dispatch) => ({
  closeEditBlockTitleModal: () => dispatch(closeEditBlockTitleModal()),
  saveBlockTitle: (title, current_tab_id, edited_block_id) => dispatch(saveBlockTitle(title, current_tab_id, edited_block_id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBlockTitleModal)