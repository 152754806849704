import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import DashboardAccordion from "../../shared/components/DashboardAccordion";
import TemplateTable from "../../shared/components/TemplateTable";

function AutomatedTemplatesSection(props) {
	const { automated_text_message_templates, settings } = props;

	const handleResetTemplatesClick = () => {
		if (window.confirm("Are you sure you want to reset templates?"))
			props.resetTemplates();
	};

	return (
		<div className="row section_container">
			<Col xs={12} className="p-0 mb-3 communication_center_header_section">
				AUTOMATED TEXT MESSAGES
			</Col>
			{props.isLoading ? (
				<div className="spinner_class">
					<Spinner
						className="d-block"
						animation="border"
						variant="warning"
						id="loading_spinner"
					/>
				</div>
			) : (
				<Col>
					<Row>
						<Col xs={12} className="color_red p-0">
							<span className="automated_templates_red_label mb-2">
								{automated_text_message_templates.reduce(
									(total, template_category) =>
										total +
										Object.values(template_category.templates).filter(
											(template) => template.enabled
										).length,
									0
								)}{" "}
								automated text messages are currently enabled
							</span>

							<span
								className="gems_orange_button gems_custom_button float-end mb-2 cursor_pointer"
								onClick={handleResetTemplatesClick}
							>
								Reset Templates
							</span>
						</Col>
						{automated_text_message_templates && (
							<Col xs={12} className="p-0">
								<DashboardAccordion
									id="text_message_center_custom_templates"
									items={automated_text_message_templates.map(
										(template_category, index) => {
											return {
												key: index,
												header: {
													classes: "link",
													content: template_category.name,
												},
												body: {
													classes: "p-0",
													content: (
														<TemplateTable
															notification_channel={"sms"}
															template_category={template_category}
															key={`template_table_${template_category.key}`}
															settings={settings}
															changeTemplateEnableValue={
																props.changeTemplateEnableValue
															}
															changeTemplateDemoRemindersDaysValue={
																props.changeTemplateDemoRemindersDaysValue
															}
															changeTemplateAttributeValue={
																props.changeTemplateAttributeValue
															}
														/>
													),
												},
											};
										}
									)}
								/>
							</Col>
						)}
					</Row>
				</Col>
			)}
		</div>
	);
}

export default AutomatedTemplatesSection;
