import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../stock_orders/components/Modal';

function ManageTabsModal(props) {

  const { modal_manage_tabs_open } = props

  const [editedTabTitle, setEditedTabTitle] = useState()

  const handleClose = () => {
    props.closeManageTabsModal()
  }

  const handleStartEditTabClick = (tab_id, tab_title) => {
    props.startEditTab(tab_id)
    setEditedTabTitle(tab_title)
  }

  const handleEndEditTabClick = (tab_id) => {
    props.endEditTab(tab_id)
  }

  const handleSaveTabTitle = (tab_id) => {
    props.saveTabTitle(tab_id, editedTabTitle || "Untitled")
  }

  const handleDeleteTabClick = (tab_id) => {
    if (window.confirm(`Are you sure you want to delete this tab and all its content?`)){
      props.deleteTab(tab_id, false)
    }
  }

  const handleHideTabClick = (tab_id) => {
    if (window.confirm(`Are you sure you want to hide this tab? All its content will be reset.`)){
      props.deleteTab(tab_id, true)
    }
  }

  const [draggedTabId, setDraggedTabId] = useState(null)

  const onDragStart = (e, tabId) => {
    setDraggedTabId(tabId)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  const onDragOver = (tab) => {
    if (draggedTabId === tab.id) {
      return
    }
    props.swapOrder(draggedTabId, tab.id, "tab", null)
    return
  }

  const onDragEnd = () => {
    setDraggedTabId(null)
  }

  return (
    <div className="modal_show_communication_modal" id="manage_tabs_modal">
      <Modal isOpen={modal_manage_tabs_open} modalClasses="modal-lg" dialogClassName="modal-dialog-centered" style={{overlay: { background: 'black' }}}>
        <ModalHeader>
          <h5 className="modal-title no-margin bold">Manage Tabs</h5>
          <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button" onClick={handleClose}></button>
        </ModalHeader>
        <ModalBody style={{overflow: "hidden scroll"}}>
          <div className="col tab-inner-content mt-3 mb-3">
            { props.all_tabs.sort( (a, b) => a.order <= b.order ? -1 : 1 ).map(tab =>
              <div key={tab.id} className="row tab_row" onDragOver={() => onDragOver(tab)}>
                <Col xs={2} md={1}
                    className="movable-icon cursor_pointer manage_tabs_modal_button"
                    draggable
                    onDragStart={(e) => onDragStart(e, tab.id)}
                    onDragEnd={() => onDragEnd()} 
                  >
                  <i className="fa fa-bars"></i>
                </Col>
                <Col xs={6} md={9} className="manage_tabs_modal_tab_title" style={{color: `${tab.locked ? "grey" : "black"}`}}>
                  { props.edited_tab !== tab.id + '' ? 
                  <span>
                    { tab.title }
                  </span>
                  : <input type="text"
                          className='form-control'
                          value={ editedTabTitle }
                          // onChange={ (e) => this.updateTabName(tab.id, e) }
                          // onKeyPress={ (e) => this.hideInput(tab.id, e) }
                          // onBlur={ () => this.editTab(tab.id) }
                          onInput={ e => setEditedTabTitle(e.target.value) }
                          autoFocus />
                  }
                </Col>
                {!tab.locked && props.edited_tab !== tab.id + '' && <Col xs={2} md={1} className="manage_tabs_modal_button" onClick={ () => handleStartEditTabClick(tab.id, tab.title) }>
                    <i className="fs-6 fa fa-pencil fa-fw cursor_pointer"></i>
                </Col>}
                {!tab.locked && props.edited_tab !== tab.id + '' && tab.member_profile_category_id === "" && <Col xs={2} md={1} className="manage_tabs_modal_button" onClick={ () => handleDeleteTabClick(tab.id) }>
                    <i className="fs-6 fa fa-trash cursor_pointer"></i>
                </Col>}
                {!tab.locked && props.edited_tab !== tab.id + '' && tab.member_profile_category_id !== "" && <Col xs={2} md={1} className="manage_tabs_modal_button" onClick={ () => handleHideTabClick(tab.id) }>
                  <i className="fs-6 fa fa-eye-slash cursor_pointer"></i>
                </Col>}
                {!tab.locked && props.edited_tab == tab.id + '' && <Col xs={2} md={1} className="manage_tabs_modal_button" onClick={ () => handleSaveTabTitle(tab.id) }>
                    <i className="fs-6 fa fa-check fa-fw cursor_pointer" style={{color: "green"}}></i>
                </Col>}
                {!tab.locked && props.edited_tab == tab.id + '' && <Col xs={2} md={1} className="manage_tabs_modal_button" onClick={ () => handleEndEditTabClick(tab.id) }>
                    <i className="fs-6 fa fa-remove cursor_pointer" style={{color: "red"}}></i>
                </Col>}
              </div> )}
          </div>
        </ModalBody>
        <ModalFooter>
        <div className='w-100'>
            <span
              className="float-end me-2 btn gems_custom_button gems_orange_button"
              onClick={handleClose}
            >
              Done
            </span>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ManageTabsModal
