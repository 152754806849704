import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

function AlertMessage(props) {
  return (
    <div className='row m-0 p-3' style={{backgroundColor: 'white'}}>
      <div className='col text-center add-line-height-35 colorGemsOrange'>
        <h5 className='m-0'>
          {props.message}
        </h5>
      </div>
    </div>
  )
}

export default AlertMessage
