import React from 'react';
import _ from 'lodash';

function SortingDropdown(main_props) {
  const {props, handleUpdateSorting, type} = main_props
  return (
    <div className="dropdown cursor_pointer dropdown_for_default_sort position-relative ps-2">
      <i className="dropdown-toggle color_white" data-bs-toggle="dropdown" type="button" aria-expanded="true"></i>
      <ul className="dropdown-menu sorting_dropdowns" style={{position: "absolute", inset: "0px auto auto 0px", margin: "0px", transform: "translate(0px, 17px)"}} data-popper-placement="bottom-start">
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "created_at" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "created_at", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Created Date
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "vendor" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "vendor", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Vendor Name
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "days" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "days", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Days in Stage
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "order_no" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "order_no", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Order number
          </a>
        </li>
        {type != "to_place_sorted_by" && <li className="dropdown-item ">
          <a className={props[type].sort_column == "ordered_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "ordered_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Ordered Date
          </a>
        </li>}
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "start_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "start_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Start Date
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "end_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "end_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by End Date
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "early_cancel_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "early_cancel_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Early Cancel Date
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "expected_ship_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "expected_ship_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Expected Ship Date
          </a>
        </li>
        <li className="dropdown-item ">
          <a className={props[type].sort_column == "expected_delivery_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "expected_delivery_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Expected Delivery Date
          </a>
        </li>
        {type != "to_place_sorted_by" && type != "placed_sorted_by" && <li className="dropdown-item ">
          <a className={props[type].sort_column == "delivered_date" ? props[type].sort_direction == "desc" ? "color_orange icon-sort-desc" : "color_orange icon-sort-asc" : "icon-sort-asc"} onClick={() => handleUpdateSorting(type, "delivered_date", props[type].sort_direction == "asc" ? "desc" : "asc")}>
          Sort by Delivered Date
          </a>
        </li>}
      </ul>
    </div>
  )
} export default SortingDropdown