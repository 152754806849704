import React, { Component } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react';
import update from 'immutability-helper';
import GsgButton from '../../shared/components/gsg_button';
import {ReferenceLine, Sector , LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, Legend, PieChart, Pie } from 'recharts';
import TimePeriod from './TimePeriod'
import AccountHome from './home/AccountHome'
import OrdersRepairsHome from './home/OrdersRepairsHome'
import DemoClubsHome from './home/DemoClubsHome'
import OrOnlineSurveyHome from './home/OrOnlineSurveyHome';
function Home(props) {
  
  const initialState = {}
  
  const [state, setState] = useState(initialState);

  const {customer_id, moduleKey, from, to, refresh_date} = props;
  const {} = state;

  useEffect(() => {
    if(props.isLoading)
      props.load_component_data({key: "home", customer_id: customer_id, to: to, from: from})
  });

  return (
    <div className="pe-0 h-100 background_white home_container_class">
      <div className="row home_class_account add-margin-all-10">
        <div className='col-9'>
          <AccountHome data={props.components.account["data"]} isLoading={props.components.account["isLoading"]}/>
        </div>
        <div className='col-3 text-right'>
          <TimePeriod to={to} 
                      from={from} 
                      refresh_date={refresh_date}
                      moduleKey={moduleKey} 
                      load_component_data={props.load_component_data}/>
        </div>
      </div>
      <div className="flex-container overflow-auto justify-content-center">
        <OrdersRepairsHome data={props.components.special_orders} title="SPECIAL ORDERS"/>
        <DemoClubsHome data={props.components.demo_clubs}/>
        <OrdersRepairsHome data={props.components.stock_orders} title="STOCK ORDERS"/>
        <OrdersRepairsHome data={props.components.club_repairs} title="CLUB REPAIRS"/>
        <OrOnlineSurveyHome data={props.components.online_orders["data"]} isLoading={props.components.online_orders["isLoading"]} title="OPEN ONLINE ORDERS"/>
        <OrOnlineSurveyHome data={props.components.surveys["data"]} isLoading={props.components.surveys["isLoading"]} title="OPEN SURVEYS"/>
      </div>
    </div>
  )
}
export default Home