import { connect } from 'react-redux'
import NewPageForm from '../presentational/new_page_form'
import {
  setActiveBlock,
  setActivePosition,
  setIframeFormState,
  displayMode,
  addNewWidget,
  createColumn,
  DISPLAY_MODES,
} from '../../actions'

const mapStateToProps = state => ({
  URL: state.filters.new_page_path,
  iframeFormStatus: state.filters.form_submitted,
  blockID: state.filters.active_block,
  position: state.filters.position,
})

const mapDispatchToProps = dispatch => ({
  close: () => {
    dispatch(setIframeFormState(null))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },

  setIframeFormState: (_state) => dispatch(setIframeFormState(_state)),

  createColumn: (_blockID, _widgetId, _position, _title, _viewMore, _options) => {
    dispatch(createColumn(_blockID, _widgetId, _position, _title, _viewMore, _options))
    dispatch(setIframeFormState(null))
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },

  addNewWidget: (_widget) => dispatch(addNewWidget(_widget)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewPageForm)
