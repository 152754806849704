import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { displayMode, DISPLAY_MODES } from '../../actions'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

const AddBlock = ({ dispatch, schemaColor }) => {
  return (
    <div>
      <button type="button" 
              onClick={(e) => { e.preventDefault(); dispatch(displayMode(DISPLAY_MODES.BLOCK)) } }
              className="add-block-btn btn gems_custom_button" style={ { backgroundColor: schemaColor, color: getComplementaryColor((schemaColor)) }}>
        { window.I18n.t('multi_widgets.add_new_block') }
      </button>
    </div>
  )
}

const mapStateToProps = state => ({
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

AddBlock.propTypes = {
  dispatch: PropTypes.func,
  activeTab: PropTypes.string,
}

export default connect(mapStateToProps)(AddBlock)

