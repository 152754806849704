import React, {useState,useEffect} from 'react';
import {FaCloudUploadAlt} from 'react-icons/fa'
import {Form} from 'react-bootstrap';

function UploadFile(props){
	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

  useEffect(() => {
    if(isSelected)
      handleSubmission()
  });

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    formData.append('id', props.item_id);
    formData.append('customer_id', props.customer_id);

    props.uploadFile(formData)
    setSelectedFile(null)
    setIsSelected(false)
    document.getElementById(props.file_id).value= null;
	};

	return(
    <Form.Group className="mb-3" controlId="formUploadAttch" >
      <input type="file" name={props.file_id} onChange={changeHandler} id={props.file_id}  style={{display: props.display}}/>          
    </Form.Group>
	)
}

export default UploadFile