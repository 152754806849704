import React, {Component, useState} from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import _ from "lodash";
import update from "immutability-helper";
import FroalaEditor from "react-froala-wysiwyg";

function EditTabs(props) {
  
  const initialState = {
    froalaItemIndex: null,
    froalaStagingContent: "",
    draggedItem: null,
    tabs: props.tabs,
  }
  
  const [state, setState] = useState(initialState);

  const removeTab = (id) => {
    const result = window.confirm(window.I18n.t('multi_widgets.tabs.confirm_delete')) // eslint-disable-line no-alert
    if (result){
      const remainingTabs = state.tabs.filter(t => t.id !== id)
      
      setState(update(state, {
        tabs: {$set : remainingTabs},
        }
      ))
      
    }
  }

  const swapTabs = (tab1Id, tab2Id) => {
    let tab1 = _.find(state.tabs, (t) => t.id == tab1Id)
    let tab2 = _.find(state.tabs, (t) => t.id == tab2Id)
    let tab_position1 = _.findIndex(state.tabs, (t) => t.id == tab1Id)
    let tab_position2 = _.findIndex(state.tabs, (t) => t.id == tab2Id)
    let index1 = tab1.index
    let index2 = tab2.index
    
    setState(update(state,{ tabs:
        {[tab_position1]: { index: { $set: index2 }},
          [tab_position2]: { index: { $set: index1 }}
        }}))
    
  }

  const updateTabName = (_tabId, e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    let tab_position = _.findIndex(state.tabs, (t) => t.id == _tabId)
    
    setState(update(state,{ tabs:
        {[tab_position]: { title: { $set: e.target.value }}
        }}))
    return false
  }

  const hideInput = (_tabId, e) => {
    if (e.key === 'Enter'){
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      editTab(_tabId)
      return false
    }
  }

  const editTab = (_tabId) => {
    let tab = _.find(state.tabs, (t) => t.id == _tabId)
    let tab_position = _.findIndex(state.tabs, (t) => t.id == _tabId)
    
    setState(update(state,{ tabs:
        {[tab_position]: { editing: { $set: !tab.editing }}
    }}))
  }

  const onDragStart = (e, tabId) => {
    setState(update(state, {
      draggedItem: {$set : tabId},
      }
    ))
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.width, e.target.parentNode.height)
  }

  const onDragOver = (tabId) => {
    // if the item is dragged over itself, ignore
    if (state.draggedItem === tabId) {
      return
    }
    swapTabs(tabId, state.draggedItem)
    return
  }

  const onDragEnd = () => {
    setState(update(state, {
        draggedItem: {$set : null},
      }
    ))
  }
  
  let config_froala = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    key: 'aLF3c1C9C7A5D3F2H3C-7SLJCKHXOSLMc2YGSGe1ZXHSa1CgC3E3G3B2B7A4B3F4E2B2==',
    attribution: false,
    zIndex: 9990,
    minHeight: 130,
    maxHeight: 1000,
    width: 600,
    toolbarButtons: {
      'moreRich': {},
      'moreMisc': {},
      'moreText': {
        
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        
      },
      
      'moreParagraph': {
        
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        
      },}
  }
  
  const enableFroalaEditor = (_tabId) => {
    let tab = _.find(state.tabs, (t) => t.id == _tabId)
    let tab_position = _.findIndex(state.tabs, (t) => t.id == _tabId)
    
    setState(update(state, {
        tabs: { [tab_position]: {editing: {$set : true }}},
        froalaStagingContent: { $set : tab.title },
        froalaItemIndex: { $set : tab_position },
      }
    ))
    
    // hide modal footer
    document.getElementById("gsc_modal_footer").classList.add("d-none")
  }
  
  const setText = () => {
    setState(update(state, {
        tabs: { [state.froalaItemIndex]: {title: {$set : state.froalaStagingContent }, editing: {$set : false }}},
        froalaStagingContent: { $set : "" },
        froalaItemIndex: { $set : null },
      }
    ))
    // show modal footer
    document.getElementById("gsc_modal_footer").classList.remove("d-none")
  }
  
  const clearFroala = () => {
    setState(update(state, {
        froalaItemIndex: {$set : null},
      }
    ))
    // show modal footer
    document.getElementById("gsc_modal_footer").classList.remove("d-none")
  }
  
  const setFroalaStagingText = (content) => {
    setState(update(state, {
        froalaStagingContent: { $set : content }
      }
    ))
  }
  
  return (
    <div className='manage-tabs col'>
      <ModalHeader >
        <h4 className="modal-title form_new_tab no-margin">{window.I18n.t('multi_widgets.tabs.manage_title')}</h4>
      </ModalHeader>
      <ModalBody>
        {state.froalaItemIndex == null
          ?
          <div className="col inner-content mt-3 mb-3">
            { state.tabs.sort( (a, b) => a.index <= b.index ? -1 : 1 ).map(tab => <>{
              <div key={tab.id} className="row tab" onDragOver={() => onDragOver(tab.id)}>
                <Col xs={2} md={1}
                     className="movable-icon"
                     draggable
                     onDragStart={(e) => onDragStart(e, tab.id)}
                     onDragEnd={onDragEnd}>
                  <i className="fa fa-bars"></i>
                </Col>
                <Col xs={6} md={9}>
                <span
                  dangerouslySetInnerHTML={{__html: tab.title}}>
                </span>
                </Col>
                <Col xs={2} md={1} onClick={() => enableFroalaEditor(tab.id)}>
                  <i className="fs-6 fa fa-pencil fa-fw"></i>
                </Col>
                {
                  state.tabs.length > 1 && <Col xs={2} md={1} onClick={() => removeTab(tab.id)}>
                    <i className="fs-6 fa fa-trash"></i>
                  </Col>
                }
              </div>
              }</>
            )}
          </div>
        :
        <>
          <div className="col-12 p-0 mt-3 mb-3">
            <div className="row m-0">
              <div className="col-12 mb-3" style={{minHeight: 50}}>
                <FroalaEditor tag='textarea'
                                  config={{...config_froala, ...{height: 30}}}
                                  model={state.froalaStagingContent}
                                  onModelChange={setFroalaStagingText}/>
                </div>
              </div>
          </div>
          <div className="row pb-2 me-0">
            <div className="col-12">
              <button className="btn gems_custom_button save pull-right gems_orange_button"
                onClick={setText}>Apply Text Change
            </button>
            <button className="btn gems_custom_button manage_action pull-left mx-2" onClick={clearFroala}>Cancel
              Text Change
            </button>
          </div>
        </div>
      </>}
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={ e => { e.preventDefault(); props.cancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </button>
        <button
          className="btn gems_custom_button float-end"
          style={ { backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor)) }}
          onClick={ e => { e.preventDefault(); props.saveTabs(state.tabs, props.activeTab); props.cancel(); return false } } >
              {window.I18n.t('multi_widgets.save')}
        </button>
      </ModalFooter>
    </div>
  )
}
export default EditTabs
