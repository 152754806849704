import React from 'react'
import { Col, Table, Spinner, Row } from 'react-bootstrap';

function SentTextMessagesSection(props) {

  const {text_messages, quick_search_value, page, total_pages} = props;

  const handlePagination = (event) => {
    props.paginateCommunicationHistory()
  }

  const handleShowBulkTextMessageModalClick = (event) => {
    const template_id = event.target.getAttribute('data-template-id')
    props.showBulkTemplate(template_id)
  }

  return (
      <div className='row section_container mb-4'>
        <Col xs={12} className="mb-3 p-0 communication_center_header_section">
          SENT TEXT MESSAGES
        </Col>
        { props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <Col xs={12} className="p-0">
            <Row>
              <Col xs={12} className="communication_table_container">
                <Table striped className="tableFixHead custom_template_communications">
                  <thead>
                    <tr>
                      <th className='text-center' style={{width: "105px"}}>Date</th>
                      <th className='text-center' style={{width: "105px"}}>Time</th>
                      <th>Type</th>
                      <th>Recipients</th>
                      {/* <th className='text-center' style={{width: "105px"}}>Delivered</th> */}
                      <th className='text-center' style={{width: "75px"}}/>
                    </tr>
                  </thead>

                  <tbody>
                    {text_messages.map((text_message, index) =>
                      (!quick_search_value || (quick_search_value && (text_message.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) && 
                      <tr key={index}>
                        <td className='text-center'> {text_message.sent_date} </td>
                        <td className='text-center'> {text_message.nice_format_time} </td>
                        {text_message.entity_preview_link && <td> {<div dangerouslySetInnerHTML={{ __html: text_message.subject + ` - <a class="link" href="${text_message.entity_preview_link}" data-method="get">${text_message.entity_type == "OnlinePoForm" ? "View Online Form" : "View Survey"}</a>`}}/>} </td>}
                        {!text_message.entity_preview_link && <td> {text_message.subject} </td>}
                        <td> 
                          {text_message.recipients}
                        </td>
                        {/* <td className='text-center'> {text_message.delivered} </td> */}
                        <td className='text-center'> 
                          <span 
                            className='link' 
                            onClick={handleShowBulkTextMessageModalClick} 
                            data-template-id={text_message.id}
                          > 
                            Preview 
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  
                </Table>
              </Col>
              {text_messages.length > 0 && page < total_pages && <Col xs={12}>
                <span 
                  className='link'
                  onClick={handlePagination}
                >
                  View More
                </span>
              </Col>}
              {text_messages.length <= 0 && <Col xs={12}>
                <span>
                  No sent text messages
                </span>
              </Col>}
            </Row>
          </Col>
        }
      </div>
  )
}

export default SentTextMessagesSection
