import React from 'react';
import { Col, Row } from 'react-bootstrap';

function HeaderMatchingCategories(props) {
  return (
    <Row className="background_orange fs-6">
      <Col className="py-2">
        <Row className="info_text">
          <Col xs={1} className="pe-0">
            <b>Note:</b>
          </Col>
          <Col xs={11} className="ps-0">
            Below are your Callaway Connect categories that need to be matched to a category defined in Golf Shop. 
            <br/>
            For each Callaway Connect category, choose to match to an existing category or create a new coresponding category automatically.
            Order category will not be able to be automatically matched by integration with Callaway Connect in Golf Shop if categories are not matched to a Golf Shop Category.
          </Col>
        </Row>
      </Col>
    </Row>
    )}
export default HeaderMatchingCategories

