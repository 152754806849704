import { connect } from 'react-redux'
import { fetchCommunicationCenterData } from '../actions'
import Dashboard from '../components/Dashboard'

const mapDispatchToProps = (dispatch) => ({
  fetchCommunicationCenterData: () => dispatch(fetchCommunicationCenterData())
})

export default connect(
  null,
  mapDispatchToProps
)(Dashboard)
