import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  FETCH_PROFILE_SETUP_DATA_REQUEST: 'FETCH_PROFILE_SETUP_DATA_REQUEST',
  SET_CURRENT_TAB: 'SET_CURRENT_TAB',
  OPEN_MANAGE_TABS_MODAL: 'OPEN_MANAGE_TABS_MODAL',
  CLOSE_MANAGE_TABS_MODAL: 'CLOSE_MANAGE_TABS_MODAL',
  HIDE_FIELD: 'HIDE_FIELD',
  DELETE_METADATUM: 'DELETE_METADATUM',
  SHOW_FIELD: 'SHOW_FIELD',
  OPEN_ADD_TAB_MODAL: 'OPEN_ADD_TAB_MODAL',
  CLOSE_ADD_TAB_MODAL: 'CLOSE_ADD_TB_MODAL',
  SAVE_NEW_TAB: 'SAVE_NEW_TAB',
  DELETE_TAB: 'DELETE_TAB',
  START_EDIT_TAB: 'START_EDIT_TAB',
  END_EDIT_TAB: 'END_EDIT_TAB',
  SAVE_TAB_TITLE: 'SAVE_TAB_TITLE',
  OPEN_ADD_BLOCK_MODAL: 'OPEN_ADD_BLOCK_MODAL',
  CLOSE_ADD_BLOCK_MODAL: 'CLOSE_ADD_BLOCK_MODAL',
  SAVE_NEW_BLOCK: 'SAVE_NEW_BLOCK',
  LOCK_METADATUM: 'LOCK_METADATUM',
  UNLOCK_METADATUM: 'UNLOC_KMETADATUM',
  ADD_NEW_FIELD: 'ADD_NEW_FIELD',
  DELETE_BLOCK: 'DELETE_BLOCK',
  SAVE_CHANGES: 'SAVE_CHANGES',
  SWITCH_VIEW: 'SWITCH_VIEW',
  CLOSE: 'CLOSE',
  OPEN_EDIT_BLOCK_TITLE_MODAL: 'OPEN_EDIT_BLOCK_TITLE_MODAL',
  CLOSE_EDIT_BLOCK_TITLE_MODAL: 'CLOSE_EDIT_BLOCK_TITLE_MODAL',
  SAVE_BLOCK_TITLE: 'SAVE_BLOCK_TITLE',
  OPEN_EDIT_VALUES_MODAL: 'OPEN_EDIT_VALUES_MODAL',
  CLOSE_EDIT_VALUES_MODAL: 'CLOSE_EDIT_VALUES_MODAL',
  SAVE_MEMBER_CHANGES: 'SAVE_MEMBER_CHANGES',
  SET_FILTERED_ACTIVITY_LOG: 'SET_FILTERED_ACTIVITY_LOG',
  SET_METADATUM_VALUES: 'SET_METADATUM_VALUES',
  UPLOAD_PROFILE_PICTURE: 'UPLOAD_PROFILE_PICTURE',
  CLEAR_PHOTO: 'CLEAR_PHOTO',
  SWAP_ORDER: 'SWAP_ORDER'
}

export const fetchProfileSetupData = () => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups'
  const params = { customer_id: state.profile_setup.customer_id }

  callAPI(dataURL, 'GET', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_PROFILE_SETUP_DATA_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const setCurrentTab = (id) => (dispatch) => {

  dispatch({
    type: ActionTypes.SET_CURRENT_TAB,
    data: id
  })
}

export const openManageTabsModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_MANAGE_TABS_MODAL
  })
}

export const closeManageTabsModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_MANAGE_TABS_MODAL
  })
}

export const openAddTabModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_ADD_TAB_MODAL
  })
}

export const closeAddTabModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_ADD_TAB_MODAL
  })
}

export const openEditValuesModal = (tab_id, block_id, metadatum_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_EDIT_VALUES_MODAL,
    data: {
      tab_id: tab_id,
      block_id: block_id,
      metadatum_id: metadatum_id
    }
  })
}

export const closeEditValuesModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_EDIT_VALUES_MODAL
  })
}

export const setMetadatumValues = (metadatum_id, values_type, values) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_METADATUM_VALUES,
    data: {
      metadatum_id: metadatum_id,
      values_type: values_type,
      possible_values: values
    }
  })
}

export const saveNewTab = (title, all_tabs, profile_category_id, hidden_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/save_new_tab'
  const params = { title: title, customer_id: state.profile_setup.customer_id, all_tabs: all_tabs, profile_category_id: profile_category_id, hidden_tabs: hidden_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SAVE_NEW_TAB,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const deleteTab = (tab_id, is_profile_category) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_TAB,
    data: {
      id: tab_id,
      is_profile_category: is_profile_category
    }
  })
}

export const openAddBlockModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_ADD_BLOCK_MODAL
  })
}

export const closeAddBlockModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_ADD_BLOCK_MODAL
  })
}

export const saveNewBlock = (title, tab_id, all_tabs) => (dispatch) => {
  dispatch({
    type: ActionTypes.SAVE_NEW_BLOCK,
    data: {
      tab_id: tab_id,
      title: title
    }
  })
}

export const openEditBlockTitleModal = (block_id, tab_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.OPEN_EDIT_BLOCK_TITLE_MODAL,
    data: {
      tab_id: tab_id,
      block_id: block_id
    }
  })
}

export const closeEditBlockTitleModal = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLOSE_EDIT_BLOCK_TITLE_MODAL
  })
}

export const saveBlockTitle = (title, tab_id, block_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.SAVE_BLOCK_TITLE,
    data: {
      tab_id: tab_id,
      block_id: block_id,
      title: title
    }
  })
}

export const deleteBlock = (block_id, tab_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_BLOCK,
    data: {tab_id: tab_id, block_id: block_id},
  })  
}

export const hideMetadatum = (metadatum_id, block_id, tab_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.HIDE_FIELD,
    data: {
      metadatum_id: metadatum_id,
      block_id: block_id,
      tab_id: tab_id
    }
  })
}

export const deleteMetadatum = (metadatum_id, block_id, tab_id, all_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/delete_metadatum'
  const params = { customer_id: state.profile_setup.customer_id, metadatum_id: metadatum_id, block_id: block_id, tab_id: tab_id, all_tabs: all_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.DELETE_METADATUM,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const showField = (metadatum_id, block_id, tab_id, all_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/show_field'
  const params = { customer_id: state.profile_setup.customer_id, metadatum_id: metadatum_id, block_id: block_id, tab_id: tab_id, all_tabs: all_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SHOW_FIELD,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const lockMetadatum = (metadatum_id, block_id, tab_id, all_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/lock_metadatum'
  const params = { customer_id: state.profile_setup.customer_id, metadatum_id: metadatum_id, block_id: block_id, tab_id: tab_id, all_tabs: all_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.LOCK_METADATUM,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const unlockMetadatum = (metadatum_id, block_id, tab_id, all_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/unlock_metadatum'
  const params = { customer_id: state.profile_setup.customer_id, metadatum_id: metadatum_id, block_id: block_id, tab_id: tab_id, all_tabs: all_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.UNLOCK_METADATUM,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const addNewField = (title, member_profile_category_id, block_id, tab_id, all_tabs) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/add_new_field'
  const params = { customer_id: state.profile_setup.customer_id, title: title, member_profile_category_id: member_profile_category_id, block_id: block_id, tab_id: tab_id, all_tabs: all_tabs }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.ADD_NEW_FIELD,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const saveChanges = (all_tabs, all_metadata, member_info) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/save_changes'
  const params = { customer_id: state.profile_setup.customer_id, all_tabs: all_tabs, all_metadata: all_metadata, member_info: member_info, member_id: state.profile_setup.member_id }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SAVE_CHANGES,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const switchView = (view_mode) => (dispatch) => {
  dispatch({
    type: ActionTypes.SWITCH_VIEW,
    data: view_mode
  })
}

export const close = (current_tab_id) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/close'
  const params = { customer_id: state.profile_setup.customer_id, current_tab_id: current_tab_id }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.CLOSE,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const saveMemberChanges = (updated_member_info, updated_member_metadata, updated_member_metadata_ids, member_notifications, clear_photo, centralPage) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/save_member_changes'
  const params = { customer_id: state.profile_setup.customer_id, member_id: state.filters ? state.filters.member_id : state.profile_setup.member_id, updated_member_info: updated_member_info, updated_member_metadata: updated_member_metadata, updated_member_metadata_ids: updated_member_metadata_ids, member_notifications: member_notifications, clear_photo: clear_photo, central_page: centralPage }

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SAVE_MEMBER_CHANGES,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const startEditTab = (tab_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.START_EDIT_TAB,
    data: tab_id
  })
}

export const endEditTab = () => (dispatch) => {
  dispatch({
    type: ActionTypes.END_EDIT_TAB
  })
}

export const saveTabTitle = (tab_id, title) => (dispatch) => {
  dispatch({
    type: ActionTypes.SAVE_TAB_TITLE,
    data: {
      tab_id: tab_id,
      title: title
    }
  })
}

export const setFilteredActivityLog = (entity_type, member_id) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/set_filtered_activity_log'
  const params = { customer_id: state.profile_setup.customer_id, entity_type: entity_type, member_id: member_id}

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.SET_FILTERED_ACTIVITY_LOG,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while fetching data!')
    })
}

export const uploadProfilePicture = data => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/api/web/profile_setups/upload_profile_picture'
  if (data) {
    document.getElementById("member_profile_picture").classList.add("d-none");
    document.getElementById("member_profile_picture_spinner").classList.remove("d-none");
    return new Promise((resolve, reject) => {
      fetch(
        dataURL,
        {
          method: 'POST',
          body: data,
        }
      )
        .then(response => Promise.all([response, response.json()]))
        .then(([response, json]) => {
          if (response.status === 200 && _.isEmpty(json.errorMessage)) {
            resolve(json)
          } else {
            reject(json.errorMessage, json)
          }
        })
        .catch(() => {
          reject()
        })
    }).then(response => {
      dispatch({
        type: ActionTypes.UPLOAD_PROFILE_PICTURE,
        data: response,
      })
      document.getElementById("member_profile_picture_spinner").classList.add("d-none");
      document.getElementById("member_profile_picture").classList.remove("d-none");
    })
      .catch(() => {
        showErrorNotification('Error while fetching data!')
      })
  }
}

export const clearPhoto = () => (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_PHOTO,
    data: {
    }
  })
}

export const swapOrder = (draggedId, hoveredId, type, blockId, placement) => (dispatch) => {
  dispatch({
    type: ActionTypes.SWAP_ORDER,
    data: {
      draggedId: draggedId,
      hoveredId: hoveredId,
      blockId: blockId,
      type: type,
      placement: placement
    }
  })
}
