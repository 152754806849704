import React from 'react'
import {useState, useEffect} from 'react';
import {Form, Col, Row} from 'react-bootstrap';
import Select from "react-select";

function ProductAttribute (props){
  const {
    field,
    product,
    currentFieldValue,

    changeHandler
  } = props;


  const initialState = {
    selectInputHeight: 30,
    selectInputFontSize: "0.8rem",
  }

  const [state, setState] = useState(initialState);

  const handleSelectFieldOption = (value, field) => {
    changeHandler(field, value)
  }

  const handleTextFieldChange = (value, field) => {
    changeHandler(field, value)
  }

  const field_options = [{label: `Please select`, value: ''}].concat(field.values.split(',').map((value) => ({ label: value, value: value })))

  return (
    <Row className='field mb-2' data-field-required={field.required} id={`product_${product.id}_field_${field.id}`}>
      <Col xs={12} className='mb-1'>
        <b className=''>
          <label> {field.name} </label>
          {field.required && <span className='color_red ms-2'>*</span>}
        </b>
      </Col>
      <Col xs={12}>
        {
          field.values.length > 0 ?
            <Select
              className="field_select_option"
              id={`product_${product.id}_field_${field.id}_select`}
              name=""
              menuPlacement="auto"
              isSearchable={false}
              styles={{
                container: base => ({
                }),
                control: base => ({
                  ...base,
                  borderRadius: 0,
                  outline: 'none',
                  minHeight: "29px!important",
                  border: state.isFocused ? "1px solid #FF6633" : "1px solid #cccccc",
                  boxShadow: state.isFocused ? "0px 0px 6px #FF6633" : "none",
                  "&:hover": {
                    border: "1px solid #FF6633",
                    boxShadow: "0px 0px 6px #FF6633"
                  }              
                }),
                indicatorSeparator: base => ({
                  width: 0,
                }),
                dropdownIndicator: base => ({
                  padding: "0 8px!important",
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                option: base => ({
                  ...base,
                  backgroundColor: "cccccc",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#f5f5f5!important",
                  }              
                }),
              }}
              data-id={field.id}
              value={field_options.filter(function (option) {
                return option.value === currentFieldValue;
              })}
              options={field_options}
              menuPortalTarget={document.querySelector('body')}
              onChange={(event) => handleSelectFieldOption(event.value, field)}
            />
          :
            <input type="text" className="form-control text_free_value" value={currentFieldValue} onChange={(event) => handleTextFieldChange(event.target.value, field)}/>
        }
      </Col>
    </Row>
  )
}


export default ProductAttribute