import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function TemplateInfoIcon(props) {

  const {text} = props;

  return (
    <OverlayTrigger
      key="background_opacity"
      placement="top"
      overlay={
        <Tooltip id={`tooltip-background_opacity`}>
          {text}
        </Tooltip>
      }
    >
      <i className='font_size_large gga-info-circle'/>
    </OverlayTrigger>
  )
}

export default TemplateInfoIcon
