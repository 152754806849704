import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import PipedriveItem from './PipedriveItem'
import _ from 'lodash'
class PipedriveBody extends PureComponent {
  constructor(props) {
    super(props)
  }


  render() {
    const { to_place, placed, delivered, filters_list, quick_search_value, condensed_tile_layout, to_place_sorted_by, placed_sorted_by, delivered_sorted_by } = this.props

    let to_place_sorted = to_place.length > 0 ? to_place_sorted_by.sort_direction == 'desc' ? _.sortBy(to_place, [function (o) { return o[to_place_sorted_by.sort_column]; }]).reverse() : _.sortBy(to_place, [function (o) { return o[to_place_sorted_by.sort_column]; }]) : to_place
    let placed_sorted = placed.length > 0 ? placed_sorted_by.sort_direction == 'desc' ? _.sortBy(placed, [function (o) { return o[placed_sorted_by.sort_column]; }]).reverse() : _.sortBy(placed, [function (o) { return o[placed_sorted_by.sort_column]; }]) : placed
    let delivered_sorted = delivered.length > 0 ? delivered_sorted_by.sort_direction == 'desc' ? _.sortBy(delivered, [function (o) { return o[delivered_sorted_by.sort_column]; }]).reverse() : _.sortBy(delivered, [function (o) { return o[delivered_sorted_by.sort_column]; }]) : delivered

    return (
      <div className='row m-0 main pipedrive_sections_container flex-grow-1 overflow-auto '>
        <div className='col-4 section pb-2 border-right-gray-dashed'>

          {to_place_sorted.length > 0 && (
            <div>
              {to_place_sorted.map((st) => (
                (!quick_search_value || (quick_search_value && (st.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                <PipedriveItem key={st.id} data={st}
                  newStockOrder={this.props.newStockOrder}
                  uploadStockOrder={this.props.uploadStockOrder}
                  editStockOrder={this.props.editStockOrder}
                  deleteStockOrder={this.props.deleteStockOrder}
                  openSplitStockOrder={this.props.openSplitStockOrder}
                  restoreStockOrder={this.props.restoreStockOrder}
                  openCancelStockOrder={null}
                  visible={(filters_list == "all") ? "present" : _.find(st.stock_order_categories_list, function (o) { return _.find(filters_list, function (i) { return i.category_id.includes(o.category_id) }) })}
                  next_action_name="Place Stock Order"
                  condensed_tile_layout={condensed_tile_layout}
                  nextAction={this.props.openPlaceStockOrder}
                  can_place_stock_orders={this.props.can_place_stock_orders}
                  view_only_stock_orders={this.props.view_only_stock_orders}
                  stock_order_id={this.props.stock_order_id} />
              ))}
            </div>
          )}
        </div>
        <div className='col-4 section pb-2 border-right-gray-dashed'>
          {placed_sorted.length > 0 && (
            <div>
              {placed_sorted.map((st) => (
                (!quick_search_value || (quick_search_value && (st.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                <PipedriveItem key={st.id} data={st}
                  newStockOrder={this.props.newStockOrder}
                  uploadStockOrder={this.props.uploadStockOrder}
                  editStockOrder={this.props.editStockOrder}
                  deleteStockOrder={this.props.deleteStockOrder}
                  openSplitStockOrder={this.props.openSplitStockOrder}
                  restoreStockOrder={this.props.restoreStockOrder}
                  openCancelStockOrder={this.props.openCancelStockOrder}
                  openShipDetailsStockOrder={this.props.openShipDetailsStockOrder}
                  visible={(filters_list == "all") ? "present" : _.find(st.stock_order_categories_list, function (o) { return _.find(filters_list, function (i) { return i.category_id.includes(o.category_id) }) })}
                  next_action_name="Receive"
                  condensed_tile_layout={condensed_tile_layout}
                  nextAction={this.props.openReceiveStockOrder}
                  can_place_stock_orders={this.props.can_place_stock_orders}
                  view_only_stock_orders={this.props.view_only_stock_orders}
                  stock_order_id={this.props.stock_order_id} />
              ))}
            </div>
          )}
        </div>
        <div className='col-4 section pb-2'>
          {delivered_sorted.length > 0 && (
            <div>
              {delivered_sorted.map((st) => (
                (!quick_search_value || (quick_search_value && (st.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                <PipedriveItem key={st.id} data={st}
                  newStockOrder={this.props.newStockOrder}
                  uploadStockOrder={this.props.uploadStockOrder}
                  editStockOrder={this.props.editStockOrder}
                  deleteStockOrder={this.props.deleteStockOrder}
                  restoreStockOrder={this.props.restoreStockOrder}
                  openSplitStockOrder={this.props.openSplitStockOrder}
                  openCancelStockOrder={null}
                  next_action_name="Finalize"
                  condensed_tile_layout={condensed_tile_layout}
                  visible={(filters_list == "all") ? "present" : _.find(st.stock_order_categories_list, function (o) { return _.find(filters_list, function (i) { return i.category_id.includes(o.category_id) }) })}
                  nextAction={this.props.openFinalizeStockOrder}
                  can_place_stock_orders={this.props.can_place_stock_orders}
                  view_only_stock_orders={this.props.view_only_stock_orders}
                  stock_order_id={this.props.stock_order_id} />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}


export default PipedriveBody
