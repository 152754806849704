import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

const FormContext = React.createContext()
const {
  Provider: FormProvider,
  Consumer: FormConsumer,
} = FormContext

const CombinedProvider = ({ store, data, children }) => {
  return (
    <FormProvider value={ data }>
      <Provider store={ store }>
        { children }
        </Provider></FormProvider>)
}

CombinedProvider.propTypes = {
  store: PropTypes.object.isRequired,
  data: PropTypes.object,
  children: PropTypes.object,
}

CombinedProvider.defaultProps = {
  data: {},
}

export const GlobalConsumer = FormConsumer
export const GlobalProvider = CombinedProvider
