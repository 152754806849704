import { connect } from 'react-redux'
import { closeFinalizeModal, updateStockOrder, uploadFile} from '../actions'
import FinalizeStockOrderModal from '../components/FinalizeStockOrderModal'
                          
const mapStateToProps = (state) => ({
  modal_finalize_open: state.stock_order.modal_finalize_open,
  item: state.stock_order.item ,
  customer_id: state.stock_order.customer_id,
  finalized_fields: state.stock_order.finalized_fields
})

const mapDispatchToProps = (dispatch) => ({
  closeFinalizeModal: () => dispatch(closeFinalizeModal()),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalizeStockOrderModal)
