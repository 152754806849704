import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col, Table } from 'react-bootstrap';
import {useState} from 'react';
import update from 'immutability-helper';
import Summary from './Summary';
import FroalaEditor from 'react-froala-wysiwyg'; 
import CancelDetailedTable from './CancelDetailedTable';

function CancelStockOrderModal(props) {

  const initialState = props.item

  const [state, setState] = useState(initialState);

  const config_froala = {
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: false,
    key: 'aLF3c1C9C7A5D3F2H3C-7SLJCKHXOSLMc2YGSGe1ZXHSa1CgC3E3G3B2B7A4B3F4E2B2==',
    attribution: false
  }

  const handleQuantity = (soc_id, soi_id, size, qty) => {
    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id)
    let current_qty = 0
    if(soi_id){
      let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
      if(size){
        let soq_index = _.findIndex(stock_order_categories[soc_index].stock_order_items[soi_index].stock_order_quantities, (q) => q.size == size)
        if(soq_index != -1){
          current_qty = stock_order_categories[soc_index].stock_order_items[soi_index].stock_order_quantities[soq_index].qty
          qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
          setState(update(state, {
            stock_order_categories: {
              [soc_index] : {
                stock_order_items : {
                  [soi_index]: {
                    stock_order_quantities : {
                      [soq_index]: 
                      { nr_of_items_to_move: {$set : qty }}
                    }
                  }
                }
              } 
            }
          }))
        }
      }
      else
      {
        current_qty = stock_order_categories[soc_index].stock_order_items[soi_index].qty
        qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
        setState(update(state, {
          stock_order_categories: {
            [soc_index] : {
              stock_order_items : {
                [soi_index]: {
                  nr_of_items_to_move: {$set: qty}
                }
              }
            }
          }
        }
      ))
      }
    }
    else
    {
      current_qty = stock_order_categories[soc_index].qty
      qty = qty < 0 ? 0 : qty > current_qty ? current_qty : qty
      setState(update(state, {
        stock_order_categories: {
          [soc_index] : {
            nr_of_items_to_move: {$set: qty}
          }
        }
      }
    ))
    }
  }

  const handleRadioInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.value });
  }

  const handleClose = () => {

    props.closeCancelModal();
  }
  
  const handleSubmit = () => {
    let params = {...state}

    params.stock_order_categories.map((soc) => { 
      soc.stock_order_items.map((soi) => { 
        soi.stock_order_quantities = JSON.stringify(soi.stock_order_quantities)
      })
      soc.stock_order_items = JSON.stringify( soc.stock_order_items)
    })

    params.stock_order_categories = JSON.stringify(params.stock_order_categories)

    props.cancelStockOrder(params)
    handleClose()
  }

  const handleHtmlChange = (event) => {
    setState({...state, body: event });
  }

  const handleInputChange = (event) => {
    if(event.target.getAttribute("assigned") == "remove_file")
        setState({...state, [event.target.name]: "remove" });
    else
      setState({...state,  [event.target.name]: event.target.value});
  }

  const handleCheckboxInputChange = (event) => {
    setState({...state, [event.target.name]: event.target.checked });
  }

  const markCancelled = (soc_id, soi_id = null, qty = null) => {
    if(props.item.detailed_entry)
    {
      let categories = [...stock_order_categories]
      let soc_index = _.findIndex(categories, (soc) => soc.category_id == soc_id)
      if(soi_id){
        let soi_index = _.findIndex(categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
        if(categories[soc_index]["stock_order_sizing_table_id"])
        categories[soc_index].stock_order_items[soi_index].stock_order_quantities.map((soq, index) => {
            soq["nr_of_items_to_move"] = soq["qty"]
          })
        else
          categories[soc_index].stock_order_items[soi_index].nr_of_items_to_move = stock_order_categories[soc_index].stock_order_items[soi_index].qty
      }
      setState(update(state, {
        stock_order_categories: {$set: categories}
      }
      ))
    }
    else
    {
      let input_id = "cancel_item_"+soc_id

      document.getElementById(input_id).value = qty
      setState({...state,  [input_id]: qty});
    }
  }

    const {modal_cancel_open, customer_id} = props;
    const {id, order_nickname, order_number, order_description, internal_notes, method_of_cancelling,
            to, subject, body, email_vendor_for_cancelling, stock_order_categories} = state;
    const {files} = props.item
    
    return (
      modal_cancel_open && <div className="modal_cancel_stock_order_container">
        <Modal isOpen={modal_cancel_open} scrollable={true}>
          <ModalHeader className="header">
            <h4 className="modal-title form_new_edit m-0">CANCEL ORDER/ITEMS FOR {order_nickname}(PO: {order_number})</h4>
          </ModalHeader>
          <ModalBody className="main">
            <Form>
              <div className="row">
                <div className="col">
                  <Form.Group as={Row} className="" controlId="formMethodOfCancellingTitle">
                    <Form.Label>
                        What should be marked as cancelled?
                    </Form.Label>
                  </Form.Group>
                  <div key="inline-formMethodOfCancelling" className="mb-3 row">
                    <Form.Check 
                      inline
                      name="method_of_cancelling"
                      value = "entire"
                      type='radio'
                      id="entire"
                      label="Entire Order"
                      className="col"
                      ini={true.toString()}
                      checked = {(method_of_cancelling == "") || (method_of_cancelling == undefined) || (method_of_cancelling == "entire")}
                      onChange={handleRadioInputChange} 
                    />
                    <Form.Check 
                      inline
                      type='radio'
                      name="method_of_cancelling"
                      checked={method_of_cancelling == "items"}
                      value = "items"
                      id="items"
                      label="Specific Items"
                      className="col"
                      onChange={handleRadioInputChange}
                      // disabled = {state.detailed_entry ? true : null}
                    />
                  </div>
                  {(method_of_cancelling == "" || method_of_cancelling == undefined || method_of_cancelling == "entire") && <div className="entire_order_class" id ="entire_order_class">
                    The entire stock order will be canceled
                    <br></br>
                    <br></br>
                  </div>}
                  {method_of_cancelling == "items" && <div className="items_order_class" id ="items_order_class">
                    <Form.Group as={Row} className="mb-3" controlId="formCategoies">
                      <Form.Label>
                        {props.item.detailed_entry ? 
                          "Specify for each item and size (if multiple) the quantity to be cancelled"
                        :
                          "Specify for each category the quantity to be cancelled"
                        }
                      </Form.Label>
                    </Form.Group>
                    <div className="row">
                      {
                      props.item.detailed_entry ?
                        <CancelDetailedTable 
                          stock_order_categories={state.stock_order_categories} 
                          sizing_table={props.sizing_table} 
                          handleQuantity={handleQuantity}
                          markCancelled={markCancelled}
                          />
                        :
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Current Qty</th>
                              <th>Qty To be Cancelled</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(state.stock_order_categories).map((cat, index) => (
                              <tr key={cat.id || Math.random()}>
                                <td>{cat.category_name}</td>
                                <td>{cat.qty}</td>
                                <td>
                                  <Form.Control type="number" 
                                        name={"cancel_item_"+cat.id}
                                        id={"cancel_item_"+cat.id}
                                        position={index}
                                        defaultValue= "0"
                                        onChange={handleInputChange}
                                        min="0"
                                        max={cat.qty}
                                        />
                                </td>
                                <td>
                                  <a className="color_red" input_id={"cancel_item_"+cat.id} max_qty={cat.qty} onClick={() => markCancelled(cat.id, null, cat.qty)}>
                                    Mark All Items to be cancelled
                                  </a>
                                  </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      }
                    </div>
                  </div>}
                  <Form.Group className="mb-3" controlId="formOrderDescription">
                    <Form.Label>Order Description</Form.Label>
                    <Form.Control as="textarea" rows={3} 
                                  name='order_description'
                                  value={order_description || ""}
                                  onChange={handleInputChange}/>
                  </Form.Group>
                  <Form.Check 
                    name="email_vendor_for_cancelling"
                    defaultChecked = {false}
                    type='checkbox'
                    id="email_vendor_for_cancelling"
                    label="Email Vendor Now"
                    className="p-0 email_vendor_for_cancelling"
                    onChange={handleCheckboxInputChange} 
                  />
                  {email_vendor_for_cancelling && <div className="row">
                    <Form.Group as={Row} className="ps-3 pb-3" controlId="formInEmailTo" >
                      <Form.Label column sm="2">
                        TO:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control type="text" 
                                      name='to'
                                      onChange={handleInputChange} 
                                      value={to || ""}
                                      />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="ps-3 pb-3" controlId="formInEmailSbj" >
                      <Form.Label column sm="2">
                        SUBJECT:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control type="text" 
                                      name='subject'
                                      onChange={handleInputChange} 
                                      value={subject || ""}
                                      />
                      </Col>
                    </Form.Group>
                  <FroalaEditor tag='textarea'
                                config={config_froala}
                                model={body}
                                onModelChange={handleHtmlChange}/>
                  </div>}
                </div>
                <div className="col">
                  <Summary files={files}
                            handleInputChange = {handleInputChange}
                            uploadFile = {props.uploadFile}
                            customer_id = {customer_id}
                            state = {state}
                  />
              </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter className="footer">
          <button
              className="float-start btn gems_custom_button"
              aria-label="Close"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="btn gems_custom_button gems_orange_button float-end"
              onClick={handleSubmit}
            >
              {method_of_cancelling == "items" ? "Cancel Marked Items" : "Cancel Entire Order"}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  } 
export default CancelStockOrderModal;