import { ActionTypes } from '../actions'

const {
  CREATE_BLOCK,
  REMOVE_BLOCK,
  UPDATE_BLOCK,
  BLOCK_REORDER,
} = ActionTypes

const blocks = (state = [], action) => {
  switch (action.type) {
    case CREATE_BLOCK: {
      return [
        ...state,
        {
          id: action.id,
          title: action.title,
          layout: action.layout,
          tabId: action.tabId,
          index: action.index,
        },
      ]
    }
    case UPDATE_BLOCK: {
      return state.map(block =>
        (block.id === action.id)
          ? {...block, title: action.title}
          : block)
    }
    case REMOVE_BLOCK: {
      return state.filter(block => block.id !== action.id)
    }
    case BLOCK_REORDER: {
      const tmpState = state.filter(block => block.tabId !== action.blocks[0].tabId)
      return [
        ...tmpState,
        ...action.blocks,
      ].sort( (a, b) => a.index < b.index ? -1 : 1 )
    }
    default:
      return state
  }
}

export default blocks
