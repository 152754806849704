import React, { Component } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import Header from '../components/Header'
import BodyContainer from '../containers/BodyContainer'
import FooterContainer from '../containers/FooterContainer'
import HeaderContainer from '../containers/HeaderContainer'
import DashboardAdvancedFilterContainer from '../containers/DashboardAdvancedFilterContainer'
class Dashboard extends Component {
  constructor(props){
    super(props)
  }
  
  componentDidMount(){
    this.props.fetchStockOrdersData()
  }

  render() {
    return (
        <div className="col-12 h-100 d-flex flex-column">
          <HeaderContainer />
          <DashboardAdvancedFilterContainer/>
          <BodyContainer />
          <FooterContainer />
        </div>
    )
  }
}


export default Dashboard
