import React from 'react';
import { Spinner} from 'react-bootstrap';
import {FiExternalLink} from 'react-icons/fi'

function OrOnlineSurveyHome(props) {
  return (
    <div className='col p-3 pb-4 add-margin-all-10 text-center home_class' >
        <h4 className='mx-3 text-start mb-0 mt-2'>{props.title}</h4>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          props.data.length > 0 
          ? 
          props.data.map((os) => (<>
          <a className='row mt-20 ms-3 me-3 mb-2 text-center body_blue_component' href={os["url"]} data-method="get" style={{position: "relative"}} target="_blank">
            <FiExternalLink style={{color: "#005279", position: "absolute", right: "-0.5rem", width: "15%"}} className='fs-6 mt-2'/>
            <h5 className='add-line-height-35 m-0 pe-4 ps-4'>{os["name"]}</h5>
            <h3 className='m-0'><b>{os["value"]}</b></h3>
            <h6>{os["rate"]}</h6>
          </a>
          {/* <div className='shop_dashboard_btn btn gems_custom_button cursor_pointer'>
            <a href={os["url"]} data-method="get">View</a>
          </div> */}
          </>))
          :
          <>
          <div className='row ms-3 me-3  text-center body_blue_component'> 
            <h5 className='add-line-height-35 m-0'>NO {props.title}</h5>
          </div>
          </>
          }
      </div>
    )}
export default OrOnlineSurveyHome