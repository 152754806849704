import { combineReducers } from 'redux'
import tabs from './tabs'
import filters from './filters'
import blocks from './blocks'
import columns from './columns'
import general_settings from './general_settings'
import profile_setup from '../../profile_setup/reducers/profile_setup'

export default combineReducers({
  tabs,
  filters,
  blocks,
  columns,
  general_settings,
  profile_setup
})
