import { connect } from 'react-redux'
import { setFilteredActivityLog } from '../actions'
import ActivityTab from '../components/ActivityTab'

const mapStateToProps = (state) => ({
  activity_log: state.profile_setup.activity_log,
  activity_log_entities: state.profile_setup.activity_log_entities,
  member_id: state.filters ? state.filters.member_id : state.profile_setup.member_id
})

const mapDispatchToProps = (dispatch) => ({
  setFilteredActivityLog: (entity_type, member_id) => dispatch(setFilteredActivityLog(entity_type, member_id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTab)