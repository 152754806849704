
import React from 'react';
import _ from 'lodash';
import {useState} from 'react';

function CategoryItems(props) {
  const {dropdown_identifier, expand_subsection, category, first, last, handleClickCategory} = props
  let depth = parseInt(props.depth)

  const initialState = {
    expand_section: false,
  }
  
  const [state, setState] = useState(initialState);

  const handleExpandSection = () => {
    setState({...state, expand_section: !state.expand_section})
  }


  const active_category = (category_id) => {
    const category_element = document.getElementById(`${dropdown_identifier}_${category_id}_category_item`)
    category_element.classList.add('active_category')
    const parent_id = category_element.getAttribute('data-parent-id')
    if (parent_id != undefined && parent_id != null && parent_id != '')
      active_category(parent_id)
    return
  }

  const handleSelectedCategory = (category) => {
    const category_elements = document.getElementById(`${dropdown_identifier}_category_dropdown`).getElementsByClassName('category_container')
    Array.from(category_elements).forEach((el) => el.classList.remove('active_category'))
    active_category(category.id)
    handleClickCategory(category)
  }

  return (<>
    <li className={`${depth > 1 && !expand_subsection ? "d-none": ""} ${first} ${last} dropdown-item category_container p-0 mb-2`} data-id = {category.id} data-full-name = {category.full_name || category.name} data-parent-id={category.parent_id} data-name = {category.name} data-depth-level= {depth} id={`${dropdown_identifier}_${category.id}_category_item`}>
      <a className={`${depth > 1 ? "other_category" : ""} ${depth == 0 ? " first_level font_size_first_category" : "font_size_other_category"} add-line-height-30 searchable_field_for_categoy d-flex`} style={{ borderLeft: (depth == 0 &&`5px solid ${category.color}`), marginLeft: (depth > 1 && `${depth*20-15+((depth-2)*5)}px`) }}>
        <div className={`col-12 ${depth > 0 ? "ps-3" : "ps-2"} d-flex`} data-id = {category.id} data-full-name = {category.full_name || category.name}>
          <div className='flex-grow-1' onClick={(e) => handleSelectedCategory(category)}>
            <span data-id = {category.id} data-full-name = {category.full_name || category.name}>
              {category.name}           
            </span>
            {(category.children.length>0 && depth > 0) &&
              <>
                <span className="fa fa-circle separator_style font_size_4"/>
                <span count={`${category.children.length}`}> {`${category.children.length}`}</span> 
              </>
            }
          </div>
          {(category.children.length>0 && depth > 0) &&
            <div>
              {depth == 0 ?
              <span className="add_padding_top_2 float-end" data-id = {`${category.id}`}/>
              :
              <span 
                className={`add_padding_top_2 float-end show_hidden_children_category on fa ${state.expand_section ? 'fa-minus pe-1' : 'fa-plus'}`}
                data-id = {`${category.id}`}
                style={{fontSize: "larger", lineHeight: "30px"}}
                onClick={handleExpandSection}
              />
              }
            </div>
          }
        </div>
      </a>
    </li>
    <>
    {category.children.length > 0 &&
      category.children.map((children, i) => (
        <CategoryItems 
          expand_subsection={state.expand_section}
          category={children}
          depth={depth+1}
          i={i}
          first={`${(i == 0 && depth > 0) ? 'first' : ''}`}
          last= {`${( i == (category.children).length - 1 && depth > 0 ) ? 'last' : ''}`}
          handleClickCategory={handleClickCategory}
          dropdown_identifier={dropdown_identifier}
          key={`${dropdown_identifier}_${children.id}_category_item`}
        />
      ))
    }
    </>
    </>
  )
} 
export default CategoryItems