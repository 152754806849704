import React from 'react';
import { Col, Select } from 'react-bootstrap';
import CategoryDropdown from '../../../shared/components/CategoryDropdown';

function MatchingCategoriesTable(props) {
  const {matched_categories, unmatched_categories, categories_to_create, gs_categories, admin_side, setMatchCategoryDropdown, matchNewCategoryDropdown, createNewCategoryDropdown, setParentNewCategoryDropdown, undoNewCategory, matchCategory, unmatchCategory} = props;

  return (
        
    <div className='row pt-4 flex-grow-1 overflow-auto add-margin-bottom-50' key="matching_categories"> 
      <Col md={{span: 8, offset: 2}} className="h-100 text-center">
        <div className='table-responsive'>
          <table className='table table-bordered matcher no-select tables_v2 with_striping table-striped'>
            <thead/>
            <tbody>
              <tr>
                <th className='text-center'>Callaway Category</th>
                <th className='text-center'>Golf Shop Category</th>
                <th className='text-center'>Actions</th>
              </tr>
              { !admin_side && <tr className='header march_categories'>
                <th className='text-center' colSpan={3}>
                  <div className='unmatched_header'>
                    New Categories
                  </div>
                </th>
              </tr>}

              {!admin_side && categories_to_create.map((c, index) => (
                <tr key={`tr_${c.id}`}>
                  <td className='text-start ps-4'>{c.full_name}</td>
                  <td>
                    <strong>
                      {c.name}
                    </strong>
                    {c.parent_category && <span> - {c.parent_category.full_name}</span>}
                  </td>
                  <td>
                    <span className="background_trasparent cursor_pointer custom_vertical_align_middle delete_btn link matched_category_option no_border"
                      data-cc-category-id={c.id}
                      id={`delete_match_btn_${c.id}`}
                      onClick={() => unmatchCategory(c.id, 'new')}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}

              <tr className='header march_categories'>
                <th className='text-center' colSpan={3}>
                  <div className='matched_header'>
                    Matched Categories
                  </div>
                </th>
              </tr>

              {matched_categories.sort((a, b) => a.full_name > b.full_name ? 1 : -1).map((c, index) => (
                <tr key={`tr_${c.id}`}>
                  <td className='text-start ps-4'>{c.full_name}</td>
                  <td>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <span>{c.matched_category.full_name || c.matched_category.name}</span>
                      </div>
                      {c.matched_category.global_match && <div>
                          <i className="align-middle font_size_small white color_green fa fa-asterisk tooltip_info" data-toggle="tooltip" data-html="true" data-container="body" title="This category has been matched by the Golf Genius Team."/>
                        </div>}
                    </div>
                  </td>
                  <td>
                    <span className="background_trasparent cursor_pointer custom_vertical_align_middle delete_btn link matched_category_option no_border"
                      data-cc-category-id={c.id}
                      id={`delete_match_btn_${c.id}`}
                      onClick={() => unmatchCategory(c.id, 'matched')}
                    >
                      Unmatch
                    </span>
                  </td>
                </tr>
              ))}

              <tr className='header march_categories'>
                <th className='text-center' colSpan={3}>
                  <div className='unmatched_header'>
                    Unmatched Categories
                  </div>
                </th>
              </tr>

              {unmatched_categories.sort((a, b) => a.full_name > b.full_name ? 1 : -1).map((c, index) => (
                <tr key={`tr_${c.id}`}>
                  <td className='text-start ps-4'>{c.full_name}</td>
                  <td>
                    { !c.new_category && <CategoryDropdown
                      categories = {gs_categories}
                      is_matching_page={true}
                      className='bg-white'
                      dropdown_identifier={c.id}
                      is_admin_side={admin_side}
                      setMatchCategory={setMatchCategoryDropdown}
                      createNewCategory={matchNewCategoryDropdown}
                    />}

                    {
                      c.new_category && <div>
                        <div className='mb-2 text-start'>
                          Category: <strong> {c.name} </strong>

                        </div>
                        <div className='text-start'>
                          <b>Please select a parent category:</b>

                        </div>
                        <div className=''>
                          <CategoryDropdown
                            categories = {gs_categories}
                            className='bg-white'
                            is_create_category={true}
                            dropdown_identifier={c.id}
                            is_admin_side={admin_side}
                            setSelectedCategory={setParentNewCategoryDropdown}
                          />
                        </div>
                      </div>
                    }
                  </td>
                  <td>
                    { c.matched_category &&
                      <span 
                        className="background_trasparent color_red cursor_pointer custom_vertical_align_middle link match_btn no_border unmatched_category_option"
                        data-cc-category-id={c.id}
                        id={`new_match_btn_${c.id}`}
                        onClick={() => matchCategory(c.id)}
                      >
                        Match
                      </span>
                    }

                    { c.new_category &&
                      <div className='d-flex flex-column'>
                        <span 
                          className="background_trasparent color_red cursor_pointer custom_vertical_align_middle link match_btn no_border unmatched_category_option mb-2"
                          data-cc-category-id={c.id}
                          id={`new_match_btn_${c.id}`}
                          onClick={() => createNewCategoryDropdown(c.id)}
                        >
                          Match
                        </span>

                        <span 
                          className="background_trasparent cursor_pointer custom_vertical_align_middle link match_btn no_border unmatched_category_option"
                          data-cc-category-id={c.id}
                          id={`undo_match_btn_${c.id}`}
                          onClick={() => undoNewCategory(c.id)}
                        >
                          Undo
                        </span>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </div>
)}

export default MatchingCategoriesTable