import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class NotificationForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: true,
      sms: true
    }
  };

  handleCheckboxInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    })
  }
  handleAddToWaitingList = (event) => {
    let notification_type = this.state.email && this.state.sms ? "all" : this.state.email ? "email" : "sms"
    this.props.addToWaitingList(notification_type)
  }

  render() {
    return (  
      <div className='col'>
      {/* <ModalHeader >
        <h4 className="modal-title form_new_block no-margin">Confirm Back Into Inventory Notification</h4>
      </ModalHeader> */}
      <ModalBody>
        <div className='row'>
          <div className='col-12 mt-3 mb-3'>
            How would you like to get notified when this club / component is available?
          </div>
          <div className='col-12 m-2'>
            <div className='row'>
              <Form.Check 
                name="email"
                value = {this.state.email}
                defaultChecked = {this.state.email}
                type='checkbox'
                id="notification_type_email"
                label="Email"
                className="col-6 add-line-height-35 p-0"
                onChange={this.handleCheckboxInputChange} 
              />
              <Form.Check 
                name="sms"
                value = {this.state.sms}
                defaultChecked = {this.state.sms}
                type='checkbox'
                id="notification_type_sms"
                label="Text Message"
                className="col-6 add-line-height-35 p-0"
                onChange={this.handleCheckboxInputChange} 
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Back"
            onClick={ e => { e.preventDefault(); this.props.cancel() } } >
            {window.I18n.t('multi_widgets.cancel')}
          </button>
          <button
            className={ `btn gems_custom_button float-end ${!this.props.member_id ? 'disabled' : ''}` }
            style={{backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor))}}
            onClick={this.props.member_id ? this.handleAddToWaitingList : ""} >
            Get Notified
          </button>
        </ModalFooter>
      </div>
    )
  }
}

export default NotificationForm
