import React from 'react'
import { Col } from 'react-bootstrap';
import Footer from '../components/Footer';
import MatchingCategoriesTableContainer from '../containers/MatchingCategoriesTableContainer';
import Header from './Header';
import HeaderMatchingCategories from './HeaderMatchingCategories';

function MatchingCategoriesPage(props) {
  const {saveCategoriesConfiguration} = props;
  return (
    <div className="col-12 h-100 d-flex flex-column">
      <Header action="MATCHING CATEGORIES"/>
      <HeaderMatchingCategories />
      <MatchingCategoriesTableContainer/>
      <Footer onClickHandler={saveCategoriesConfiguration} />
    </div>
  )
}

export default MatchingCategoriesPage
