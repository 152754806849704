import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Select from 'react-select';
import {useState, useEffect, useRef} from 'react';
import update from 'immutability-helper';
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import CollagePage from "../containers/CollagePage";
import {config_froala} from "../../../shared/froala_config";
import FroalaEditor from "react-froala-wysiwyg";
function EditColumns(props){

  const collageTileSave = useRef(null)

  const initial_options = {
    html_code: "",
    enable_club_repair_request: true,
    enable_reservation: true,
    enable_availability_notification: true,
    days_in_advances: 3,
    managers_reserve_notifications: props.managers.map(o => o["value"]),
    online_form_id: null,
    member_profile_data: props.member_profile_data,
    items: []
  }
  
  const initialState = {
    title: props.column ? props.column.title : "",
    options: props.column ? props.column.options : initial_options,
    position: props.position,
    widget: props.column ? props.column.widget_id : null,
    displayActions: false,
    height: 262
    }
  
  const [state, setState] = useState(initialState);
  
  let config = config_froala
  config["placeholderText"] = "Title"
  config["minHeight"] = 130
  config["maxHeight"] = 1000
  config["width"] = "auto"
  config["toolbarButtons"]= {
    'moreRich': {},
    'moreMisc': {},
    'moreText': {
      
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      
    },
    
    'moreParagraph': {
      
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      
    }}
  const setTitle = (_title) => {
    setState({...state,
      title: _title,
    })
  }
  

  const setDays = (days) => {
    setState(update(state, {
      options: {
            days_in_advances: { $set : days }
            }
          }
      ))
  }

  const resetState = () => {
    setState(initialState)
  }
  
  const setWidget = (event) => {
    setState({...state,
      widget: event.value, options: initial_options
    })
  }


  const setOnlineForms = (event) => {
    setState(update(state, {
      options: {
            online_form_id: { $set : event.value }
            }
          }
      ))
  }

  const setHtmlCode = (value) => {
    setState(update(state, {
      options: {
            html_code: { $set : value }
            }
          }
      ))
  }


  const handleOptionsCheckboxInputChange = (event) => {
    setState(update(state, {
      options: {
        [event.target.name]: { $set : event.target.checked }
            }
          }
      ))
  }
  
  const handleDisplayCheckboxInputChange = (event) => {
    let key = event.target.name.split('display_')[1]
    let member_profile_data = []
    
    state.options.member_profile_data.map(data => {
      member_profile_data.push({...data})
    })

    let index =  member_profile_data.findIndex(mf => mf.key === key)
    let field = member_profile_data.filter((ii) => 
        { return ii.key == key})[0]
    member_profile_data[index].display = event.target.checked
    
    if(field.category == null)
      member_profile_data.map((m, index) => {
        if(m.category == field.name){
          member_profile_data[index].display = event.target.checked
          member_profile_data[index].category_display = event.target.checked
          }
      })

    setState(update(state, {
      options: {
        member_profile_data: { $set : member_profile_data }
            }
          }
      ))
  }

  const handleEditableCheckboxInputChange = (event) => {
    let key = event.target.name.split('editable_')[1]
    let member_profile_data = []
    
    state.options.member_profile_data.map(data => {
      member_profile_data.push({...data})
    })
    
    let index =  member_profile_data.findIndex(club => club.key === key)
    let field = member_profile_data.filter((ii) => 
        { return ii.key == key})[0]
    member_profile_data[index].editable = event.target.checked
    
    if(field.category == null)
      member_profile_data.map((m, index) => {
        if(m.category == field.name)
          member_profile_data[index].editable = event.target.checked
      })

    setState(update(state, {
      options: {
        member_profile_data: { $set : member_profile_data }
            }
          }
      ))
  }

  const handleSubmit = (event) => {

    let actual_state = {...state}
    
    let options = {...actual_state.options}
    
    if(options.member_profile_data)
    {
      let member_profile_data = options.member_profile_data
      member_profile_data = member_profile_data.filter(f => f.display)

      options.member_profile_data = member_profile_data
    }

    if(props.id)
      props.updateColumn(props.id, actual_state.title, actual_state.view_more, actual_state.widget, actual_state.options)
    else 
      props.createColumn(props.blockId, actual_state.widget, props.position, actual_state.title, actual_state.view_more, options)
    
    if(state.widget == "collage")
      collageTileSave.current()
  }

  const setManagers = (event) => {
    let arr = [...state.options.managers_reserve_notifications]

    if(event.target.checked)
      arr = [...state.options.managers_reserve_notifications, event.target.name]
    else
      arr = arr.filter((ii) => 
        { return ii != event.target.name})

    setState(update(state, {
      options: {
        managers_reserve_notifications: { $set : arr }
            }
          }
      ))
  }

  const disableEnabledOption = (cat) => {
    return (cat.category === "System Fields" || cat.name === "System Fields")
  }

  const tile_types_arr = [
                            {value: 'special_orders', label: "Special Orders"},
                            {value: 'demo_clubs', label: "Demo Clubs"},
                            {value: 'club_repairs', label: "Club Repairs"},
                            {value: 'online_ordering', label: "Online Ordering"},
                            // {value: 'survey', label: "Survey"},
                            // {value: 'member_profile', label: "Member Profile (Legacy)"},
                            {value: 'enhanced_member_profile', label: "Member Profile"},
                            {value: 'collage', label: "Collage Page"},
                            {value: 'html', label: "HTML"}
                          ]

  return (
    <div className='col'>
      <ModalHeader >
        <h4 className="modal-title form_new_block no-margin">{props.id ? window.I18n.t('multi_widgets.edit_column.title') : window.I18n.t('multi_widgets.edit_column.add_new_tile')}</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="row m-0 mt-3">
            <div className="col inner-content" style={ { width: "900px" }} id="tileTypeTitleId">
            {!props.id && <div className="alert alert-warning text-center" dangerouslySetInnerHTML={{__html: "Select a tile type and specify a title."}}/>}
              <Form.Group as={Row} className="mb-3 form-row top-row" controlId="formTileTitle">
                <Form.Label column sm="4">
                  <div className="row">
                    <div className="col-12">Title</div>
                  </div>
                </Form.Label>
                <Col sm="8">
                  <FroalaEditor tag='textarea'
                                config={{...config, ...{height: 30}}}
                                model={state.title}
                                onModelChange={setTitle}/>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3 form-row" controlId="formTileType">
                <Form.Label column sm="4">
                  Select type
                </Form.Label>
                <Col sm="8">
                  <Select id = "tileType" 
                              name="tileType"
                              value={tile_types_arr.filter(function(option) {
                                          return option.value === state.widget;
                                        })}
                              onChange={setWidget}
                              options={tile_types_arr}
                              />
                </Col>
              </Form.Group>
              {state.widget == "html" && <Form.Group as={Row} className="mb-3 form-row" controlId="formTileTypeHtml" id="formTileTypeHtml">
                <Form.Label column sm="4">
                  HTML Code
                </Form.Label>
                <Col sm="8">
                  <textarea
                    placeholder=""
                    className="form-control"
                    value={ state.options.html_code}
                    onChange={ e => setHtmlCode(e.target.value) }
                    autoFocus />
                </Col>
                </Form.Group>
              }
              {state.widget == "online_ordering" && <Form.Group as={Row} className="mb-3 form-row" controlId="formTileTypeOF" id="formTileTypeOF">
                <Form.Label column sm="4">
                  Select Order Form
                </Form.Label>
                <Col sm="8">
                  <Select id = "tileType" 
                              name="tileType"
                              onChange={ setOnlineForms }
                              options={props.onlineForms}
                              />
                </Col>
              </Form.Group>}
              {state.widget == "demo_clubs" && 
                <Form.Group as={Row} className="mb-3 form-row p-0" controlId="formReserve" id="formReserve">
                  <Form.Check 
                  name="enable_reservation"
                  value = {state.options.enable_reservation}
                  defaultChecked = {state.options.enable_reservation}
                  type='checkbox'
                  id="enable_reservation"
                  label="Enable Demo Reservation"
                  className="col-6 add-line-height-25 py-1"
                  onChange={handleOptionsCheckboxInputChange} 
                />
                </Form.Group>}
                {state.widget == "club_repairs" && 
                  <div className='row'>  
                    <Form.Group as={Row} className="mb-3 form-row p-0 py-1" controlId="formReserve" id="formReserve">
                      <Form.Check 
                      name="enable_club_repair_request"
                      value = {state.options.enable_club_repair_request}
                      defaultChecked = {state.options.enable_club_repair_request}
                      type='checkbox'
                      id="enable_club_repair_request"
                      label="Enable Club Repair Request"
                      className="col-6 add-line-height-25 py-1"
                      onChange={handleOptionsCheckboxInputChange} 
                    />
                    </Form.Group>
                </div>}
                {state.widget == "demo_clubs" && state.options.enable_reservation &&
                  <Form.Group as={Row} className="mb-3 form-row m-0" controlId="formDays" id="formDays">
                    <div className='col'>
                      <span className=''>
                        Maximum number of days in advance to allow reservations:
                      </span>
                      <input type="text"
                        className="mx-2"
                        style={{ width: 30 }}
                        defaultValue = {state.options.days_in_advances}
                        onChange={ e => setDays(e.target.value) }
                        autoFocus/>
                    </div>
                    
                  </Form.Group>}
                  {((state.widget == "club_repairs" && state.options.enable_club_repair_request) || (state.widget == "demo_clubs" && state.options.enable_reservation )) &&
                    <div className='row m-0'>
                      <div className='col-12'>
                      {state.widget == "demo_clubs" ? 
                        "Select Manager to receive reserve notifications:"
                        :
                        "Select Manager to receive new club repair request notifications:"
                        }
                      </div>
                      <div className='col-12 overflow-auto' style={{maxHeight: "100px"}}>
                        <div className='row'>
                          {props.managers.map((m, index) => (
                            <Form.Check
                              key={m.id}
                              name={m.value}
                              checked = { state.options.managers_reserve_notifications.indexOf(m.value) !== -1 }
                              type='checkbox'
                              id={m.value}
                              label={m.label}
                              className="col-6 add-line-height-25"
                              onChange={setManagers} 
                            />
                          ))}
                        </div>
                      </div>
                    </div>}
                    {state.widget == "demo_clubs" && state.options.enable_reservation && <div className='row mt-2'>
                    <div className='col'>
                      The return interval is <b>{props.demo_return_interval}</b> days. It can be customized by going to
                      <a className='link' style={{color: '#0d6efd', paddingLeft: '3px'}} href={props.demo_clubs_settings_url}><u>{"Settings -> Demo Clubs -> General"}</u></a>
                    </div>
                  </div>}
              {state.widget == "demo_clubs" && <Form.Group as={Row} className="mb-3 form-row p-0" controlId="formNotif" id="formNotif">
                  <Form.Check 
                  name="enable_availability_notification"
                  value = {state.options.enable_availability_notification}
                  defaultChecked = {state.options.enable_availability_notification}
                  type='checkbox'
                  id="enable_availability_notification"
                  label="Enable Availability Notifications"
                  className="col-6 add-line-height-25 py-1"
                  onChange={handleOptionsCheckboxInputChange} 
                />
                </Form.Group>}
              {state.widget == "member_profile" && <div className="row tableFixHead" style={{maxHeight: "400px"}}>
                <Table class="table-bordered">
                  <thead class="border-start border-end border-top table-lightgrey " style={{zIndex: 0}}>
                    <tr>
                      <th>Category</th>
                      <th>Field</th>
                      <th>Display?</th>
                      <th>Editable?</th>
                    </tr>
                  </thead>
                  <tbody class="border-start border-end border-bottom">
                    {(state.options.member_profile_data).map((cat, index) => 
                      ((!cat.category || cat.category_display) && 
                      <tr key={cat.key || Math.random()}>
                        <td class="bold">{cat.category ? '-' : cat.name}</td>
                        <td>{cat.category ? cat.name : '-'}</td>
                        <td><Form.Check 
                              name={'display_'+cat.key}
                              checked = {cat.display}
                              defaultChecked = {cat.display}
                              type='checkbox'
                              onChange={handleDisplayCheckboxInputChange} 
                            /></td>
                        <td>{
                          disableEnabledOption(cat) ?
                            !cat.category && <OverlayTrigger
                              key="editable_info"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-managers_notification`}>
                                  System fields can not be editable
                                </Tooltip>
                              }
                            >
                              <i className="gga-info-circle tooltip_info ps-1 fs-6" style={{position: 'initial'}}/>
                            </OverlayTrigger>
                            :
                            <Form.Check 
                              name={'editable_'+cat.key}
                              checked = {(disableEnabledOption(cat) ? false : cat.editable )}
                              defaultChecked = {(disableEnabledOption(cat) ? false : cat.editable )}
                              disabled = {(disableEnabledOption(cat) ? true : false )}
                              type='checkbox'
                              onChange={handleEditableCheckboxInputChange} 
                            />
                            }
                            </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>}
          </div>
        </div>
        </Form>
        {
          state.widget == "collage" && 
            <div className="row m-0 mt-2 mb-2">
              <div className="col-12 pe-0">
                <CollagePage collageTileSave={collageTileSave} colId = {props.id} column={state} blockId={props.blockId}  options= {state.options} displayActions={true}/>
              </div>
            </div>
          }
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={ e => { e.preventDefault(); props.cancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </button>

        <button
          className="btn gems_custom_button gems_orange_button float-end"
          onClick={handleSubmit} 
          disabled={(((state.widget == "club_repairs" && state.options.enable_club_repair_request) || (state.widget == "demo_clubs" && state.options.enable_reservation )) && (state.options.managers_reserve_notifications.length == 0)) || (state.widget == "html" && state.html_code == "")}
        >
          {window.I18n.t('multi_widgets.save')}
        </button>

        { ((state.widget == "club_repairs" && state.options.enable_club_repair_request) || (state.widget == "demo_clubs" && state.options.enable_reservation )) && (state.options.managers_reserve_notifications.length == 0) &&
          <OverlayTrigger
            key="managers_notification"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-managers_notification`}>
                Please select at least one manager!
              </Tooltip>
            }
          >
            <i className=" align-middle gga-info-circle tooltip_info float-end py-1 fs-6"/>
          </OverlayTrigger>
        }
      </ModalFooter>
    </div>
  )
}

EditColumns.propTypes = {
  widgets: PropTypes.array,
  blockId: PropTypes.any,
  position: PropTypes.string,
  createColumn: PropTypes.func.isRequired,
  addNewPage: PropTypes.func,
  cancel: PropTypes.func,
}

export default EditColumns
