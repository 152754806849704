import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
const { Header, Title, Body, Footer } = Modal
import formSerialize from 'form-serialize'

class GlgPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.handleSave = this.handleSave.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formRef = React.createRef()
    this.state = {
      submitted: false,
    }
  }

  getSaveButtonText() {
    if (this.state.submitted) {
      return this.props.saveDisableWith || this.props.saveButtonText
    } else {
      return this.props.saveButtonText
    }
  }

  getSaveButtonEnabled() {
    return this.props.saveButtonEnabled && !this.state.submitted
  }

  handleSave(event) {
    if (!this.props.formURL) {
      event.preventDefault()
    }
    if (this.props.onSave) {
      const data = formSerialize(this.formRef.current, { hash: true, empty: true })
      this.props.onSave(data, this.props.onClose)
    }
    if (!this.props.formURL && !this.props.manualClose) {
      this.props.onClose()
    }
  }

  handleSubmit() {
    this.setState({
      submitted: true,
    })
  }

  handleCustomButtonClick(callback) {
    callback()
    if (!this.props.manualClose) {
      this.props.onClose()
    }
  }

  render() {
    const TitleComponent = typeof(this.props.title) === 'string' ? (() => this.props.title) : this.props.title

    return (
      <Modal show={ this.props.show }
             onHide={ this.props.onClose }
             dialogClassName={ `glg-popup ${ this.props.modalClass || '' } ${ this.props.children ? '' : 'empty-body' }` }
             onExited={ this.props.onHidden }
             backdrop={ this.props.showBackdrop }
             size={ this.props.size }
             onShow={this.props.onShow}>
        <form action={ this.props.formURL } method="POST" remote="true" ref={ this.formRef }
              onSubmit={ this.handleSubmit }>
          <Header closeButton={ true }>
            <Title>
              <TitleComponent></TitleComponent>
              </Title>
          </Header>
          {this.props.children && (
            <Body>
              { this.props.children }
              </Body>)}
          <Footer>
            {this.props.showCloseButton && (
              <Button onClick={ this.props.onClose }>
                { this.props.closeButtonText }
                </Button>)}
            {this.props.customButtons && this.props.customButtons.map((button, index) => (
              <Button className={ button.class }
                      onClick={ () => this.handleCustomButtonClick(button.onClick) }
                      key={ index }
                      disabled={ button.disabled }>

                { button.text }
              </Button>
            ))}
            {this.props.showSaveButton && (
              <Button type="submit" bsStyle="primary"
                      className="save-button btsp_orange_button"
                      onClick={ this.handleSave }
                      disabled={ !this.getSaveButtonEnabled() }>
                { this.getSaveButtonText() }
                </Button>)}
          </Footer>
    </form>
  </Modal>  
    )}
}

GlgPopup.propTypes = {
  // Whether the popup should be visible
  show: PropTypes.bool.isRequired,
  // Whether the popup should have a backdrop
  showBackdrop: PropTypes.bool,
  // The text from the top of the popup
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]).isRequired,
  // Callback when the popup is closed (either from x and the Close button)
  onClose: PropTypes.func.isRequired,
  // If false, do not show Save button (default: true)
  showSaveButton: PropTypes.bool,
  // Callback when the Save button is clicked
  onSave: PropTypes.func,
  // Size of the modal
  size: PropTypes.string,
  // The text of the Save button
  saveButtonText: PropTypes.string,
  // Whether the Save button is enabled
  saveButtonEnabled: PropTypes.bool,
  // After clicking the Save button it will have this text
  saveDisableWith: PropTypes.string,
  // If false, do not show Close button (default: true)
  showCloseButton: PropTypes.bool,
  // The text of the Close button
  closeButtonText: PropTypes.string,
  // The URL where the form is submitted.
  formURL: PropTypes.string,
  // The class of Modal
  modalClass: PropTypes.string,
  // An array of extra buttons besides Close and Save
  customButtons: PropTypes.arrayOf(PropTypes.shape({
    // The text of the button
    text: PropTypes.string,
    // The class of the button
    class: PropTypes.string,
    // Callback when the button is clicked
    onClick: PropTypes.func,
  })),
  children: PropTypes.node,
  // Callback when the hide animation finished
  onHidden: PropTypes.func,
  // Callback for show
  onShow: PropTypes.func,
  // If true, the popup will not close automatically on save and
  // it can be done using the onSave callback parameter
  manualClose: PropTypes.bool,
}

GlgPopup.defaultProps = {
  showSaveButton: true,
  showBackdrop: true,
  saveButtonText: !window.I18n ? '' : window.I18n.t('buttons.save').charAt(0)
    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.save').slice(1)),
  saveButtonEnabled: true,
  showCloseButton: true,
  closeButtonText: !window.I18n ? '' : window.I18n.t('buttons.close').charAt(0)
    .toUpperCase() + (!window.I18n ? '' : window.I18n.t('buttons.close').slice(1)),
  manualClose: false,
  size: 'lg',
}

export default GlgPopup
