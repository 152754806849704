import React from 'react';
import DatePicker from "react-datepicker";
import { Spinner} from 'react-bootstrap';

function KeyInsight(props) {
  return (
    <div className='col p-20 add-margin-all-10 mb-0 text-center home_class' >
        <h4 className='text-start header'>KEY INSIGHT</h4>
        {props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <>
          <div className='row m-5 ms-3 me-3 mt-3 mb-1 text-start body_blue_component dark_blue'>
          {props.data.info.map((inf) => (
            <div className='mt-2 d-flex'>
            <span class="fa fa-circle order-1 p-1" style={{fontSize: "6px", lineHeight: "17px"}}></span>
            <span className='order-2 fs-6'>{inf}</span>
            </div>
            ))}
          </div>
          {props.data.categories.map((ct) => (
            <>
            <div className='row m-5 mb-0 ms-3 me-3 mt-2 p-2 ps-0 text-start body_blue_component dark_blue fs-5' style={{backgroundColor: "rgb(227, 238, 240)"}}>
              <b>{ct["name"]}</b>
            </div>
            {ct["items"].map((it) => (
              <div className='m-3 mt-1 d-flex p-2 ps-0 pb-0'>
                <span class="fa fa-circle order-1 p-1" style={{fontSize: "6px", lineHeight: "17px"}}></span>
                <span className='order-2 fs-6'>{it}</span>
              </div>
            ))}
            </>
          ))}
          </>
        }
      </div>
    )}
export default KeyInsight
