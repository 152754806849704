import { connect } from 'react-redux'
import Header from '../components/Header'
import { switchView, applyFilters, removeAllFilters } from '../actions'


const mapStateToProps = (state) => ({
  view: state.stock_order.view,
  alfa_categories: (state.stock_order.categories_list_for_filter || []).filter((cat) => cat.is_top_category),
  filters_list: state.stock_order.filters_list
})

const mapDispatchToProps = (dispatch) => ({
  switchView: view => dispatch(switchView(view)),
  applyFilters: data => dispatch(applyFilters(data)),
  removeAllFilters: () => dispatch(removeAllFilters()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)