import { connect } from 'react-redux'
import ClubRepair from '../components/ClubRepair'
import { load_component_data } from '../actions'

const mapStateToProps = (state) => ({
  components: state.dashboard.components,
  customer_id: state.dashboard.customer_id,
  to: state.dashboard.to,
  from: state.dashboard.from,
  reference_date: state.dashboard.reference_date,
  date_format: state.dashboard.date_format,
  moduleKey: state.dashboard.module_key,
  refresh_date: state.dashboard.refresh_date,
  isLoading: state.dashboard.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  load_component_data: data => dispatch(load_component_data(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubRepair)