import { connect } from 'react-redux'
import {
  changeSpecialInstructions,
  removeProductFromCart,
  updateQuantity,
  openErrorModal,
} from "../actions";
import CartTable from '../components/CartTable';
import CartList from "../components/CartList";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  formType: state.general_settings.form_type,
  displaySpecialInstructions: state.general_settings.display_special_instructions,
  currencySymbol: state.general_settings.currency_symbol,
  formClosed: state.form_state.closed,
  cartProducts: Object.values(state.cart.products),
  itemsInCart: state.cart.itemsInCart,
  products: state.products,
  checkoutItemsLimit: state.general_settings.checkout_items_limit,
  maxCredits: state.general_settings.total_credits,
  totalCost: state.cart.totalCost.toFixed(2),
})

const mapDispatchToProps = (dispatch) => ({
  changeSpecialInstructions: (product, special_instructions) => dispatch(changeSpecialInstructions(product, special_instructions)),
  removeProductFromCart: (product) => dispatch(removeProductFromCart(product)),
  updateQuantity: (product, old_quantity, quantity) => dispatch(updateQuantity(product, old_quantity, quantity)),
  openErrorModal: (errorHTML) => dispatch(openErrorModal(errorHTML)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartList)
