import React from 'react';
import { Col, Row } from 'react-bootstrap';

function BulkEmailDetails(props) {

  const {template} = props;

  return (
    <div>
      <Row className='mb-3'>
        {template.scheduled_communication && template.paused && <Col xs={12}>
          <b className="color_red">Email is paused</b>
        </Col>}

        <Col xs={12} >
          <b>Recipients: </b>
          {template.recipients}
        </Col>

        {!template.is_draft && <Col xs={12} >
          <b>{template.scheduled_communication ? "Scheduled Time: " : "Sent Time: "}</b>
          <span> {template.nice_format_date} </span>
          <span className='icon-record'/>
          <span> {template.nice_format_time} </span>
          <span>{template.time_zone}</span>
          
        </Col>}
        <Col xs={12}>
          <hr/>
        </Col>
        
        {!template.is_draft && !template.scheduled_communication && <Col xs={12} className='d-flex align-items-end'>
          <div className='me-4 text-center'>
            <div><b>{template.emails_sent}</b></div>
            <div>Sends</div>
          </div>
          <div className='me-4 text-center'>
            <div><b>{template.open_rate}</b></div>
            <div>Opens</div>
          </div>
          <div className='me-4 text-center'>
            <div><b>{template.click_rate}</b></div>
            <div>Clicks</div>
          </div>

          <div className='flex-grow-1 align-self-end text-end'>
            <a
              className='btn gems_custom_button gems_orange_button'
              href={template.emails_delivery_report_link} 
              data-method="GET"
            >
              Email Delivery Reports
            </a>
          </div>
          
        </Col>}

        <Col xs={12}>
          <hr/>
        </Col>
      </Row>
    </div>
  )
}

export default BulkEmailDetails