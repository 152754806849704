import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import _ from 'lodash';
import UploadFile from './UploadFile';

function Summary(props) {
  return ( <div className="col">
  <Tabs
      defaultActiveKey={props.btn_name != "Create" && props.btn_name != "Update" ? "summary" : "attachments"}
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
    {props.btn_name != "Create" && props.btn_name != "Update" && <Tab eventKey="summary" title="Summary">
      <Col className="h-100 table-responsive activity_log_content tableFixHead">
        <Table responsive>
          <thead>
          </thead>
          <tbody>
            <tr>
              <td> 
                <b>Categories</b>
              </td>
              <td>
                {(props.state.stock_order_categories).map((cat, index) => (
                  <div key={cat.id}>
                  <span>{(index+1) + '. ' + cat.category_name}</span>
                  <br></br>
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <td> 
                <b>Total Number of Items</b>
              </td>
              <td>
                {props.state.total_nr_of_items}
              </td>
            </tr>
            <tr>
              <td> 
                <b>Total Cost</b>
              </td>
              <td>
                {props.state.total_all_cost}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Tab>}
    <Tab eventKey="attachments" title="Files">
      <Table responsive>
        <thead>
          <tr>
            <th>File</th>
            <th>Date</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(props.files.length > 0) && 
            props.files.map((file) => (
              <tr key={file.id} className={props.state["remove_file_with_id_"+file.id] ? "d-none" : ""}>
                <td> 
                  <a className="link" target= '_blank' download={file.name} href={file.url}>{file.name}</a>
                </td>
                <td>{file.created_at}</td>
                <td>
                <Form.Control as="textarea" rows={3} 
                  name={"file_description_for_"+file.id}
                  defaultValue={file.description || ""}
                  disabled={props.view_only_stock_orders}
                  onChange={props.handleInputChange}/>
                </td>
                <td>
                  {!props.view_only_stock_orders && <a className="link color_red" name={"remove_file_with_id_"+file.id} onClick={props.handleInputChange} assigned="remove_file">Remove</a>}
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <div className="text-center w-100">
        <Spinner className="d-none" animation="border" variant="warning" id="upload_spinner"/>
      </div>
      {!props.view_only_stock_orders && <UploadFile uploadFile={props.uploadFile} customer_id={props.customer_id} item_id={props.state.id || "new"}/>}
      
    </Tab>
    {props.btn_name != "Create" && <Tab eventKey="activity_log" title="Activity Log">
      <Col className="h-100 table-responsive activity_log_content tableFixHead">
        <Table responsive>
          <thead>
            <tr>
              <th>Date / Time</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {(props.state.activity_logs.length > 0) && 
              props.state.activity_logs.map((log) => (
                <tr key={log["id"]}>
                  <td> 
                    {log["date_time"]}
                  </td>
                  <td>
                    {log["message"]}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Col>
    </Tab>}
  </Tabs>
    <Form.Group className="mb-3" controlId="formInstVendor">
      <Form.Label>
        Special Instructions to Vendor
      </Form.Label>
      <Form.Control as="textarea" rows={3} 
                  name='additional_description_to_vendor'
                  value= {props.state.additional_description_to_vendor || ""}
                  disabled={props.view_only_stock_orders}
                  onChange={props.handleInputChange}/>
    </Form.Group>
    <Form.Group className="mb-3" controlId="formInternalNotes">
      <Form.Label>Internal Notes</Form.Label>
      <Form.Control as="textarea" rows={3} 
                    name='internal_notes'
                    value={props.state.internal_notes || ""}
                    disabled={props.view_only_stock_orders}
                    onChange={props.handleInputChange}/>
    </Form.Group>
  </div>)
} 
export default Summary