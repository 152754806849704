import { connect } from "react-redux";
import CollagePageTile from "../presentational/collage_page_tile";
import _ from "lodash"
import { setActiveBlock, setActivePosition, displayMode, DISPLAY_MODES, updateCollageItems } from '../../actions'
import Column from "../presentational/column";

const mapStateToProps = (state, props) => {
  return ({
  data: {
    member_id: state.filters.member_id,
  },
  editMode: state.filters.editMode,
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  general_settings: state.general_settings,
  options: props.options,
  colId: props.colId,
  blockId: props.blockId,
  tileType: props.tileType,
  column: _.find(state.columns, (col) => col.id == props.colId ) || props.column,
  block: _.find(state.blocks, (col) => col.id == props.blockId ),
  collageTileSave: props.collageTileSave
})};

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(setActivePosition(null))
    dispatch(setActiveBlock(null))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  updateCollageItems: (_id, _items) => {
    dispatch(updateCollageItems(_id, _items))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CollagePageTile);
