import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Nav, Navbar, Spinner} from 'react-bootstrap'
import {Form} from 'react-bootstrap';

import ProfileSetupBlocksContainer from '../containers/ProfileSetupBlocksContainer'
import ManageTabsModalContainer from '../containers/ManageTabsModalContainer'
import AddTabModalContainer from '../containers/AddTabModalContainer'

import defaultAvatar from '../../../../assets/images/fallback/avatar.png'

function Body(props){

	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
   
	};

  const getMemberNotifications = () => {
    let memberSettings = {}

    memberSettings.email = {}
    memberSettings.sms = {}

    Object.keys(props.notification_settings.email).map(object => {
      Object.keys(props.notification_settings.email[object]).map(key => {
          memberSettings.email[key] = props.notification_settings.email[object][key].value
      })
    })
    Object.keys(props.notification_settings.sms).map(object => {
      Object.keys(props.notification_settings.sms[object]).map(key => {
          memberSettings.sms[key] = props.notification_settings.sms[object][key].value
      })
    })

    return memberSettings
  }

  const [memberMetadata, setMemberMetadata] = useState(props.member_metadata_values);
  const [updatedMemberMetadata, setUpdatedMemberMetadata] = useState([])

  const [memberNotifications, setMemberNotifications] = useState(getMemberNotifications())

  const [initialProfilePictureUrl, setInitialProfilePictureUrl] = useState(props.profile_picture_url)

  const handleOpenManageTabsModal = (event) => {
    props.openManageTabsModal()
  }

  const handleAddNewTabClick = () => {
    props.openAddTabModal()
  }

  const handleSaveMemberChangesClick = () => {
    props.saveMemberChanges(JSON.stringify(props.memberInfo), JSON.stringify(memberMetadata), JSON.stringify(updatedMemberMetadata), JSON.stringify(memberNotifications), props.profile_picture_url === null, props.centralPage)
  }

  const handleMemberEditProfileClick = () => {
    props.switchView("member_edit")
  }

  const { modal_manage_tabs_open, modal_add_tab_open } = props

  const myInput = useRef(null);

  const clickElement = () => {
    // To simulate a user focusing an input you should use the
    // built in .focus() method.
    myInput.current?.click();

    // To simulate a click on a button you can use the .click()
    // method.
    // myInput.current?.click();
  }

  useEffect(() => {
    if(isSelected)
      handleSubmission()
  });

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    formData.append('id', props.member_id);
    formData.append('customer_id', props.customer_id);
    // formData.append('stock_order_item_id', props.stock_order_item_id);
    // formData.append('stock_order_category_id', props.stock_order_category_id);
    props.uploadProfilePicture(formData)
    setSelectedFile(null)
    setIsSelected(false)
    document.getElementById('files_id').value= null;
	};

  const handleClearPhotoClick = () => {
    props.clearPhoto()
  }
    
  return (
    <div className="bg-white h-100 align-content-start" id="page_setup_container" style={{overflowX: "hidden", overflowY: "visible"}}>
      <Row className="mb-3 header">
        <Col className='col-12 d-flex header_flex' >
          <div>
            <img className="my-3 rounded-3" id="member_profile_picture" src={props.profile_picture_url || defaultAvatar} />
          </div>
          <div className="d-none" id="member_profile_picture_spinner" style={{padding: "39px 25px", height: "fit-content"}}>
            <Spinner style={{color: "#03737a"}} animation="border" id="upload_spinner" />
          </div>      
          <div id="member_info_container" className="px-4 py-3 d-flex flex-column">
            { props.view_mode == "member_edit" && <Row className="pb-2">
              <button
                className="float-start ms-2 btn gems_custom_button"
                style={{width: "fit-content"}}
                aria-label="manage_tabs"
                onClick={() => handleSaveMemberChangesClick()}
              >
                Update
              </button>
            </Row>}
            { props.view_mode == "member_preview" && props.member_view && <Row className="pb-2">
              <button
                className="float-start ms-2 btn gems_custom_button"
                style={{width: "fit-content"}}
                aria-label="manage_tabs"
                onClick={() => handleMemberEditProfileClick()}
              >
                Edit Profile
              </button>
            </Row>}
            <h3 className="fw-bold mb-1">{props.member_info.first_name + " " + props.member_info.last_name}</h3>
            <ul className="px-3 fw-bold mb-0">
              {props.member_info.email !== null && <li>
                {props.member_info.email}
              </li>}
              {props.member_info.cell_phone !== null && <li>
                {props.member_info.cell_phone}
              </li>}
            </ul>
          </div>
        </Col>
        { props.view_mode == "member_edit" &&
          <Fragment>
            <Col className='col-12'>
              <Form.Group className="mb-2" controlId="formUploadAttch">
                <input className = "d-none" type="file" name="file" id="files_id" onChange={changeHandler} ref={myInput} accept={".jpg,.jpeg,.png,.webp,.gif"}/>    
                <button
                    className="btn gems_custom_button"
                    style={{width: "fit-content", backgroundColor: "white", color: "#333f48", borderColor: "#333f48"}}
                    aria-label="manage_tabs"
                    onClick={clickElement}
                  >
                    <i className='fa fa-camera'/> Change Photo
                </button>
              </Form.Group>
            </Col>
            {props.profile_picture_url && <Col className='col-12 mt-1'>
                <button
                  className="btn gems_custom_button"
                  style={{width: "fit-content", backgroundColor: "white", color: "#dc010f", borderColor: "#dc010f"}}
                  aria-label="manage_tabs"
                  onClick={() => handleClearPhotoClick()}
                >
                  <i className='fa fa-trash'/> Clear Photo
                </button>
            </Col>}
          </Fragment>
        }
      </Row>
      <Row className="d-flex body_container">
        <div className="left_column" onSelect={ () => { setState({ buttonMenuClass: 'down' }) } }>
          <Navbar expand="md">
              <div className="w-100">
                <Navbar.Toggle style={ {backgroundColor: "#FFFFFF"}} aria-controls="navbarScroll">
                <div className="d-flex flex-row align-items-center">
                  <div className="pe-0 me-0" style={{paddingLeft: "5px", width: "90%"}}>
                    {props.current_tab.title}
                  </div>
                  <div className="ps-0 ms-0" style={{paddingRight: "5px", width: "10%"}}>
                    <i className="float-end fa fa-caret-down icon_dropdown_search fs-4" id="icon_dropdown_search"></i>
                  </div>
                </div>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbarScroll">
                  <Nav className='d-flex flex-column'
                    navbarScroll
                  >
                    {
                      props.all_tabs.map(tab =>
                        <Nav.Link key={`tabs_${tab.id}`} className="p-0">
                          <div className={props.current_tab.id == tab.id ? 'active' : ''} 
                                key={tab.id} eventkey={ `mw-tab-${tab.id}` } 
                                onClick={ () => props.setCurrentTab(tab.id) } 
                                >
                            <a className='section-title nav-link p-2 ps-3'>
                              { tab.title }
                            </a>
                          </div>
                        </Nav.Link>
                        )
                      }
                      { props.view_mode == "manager" && <Nav.Link key={`add_tab`} className='p-0'>
                      <div className={'p-0'} 
                            key={"add_tab_key"} eventkey={ `mw-tab-add_tab` } 
                            onClick={handleAddNewTabClick} 
                            style={{backgroundColor: "#FFFFFF"}}
                            >
                        <a className='section-title nav-link p-2 ps-3'>
                          Add Tab
                        </a>
                      </div></Nav.Link>}
                  </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
          { props.view_mode == "manager" && <Row className="m-0">
            <Col id="manage_tabs_btn_container">
              <button
                id="manage_tabs_btn"
                className="float-start ms-2 mt-2 btn gems_custom_button"
                aria-label="manage_tabs"
                onClick={handleOpenManageTabsModal}
              >
                Manage Tabs
              </button>
            </Col>
          </Row>}
        </div>
        <div className={props.current_tab.title === "Activity" ? 'right_column flex-grow-1 pb-4 activity_tab_container' : 'right_column flex-grow-1 pb-4'}>
          <ProfileSetupBlocksContainer memberMetadata={memberMetadata} setMemberMetadata={setMemberMetadata} updatedMemberMetadata={updatedMemberMetadata} setUpdatedMemberMetadata={setUpdatedMemberMetadata} memberNotifications={memberNotifications} setMemberNotifications={setMemberNotifications} memberInfo={props.memberInfo} setMemberInfo={props.setMemberInfo} member_info={props.member_info} originalMemberMetadata={props.member_metadata_values} />
        </div>
      </Row>

      {modal_manage_tabs_open && <ManageTabsModalContainer/>}
      {modal_add_tab_open && <AddTabModalContainer/>}
    </div>
  )
}

export default Body
