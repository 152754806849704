import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
class Header extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedCategoryLabel: null,
      displayAllLabel: false
    }
  }

  handleCategoryClick = (id, category_name) => {
    this.setState({ displayAllLabel: true, selectedCategoryLabel: id != '' ? id : 'all' })

    this.props.applyFilters([id != '' ? { category_id: id, category_name: category_name } : {}])
  }

  handleAllClick = () => {
    this.setState({ selectedCategoryLabel: 'all' })

    this.props.removeAllFilters()
  }

  render() {
    return (
      <div className="row custom_name border-bottom border-dark">
        <div className='col-3 p-0'>
          <h5 className="page-title ms-0">
            {" ORDERS "}
            <span className="icon-record" />
            {" STOCK ORDERS "}
            <span className="icon-record" />
            {" DASHBOARD "}
          </h5>
        </div>

        <div className='col border-end border-dark border-1 d-flex flex-wrap justify-content-end pb-1 mb-3'>
          <span className={`px-2 cursor_pointer all_categories ${this.state.displayAllLabel ? '' : 'd-none'}`} style={{ borderLeft: '2px solid grey' }}>
            <b className={`${this.state.selectedCategoryLabel == 'all' || this.props.filters_list.length == 0 ? 'color_orange' : 'color_gray'} searchable_category`} data-id={''} data-category-name={'all'} onClick={this.handleAllClick}>{" ALL "}</b>
          </span>

          {(this.props.alfa_categories || []).map((c, index_c) => (
            <span className='px-2 cursor_pointer' style={{ borderLeft: `2px solid ${c.color}` }}>
              <b className={`searchable_category ${this.state.selectedCategoryLabel == c.value && this.props.filters_list.map((it) => it.category_id == c.value).length > 0 ? 'color_orange' : 'color_gray'}`} data-id={c.value} data-category-name={c.label} onClick={() => this.handleCategoryClick(c.value, c.label)}>{`${c.label.toUpperCase()}`}</b>
            </span>
          ))}
        </div>

        <div className='col ps-0' style={{ maxWidth: 100}}>
          <p className={"cursor_pointer fa fa-calendar float-end fs-5 " + (this.props.view == "calendar" ? "color_orange" : "")} id="calendar_view" data-tbody_show="#calendar_view_dashboard"
            onClick={() => this.props.switchView("calendar")}>
          </p>
          <p className={"cursor_pointer fa fa-th-list float-end fs-5 me-2 " + (this.props.view == "list" ? "color_orange" : "")} id="table_view" data-tbody_show="#table_view_dashboard"
            onClick={() => this.props.switchView("list")} >
          </p>
          <p className={"cursor_pointer fa fa-th-large float-end fs-5 me-2 " + (this.props.view == "pipedrive" ? "color_orange" : "")} id="icons_view" data-tbody_show="#icons_view_dashboard"
            onClick={() => this.props.switchView("pipedrive")}>
          </p>
        </div>
      </div>

    )
  }
}

export default Header
