import React from 'react';
import { Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap'

function NotificationsTab(props) {

  const beautifyKey = (key) => {
    const splitKey = key.split("_");

    for (let i = 0; i < splitKey.length; i++) {
      splitKey[i] = splitKey[i][0].toUpperCase() + splitKey[i].substr(1);
    }

    return splitKey.join(" ");
  }

  const handleEmailNotificationChange = (event, key) =>{
    const enabled = event.target.checked;

    const updatedHash = {...props.memberNotifications}
    updatedHash.email = {...updatedHash.email}
    updatedHash.email[key] = enabled

    props.setMemberNotifications(updatedHash)
  }

  const handleSmsNotificationChange = (event, key) =>{
    const enabled = event.target.checked;

    const updatedHash = {...props.memberNotifications}
    updatedHash.sms = {...updatedHash.sms}
    updatedHash.sms[key] = enabled

    props.setMemberNotifications(updatedHash)
  }

  return(
    <Row className="w-100">
      <Col sm="12" xl="6">
        <Row className='mb-3'>
          <Col sm="12" className="no_padding_top">
            <h3 className="no_margin_top">
              Emails
            </h3>
          </Col>
        </Row>
        {Object.keys(props.notification_settings.email).map(object =>
          <div className='mb-3'>
            <Row>
              <Col sm="12">
                <h5 className="add_padding_bottom_5 border_bottom_1">
                  {beautifyKey(object)}
                </h5>
              </Col>
            </Row>
            {Object.keys(props.notification_settings.email[object]).map(key => 
              <Row>
                <Col sm="12">
                  <Row className="m-0">
                    <Col sm="9" className="p-0 d-flex align-items-center">
                      {props.notification_settings.email[object][key].settings.name}  
                    </Col>
                    <Col sm="3" className="p-0">
                      <div className="form-check form-switch d-flex justify-content-end m-0 p-0">
                        <input
                          type="checkbox"
                          defaultChecked={props.memberNotifications.email[key]}
                          data-id={key}
                          disabled={props.view_mode !== "member_edit" || !props.notification_settings.email[object][key].customer_enabled}
                          className="form-check-input font_size_1_25_rem email_notification_toggle"
                          onChange={(event) => handleEmailNotificationChange(event, key)}
                        />
                      </div>
                      {!props.notification_settings.email[object][key].customer_enabled && <Col sm="3" style={{paddingTop: "0.15rem"}}>
                        <div className="col-1 p-0">
                          <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-sms_${key}`}>
                                  Notifications of this type are turned off for all members
                                </Tooltip>
                              }
                          >
                            <i className=" align-middle gga-info-circle tooltip_info"/>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Col>
      <Col sm="12" xl="6">
        <Row className='mb-3'>
          <Col sm="12" className="no_padding_top">
            <h3 className="no_margin_top">
              Text Messages
            </h3>
          </Col>
        </Row>
        {Object.keys(props.notification_settings.sms).map(object =>
          <div className='mb-3'>
            <Row>
              <Col sm="12">
                <h5 className="add_padding_bottom_5 border_bottom_1">
                  {beautifyKey(object)}
                </h5>
              </Col>
            </Row>
            {Object.keys(props.notification_settings.sms[object]).map(key => 
              <Row>
                <Col sm="12">
                  <Row className="m-0">
                    <Col sm="9" className="p-0 d-flex align-items-center">
                      {props.notification_settings.sms[object][key].settings.name}  
                    </Col>
                    <Col sm="3" className="p-0">
                      <div className="form-check form-switch d-flex justify-content-end m-0 p-0">
                        <input
                          type="checkbox"
                          defaultChecked={props.memberNotifications.sms[key]}
                          data-id={key}
                          disabled={props.view_mode !== "member_edit" || !props.notification_settings.sms[object][key].customer_enabled}
                          className="form-check-input font_size_1_25_rem email_notification_toggle"
                          onChange={(event) => handleSmsNotificationChange(event, key)}
                        />
                      </div>
                      {!props.notification_settings.sms[object][key].customer_enabled && <Col sm="3" style={{paddingTop: "0.15rem"}}>
                        <div className="col-1 p-0">
                          <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-sms_${key}`}>
                                  Notifications of this type are turned off for all members
                                </Tooltip>
                              }
                            >
                            <i className=" align-middle gga-info-circle tooltip_info"/>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Col>
    </Row>  
  )
}
export default NotificationsTab