import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Toast, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ClubRepairContent from './club_repair_content'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class ClubRepairsTile extends Component {
  constructor(props) {
    super(props)
  };
  
  handleRequestRepair = () => {
    this.props.setActiveColumn(this.props.colId)
    this.props.getRequestRepair()
  }
  
  render() {
    return (  
        <div className="club_repairss_tile h-100" style={{overflowY: 'auto', overflowX: 'hidden'}}>
          <div className='row'>
            <div className='col p-0 mt-1'>
              {this.props.enable_club_repair_request && <a
                className="btn gems_custom_button ms-2 float-end"
                style={{backgroundColor: this.props.schemaColor, color: getComplementaryColor((this.props.schemaColor))}}
                onClick={this.handleRequestRepair}
              >
                Request Club Repair
              </a>}
            </div>
          </div>
          <div className='row'>
            <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
              <span>CURRENT CLUB REPAIRS</span>
            </div>
            {this.props.data.current_clubs.map((club, index) => (
              <ClubRepairContent key={`${club.id}_${index}`} club={club} maxWidthChildren={this.props.maxWidthChildren}/>
              ))}
            <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
              <span>REQUESTED CLUB REPAIRS</span>
            </div>
            {this.props.data.requested_repairs.map((club, index) => (
              <ClubRepairContent key={`${club.id}_${index}`} club={club} maxWidthChildren={this.props.maxWidthChildren}/>
              ))}
            <div className="col-12 text-center my-2 py-1 px-0 custom_title_tiles white">
              <span>PAST CLUB REPAIRS</span>
            </div>
            {this.props.data.past_clubs.map((club, index) => (
              <ClubRepairContent key={`${club.id}_${index}`} club={club} maxWidthChildren={this.props.maxWidthChildren}/>
              ))}
        </div>
      </div>
    )
  }
}

export default ClubRepairsTile
