import { connect } from 'react-redux'
import UnsentTextMessagesSection from '../components/UnsentTextMessagesSection'
import { paginateUnsentTextMessages, openCommunicationTemplateModal, unscheduleTextMessage, deleteDraftTextMessage, pauseScheduledTextMessage, resumeScheduledTextMessage } from '../actions'


const mapStateToProps = (state) => ({
  text_messages: state.scheduled_text_messages.text_messages,
  quick_search_value: state.dashboard.quick_search_value,
  isLoading: state.scheduled_text_messages.isLoading,
  total_pages: state.scheduled_text_messages.scheduled_text_messages_total_pages,
  page: state.scheduled_text_messages.page
})

const mapDispatchToProps = (dispatch) => ({
  paginateUnsentTextMessages: () => dispatch(paginateUnsentTextMessages()),
  showBulkTemplate: (data) => dispatch(openCommunicationTemplateModal(data, "scheduled_text_message")),
  unscheduleTextMessage: (data) => dispatch(unscheduleTextMessage(data)),
  deleteDraftTextMessage: (data) => dispatch(deleteDraftTextMessage(data)),
  pauseScheduledTextMessage: (data) => dispatch(pauseScheduledTextMessage(data)),
  resumeScheduledTextMessage: (data) => dispatch(resumeScheduledTextMessage(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsentTextMessagesSection)