import { connect } from 'react-redux'
import Page from '../presentational/page'
import {removeInfoMessage, saveGeneralSettings} from '../../actions'

const mapStateToProps = state => ({
  activeTab: state.filters.active_tab,
  tabs: state.tabs,
  blocks: state.blocks,
  displayMode: state.filters.display_mode,
  member_view: state.filters.member_view,
  editMode: state.filters.editMode,
  general_settings: state.general_settings,
  state: state,
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  infoMessage: state.filters.infoMessage,
  preview: state.filters.preview,
  new_template: state.filters.new_template,
  copy_template: state.filters.copy_template,
  have_settings: state.filters.have_settings,
  customer_template: state.filters.customer_template,
  editSettings: state.filters.edit_settings,
})

const mapDispatchToProps = (dispatch) => ({
  removeInfoMessage: () => dispatch(removeInfoMessage()),
  saveGeneralSettings: ( data ) => dispatch(saveGeneralSettings(data))
})

export default connect(
  mapStateToProps,mapDispatchToProps
)(Page)
