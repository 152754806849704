import { combineReducers } from 'redux'
import form_state from './form_state'
import cart from './cart'
import customer from './customer'
import products from './products'
import general_settings from './general_settings'


export default combineReducers({
  products,
  form_state,
  cart,
  customer,
  general_settings
})
