import React, { useId } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FiLink2 } from 'react-icons/fi'
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from 'react-color'; //import the desired picker
import { useState, useEffect } from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import UploadFile from './UploadFile';
import Summary from './Summary';
import CategoryDropdown from './CategoryDropdown';
import QuickEntry from './QuickEntry';
import DetailedEntryContainer from '../containers/DetailedEntryContainer';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


function StockOrderModal(props) {

  //TO DO here initialize inititial state based on properties!!
  //items: { stock_order_categories: [], StockCategoryOutline: {}, ... }
  const initialState = {
    detailed_entry: true,
    ...props.item,
    total_items: 0,
    total_cost: 0.0,
    is_similar_stock_order: false
  }

  const [state, setState] = useState(initialState);
  const forceUpdate = React.useCallback(() => setState({}), []);
  //TO DO stock order modal for simplicity be initaliazed when data is available props.item etc and destroyed in parent
  //use effect is needed to update the state only if state is blank - can't tell otherwise where the udpate is comming from
  //since useeffect triggers as didmount / didupdate combined
  useEffect(() => {
    updateQuantities()
  }, [state.stock_order_categories])

  const presetColors = ['#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505',
    '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000',
    '#4A4A4A', '#9B9B9B']

  const handleQuantity = (soc_id, soi_id, size, qty) => {
    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)

    if (soi_id) {
      let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
      if (size) {
        let soq_index = _.findIndex(stock_order_categories[soc_index].stock_order_items[soi_index].stock_order_quantities, (q) => q.size == size)
        if (soq_index != -1) {
          setState(update(state, {
            stock_order_categories: {
              [soc_index]: {
                stock_order_items: {
                  [soi_index]: {
                    stock_order_quantities: {
                      [soq_index]:
                        { qty: { $set: qty } }
                    }
                  }
                }
              }
            }
          }))
        } else {
          setState(update(state, {
            stock_order_categories: {
              [soc_index]: {
                stock_order_items: {
                  [soi_index]: {
                    stock_order_quantities : {
                      $push : [{ id: uuidv4(), size: size, qty: qty}]
                    }
                  }
                }
              }
            }
          }))
        }
      }
      else
        setState(update(state, {
          stock_order_categories: {
            [soc_index]: {
              stock_order_items: {
                [soi_index]: {
                  qty: { $set: qty }
                }
              }
            }
          }
        }
        ))
    }
    else
      setState(update(state, {
        stock_order_categories: {
          [soc_index]: {
            qty: { $set: qty }
          }
        }
      }
      ))
  }

  const handleInputChange = (soc_id, soi_id, name, value, metadatum = null) => {
    if (soc_id) {
      let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)
      if (soi_id) {
        let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
        if (metadatum) {
          let m_index = _.findIndex(stock_order_categories[soc_index].stock_order_items[soi_index].attributes, (m) => m.metadatum_id == name)
          if (m_index == -1)
            setState(update(state, {
              stock_order_categories: {
                [soc_index]: {
                  stock_order_items: {
                    [soi_index]: {
                      attributes: { $push: [{ metadatum_id: name, values: value }] }
                    }
                  }
                }
              }
            }))
          else
            setState(update(state, {
              stock_order_categories: {
                [soc_index]: {
                  stock_order_items: {
                    [soi_index]: {
                      attributes: {
                        [m_index]: { $set: { metadatum_id: name, values: value } }
                      }
                    }
                  }
                }
              }
            }))
        }
        else
          setState(update(state, {
            stock_order_categories: {
              [soc_index]: {
                stock_order_items: {
                  [soi_index]: {
                    [name]: { $set: value }
                  }
                }
              }
            }
          }))
      }
      else
        setState(update(state, {
          stock_order_categories: {
            [soc_index]: {
              [name]: { $set: value }
            }
          }
        }))
    }
    else
      if(name.includes("remove_file_with_id_"))
        setState({...state, [name]: "remove" });
      else
        setState({...state, [name]: value });
  }

  const handleSizingSelectChange = (value, soc_id) => {
    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)
    // reset qunatities values
    let stock_order_items_arr = [...stock_order_categories[soc_index].stock_order_items]

    stock_order_items_arr.map((soi) => {
      if (soi.stock_order_quantities)
        soi.stock_order_quantities = []
    })
    setState(update(state, {
      stock_order_categories: {
        [soc_index]: {
          stock_order_sizing_table_id: { $set: value }
        }
      }
    }))
  }

  const handleCancelDateChange = (event) => {
    setState({ ...state, cancellation_date: event });
  }

  const handleColorChange = (event) => {
    setState({ ...state, color: event.hex });
  }

  const handleColorGroupChange = (soc_id, soi_id, hex_value) => {
    if (soc_id) {
      let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id)
      if (soi_id) {
        let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)

        setState(update(state, {
          stock_order_categories: {
            [soc_index]: {
              stock_order_items: {
                [soi_index]: {
                  color_group: { $set: hex_value }
                }
              }
            }
          }
        }))
      }
    }
  }

  const handleLogoChange = (soc_id, soi_id, logo_id, lgos) => {
    let logos_index = _.findIndex(state.logos, (l) => l.stock_order_category_id == soc_id && l.stock_order_item_id == soi_id)
    if(soc_id){
      let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id)
      if(soi_id){
        let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
        if(logos_index == -1)
          setState(update(state, {
            stock_order_categories: {
              [soc_index] : {
                stock_order_items : {
                  [soi_index]: {
                    logo_id : { $set : logo_id }
                    } 
                  }
                } 
              },
            logos: { $push : [lgos] }
            }))
        else
          setState(update(state, {
            stock_order_categories: {
              [soc_index] : {
                stock_order_items : {
                  [soi_index]: {
                    logo_id : { $set : logo_id }
                    } 
                  }
                } 
              },
            logos: { [logos_index] : {$set : lgos }}
            }))
        }
      }
  }

  const handleEarlyCancelDateChange = (event) => {
    setState({ ...state, early_cancel_date: event });
  }

  const handleCancelCutDateChange = (event) => {
    setState({ ...state, end_date: event });
  }

  const handleStartDateChange = (date) => {
    let updates = { start_date: date }

    //initialze end date 30 days after start date
    if (!state.end_date && date) {
      let new_cancel_date = new Date(date)

      new_cancel_date.setDate(date.getDate() + 30)
      updates["end_date"] = new_cancel_date
    }
    setState({ ...state, ...updates });
  }

  const handleOrderedDateChange = (event) => {
    setState({ ...state, ordered_date: event });
  }
  const handleShipDateChange = (event) => {
    setState({ ...state, expected_ship_date: event });
  }
  const handleExpDeliveryDateChange = (event) => {
    setState({ ...state, expected_delivery_date: event });
  }


  const handleVendorCancelDateChange = (event) => {
    setState({ ...state, vendor_cancel_date: event });
  }

  const handleDeliveredDateChange = (event) => {
    setState({ ...state, delivered_date: event });
  }

  const handleVendorSelectChange = (event) => {
    let po = state.order_number

    if (props.btn_name != "Update")
      po = props.po + '-' + event.label.replaceAll(' ', '').substring(0, 20);

    setState({ ...state, vendor_id: event.value, order_number: po });
  }

  const handleNewStockCategory = (id) => {
    if (id) {
      let sc_index = _.findIndex(stock_order_categories, (c) => c.category_id == id && !c.deleted)

      if(sc_index == -1)
        {
          let cat_name = props.categories_by_id[id].full_name
          let new_category = {category_id: id, stock_order_items: [{ id: uuidv4(), stock_order_quantities: [], line: 1, attributes: [] }], category_name: cat_name, qty: 0, total_cost: 0, attributes: props.categories_attributes[id].attributes, stock_order_sizing_table_id: props.categories_by_id[id].stock_order_sizing_table_id == "0" ? null : props.categories_by_id[id].stock_order_sizing_table_id, total_qty_by_sizes: {}}  
          
          setState(update(state, {
            stock_order_categories: {
              $push : [new_category]
            }
          }))
        }
      else
        setState(update(state, {
          stock_order_categories: {
            [sc_index]: {
              deleted: {
                $set: false
              },
              stock_order_items: { $push: [new_category] }
            }
          }
        }))
    }
  }

  const handleCheckboxInputChange = (event) => {
    if (event.target.name == "vendor_invoice_received")
      setState({ ...state, [event.target.name]: event.target.checked, ["vendor_invoice_number"]: "" });
    else
      setState({ ...state, [event.target.name]: event.target.checked });
  }

  const handleEntryChange = (event) => {
    setState({ ...state, detailed_entry: !state.detailed_entry });
  }

  const handleSubmit = (open_place_popup, event) => {
    let params = { ...state }

    if (!params.order_number) {
      alert("Order Number is mandatory.");
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    if (!params.vendor_id || params.vendor_id == 0) {
      alert("Vendor is mandatory.");
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    let not_ok = false
    let logos = state.logos
    
    if(params.detailed_entry)
    {

      params.stock_order_categories.map((soc) => {
        if ((_.findLastIndex(soc.stock_order_items, (soi) => (((!soi["sku"] || soi["sku"] == "") && (!soi["description"] || soi["description"] == "")) && !soi["deleted"]))) > -1) {
          not_ok = true
          return false;
        }

      })

      if (not_ok) {
        alert("SKU / Style # or Description is mandatory.")
        event.preventDefault();
        event.stopPropagation();
        return false;
      }

      params.stock_order_categories.map((soc) => {
        soc.stock_order_items.map((soi) => {
          soi.logo_id = (_.find(logos, (l) => (l.stock_order_item_id == soi.id) && (l.stock_order_category_id == soc.category_id)) || { id: null })["id"]

          soi.stock_order_quantities = JSON.stringify(soi.stock_order_quantities)
          soi.attributes = JSON.stringify(soi.attributes)
        })
        soc.stock_order_items = JSON.stringify(soc.stock_order_items)
      })
    }

    params.stock_order_categories = JSON.stringify(params.stock_order_categories)

    params["files"] = []
    if (state.id) {
      params["action_type"] = "update"
      updateStockOrder(params)
    }
    else {
      props.item.files.map((element) => {
        params["files"].push(element.id)
      })
      params["files"] = JSON.stringify(params["files"])

      createStockOrder(params, open_place_popup)
    }

    handleClose()
  }

  const handleAddCategory = () => {
    let new_category = { category_id: state.category_id, category_name: state.category_name, qty: 1, total_cost: 0, key: Math.random() }

    setState(update(state, {
      stock_order_categories: {
        $push: [new_category]
      }
    }))
  }

  const handleShippingSelectChange = (event) => {
    setState({ ...state, shipping_company: event.value });
  }

  const handleClose = () => {
    closeModal()
  }

  const editParentStockOrder = (event) => {
    let parent_child_id = event.target.getAttribute("parent_child_id")

    if (parent_child_id) {
      handleClose()
      editStockOrder(parent_child_id)
    }
  }

  const updateQuantities = () => {
    let old_state = { ...state }
    let categories = [...old_state.stock_order_categories]
    state.total_cost = 0
    state.total_items = 0

    if (old_state.detailed_entry) {
      categories.map((soc) => {
        let total_cost = 0.0
        let total_qty = 0

        if (soc["stock_order_items"]) {
          soc.total_qty_by_sizes = {}
          soc["stock_order_items"] && soc["stock_order_items"].map((soi) => {
            if (!soi["deleted"] && !soi["cancellation_date"]) {
              let total_qty_item = 0
              if (soc.stock_order_sizing_table_id) {
                soi["stock_order_quantities"] && soi["stock_order_quantities"].map((soq) => {
                  total_qty_item += parseInt(soq.qty) || 0
                  if (!soc.total_qty_by_sizes[soq.size])
                    soc.total_qty_by_sizes[soq.size] = 0
                  soc.total_qty_by_sizes[soq.size] += parseInt(soq.qty) || 0
                })
                soi.qty = total_qty_item
              }

              total_qty += parseInt(soi.qty) || 0
              total_cost += ((parseInt(soi.qty) || 0) * ((parseFloat(soi.cost) || 0.0) + (parseFloat(soi.logo_cost) || 0.0)))
            }
          })
          soc.qty = total_qty
          soc.total_cost = total_cost

          state.total_cost += total_cost
          state.total_items += total_qty
        }
      })
    }
    setState({ ...state })
  }

  const handleRestore = (soc_id, soi_id = null, qty = null, total_cost = null, index = null) => {
    if (props.item.detailed_entry) {
      if (soc_id) {
        let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)
        if (soi_id) {
          let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
          setState(update(state, {
            vendor_cancel_date: { $set: null },
            stock_order_categories: {
              [soc_index]: {
                cancellation_date: { $set: null },
                stock_order_items: {
                  [soi_index]: {
                    cancellation_date: { $set: null }
                  }
                }
              }
            }
          }
          ))
        }
        else
          setState(update(state, {
            vendor_cancel_date: { $set: null },
            stock_order_categories: {
              [soc_index]: {
                cancellation_date: { $set: null }
              }
            }
          }
          ))
      }
    }
    else {
      let category_id = soc_id
      let new_qty = 0
      let new_cost = 0

      let index_inside = _.findIndex(state.stock_order_categories, function (o) { return ((o.category_id == category_id) && !o.cancellation_date); });

      if (index_inside >= 0) {
        new_qty = parseInt(state.stock_order_categories[index_inside].qty) + parseInt(qty)
        new_cost = (parseFloat(state.stock_order_categories[index_inside].total_cost) + parseFloat(total_cost)).toPrecision(2)
        setState(update(state, {
          stock_order_categories: {
            [index_inside]: {
              ["qty"]: { $set: new_qty },
              ["total_cost"]: { $set: new_cost }
            },
            [index]: {
              ["deleted"]: { $set: true }
            }
          }
        }))
      }
      else
        setState(update(state, {
          stock_order_categories: {
            [index]: {
              ["cancellation_date"]: { $set: "" }
            }
          }
        }))
    }
  }

  const sortItems = (soc_id) => {
    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)
    let items = stock_order_categories[soc_index].stock_order_items
    items = _.sortBy(items, [function (o) { return parseInt(o.line); }]);

    setState(update(state, {
      stock_order_categories: {
        [soc_index]: {
          stock_order_items: { $set: items }
        }
      }
    }))
  }

  const handleRemove = (soc_id, soi_id) => {
    let soc_index = _.findIndex(stock_order_categories, (soc) => soc.category_id == soc_id && !soc.deleted)
    if (soi_id) {
      let soi_index = _.findIndex(stock_order_categories[soc_index].stock_order_items, (soi) => soi.id == soi_id)
      let all_deleted = _.find(stock_order_categories[soc_index].stock_order_items, (soi) => !soi.deleted && (soi.id != soi_id)) ? false : true

      setState(update(state, {
        stock_order_categories: {
          [soc_index]: {
            deleted: { $set: all_deleted },
            stock_order_items: {
              [soi_index]: {
                deleted: { $set: true }
              }
            }
          }
        }
      }
      ))
    }
    else
      setState(update(state, {
        stock_order_categories: {
          [soc_index]: {
            deleted: { $set: true }
          }
        }
      }
      ))
  }

  const handleNewItem = (soc_id, soi_id) => {
    let index = _.findIndex(stock_order_categories, (soc) =>  soc.category_id == soc_id && !soc.deleted)
    let new_item ={ id: uuidv4(), stock_order_quantities: [], attributes: [] }
    let items = stock_order_categories[index].stock_order_items

    if(soi_id)
      {
        new_item = {..._.find(stock_order_categories[index].stock_order_items, (soi) =>  soi.id == soi_id)}
        new_item.id = uuidv4()
        new_item.stock_order_quantities = [...new_item.stock_order_quantities]
        new_item.attributes = [...new_item.attributes]
        new_item.line = parseInt((_.maxBy(items, (i) => (i.line)) || {line: 0}).line) + 1
        let logo = _.find(state.logos, (l) =>  (l.stock_order_item_id == soi_id.toString()) && (l.stock_order_category_id == soc_id.toString()))
        
        if(logo)
          state.logos.push({id: logo.id, stock_order_item_id: new_item.id.toString(), stock_order_category_id: soc_id.toString(), url: logo.url})
      }
    
    if(soi_id){
      items.push(new_item)
      items = _.sortBy(items, [function (o) { return parseInt(o.line); }]);
    }
    else {
      items = _.sortBy(items, [function (o) { return parseInt(o.line); }]);
      new_item["line"] = parseInt((_.maxBy(items, (i) => (i.line)) || { line: 0 }).line) + 1
      items.push(new_item)
    }

    setState(update(state, {
      stock_order_categories: {
        [index]: {
          stock_order_items: { $set: items }
        }
      }
    }))
  }

  const { id, category_id, shipping_cost, invoice_processed, received_into_inventory, vendor_invoice_received,
    shipping_company, tracking_number, delivered_date, expected_delivery_date, expected_ship_date, ordered_date,
    vendor_confirmation_number, vendor_id, order_number, order_nickname,
    order_description, end_date, cancellation_date, start_date,
    additional_description_to_vendor, internal_notes, stock_order_categories,
    early_cancel_date, activity_logs, color, vendor_cancel_date, vendor_invoice_number, detailed_entry, added_categories, is_similar_stock_order } = state;

  const { files, print_po_url } = props.item

  const { modal_open, closeModal, vendors_list, categories_list, title, btn_name,
    carrier_options, editStockOrder, createStockOrder, updateStockOrder, customer_id, po, similar_stock_order } = props;

  const sizing_is_present = _.find(stock_order_categories, (soc) => soc.stock_order_sizing_table_id)

  if (similar_stock_order && !is_similar_stock_order) {
    setState(update(state, {
      is_similar_stock_order: { $set: true },
      vendor_id: { $set: similar_stock_order.vendor_id },
      order_number: { $set: similar_stock_order.order_number },
      order_nickname: { $set: similar_stock_order.order_nickname },
      order_description: { $set: similar_stock_order.order_description },
      color: { $set: similar_stock_order.color },
      detailed_entry: { $set: similar_stock_order.detailed_entry },
      stock_order_categories: { $set: similar_stock_order.stock_order_categories },
      start_date: { $set: similar_stock_order.start_date },
      ordered_date: { $set: similar_stock_order.ordered_date },
      early_cancel_date: { $set: similar_stock_order.early_cancel_date },
      expected_ship_date: { $set: similar_stock_order.expected_ship_date },
      expected_delivery_date: { $set: similar_stock_order.expected_delivery_date }
    }))
  }

  return (
    <div className="modal_stock_order_container">
      {/* TO DO: modal is called even if no data is initialized and is set to isOpen false but this component is rendered anyhow */}
      <Modal isOpen={modal_open} detailed_entry={detailed_entry} sizing_is_present={sizing_is_present}>
        <ModalHeader >
          <div className="row">
            <div className="col-7 order-1">
              <h4 className="modal-title form_new_edit no-margin">{title}</h4>
            </div>
            <div className="col-5 order-2 text-end">
              {(props.item.parent_childrens.length > 0) &&
                props.item.parent_childrens.map((pc) => (
                  props.item.parent_stock_order_id ?
                    <label key={pc.id} style={{ lineHeight: "26px" }}>
                      {"This order was split on " + props.item.split_date + " from order "}
                      <a className="link pull-right" onClick={editParentStockOrder} parent_child_id={pc.id}>
                        <FiLink2 className="fs-3 p-1 color_black" parent_child_id={pc.id} />
                        <u parent_child_id={pc.id}>
                          {pc.name}
                        </u>
                      </a>
                    </label>
                    :
                    <label key={pc.id} style={{ lineHeight: "26px" }}>
                      {"This order was split on " + props.item.split_date + " into order "}
                      <a className="link pull-right" onClick={editParentStockOrder} parent_child_id={pc.id}>
                        <FiLink2 className="fs-3 p-1 color_black" parent_child_id={pc.id} />
                        <u parent_child_id={pc.id}>
                          {pc.name}
                        </u>
                      </a>
                    </label>
                ))
              }
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form id="StockOrderForm">
            <div className="row">
              <div className="col pt-3">
                <Form.Group as={Row} className="mb-3" controlId="formVendor">
                  <Form.Label column sm="2">
                    Vendor
                  </Form.Label>
                  <Col sm="10">
                    <Select id="vendor_id"
                      name='vendor_id'
                      isDisabled={props.view_only_stock_orders}
                      value={vendors_list.filter(function (option) {
                        return option.value === vendor_id;
                      })}
                      options={vendors_list}
                      onChange={handleVendorSelectChange} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formOrderNumberNickname">
                  <Form.Label column sm="2">
                    Order Number
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text"
                      name='order_number'
                      disabled={props.view_only_stock_orders}
                      value={order_number || props.default_order_number || ""}
                      onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                    />
                  </Col>
                  {props.optional_fields.includes("order_nickname") && <Form.Label column sm="2">
                    Order Nickname
                  </Form.Label>}
                  {props.optional_fields.includes("order_nickname") && <Col sm="4">
                    <Form.Control type="text"
                      name='order_nickname'
                      disabled={props.view_only_stock_orders}
                      value={order_nickname || ""}
                      onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                    />
                  </Col>}
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formCancelDates">
                {props.optional_fields.includes("early_cancel_date") &&
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-11">
                        Early Cancel Date
                      </div>
                      <div className="col-1 p-0">
                        <OverlayTrigger
                          key="early_cancel_date"
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-early_cancel_date`}>
                              This is the date that you must cancel the order by in order to stop shipment and avoid an invoice from the vendor. Confirm with your vendor if unknown.
                            </Tooltip>
                          }
                        >
                          <i className=" align-middle gga-info-circle tooltip_info" />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Form.Label>}
                  {props.optional_fields.includes("early_cancel_date") && <Col sm="4">
                    <DatePicker className="form-control"
                      name='early_cancel_date'
                      disabled={props.view_only_stock_orders}
                      id='early_cancel_date'
                      selected = {Date.parse(moment(early_cancel_date, props.date_format.toUpperCase()))}
                      value =  {Date.parse(moment(early_cancel_date, props.date_format.toUpperCase()))}
                      dateFormat={ props.date_format.replaceAll('m', 'M')}
                      onChange={handleEarlyCancelDateChange}
                      autoComplete="off"
                    />
                  </Col>}
                  {/* <Form.Label column sm="2">
                    Cancel Date
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                              name='cancellation_date'
                              id='cancellation_date'
                              selected= {Date.parse(cancellation_date)}
                              value =  {Date.parse(cancellation_date)}
                              onChange={handleCancelDateChange}
                              dateFormat='MM/dd/yyyy'
                              />
                  </Col> */}
                  {props.optional_fields.includes("vendor_confirmation_number") && <Form.Label column sm="2">
                    Vendor Confirmation Number
                  </Form.Label>}
                  {props.optional_fields.includes("vendor_confirmation_number") && <Col sm="4">
                    <Form.Control type="text" 
                          name='vendor_confirmation_number'
                          disabled={props.view_only_stock_orders}
                          value={vendor_confirmation_number || ""}
                          onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value )} 
                          />
                  </Col>}
                </Form.Group>
                {(props.optional_fields.includes("start_date") || props.optional_fields.includes("end_date")) && <Row className="pb-3">
                  <Accordion defaultActiveKey="0" className="ps-0">
                    <Card>
                      <Card.Header >
                        Shipping window
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Form.Group as={Row} className="" controlId="formStartCancelDate">
                            {props.optional_fields.includes("start_date") && <Form.Label column sm="2">
                                <Row>
                                <div className="col-11">
                                  Start Ship Date
                                </div>
                                <div className="col-1 p-0 add-line-height-25">
                                  <OverlayTrigger
                                    key="start_date"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-start_date`}>
                                        This is the earliest date that you will accept the shipment of the order. Confirm with your vendor if unknown.
                                      </Tooltip>
                                    }
                                  >
                                    <i className=" align-middle gga-info-circle tooltip_info" />
                                  </OverlayTrigger>
                                </div>
                                </Row>
                              </Form.Label>}
                              {props.optional_fields.includes("start_date") && <Col sm="4">
                                <DatePicker className="form-control"
                                          name='start_date'
                                          disabled={props.view_only_stock_orders}
                                          id='start_date'
                                          selected = {Date.parse(moment(start_date, props.date_format.toUpperCase()))}
                                          value =  {Date.parse(moment(start_date, props.date_format.toUpperCase()))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          onChange={handleStartDateChange}
                                          autoComplete = "off"
                                          />
                              </Col>}
                              {props.optional_fields.includes("end_date") && <Form.Label column sm="2">
                                <div className="row">
                                  <div className="col-11">
                                    End Ship Date
                                  </div>
                                  <div className="col-1 p-0">
                                    <OverlayTrigger
                                        key="end_date"
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-end_date`}>
                                            This is the date that you require that the order is shipped by. If it is not, the order must be cancelled by the vendor. Confirm with your vendor if unknown.
                                          </Tooltip>
                                        }
                                      >
                                      <i className=" align-middle gga-info-circle tooltip_info"/>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </Form.Label>}
                              {props.optional_fields.includes("end_date") && <Col sm="4">
                                <DatePicker className="form-control"
                                          name='end_date'
                                          disabled={props.view_only_stock_orders}
                                          id='end_date'
                                          selected = {Date.parse(moment(end_date, props.date_format.toUpperCase()))}
                                          value =  {Date.parse(moment(end_date, props.date_format.toUpperCase()))}
                                          dateFormat={ props.date_format.replaceAll('m', 'M')}
                                          onChange={handleCancelCutDateChange}
                                          autoComplete = "off"
                                          />

                              </Col>}
                            </Form.Group>
                          </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Row>}
                <Row className="mb-3">
                  <Accordion defaultActiveKey="0" className="ps-0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        Shipping Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form.Group as={Row} className="mb-3" controlId="formInPersonShipDeliveryDate" >
                          <Form.Label column sm="2">
                            <div className="row">
                              <div className="col-12">
                                Expected Ship Date
                              </div>
                              {/* <div className="col-1 p-0">
                                <OverlayTrigger
                                    key="expected_ship_date"
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-expected_ship_date">
                                        Expected Ship Date
                                      </Tooltip>
                                    }
                                  >
                                  <i className=" align-middle gga-info-circle tooltip_info"/>
                                </OverlayTrigger>
                              </div> */}
                            </div>
                          </Form.Label>
                          <Col sm="4">
                            <DatePicker className="form-control"
                              name='expected_ship_date'
                              disabled={props.view_only_stock_orders}
                              id='expected_ship_date'
                              onChange={handleShipDateChange}
                              selected = {Date.parse(moment(expected_ship_date, props.date_format.toUpperCase()))}
                              value =  {Date.parse(moment(expected_ship_date, props.date_format.toUpperCase()))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              autoComplete="off"
                            />
                          </Col>
                          <Form.Label column sm="2">
                            <div className="row">
                              <div className="col-12">
                                Expected Delivery Date
                              </div>
                              {/* <div className="col-1 p-0">
                                <OverlayTrigger
                                    key="expected_delivery_date"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-expected_delivery_date`}>
                                        Expected Delivery Date
                                      </Tooltip>
                                    }
                                  >
                                  <i className=" align-middle gga-info-circle tooltip_info"/>
                                </OverlayTrigger>
                              </div> */}
                            </div>
                          </Form.Label>
                          <Col sm="4">
                            <DatePicker className="form-control"
                              name='expected_delivery_date'
                              disabled={props.view_only_stock_orders}
                              id='expected_delivery_date'
                              onChange={handleExpDeliveryDateChange}
                              selected = {Date.parse(moment(expected_delivery_date, props.date_format.toUpperCase()))}
                              value =  {Date.parse(moment(expected_delivery_date, props.date_format.toUpperCase()))}
                              dateFormat={ props.date_format.replaceAll('m', 'M')}
                              autoComplete="off"
                            />
                          </Col>
                        </Form.Group>
                        {(btn_name == "Update") && <Form.Group as={Row} className="" controlId="formInShippingDetails" >
                          <Form.Label column sm="2">
                            Shipping Company
                          </Form.Label>
                          <Col sm="4">
                            <Select id="shipping_company"
                              name='shipping_company'
                              isDisabled={props.view_only_stock_orders}
                              value={carrier_options.filter(function (option) {
                                return option.value === shipping_company;
                              })}
                              options={carrier_options}
                              onChange={handleShippingSelectChange} />
                          </Col>
                          <Form.Label column sm="2">
                            Tracking Number
                          </Form.Label>
                          <Col sm="4">
                            <Form.Control type="text"
                              name='tracking_number'
                              disabled={props.view_only_stock_orders}
                              onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                              value={tracking_number || ""}
                            />
                          </Col>
                        </Form.Group>}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
                {(btn_name == "Update") && <Form.Group as={Row} className="mb-3" controlId="formOrderedDateVCN">
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">
                        Ordered Date
                      </div>
                      {/* <div className="col-1 p-0">
                        <OverlayTrigger
                            key="ordered_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-ordered_date`}>
                                Ordered Date
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div> */}
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                      name='ordered_date'
                      disabled={props.view_only_stock_orders || !props.can_place_stock_orders}
                      id='ordered_date'
                      selected = {Date.parse(moment(ordered_date, props.date_format.toUpperCase()))}
                      value =  {Date.parse(moment(ordered_date, props.date_format.toUpperCase()))}
                      dateFormat={ props.date_format.replaceAll('m', 'M')}
                      onChange={handleOrderedDateChange}
                      autoComplete="off"
                    />
                  </Col>
                  <Form.Label column sm="2">
                    <div className="row">
                      <div className="col-12">
                        Delivered Date
                      </div>
                      {/* <div className="col-1 p-0">
                        <OverlayTrigger
                            key="delivered_date"
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-delivered_date`}>
                                Delivered Date
                              </Tooltip>
                            }
                          >
                          <i className=" align-middle gga-info-circle tooltip_info"/>
                        </OverlayTrigger>
                      </div> */}
                    </div>
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker className="form-control"
                      name='delivered_date'
                      disabled={props.view_only_stock_orders}
                      id='delivered_date'
                      selected = {Date.parse(moment(delivered_date, props.date_format.toUpperCase()))}
                      value =  {Date.parse(moment(delivered_date, props.date_format.toUpperCase()))}
                      dateFormat={ props.date_format.replaceAll('m', 'M')}
                      onChange={handleDeliveredDateChange}
                      autoComplete="off"
                    />
                  </Col>
                </Form.Group>}
                {(!state.id || stock_order_categories.length == 0) &&
                  <div className='row mb-3'>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      disabled={props.view_only_stock_orders}
                      label="Detailed Entry Mode"
                      defaultChecked={detailed_entry}
                      onChange={handleEntryChange}
                      className="p-0"
                    />
                  </div>}
                <Form.Group as={Row} className="mb-3" controlId="formCategoies">
                  <Form.Label column sm="2">
                    Categories
                  </Form.Label>
                  <Col sm="7">
                    {/* <Select id = "category_id" 
                                name = 'category_id' 
                                options={categories_list}
                                value={categories_list.filter(function(option) {
                                  return option.value === category_id;
                                })}
                                onChange={handleNewStockCategory}
                                /> */}
                    <CategoryDropdown categories={categories_list} can_place_stock_orders={props.can_place_stock_orders} view_only_stock_orders={props.view_only_stock_orders} handleNewStockCategory={handleNewStockCategory} />
                  </Col>
                  {/* {category_id && <Col sm="3">
                    <a
                      className="btn gems_custom_button gems_grey_button add_category_btn"
                      onClick={handleAddCategory}
                    >
                      Add Category
                    </a>
                  </Col>} */}
                </Form.Group>
              </div>
              <div className="col pt-3">
                <Summary files={files}
                  handleInputChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                  uploadFile={props.uploadFile}
                  customer_id={customer_id}
                  state={state}
                  btn_name={props.btn_name || null}
                  view_only_stock_orders={props.view_only_stock_orders}
                />
              </div>
            </div>
            <div className="row">
                {!detailed_entry && 
                  <QuickEntry 
                    stock_order_categories={stock_order_categories} 
                    view_only_stock_orders={props.view_only_stock_orders}
                    handleInputChange={handleInputChange}
                    handleRestore={handleRestore}
                    handleRemove={handleRemove}
                    handleQuantity={handleQuantity}
                    />
                  }
              </div>
              <div className="row">
                {detailed_entry && <DetailedEntryContainer
                    stock_order_categories={stock_order_categories}
                    handleNewItem={handleNewItem}
                    handleInputChange={handleInputChange}
                    handleRestore={handleRestore}
                    handleQuantity={handleQuantity}
                    handleRemove={handleRemove}
                    handleSizingSelectChange={handleSizingSelectChange}
                    handleColorGroupChange={handleColorGroupChange}
                    handleLogoChange={handleLogoChange}
                    total_items={state.total_items}
                    total_cost={state.total_cost}
                    sortItems={sortItems}
                    uploadFile = {props.uploadFile}
                    customer_id = {customer_id}
                    logos = {state.logos}
                    />
                  }
              </div> 
            <div className="row">
            {props.optional_fields.includes("additional_description_to_vendor") && <Form.Group as={Row} className="mb-3 pe-0" controlId="formOrderDescription">
                <Form.Label>Order Description</Form.Label>
                <Form.Control as="textarea" rows={3} 
                              name='order_description'
                              disabled={props.view_only_stock_orders}
                              value={order_description || ""}
                              onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value )}/>
              </Form.Group>}
              <div className='col-6'>
                {(btn_name == "Update") && <Form.Group as={Row} className="mb-3" controlId="formDeliveredDateInvoiceReceived">
                  <Form.Check
                    name="vendor_invoice_received"
                    disabled={props.view_only_stock_orders}
                    value={vendor_invoice_received || false}
                    defaultChecked={vendor_invoice_received || false}
                    type='checkbox'
                    id="vendor_invoice_received"
                    label="Vendor Invoice Received"
                    className="col add-line-height-35 p-0"
                    onChange={handleCheckboxInputChange}
                  />
                  {vendor_invoice_received && <><Form.Label column sm="2">
                    Vendor Invoice Number
                  </Form.Label>
                    <Col sm="4">
                      <Form.Control type="text"
                        name='vendor_invoice_number'
                        disabled={props.view_only_stock_orders}
                        value={vendor_invoice_received ? vendor_invoice_number || "" : ""}
                        onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                      />
                    </Col></>}
                </Form.Group>}
                {(btn_name == "Update") && <Form.Group as={Row} className="mb-3" controlId="formIntoInventoryProcessed">
                  <Form.Check
                    name="received_into_inventory"
                    disabled={props.view_only_stock_orders}
                    value={received_into_inventory || false}
                    defaultChecked={received_into_inventory || false}
                    type='checkbox'
                    id="received_into_inventory"
                    label="Received into Inventory"
                    className="col p-0"
                    onChange={handleCheckboxInputChange}
                  />
                  <Form.Check
                    name="invoice_processed"
                    disabled={props.view_only_stock_orders}
                    value={invoice_processed || false}
                    defaultChecked={invoice_processed || false}
                    type='checkbox'
                    id="invoice_processed"
                    label="Invoice Processed"
                    className="col p-0"
                    onChange={handleCheckboxInputChange}
                  />
                </Form.Group>}
                {(btn_name == "Update") && <Form.Group as={Row} className="mb-3" controlId="formCancelTotalShip">
                  <Form.Label column sm="2">
                    Total Shipping Cost
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control type="text"
                      name='shipping_cost'
                      disabled={props.view_only_stock_orders}
                      value={shipping_cost || ""}
                      onChange={(e) => handleInputChange(null, null, e.target.name, e.target.value)}
                    />
                  </Col>
                  {/* <Form.Label column sm="2">
                      <div className="row">
                        <div className="col-12">Cancelled Date</div> */}
                  {/* <div className="col-1 p-0">
                          <OverlayTrigger
                              key="vendor_cancel_date"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-vendor_cancel_date`}>
                                  Cancelled Date
                                </Tooltip>
                              }
                            >
                            <i className=" align-middle gga-info-circle tooltip_info"/>
                          </OverlayTrigger>
                        </div> */}
                  {/* </div>
                    </Form.Label>
                    <Col sm="4">
                      <DatePicker className="form-control"
                                name='vendor_cancel_date'
                                id='vendor_cancel_date'
                                selected= {Date.parse(vendor_cancel_date)}
                                value =  {Date.parse(vendor_cancel_date)}
                                onChange={handleVendorCancelDateChange}
                                dateFormat='MM/dd/yyyy'
                                autoComplete = "off"
                                />
                    </Col> */}
                </Form.Group>}
              </div>
              <Form.Group as={Row} className="mb-3" controlId="formOrderDescription">
                <Form.Label column sm="2">Calendar Color</Form.Label>
                <Col sm="4">
                  {/* <HexColorPicker color={color || ""} onChange={handleColorChange} /> */}
                  <div className="row stock_order_color_class">
                    <div style={{ backgroundColor: color || '#9B9B9B' }} className="col-2 calendar_color_class add_padding_all_14"></div>
                    {!props.view_only_stock_orders && <div className="col-10">
                      <SketchPicker
                        color={color || '#9B9B9B'}
                        disableAlpha={true}
                        width="100%"
                        presetColors={presetColors}
                        onChange={handleColorChange}
                      />
                    </div>}
                  </div>
                </Col>
              </Form.Group>

            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          {print_po_url && <a className='btn gems_custom_button gems_orange_button float-start mb-2' href={print_po_url} data-method="get" target='_blank'>
            Print Purchase Order
          </a>}
          {btn_name == "Create" && <button
            className={`btn gems_custom_button gems_orange_button float-end ${props.can_place_stock_orders == true ? '' : 'd-none'}`}
            onClick={(event) => handleSubmit(true, event)}
          >
            Create and Place Stock Order
          </button>
          }
          <button
            className={`btn gems_custom_button gems_orange_button float-end ${props.view_only_stock_orders == true ? 'd-none' : ''}`}
            onClick={(event) => handleSubmit(false, event)}
          >
            {btn_name}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default StockOrderModal