import React, {Component, useState} from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col } from 'react-bootstrap';
import _ from 'lodash'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import update from "immutability-helper";
import FroalaEditor from "react-froala-wysiwyg";
import {config_froala} from "../../../shared/froala_config";

function UpdateBlockForm (props) {
  
  const initialState = {
    title: props.title
  }
  
  const [state, setState] = useState(initialState);
  
  const setTitle = (_title) => {
    setState(update(state, {
        title: {$set: _title},
      }
    ))
  };

  let config = config_froala
  config["placeholderText"] = "Title"
  config["minHeight"] = 130
  config["maxHeight"] = 1000
  config["width"] = 600
  config["toolbarButtons"]= {
    'moreRich': {},
    'moreMisc': {},
    'moreText': {
      
      'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
      
    },
    
    'moreParagraph': {
      
      'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      
    }}
  
  return (
    <div className='col'>
      <ModalHeader >
        <h4 className="modal-title form_new_tab no-margin">{_.startCase(_.toLower(window.I18n.t('multi_widgets.block.edit')))}</h4>
      </ModalHeader>
      <ModalBody>
        <Form className='inner-content'>
          <div className="row mt-3 ">
            <div className="col">
              <Form.Group as={Row} className="mb-3" controlId="formTabForm">
                  <FroalaEditor tag='textarea'
                                config={{...config, ...{height: 30}}}
                                model={state.title}
                                onModelChange={setTitle}/>
                
              </Form.Group>
              
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button"
          aria-label="Close"
          onClick={ e => { e.preventDefault(); props.onCancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </button>
        <button
          className="btn gems_custom_button float-end"
          style={ { backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor)) }}
          onClick={ e => { e.preventDefault(); props.onUpdate(props.id, state.title) } } >
              {window.I18n.t('multi_widgets.save')}
        </button>
      </ModalFooter>
    </div>

    )
}

UpdateBlockForm.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
}

export default UpdateBlockForm
