import { connect } from 'react-redux'
import { closeLogosModal, updateStockOrder, uploadFile, deleteLogo} from '../actions'
import LogosModal from '../components/LogosModal'

const mapStateToProps = (state) => ({
  modal_logos_open: state.stock_order.modal_logos_open,
  customer_id: state.stock_order.customer_id,
  logos_list: state.stock_order.logos_list,
  current_item: state.stock_order.current_item || {},
  current_category_id: state.stock_order.current_category_id,
  item_id: state.stock_order.item.id,

})

const mapDispatchToProps = (dispatch) => ({
  closeLogosModal: () => dispatch(closeLogosModal()),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data)),
  deleteLogo: id => dispatch(deleteLogo(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogosModal)
