
import React from 'react';
import _ from 'lodash';
import {useState} from 'react';
import GripDropdownItem from './GripDropdownItem';

function GripDropdown(props) {
  
  const {grips, display_grip_retail_price, handleClubRepairGripSelection} = props

  const initialState = {grip_selected: null, dropdownIsOpen: false}

  const [state, setState] = useState(initialState);

  const handleGripSelection = (grip) => {
    var elem = document.getElementById('grip_dropdown_list').classList
    elem.remove('show')

    setState({...state, grip_selected: grip, dropdownIsOpen: false});
    handleClubRepairGripSelection(grip.id, grip.total_price);
  }

  const handleRemoveGripSelection = () => {
    var elem = document.getElementById('grip_dropdown_list').classList
    elem.remove('show')

    setState({...state, grip_selected: null, dropdownIsOpen: false});
    handleClubRepairGripSelection(null, null);
    
  }

  const handleToggleDropdown = (e) => {
    
    var elem = document.getElementById('grip_dropdown_list').classList
    elem.contains('show') ? elem.remove('show') : elem.add('show');

    setState({...state, dropdownIsOpen: elem.contains('show') ? true : false});
  }

  
  return (
    <div className={`col-12 py-1 ${state.grip_selected && !state.dropdownIsOpen ? "ps-0 pe-2" : "border px-2"} dropdown grip-dropdown-container`}>
      <button className={`w-100 btn btn-default dropdown-toggle text_align_left font_weight_300 font_size_14 d-flex ${state.grip_selected && !state.dropdownIsOpen ? "h-auto" : ""} cursor_pointer`} id="grip_btn" aria-expanded = "false" type = "button" onClick={(e) => handleToggleDropdown(e)}>
        <div className='col-11'>
          { !state.grip_selected ?
            <span className='text-truncate' id='grip_btn_text'>
              No grip selected
            </span>
            :
            (!state.dropdownIsOpen ? 
              <GripDropdownItem grip={state.grip_selected} display_grip_retail_price={display_grip_retail_price} handleGripSelection = {null}/>
              :
              <span className='text-truncate bold' id='grip_btn_text' data-tooltip>
                {state.grip_selected.name}
              </span>
              
            )
            
          }
        </div>
        <div className='col-1'>
          <i className='float-end fs-4 fa fa-caret-down'/>
        </div>
      </button>


      { state.grip_selected && !state.dropdownIsOpen &&
        <div className='row'>
          <span className='color_red cursor_pointer' onClick={(e) => handleRemoveGripSelection()}>
            <i className='fa fa-times-circle'/>
            <span className='font_size_12'>
              Clear
            </span>
          </span>
        </div>
      }
      <div className="dropdown-menu rounded-0 w-100 position-absolute" id="grip_dropdown_list" style={{transform: "translate3d(0px, 34px, 0px)!important", overflow: "hidden", ariaLabelledby: "grip_dropdown_btn"}}>
        <div className="dropdown-item px-2" style = {{height: "350px", overflowX: "hidden", overflowY: "auto"}}>
          {grips.map((grip) => (
            <GripDropdownItem grip={grip} display_grip_retail_price={display_grip_retail_price} handleGripSelection={handleGripSelection} grip_selected={state.grip_selected}/>
            ))
          }
        </div>
      </div>
  </div>
)
} 
export default GripDropdown