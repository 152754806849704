import { connect } from 'react-redux'
import Body from '../components/Body'
import { openAddBlockModal, openEditBlockTitleModal, deleteBlock, addNewField } from '../actions'
import ProfileSetupBlocks from '../components/ProfileSetupBlocks'

const mapStateToProps = (state, props) => ({
  all_tabs: state.profile_setup.all_tabs,
  view_mode: state.profile_setup.view_mode,
  current_tab: state.profile_setup.current_tab,
  modal_add_block_open: state.profile_setup.modal_add_block_open || false,
  modal_edit_block_title_open: state.profile_setup.modal_edit_block_title_open || false,
  modal_edit_values_open: state.profile_setup.modal_edit_values_open || false,
  member_metadata_values: state.profile_setup.member_metadata_values || false,
  edited_block: state.profile_setup.edited_block || null,
  setMemberMetadata: props.setMemberMetadata,
  memberMetadata: props.memberMetadata,
  setUpdatedMemberMetadata: props.setUpdatedMemberMetadata,
  updatedMemberMetadata: props.updatedMemberMetadata,
  setMemberInfo: props.setMemberInfo,
  memberInfo: props.memberInfo,
  setMemberNotifications: props.setMemberNotifications,
  memberNotifications: props.memberNotifications,
  original_member_info: props.member_info,
  originalMemberMetadata: props.originalMemberMetadata
})

const mapDispatchToProps = (dispatch) => ({
  openAddBlockModal: () => dispatch(openAddBlockModal()),
  openEditBlockTitleModal: (tab_id, block_id) => dispatch(openEditBlockTitleModal(tab_id, block_id)),
  deleteBlock: (block_id, tab_id) => dispatch(deleteBlock(block_id, tab_id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileSetupBlocks)