import React from 'react'
import PipedriveBodyContainer from '../containers/PipedriveBodyContainer'
import CalendarBodyContainer from '../containers/CalendarBodyContainer'
import StockOrderModalContainer from '../containers/StockOrderModalContainer'
import UploadStockOrderModalContainer from '../containers/UploadStockOrderModalContainer'
import PlaceStockOrderModalContainer from '../containers/PlaceStockOrderModalContainer'
import ShipDetailsStockOrderModalContainer from '../containers/ShipDetailsStockOrderModalContainer'
import ReceiveStockOrderModalContainer from '../containers/ReceiveStockOrderModalContainer'
import FinalizeStockOrderModalContainer from '../containers/FinalizeStockOrderModalContainer'
import SplitStockOrderModalContainer from '../containers/SplitStockOrderModalContainer'
import CancelStockOrderModalContainer from '../containers/CancelStockOrderModalContainer'
import FilterCategoryModalContainer from '../containers/FilterCategoryModalContainer'
import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css'
import PipedriveHeaderContainer from '../containers/PipedriveHeaderContainer'
import ListBodyContainer from '../containers/ListBodyContainer'
import ListViewOptionalFieldsModalContainer from '../containers/ListViewOptionalFieldsModalContainer'

function Body(props) {

  const {modal_open, modal_upload_open, view, modal_place_open, modal_delivered_open, modal_finalize_open, modal_split_open, 
    modal_cancel_open, modal_filter_open, modal_ship_details_open, modal_list_view_optional_fields} = props;

  return (
    <div className="row flex-grow-1 bg-white" id="container_view_dashboard_stock_orders" style={{overflowX: "auto", overflowY: "hidden"}}>
      {
        view == "pipedrive" && <div className="col-12 p-0 h-100 d-flex flex-column" style={{minWidth: "1000px"}}>
          <PipedriveHeaderContainer/>
          <PipedriveBodyContainer/>
        </div>
      }

      {
        view == "list" && <div className="col p-0 h-100">
          <ListBodyContainer/>
        </div>
      }
      
      {
        view == "calendar" && <div className="col p-3 h-100">
        <CalendarBodyContainer/>
        </div>
      }
      
      {modal_open && <StockOrderModalContainer/>}
      {modal_upload_open && <UploadStockOrderModalContainer/>}
      {modal_place_open && <PlaceStockOrderModalContainer/>}
      {modal_ship_details_open && <ShipDetailsStockOrderModalContainer/>}
      {modal_delivered_open && <ReceiveStockOrderModalContainer/>}
      {modal_finalize_open && <FinalizeStockOrderModalContainer/>}
      {modal_split_open && <SplitStockOrderModalContainer/>}
      {modal_cancel_open && <CancelStockOrderModalContainer/>}
      {modal_filter_open && <FilterCategoryModalContainer/>}
      {modal_list_view_optional_fields && <ListViewOptionalFieldsModalContainer/>}
    </div>
  )
}

export default Body
