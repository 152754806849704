import { showErrorNotification, callAPI } from 'shared/helpers'

export const ActionTypes = {
  CHECK_MERGING_OPTIONS_REQUEST: 'CHECK_MERGING_OPTIONS_REQUEST',
  OPEN_HANDLE_DUPLICATES_MODAL: 'OPEN_HANDLE_DUPLICATES_MODAL',
  CLOSE_HANDLE_DUPLICATES_MODAL: 'CLOSE_HANDLE_DUPLICATES_MODAL',
  SET_LOADING_BTN_ON: 'SET_LOADING_BTN_ON'
}

export const checkMergingOptions = (data) => (dispatch, getState) => {
  const state = getState()

  const dataURL = '/customers/'+state.merge_page.customer_id+'/members/check_merging_options'
  
  const params = {customer_id: state.merge_page.customer_id, data: data, spreadsheetfile_id: state.merge_page.spreadsheetfile_id}
  
  dispatch({
    type: ActionTypes.SET_LOADING_BTN_ON,
  })

  callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
    .then(response => {
      dispatch({
        type: ActionTypes.CHECK_MERGING_OPTIONS_REQUEST,
        data: response,
      })
    })
    .catch(() => {
      showErrorNotification('Error while checking data!')
    })
}

export const submitMergingOptions = (data) => (dispatch, getState) => {
  // const state = getState()
  
  // const dataURL = '/customers/'+state.merge_page.customer_id+'/members/match_custom_fields'
  
  // const params = {from_react: true, customer_id: state.merge_page.customer_id, data: data, 
  //   spreadsheetfile_id: state.merge_page.spreadsheetfile_id}
  dispatch({
    type: ActionTypes.SET_LOADING_BTN_ON,
  })
  // callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
  //   .then(response => {
  //     window.location.href = response.path
  //   })
  //   .catch(() => {
  //     showErrorNotification('Error while checking data!')
  //   })
}

export const openHandleDuplicatesModal = (data) => (dispatch, getState) => {
  if(data){
    const state = getState()

    const dataURL = '/customers/'+state.merge_page.customer_id+'/members/check_merging_options'
    
    const params = {customer_id: state.merge_page.customer_id, data: data, spreadsheetfile_id: state.merge_page.spreadsheetfile_id, handle_duplicates: true}
    
    dispatch({
      type: ActionTypes.SET_LOADING_BTN_ON,
    })

    callAPI(dataURL, 'POST', params, new Headers({ 'Accept': 'application/json' }))
      .then(response => {
        dispatch({
          type: ActionTypes.CHECK_MERGING_OPTIONS_REQUEST,
          data: response,
        })
        dispatch({
          type: ActionTypes.OPEN_HANDLE_DUPLICATES_MODAL,
        })
      })
      .catch(() => {
        showErrorNotification('Error while checking data!')
      })
  }
  else
  {
    dispatch({
      type: ActionTypes.SET_LOADING_BTN_ON,
    })
    dispatch({
      type: ActionTypes.OPEN_HANDLE_DUPLICATES_MODAL,
    })
  }

}
export const closeHandleDuplicatesModal = () => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.CLOSE_HANDLE_DUPLICATES_MODAL,
  })
}