//Modal component
import React, { Component } from 'react';

export const ModalHeader = props => {
  return <div className="modal-header">{props.children}</div>;
};

export const ModalBody = props => {
  return <div className="modal-body" style={props.style}>{props.children}</div>;
};

export const ModalFooter = props => {
  return <div className="modal-footer px-0">{props.children}</div>;
};

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: '',
      display: 'none'
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      modalShow: 'show',
      display: 'block'
    });
  }

  closeModal() {
    this.setState({
      modalShow: '',
      display: 'none'
    });
  }

  componentDidMount() {
    this.props.isOpen ? this.openModal() : this.closeModal();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.props.isOpen ? this.openModal() : this.closeModal();
    }
  }

  render() {
    return (
      <div
        className={'modal h-100 in fade' + this.state.modalShow}
        tabIndex="-1"
        role="dialog"
        style={{ display: this.state.display }}
      >
        <div className={"modal-dialog body " + this.props.modalClasses} role="document">
          <div className={ ((this.props.detailed_entry && this.props.sizing_is_present) ? "w-99" : "") + " modal-content body " + this.props.className }>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;