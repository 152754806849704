import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import ColumnMenubar from './column_menubar'
import ColumnContent from './column_content'
import Inventory from '../containers/Inventory'

class Column extends Component {
  constructor(props) {
    super(props)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.props.setColumnDisplay(true)
    $( '.dnd-placeholder' ).remove()
  }

  onDragStart(colId, e) {
    this.props.setDraggebleElement(colId)
    this.props.setDraggedOverElement(null)
    this.props.setDraggedOverPosition(null)
    this.props.setIframePointerOptions('none')
    const parentElement = e.target.parentNode.parentNode
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', parentElement)
    e.dataTransfer.setDragImage(parentElement, parentElement.width, parentElement.height)
    this.props.setColumnDisplay(false)
  }

  onDragOver(e, colId) {
    // See if mouse is hoverd on the top or bottom half of a column
    const columnContainer = $(e.target).closest('.column-container')
    let placeholderPosition = null
    if ( e.pageY <= (columnContainer.offset().top + (columnContainer.height() / 2)) ) {
      placeholderPosition = 'beforebegin'
    } else {
      placeholderPosition = 'afterend'
    }

    // Case when you drag the column over itself
    if (this.props.DraggableElement === colId) {
      $( '.dnd-placeholder' ).remove()
      if (this.props.DraggedOverPosition !== null || this.props.DraggedOverElement !== null) {
        this.props.setDraggedOverPosition(null)
        this.props.setDraggedOverElement(null)
      }
      return
    }

    if (this.props.DraggedOverPosition !== placeholderPosition) {
      this.props.setDraggedOverPosition(placeholderPosition)
    } else {
      if (colId === this.props.DraggedOverElement) {
        return
      }
    }
    this.props.setDraggedOverElement(colId)
    $( '.dnd-placeholder' ).remove()
    // Placeholder element
    e.target.closest('.column-container').insertAdjacentHTML(placeholderPosition, `<div class="col-xs-12 dnd-placeholder">${window.I18n.t('multi_widgets.add_column.drop_here')}</div>`)
    return
  }

  onDragEnd() {
    this.props.setIframePointerOptions('auto')
    if (!_.find(this.props.columns, (col) => col.id == this.props.DraggedOverElement )) {
      if(typeof this.props.DraggedOverElement === 'string')
        this.props.ColumnChangePosition(this.props.DraggedOverElement)
    } else {
      this.props.swapColumns(this.props.DraggedOverElement, this.props.DraggableElement, this.props.DraggedOverPosition)
    }
    this.props.setDraggebleElement(null)
    this.props.setDraggedOverElement(null)
    this.props.setDraggedOverPosition(null)
    this.props.setColumnDisplay(true)
    $( '.dnd-placeholder' ).remove()
  }

  render() {
    return (
      <Col xs={12}
           md={ this.props.size }
           key={ this.props.id }
           className={ this.props.display === true ? 'column-container' : 'column-container dimmer' }
           onDragOver={ e => this.onDragOver(e, this.props.id) } >
           {(this.props.widget_id == "demo_clubs" && this.props.demo_page == 'inventory' && this.props.id == this.props.activeColumn) && <Inventory colId={this.props.id} options={this.props.options} maxWidthChildren={this.props.maxWidthChildren} height={this.props.height}/>}
          {((this.props.widget_id != "demo_clubs") || !this.props.demo_page || this.props.id != this.props.activeColumn) &&
          <>
            <ColumnMenubar onRemove={ this.props.onRemove }
                          onEdit={ this.props.onEdit }
                          onEditProps={ this.props.onEditProps }
                          DragStart={ (e) => this.onDragStart(this.props.id, e) }
                          DragEnd={ () => this.onDragEnd() }
                          url={ this.props.ViewMoreUrl }
                          title={ this.props.title }
                          editMode={this.props.editMode}
                          view_more={ this.props.view_more } 
                          schemaColor={this.props.schemaColor}/>
            <ColumnContent URL={ this.props.IframeUrl }
                          HEIGHT={this.props.height}
                          blockId={this.props.blockId}
                          IframePointerOption={ this.props.IframePointerOption }
                          setIframePointerOptions={ (_option) => this.props.setIframePointerOptions(_option) }
                          setIframeFormState={ this.props.setIframeFormState }
                          schemaColor={ this.props.schemaColor }
                          onEdit={ this.props.onEdit }
                          resizeColumn={ this.props.resizeColumn }
                          tile_type = { this.props.widget_id}
                          editMode={this.props.editMode}
                          onlineFormsURL = { this.props.onlineFormsURL}
                          options={this.props.options}
                          colId={this.props.id}
                          maxWidthChildren={this.props.maxWidthChildren}
                          profile_setup={this.props.profile_setup}
                          member_profile_values={this.props.member_profile_values}
                          member_view={this.props.member_view}
                          />
        </>}
      </Col>
    )
  }
}

Column.propTypes = {
  ColumnChangePosition: PropTypes.func,
  display: PropTypes.bool,
  DraggableElement: PropTypes.string,
  DraggedOverElement: PropTypes.any,
  DraggedOverPosition: PropTypes.any,
  height: PropTypes.number,
  id: PropTypes.string,
  IframePointerOption: PropTypes.string,
  IframeUrl: PropTypes.string,
  onEdit: PropTypes.func,
  onEditProps: PropTypes.func,
  onRemove: PropTypes.func,
  resizeColumn: PropTypes.func,
  setColumnDisplay: PropTypes.func,
  setDraggebleElement: PropTypes.func,
  setDraggedOverElement: PropTypes.func,
  setDraggedOverPosition: PropTypes.func,
  setIframePointerOptions: PropTypes.func,
  setIframeFormState: PropTypes.func,
  size: PropTypes.string,
  swapColumns: PropTypes.func,
  title: PropTypes.any,
  view_more: PropTypes.bool,
  ViewMoreUrl: PropTypes.string,
}

export default Column
