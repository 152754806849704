import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select';
import { Row, Form, Col, Table, Tab, Tabs, Spinner, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ModalHeader, ModalBody, ModalFooter} from './Modal';
import {useState, useEffect} from 'react';
import _ from 'lodash';
import DatePicker from "react-datepicker";
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'
import GripDropdown from '../../../grips/components/GripDropdown';
import moment from 'moment';

function ReserveDemoForm(props) {
  const {repairTypes, grips, member_id, customer_id, currency_symbol, display_grip_retail_price} = props
  const initialState = {repair_location: "onsite",
                        customer_type: "member", 
                        member_id: member_id, 
                        customer_id: customer_id,
                        currency_symbol: currency_symbol,
                        repair_type_id: null,
                        number_of_clubs: 0,
                        repair_description: null,
                        expected_completion_date: null,
                        grip_id: null,
                        source: "online_request",
                        regripping_selected: false,
                        managers_reserve_notifications: JSON.stringify(props.column.options.managers_reserve_notifications)}

  const [state, setState] = useState(initialState);


  const { repair_type_id,
          number_of_clubs, 
          repair_description, 
          expected_completion_date, 
          regripping_selected,
          grip_price,
          grip_id
          } = state
  
  const handleRepairTypeSelectChange = (event) => {
    setState({...state, repair_type_id: event.value, regripping_selected: event.label.includes("grip")});
  }

  const handleInputChange = (event) => {
    let value = event.target.value
    if (event.target.name == 'number_of_clubs') {
      value = event.target.value.replace(/^0+/, '') || 0
      document.getElementById("formNrClubs").value = value
    }
    
    setState({...state, [event.target.name]:  value});
  }


  const handleSubmit = (event) => {
    let params = {...state}
  
    props.requestRepair(params)

    event.preventDefault();
    props.cancel();
  }
  
  const handleDesiredDateChange = (event) => {
    setState({...state, expected_completion_date: event });
  }

  const handleClubRepairGripSelection = (id, price) => {
    setState({...state, grip_id: id, grip_price: price});
  }

  return (  
    <div className='col'>
    <ModalHeader >
      <h4 className="modal-title form_new_block no-margin">Request Repair</h4>
    </ModalHeader>
    <ModalBody className="modal-lg">
    <Form>
      <div className="row m-0 mt-3">
        <div className="col inner-content">
          <Form.Group as={Row} className="mb-3 form-row" controlId="formSelect">
            <Form.Label column sm="4">
              Repair Type
            </Form.Label>
            <Col sm="8">
              <Select id = "repairTypeSelect" 
                          name="repairTypeSelect"
                          value={repairTypes.filter(function(option) {
                                return option.value === repair_type_id;
                              })}
                          options={repairTypes}
                          onChange={handleRepairTypeSelectChange}
                          />
            </Col>
          </Form.Group>
          { regripping_selected && grips?.length > 0 &&
            <Form.Group as={Row} className="mb-3 form-row" controlId="formGripInventory">
              <Form.Label column sm="4">
                Grip Inventory
              </Form.Label>
              <Col sm="8">
                <div className="row m-0">
                  <GripDropdown grips={grips} display_grip_retail_price={display_grip_retail_price} handleClubRepairGripSelection={handleClubRepairGripSelection}/>
                </div>
              </Col>
            </Form.Group>
          }
          <Form.Group as={Row} className="mb-3 form-row" controlId="formNrClubs">
            <Form.Label column sm="4">
            Number of Clubs
            </Form.Label>
            <Col sm="8">
              <Form.Control type="number" rows={3} 
                            name='number_of_clubs'
                            value={parseInt(number_of_clubs || "0")}
                            onChange={handleInputChange}/>
            </Col>
            { grip_price && display_grip_retail_price && 
              <Col sm={{span: 8, offset: 4}} className="mt-2" >
                <span>
                  {`${number_of_clubs} club(s) x ${currency_symbol == '$' ? `${currency_symbol}${grip_price}` : `${grip_price}${currency_symbol}`} repair price per grip including installation = `}
                </span>
                <span className='bold'>
                  {`${currency_symbol == '$' ? `${currency_symbol}${(number_of_clubs * grip_price).toFixed(2)}` : `${(number_of_clubs * grip_price).toFixed(2)}${currency_symbol}`}`}
                </span>
              </Col>
            }
          </Form.Group>
          <Form.Group as={Row} className="mb-3 form-row" controlId="formDesc">
            <Form.Label column sm="4">
            Repair Description
            </Form.Label>
            <Col sm="8">
              <Form.Control as="textarea" rows={3} 
                            name='repair_description'
                            value={repair_description || ""}
                            onChange={handleInputChange}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3 form-row" controlId="formDesc">
          <Form.Label column sm="4">
              Desired Completion Date
            </Form.Label>
            <Col sm="8">
              <DatePicker className="form-control"
                        name='expected_completion_date'
                        id='expected_completion_date'
                        selected = {Date.parse(moment(expected_completion_date, props.date_format.toUpperCase()))}
                        value =  {Date.parse(moment(expected_completion_date, props.date_format.toUpperCase()))}
                        dateFormat={ props.date_format.replaceAll('m', 'M')}
                        onChange={handleDesiredDateChange}
                        />
            </Col>
          </Form.Group> 
        </div>
      </div>
    </Form>
    </ModalBody>
    <ModalFooter>
        <a
          className="float-start btn gems_custom_button"
          aria-label="Back"
          onClick={ e => { e.preventDefault(); props.cancel() } } >
          {window.I18n.t('multi_widgets.cancel')}
        </a>
        <a
          className={ `btn gems_custom_button float-end ${!props.member_id ? 'disabled' : ''}` }
          style={{backgroundColor: props.schemaColor, color: getComplementaryColor((props.schemaColor))}}
          onClick={props.member_id ? handleSubmit : ()=>{}} >
          Submit
        </a>
      </ModalFooter>
    </div>
  )
}

export default ReserveDemoForm
