import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
import { hexToRgb, getComplementaryColor } from '../../../shared/helpers'

class ColumnMenubar extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip()
  }

  render() {
    return (
      <Row top="xs" className={ `column-menubar ${(this.props.title !== '' || this.props.view_more === true) || this.props.editMode ? '' : 'without-title'}` } style={{backgroundColor: this.props.schemaColor+"C8"}}>
        {this.props.editMode && <div className="right-container pull-right col-2 w-25 p-0 order-2">
          <span onClick={ this.props.onRemove } className="wrapper right" data-toggle="tooltip" data-original-title={window.I18n.t('multi_widgets.column_menu.remove')}>
            <i className="fa fa-trash" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
          </span>
          { this.props.url !== null
            ? <span onClick={ this.props.onEdit } className="wrapper" data-toggle="tooltip" data-original-title={window.I18n.t('multi_widgets.column_menu.edit_content')}>
              <i className="fa fa-gear fa-fw" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
            </span> : null }
        </div>}
        {!this.props.editMode && <div className="title-elipsis float-left" style={{color: getComplementaryColor((this.props.schemaColor))}} dangerouslySetInnerHTML={{__html: this.props.title}}>
              {/*{ this.props.title }*/}
            </div>}
        {this.props.editMode && <div className="col-10 title pull-right p-0 w-75">
          <div className="column-drag-arrow pull-left cursor_pointer"
              onDragStart={ (e) => this.props.DragStart(e) }
              onDragEnd={ this.props.DragEnd }
              data-toggle="tooltip"
              data-placement="left"
              data-original-title={window.I18n.t('multi_widgets.column_menu.drag_drop_tooltip')}
              draggable>
            <i className="fa fa-arrows" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
          </div>
          <span className="pull-left column-menubar-center">
            <div className="title-elipsis float-left" style={{color: getComplementaryColor((this.props.schemaColor))}} dangerouslySetInnerHTML={{__html: this.props.title}}>
              {/*{ this.props.title }*/}
            </div>
            <div onClick={ () => this.props.onEditProps() }
                  className="wrapper right"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-original-title={window.I18n.t('multi_widgets.column_menu.edit_title')}>
              <i className="fa fa-pencil fa-fw" style={{color: getComplementaryColor((this.props.schemaColor))}}></i>
            </div>
          </span>
        </div>}
      </Row>
    )
  }
}

ColumnMenubar.propTypes = {
  title: PropTypes.any,
  view_more: PropTypes.bool,
  url: PropTypes.string,
  onEdit: PropTypes.func,
  onEditProps: PropTypes.func,
  onRemove: PropTypes.func,
  DragStart: PropTypes.func,
  DragEnd: PropTypes.func,
}

export default ColumnMenubar
