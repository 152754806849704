import React from 'react';
import { Form, Col, Table, Tab, Tabs, Spinner} from 'react-bootstrap';
import _ from 'lodash';

function QuickEntry(props) {
  return (
    <Table responsive style={{minWidth: "600px"}}>
      <thead>
        <tr>
          <th style={{minWidth: "200px"}}>Category</th>
          <th>Number of Items</th>
          <th>Total Cost</th>
          <th className='text-end'>Actions</th>
        </tr>
      </thead>
      <tbody>
        {(props.stock_order_categories).map((cat, index) => (
          <tr id={cat.category_id} key={cat.id || cat.key} className={cat.deleted ? "d-none" : ""}>
            <td className={cat.cancellation_date ? "text-decoration-line-through" : ""}>{cat.category_name}</td>
            <td className={cat.cancellation_date ? "text-decoration-line-through" : ""}>
                {cat.cancellation_date 
                ?
                <div>
                  {cat.qty}
                </div>
                :
                <Form.Control type="text" 
                    name='qty'
                    disabled={props.view_only_stock_orders}
                    className="w-50"
                    value={cat.qty}
                    onChange={(e) => props.handleQuantity(cat.category_id, null, null, e.target.value )}
                    />
                }
            </td>
            <td className={cat.cancellation_date ? "text-decoration-line-through" : ""}>
              {cat.cancellation_date
              ?
              <div>
              {cat.total_cost}
              </div>
              :
              <Form.Control type="text" 
                    name='total_cost'
                    disabled={props.view_only_stock_orders}
                    className="w-50"
                    value={cat.total_cost}
                    onChange={(e) => props.handleInputChange(cat.category_id, null, e.target.name, e.target.value )}
                    />
              }
            </td>
            <td className="text-end">
              {cat.cancellation_date && !props.view_only_stock_orders ? 
              <a className="link" onClick={()=> props.handleRestore(cat.category_id, null, cat.qty, cat.total_cost, index)} index={index} category_id={cat.category_id} qty={cat.qty} total_cost={cat.total_cost}>
                Restore
              </a> 
              : ""}
              {!props.view_only_stock_orders && <a className="ms-3 link color_red" onClick={() => props.handleRemove(cat.category_id ,null)} index={index}>Remove</a>}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>)
} 
export default QuickEntry