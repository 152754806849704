import React from 'react';
import DatePicker from "react-datepicker";
import {useState, useEffect} from 'react';
import moment from 'moment'
import Select from 'react-select';
import { VscCalendar } from 'react-icons/vsc'

function TimePeriod(props) {
  const initialState = {
    from: props.from,
    to: props.to,
    reference_date: props.reference_date,
    date_format: props.date_format,
  }

  const [state, setState] = useState(initialState);
  
  const handleRangeDateChange = (name, value) => {
    let format_value = ""
    if(name == "reference_date")
    format_value = value
    else
      format_value = moment(value).format(state.date_format.toUpperCase())

    setState({...state, [name]: format_value});
  }
  
  let module_name = props.moduleKey == "special_order" || props.moduleKey == "stock_order" ? "orders between" : props.moduleKey == "demo_club" ? "demos between" : "clubs between"
  return (
    <div className={`${props.moduleKey == 'home' ? 'd-flex justify-content-end pt-3' : 'pt-4'} pb-2 d-flex w-100 time_period_container`} style={{height: "76px"}}>
      {props.moduleKey != "home" && <div className='d-flex flex-grow-1'> 
      <div className='order-1' ><b className='add-line-height-40' style={{minWidth: "100px"}}>Include </b></div>
      <div className='order-2 h-100' style={{minWidth: "215px"}} >
      <Select className="ps-1 pe-1 h-100"
                              menuPortalTarget={document.getElementById('timePeriodOrders')}
                              menuPosition={'fixed'} 
                              name = "reference_date"
                              defaultValue={props.options_for_select.filter(function(option) {
                                return option.value === state.reference_date;
                              })}
                              options={props.options_for_select}
                              onChange={(e) => {handleRangeDateChange("reference_date", e.value)}}/>
      </div>
      <div className='order-3' style={{minWidth: "130px"}}><b style={{minWidth: "130px"}} className='add-line-height-40'>{module_name}</b></div>
      <div className='order-4 d-flex ms-1 me-1 cursor_pointer'>
        <div className='order-1 d-flex' style={{border: "1px solid #B8B8B8"}}>
          <DatePicker className="add-line-height-40  ps-1 no_border order-1 w-100px"
                                name='from'
                                id='from'
                                selected= {Date.parse(moment(state.from, state.date_format.toUpperCase()))}
                                value =  {Date.parse(moment(state.from, state.date_format.toUpperCase()))}
                                onChange={(e) => {handleRangeDateChange("from", e)}}
                                dateFormat={ state.date_format.replaceAll('m', 'M')}
                                autoComplete = "off"
                                />
          <div className='order-2 fs-4 ps-2 add-line-height-40' style={{backgroundColor: "#B8B8B8", color: "#333333"}} onClick={(e)=> {$('#from').click()}}>
            <VscCalendar className=''/>
            <span className="right fs-3 icon-down-open-mini"></span>
          </div>
        </div>
        <b className="add-line-height-40 ps-2 pe-2 order-2">and</b>
        <div className='order-3 d-flex' style={{border: "1px solid #B8B8B8"}}>
          <DatePicker className="add-line-height-40  ps-1 no_border order-1 w-100px"
                                name='to'
                                id='to'
                                selected= {Date.parse(moment(state.to, state.date_format.toUpperCase()))}
                                value =  {Date.parse(moment(state.to, state.date_format.toUpperCase()))}
                                onChange={(e) => {handleRangeDateChange("to", e)}}
                                dateFormat={ state.date_format.replaceAll('m', 'M')}
                                autoComplete = "off"
                                />
          <div className='order-2 fs-4 ps-2 add-line-height-40' style={{backgroundColor: "#B8B8B8", color: "#333333"}} onClick={(e)=> {$('#to').click()}}>
            <VscCalendar className=''/>
            <span className="right fs-3 icon-down-open-mini "></span>
          </div>
        </div>
      </div>            
      </div>}
      { props.moduleKey != 'home' && <div className='flex-grow-1 order-3'></div> }
      <div className='order-4 add-line-height-40'><span>Last Refresh:</span></div>
      <div className='order-5 add-line-height-40 pe-1 ms-1 last_refresh_class'><b>{props.refresh_date}</b></div>
      <div className='order-5 ps-1 pe-1 add-line-height-40'>
        <a onClick={(e) => props.load_component_data({key: props.moduleKey, customer_id: props.customer_id, to: state.to, from: state.from, reference_date: state.reference_date})} 
          className="btn gems_custom_button gems_orange_button ps-3 pe-3">
          Refresh
        </a>
      </div>
    </div>
    )}
export default TimePeriod