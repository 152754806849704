import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

import TextMessageCenterPageContainer from './TextMessageCenterPageContainer'

const TextMessageCenterRoot = ({customer_id}) => {
  const state = {
    dashboard: {
      customer_id: customer_id
    },
    automated_templates: {
      isLoading: true
    },
    custom_templates: {
      isLoading: true
    },
    scheduled_text_messages: {
      isLoading: true,
      page: 1
    },
    sent_text_messages_history: {
      isLoading: true,
      page: 1
    }
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <TextMessageCenterPageContainer/>
    </GlobalProvider>
  )
}

TextMessageCenterRoot.propTypes = {
  customer_id: PropTypes.number.isRequired,
}


export default TextMessageCenterRoot