import React from 'react'
import ReactDOM from 'react-dom'
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'


const localizer = momentLocalizer(moment) // or globalizeLocalizer

class CalendarBody extends React.Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const { communication_history_list } = this.props

    const handleShowCommunicationClick = (communication_id) => {
      this.props.openCommunicationModal(communication_id)
    }

    const getEventTextColor = (communication) => {
      if (communication.scheduled_communication)
        return "#FFFFFF" 
      switch(communication.entity_type) {
        case "OnlinePoForm":
          return "#F6841F";
        case "Survey":
          return "#34ebd8";
        default:
          return communication.scheduled_communication ? "#FFFFFF" : (communication.communication_type == "email" ? "#93BDFF" : "#BBD4A2")
      }
    }

    const getEventBackgroundColor = (communication) => {
      if (!communication.scheduled_communication)
        return "#FFFFFF" 
      switch(communication.entity_type) {
        case "OnlinePoForm":
          return "#F6841F";
        case "Survey":
          return "#34ebd8";
        default:
          return communication.scheduled_communication ? (communication.communication_type == "email" ? "#93BDFF" : "#BBD4A2") : "#FFFFFF"
      }
    }
    
    let events = communication_history_list.map((c) => {
      return {
        id: c.id,
        start: new Date(moment(c.send_date, this.props.date_format.toUpperCase())),
        end: new Date(moment(c.send_date, this.props.date_format.toUpperCase())),
        title: <div className="ellipsis_effect" dangerouslySetInnerHTML={{ __html: `<span class="ms-2">${c.nice_format_time} - ${c.communication_type == "email" ? "Email" : "Text Message"}</span><br><span class="ms-2">${c.subject}</span>`}}/>,
        allDay: true,
        color: getEventTextColor(c),
        resource: c
       } 
    })

    return (
      <div className="row m-0 big_calendar h-100">
        <Calendar
          localizer={localizer}
          events={ events }
          toolbar={true}
          views={{
            week: true,
            month: true
          }}
          showAllEvents={true}
          popup={true}
          defaultView='month'
          onSelectEvent = { (v,e) => { handleShowCommunicationClick(v.resource.id)} }
          style={{ height: "100%" }}
          eventPropGetter={(event) => {
                return { style: { fontWeight: "500", marginLeft: "10px", width: "90%", backgroundColor: event.resource.scheduled_communication ? getEventBackgroundColor(event.resource) : "#FFFFFF", color: event.color, border: `1.5px solid ${event.color}`, borderRadius: '25px'} }
              }}/>
      </div>
    )
  }
}

export default CalendarBody
