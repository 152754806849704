import { connect } from 'react-redux'
import UnsentEmailsSection from '../components/UnsentEmailsSection'
import { paginateUnsentEmails, openCommunicationTemplateModal, unscheduleEmail, deleteDraftEmail, pauseScheduledEmail, resumeScheduledEmail } from '../actions'

const mapStateToProps = (state) => ({
  emails: state.scheduled_emails.emails,
  quick_search_value: state.dashboard.quick_search_value,
  isLoading: state.scheduled_emails.isLoading,
  total_pages: state.scheduled_emails.scheduled_emails_total_pages,
  page: state.scheduled_emails.page
})

const mapDispatchToProps = (dispatch) => ({
  paginateUnsentEmails: () => dispatch(paginateUnsentEmails()),
  showBulkTemplate: (data) => dispatch(openCommunicationTemplateModal(data, "scheduled_email")),
  unscheduleEmail: (data) => dispatch(unscheduleEmail(data)),
  deleteDraftEmail: (data) => dispatch(deleteDraftEmail(data)),
  pauseScheduledEmail: (data) => dispatch(pauseScheduledEmail(data)),
  resumeScheduledEmail: (data) => dispatch(resumeScheduledEmail(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsentEmailsSection)