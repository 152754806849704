import React from 'react'
import PropTypes from 'prop-types'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'
import MatchingFieldsPageContainer from './MatchingFieldsPageContainer'


const CallawayConnectMatchFieldsRoot = ({customer_id, admin_side, callaway_fields, gs_fields, matched_fields, unmatched_fields, ignored_fields}) => {
  const state = {
    callaway_connect: {
      customer_id: customer_id,
      admin_side: admin_side,
      callaway_fields: callaway_fields,
      gs_fields: gs_fields,
      matched_fields: matched_fields,
      unmatched_fields: unmatched_fields,
      ignored_fields: ignored_fields,
      fields_to_create: [],
    }
  }
  
  const store = configureStore(rootReducer, state)

  // expose the dispatch function to non-React code
  // this is needed because we want to open popups from the dropdown menu
  window.gsg.dispatch = store.dispatch

  return (
    <GlobalProvider store={ store }>
      <MatchingFieldsPageContainer/>
    </GlobalProvider>
  )
}


export default CallawayConnectMatchFieldsRoot