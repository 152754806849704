import { ActionTypes } from '../actions'

const {

} = ActionTypes

const initialState = {
}
  
const general_settings = (state = initialState, action) => {
  switch (action.type) {

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default general_settings