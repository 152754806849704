import React from 'react'
import { Col, Spinner } from 'react-bootstrap';
import DashboardAccordion from '../../shared/components/DashboardAccordion';
import TemplateTile from '../../shared/components/TemplateTile';
import TextMessageTemplateBody from './TextMessageTemplateBody';

function CustomTemplatesSection(props) {

  const {custom_text_message_templates, default_text_message_templates, quick_search_value, customer_id} = props;

  const get_thumbnail_template_body = (template) => {
    return <div className='template_content'>
       <TextMessageTemplateBody template={template}/>
    </div>
  }

  const get_accodition_items = () => {
    const items_list = [
      {
        key: "text_message_center_customer_templates",
        header: {
          classes: "link",
          content: `${customer_id == 437 ? "Template Library" : "My Templates"}`
        },
        body: {
          classes: "",
          content: custom_text_message_templates.map((et, index) => (
            (!quick_search_value || (quick_search_value && (et.searchable_fields.map((et) => (et.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
            <TemplateTile 
              template={et} 
              key={et.id}
              default_template={false}
              deleteTemplate={props.deleteTemplate}
              cloneTemplate={props.cloneTemplate}
              showTemplate={props.showTemplate}
              thumbnail_template_body={get_thumbnail_template_body(et)}
            />
          ))
        }
      }
    ]

    if (customer_id != 437)
      items_list.push({
          key: "text_message_center_library_templates",
          header: {
            classes: "link",
            content: "Template Library"
          },
          body: {
            classes: "",
            content: default_text_message_templates.map((et, index) => (
              (!quick_search_value || (quick_search_value && (et.searchable_fields.map((et) => (et.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
              <TemplateTile 
                template={et} 
                key={et.id}
                default_template={true}
                cloneTemplate={props.cloneTemplate}
                showTemplate={props.showTemplate}
                thumbnail_template_body={get_thumbnail_template_body(et)}
              />
            ))
          }
      })

    return items_list
  }

  return (
    <div className='row section_container mb-4'>
      <Col xs={12} className="mb-3 p-0 communication_center_header_section">
        TEXT MESSAGES TEMPLATES
      </Col>
      { props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
        <Col xs={12} className="p-0">
          <DashboardAccordion
            id="text_message_center_custom_templates"
            items={get_accodition_items()}
          />
        </Col>
      }
    </div>
  )
}

export default CustomTemplatesSection
