import { connect } from 'react-redux'
import DemoClubsTile from '../presentational/demo_clubs_tile'
import { viewInventory, getReserveDemo, setActiveColumn } from '../../actions'

const mapStateToProps = (state, props) => ({
  colId: props.colId,
  edit_mode: state.filters.edit_mode,
  member_id: state.filters.member_id,
  enable_reservation: props.options.enable_reservation,
  enable_availability_notification: props.options.enable_availability_notification,
  days_in_advances: props.options.days_in_advances,
  data: {
        
        current_demos: state.filters.demoClubs.current_demos,
        reserved_demos: state.filters.demoClubs.reserved_demos,
        past_demos: state.filters.demoClubs.past_demos,
      },
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
})

const mapDispatchToProps = (dispatch) => ({
  viewInventory: (colId) => dispatch(viewInventory(colId)),
  getReserveDemo: (id, colId) => dispatch(getReserveDemo(id, colId)),
  setActiveColumn: (id) => dispatch(setActiveColumn(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoClubsTile)