import { connect } from "react-redux";
import {
	changeTemplateEnableValue,
	changeTemplateDemoRemindersDaysValue,
	changeTemplateAttributeValue,
	resetTemplates,
} from "../actions";
import AutomatedTemplatesSection from "../components/AutomatedTemplatesSection";

const mapStateToProps = (state) => ({
	automated_email_templates:
		state.automated_templates.automated_email_templates,
	settings: state.automated_templates.settings,
	isLoading: state.automated_templates.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
	changeTemplateEnableValue: (data) =>
		dispatch(changeTemplateEnableValue(data)),
	changeTemplateDemoRemindersDaysValue: (data) =>
		dispatch(changeTemplateDemoRemindersDaysValue(data)),
	changeTemplateAttributeValue: (data) =>
		dispatch(changeTemplateAttributeValue(data)),
	resetTemplates: (data) => dispatch(resetTemplates(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutomatedTemplatesSection);
