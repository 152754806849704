import { connect } from 'react-redux'
import DropShipComponent from "../components/DropShipComponent";
import {
  changeSelectedDropShip,
  markDropShipAsDeleted,
  setDropShipEditMode,
  cancelDropShipEdit,
  saveDropShipEdit,
  editDropShipField,
  changeDropShipEnabled,
  saveNewDropShip,
} from "../actions";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  currencySymbol: state.general_settings.currency_symbol,
  formType: state.general_settings.form_type,
  dropShipType: state.general_settings.drop_ship_type,
  maxCredits: state.general_settings.total_credits,
  checkoutItemsLimit: state.general_settings.checkout_items_limit,

  genericForm: state.customer.generic_form,
  customer: state.customer,

  guestForm: (state.customer.generic_form && state.customer.authentication_customer_type === 'guest') || (state.customer.customer_type === 'player' && state.customer.player && _.isEmpty(state.customer.player.member_id)),
  memberForm: state.customer.customer_details && !(state.customer.customer_type === 'player' && state.customer.player && !state.customer.player.member_id),

  dropShipEnabled: state.customer.drop_ship_enabled,
  selectedDropShip: (state.customer.drop_ship_addresses || []).filter((ds, index) => ds.selected)[0] || {},
  newDropShipIndex: state.customer.new_drop_ship_index,
  dropShipError: state.form_state.dropShipError,

  orderSubmitted: state.form_state.orderSubmitted,
  isCartView: state.form_state.isCartView,
  preview: state.form_state.preview,
  editOrder: state.form_state.editMode,

  itemsInCart: state.cart.itemsInCart,
  totalCost: state.cart.totalCost.toFixed(2),
  cartErrorMessage: state.cart.errorMessage,
  isEnoughStockForOrders: state.cart.isEnoughStockForOrders,
})

const mapDispatchToProps = (dispatch) => ({
  changeSelectedDropShip: (drop_ship) => dispatch(changeSelectedDropShip(drop_ship)),
  markDropShipAsDeleted: (drop_ship) => dispatch(markDropShipAsDeleted(drop_ship)),
  setDropShipEditMode: (drop_ship) => dispatch(setDropShipEditMode(drop_ship)),
  cancelDropShipEdit: (drop_ship) => dispatch(cancelDropShipEdit(drop_ship)),
  saveDropShipEdit: (drop_ship) => dispatch(saveDropShipEdit(drop_ship)),
  saveNewDropShip: (drop_ship) => dispatch(saveNewDropShip(drop_ship)),
  editDropShipField: (drop_ship, field_id, value) => dispatch(editDropShipField(drop_ship, field_id, value)),
  changeDropShipEnabled: () => dispatch(changeDropShipEnabled()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropShipComponent)
