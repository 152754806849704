import { ActionTypes } from '../actions'

const {
  CREATE_TAB,
  EDIT_TAB,
  UPDATE_TAB,
  REMOVE_TAB,
  SWAP_TABS,
  SAVE_TABS,
} = ActionTypes

const tabs = (state = [], action) => {
  switch (action.type) {
    case CREATE_TAB: {
      const maxIndex = state.sort( (a, b) => a.index >= b.index ? -1 : 1 ).map( t => t.index )[0] || 0
      if (state.length === 1 && state[0].title === '') {
        return [
          { 
            ...state[0],
            title: action.title,
          },
        ]
      } else {
        return [
          ...state,
          {
            id: action.id,
            title: action.title,
            editing: false,
            deleting: false,
            index: maxIndex + 1,
            width: null
          },
        ]
      }
    }

    case EDIT_TAB: {
      return state.map(tab =>
        (tab.id === action.id)
          ? {...tab, editing: !tab.editing}
          : tab)
    }

    case SAVE_TABS: {
      return action.tabs
    }

    case UPDATE_TAB: {
      return state.map(tab =>
        (tab.id === action.id)
          ? {...tab, title: action.title, width: action.width}
          : tab)
    }
    case REMOVE_TAB: {
      return state.filter(tab => tab.id !== action.id)
    }
    case SWAP_TABS: {
      const tab1 = state.filter(tab => tab.id === action.tabID1)[0]
      const tab2 = state.filter(tab => tab.id === action.tabID2)[0]
      return state.map(tab =>
        (tab.id === action.tabID1)
          ? {...tab, index: tab2.index}
          : tab).map(tab =>
        (tab.id === action.tabID2)
          ? {...tab, index: tab1.index}
          : tab)
    }
    default:
      return state
  }
}

export default tabs
