import React, {useState,useEffect, useRef} from 'react';
import {FaCloudUploadAlt} from 'react-icons/fa'
import {Form} from 'react-bootstrap';

function UploadFile(props){
	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
   
	};
  const myInput = useRef(null);

  const clickElement = () => {
    // To simulate a user focusing an input you should use the
    // built in .focus() method.
    myInput.current?.click();

    // To simulate a click on a button you can use the .click()
    // method.
    // myInput.current?.click();
  }

  useEffect(() => {
    if(isSelected)
      handleSubmission()
  });

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    formData.append('id', props.item_id);
    formData.append('customer_id', props.customer_id);
    if(props.stock_order_item_id)
    {
      formData.append('stock_order_item_id', props.stock_order_item_id);
      formData.append('stock_order_category_id', props.stock_order_category_id);
    }
    props.uploadFile(formData)
    setSelectedFile(null)
    setIsSelected(false)
    document.getElementById('files_id').value= null;
	};

	return(
    <Form.Group className="mb-3" controlId="formUploadAttch">
      <input className = "d-none" type="file" name="file" onChange={changeHandler} id="files_id" ref={myInput} accept={props.stock_order_item_id ? ".jpg,.jpeg,.png,.webp,.gif" : ".jpg,.jpeg,.png,.webp,.gif,.pdf,.doc,.docx,.xls,.xlsx"}/>          
      <div className="row pull-right m-0" style={{display: "block"}}>
        <a class="fa fa-cloud-upload fs-4" aria-hidden="true" onClick={clickElement}></a>
      </div>
    </Form.Group>
	)
}
export default UploadFile