import { connect } from 'react-redux'
import { closeAddBlockModal, saveNewBlock } from '../actions'
import AddBlockModal from '../components/AddBlockModal'

const mapStateToProps = (state) => ({
  customer_id: state.profile_setup.customer_id,
  all_tabs: state.profile_setup.all_tabs,
  modal_add_block_open: state.profile_setup.modal_add_block_open,
  current_tab: state.profile_setup.current_tab
})

const mapDispatchToProps = (dispatch) => ({
  closeAddBlockModal: () => dispatch(closeAddBlockModal()),
  saveNewBlock: (title, tab_id) => dispatch(saveNewBlock(title, tab_id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBlockModal)