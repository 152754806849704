import React, { useState, useRef } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from '../../stock_orders/components/Modal';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import View from 'react'

function EditBlockTitleModal(props) {

  const [title, setTitle] = useState(props.edited_block.title);

  const handleClose = () => {
    props.closeEditBlockTitleModal()
  }

  const handleSubmit = () => {
    props.saveBlockTitle(title, props.current_tab.id, props.edited_block.id)
    handleClose()
  }

  return (props.modal_edit_block_title_open && <div id="addBlockModalDiv">
    <Modal isOpen={props.modal_edit_block_title_open} scrollable={true} className="" dialogClassName="modal-dialog-centered">
      <ModalHeader className="header">
        <h4 className="modal-title form_new_edit m-0">Edit Title</h4>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="row mt-3">
            <div className="col inner-content">
              <Form.Group as={Row} className="mb-3" controlId="formTabForm">
                <Form.Label column sm="4">
                  <div className="row">
                    <div className="col-12">Title</div>
                  </div>
                </Form.Label>
                <Col sm="8">
                  <input type="text"
                    placeholder=""
                    className="form-control"
                    value={ title }
                    onInput={ e => setTitle(e.target.value) }
                    autoFocus />
                </Col>
              </Form.Group>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="float-start btn gems_custom_button "
          aria-label="Cancel"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="btn gems_custom_button gems_orange_button float-end"
          onClick={handleSubmit}
        >
          Save
        </button>
      </ModalFooter>
    </Modal>
  </div>);
} export default EditBlockTitleModal