import { connect } from 'react-redux'
import Body from '../components/Body'
import { openAddTabModal, openManageTabsModal, setCurrentTab, switchView, saveMemberChanges, uploadProfilePicture, clearPhoto } from '../actions'

const mapStateToProps = (state, props) => ({
  all_tabs: state.profile_setup.all_tabs,
  view_mode: state.profile_setup.view_mode,
  current_tab: state.profile_setup.current_tab,
  modal_manage_tabs_open: props.modal_manage_tabs_open !== undefined ? props.modal_manage_tabs_open : state.profile_setup.modal_manage_tabs_open,
  modal_add_tab_open: props.modal_add_tab_open !== undefined ? props.modal_add_tab_open : state.profile_setup.modal_add_tab_open,
  member_metadata_values: props.member_metadata_values || state.profile_setup.member_metadata_values,
  member_info: props.originalMemberInfo || state.profile_setup.member_info,
  member_view: props.member_view || state.profile_setup.preview_member_profile || false,
  notification_settings: state.profile_setup.notification_settings,
  profile_picture_url: state.profile_setup.profile_picture_url || null,
  member_id: state.filters ? state.filters.member_id : state.profile_setup.member_id,
  customer_id: state.filters ? state.filters.customer_id : (state.profile_setup.customer_id || null),
  setMemberInfo: props.setMemberInfo,
  memberInfo: props.memberInfo,
  centralPage: props.centralPage || false
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentTab: (id) => dispatch(setCurrentTab(id)),
  openManageTabsModal: () => dispatch(openManageTabsModal()),
  openAddTabModal: () => dispatch(openAddTabModal()),
  switchView: (view_mode) => dispatch(switchView(view_mode)),
  saveMemberChanges: (memberInfo, member_metadata, updatedMemberMetadata, memberNotifications, clear_photo, centralPage) => dispatch(saveMemberChanges(memberInfo, member_metadata, updatedMemberMetadata, memberNotifications, clear_photo, centralPage)),
  uploadProfilePicture: data => dispatch(uploadProfilePicture(data)),
  clearPhoto: () => dispatch(clearPhoto())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Body)