import { connect } from 'react-redux'
import TabForm from '../presentational/tab_form'
import { createTab, displayMode, DISPLAY_MODES } from '../../actions'

const mapStateToProps = state => ({
  tabs: state.tabs,
  activeTab: state.filters.active_tab,
  schemaColor: state.general_settings.color_schema,
})

const mapDispatchToProps = dispatch => ({
  createTab: (_title) => {
    dispatch(createTab(_title))
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabForm)
