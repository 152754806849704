import { connect } from 'react-redux'
import CalendarBody from '../components/CalendarBody'

import {editStockOrder, deleteStockOrder, openSplitStockOrder, openPlaceStockOrder, openReceiveStockOrder, openFinalizeStockOrder} from '../actions'

const mapStateToProps = (state) => ({
  to_place: state.stock_order.to_place,
  placed: state.stock_order.placed,
  delivered: state.stock_order.delivered,
  calendar_filter_date: state.stock_order.calendar_filter_date,
  filters_list: state.stock_order.filters_list.length > 0 ? state.stock_order.filters_list : "all",
  quick_search_value: state.stock_order.quick_search_value || null,
  date_format: state.stock_order.date_format
})

const mapDispatchToProps = (dispatch) => ({
  editStockOrder: id => dispatch(editStockOrder(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarBody)
