import React from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter} from './Modal';
import { Row, Form, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {useState} from 'react';
import UploadFile from './UploadFile';
import _, { includes } from 'lodash';

function LogosModal(props) {
  
  const initialState = {selected_logo: props.current_item.logo_id}
  const [state, setState] = useState(initialState);

  const handleClose = () => {
    props.closeLogosModal()
  }

  const handleDeleteLogo = (id) => {
    props.deleteLogo(id)
  }
  
  const handleSubmit = () => {
    let logos = {id: selected_logo, stock_order_item_id: props.current_item.id, stock_order_category_id: props.current_category_id, url: _.find(logos_list, (l) =>  (l.id == selected_logo))["url"]}
    props.handleLogoChange(props.current_category_id, props.current_item.id, selected_logo, logos)
    handleClose()
  }
  
  const handleSelectLogo = (logo_id) => {
    setState({...state, selected_logo: logo_id});
  }

  const {modal_logos_open, customer_id, logos_list, current_item, item_id, current_logos} = props;
  const {selected_logo} = state;

  return (
    <div className="modal_receive_stock_order_container">
      <Modal isOpen={modal_logos_open}>
        <ModalHeader >
          <h4 className="modal-title form_new_edit no-margin">Select Logo</h4>
        </ModalHeader>
        <ModalBody>
          <Form>
          <div class="h-100 logos_modal_body row">
            <div class="row m-0 h-100 p-2">
              <div class="col-12" style={{height: "100px", minHeight: "100px;"}}>
                <div class="row">
                <div class="col-12 p-0 border-bottom">
                Selected Logo
                </div>
                </div>
                <div class="row pt-2">
                <div class="col-12 p-0" id="no_selected_logos">
                {selected_logo 
                  ?
                  (_.find(current_logos, (l) =>  (l.id == selected_logo)) ||  _.find(logos_list, (l) =>  (l.id == selected_logo))) &&
                      <div className="logo_container width_50px mb-1" style={{backgroundImage: `url(${((_.find(current_logos, (l) =>  (l.id == selected_logo)) ||  _.find(logos_list, (l) =>  (l.id == selected_logo)) || {url: null})["url"])})`, dataSrc: (_.find(current_logos, (l) =>  (l.id == selected_logo)) ||  _.find(logos_list, (l) =>  (l.id == selected_logo)) || {url: null})["url"], height: (_.find(current_logos, (l) =>  l.id == selected_logo) ||  _.find(logos_list, (l) =>  (l.id == selected_logo)) || {url: null})["url"] ? "60px" : "auto"}}></div>
                  :
                  <i>
                    No logo selected
                  </i>

                }
        
                </div>
                </div>
                </div>
                <div class="col-12" style={{height: "calc(100% - 250px)", minHeight: "85px"}}>
                  <div class="row">
                    <div class="col-12 p-0 border-bottom">
                      <i>
                      Upload a new logo or select from the existing list below
                      </i>
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div class="me-2 p-0 mb-2 select_logo position-relative width_90">
                      <div class="border border_grey h-100 logo_thumbnail thumb-preview thumbnail w-100">
                        <UploadFile item_id={item_id} stock_order_item_id={current_item.id} stock_order_category_id={props.current_category_id} uploadFile={props.uploadFile} customer_id={customer_id}/>
                      </div>
                    </div>
                    { 
                    logos_list.map((logo, index_logo) => (
                    <div class= "me-2 p-0 mb-2 select_logo position-relative width_90" order={index_logo} data-logo-id={logo.id}>
                      <div class={logo.id == selected_logo ? "active border border_grey h-100 logo_thumbnail thumb-preview thumbnail w-100" : "border border_grey h-100 logo_thumbnail thumb-preview thumbnail w-100"} data-logo-id={logo.id}>
                        <div className="logo_container m-1" style={{backgroundImage: `url(${logo.url})`, dataSrc: logo.url, height: "60px"}} onClick={(e) => {handleSelectLogo(logo.id)}}></div>
                        <div class="logo_actions">
                          <a data-confirm="Are you sure? This logo will be removed from library but maintained in orders selection." title="Delete" class="fa fa-remove color_red file_delete_new" onClick={(e) => {handleDeleteLogo(logo.id)}}></a>
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
        <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
          <a
            className="btn gems_custom_button gems_orange_button float-end"
            onClick={handleSubmit}
          >
            Select
          </a>
        </ModalFooter>
      </Modal>
    </div>
  );
} export default LogosModal