import { connect } from 'react-redux'
import MemberProfileTile from '../presentational/member_profile_tile'
import { viewInventory, updateMemberProfile, setActiveColumn } from '../../actions'

const mapStateToProps = (state, props) => ({
  colId: props.colId,
  edit_mode: state.filters.edit_mode,
  member_id: state.filters.member_id,
  member_profile_data: props.options.member_profile_data.filter((ii) => 
  { return ii.display || !ii.category}),
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  member_profile_values: state.filters.member_profile_values,
})

const mapDispatchToProps = (dispatch) => ({
  updateMemberProfile: (data) => dispatch(updateMemberProfile(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProfileTile)