import { connect } from 'react-redux'
import { matchCategory, unmatchCategory, undoNewCategory, setMatchCategoryDropdown, matchNewCategoryDropdown, createNewCategoryDropdown, setParentNewCategoryDropdown } from '../actions'
import MatchingCategoriesTable from '../components/MatchingCategoriesTable'

const mapStateToProps = (state) => ({
  callaway_categories: state.callaway_connect.callaway_categories,
  gs_categories: state.callaway_connect.gs_categories,
  matched_categories: state.callaway_connect.matched_categories,
  unmatched_categories: state.callaway_connect.unmatched_categories,
  categories_to_create: state.callaway_connect.categories_to_create,
  admin_side: state.callaway_connect.admin_side,
})

const mapDispatchToProps = (dispatch) => ({
  matchCategory: (cc_category_id) => dispatch(matchCategory(cc_category_id)),
  unmatchCategory: (cc_category_id, matching_action) => dispatch(unmatchCategory(cc_category_id, matching_action)),
  undoNewCategory: (cc_category_id) => dispatch(undoNewCategory(cc_category_id)),
  setMatchCategoryDropdown: (cc_category_id, category) => dispatch(setMatchCategoryDropdown(cc_category_id, category)),
  matchNewCategoryDropdown: (cc_category_id) => dispatch(matchNewCategoryDropdown(cc_category_id)),
  createNewCategoryDropdown: (cc_category_id) => dispatch(createNewCategoryDropdown(cc_category_id)),
  setParentNewCategoryDropdown: (cc_category_id, parent_category) => dispatch(setParentNewCategoryDropdown(cc_category_id, parent_category)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchingCategoriesTable)
