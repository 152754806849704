import { ActionTypes } from '../actions'

const {
} = ActionTypes

const initialState = {
}
  
const dashboard = (state = initialState, action) => {

  switch (action.type) {  
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default dashboard