import React from 'react'
import { Col, Table, Spinner, Row } from 'react-bootstrap';

function SentEmailsSection(props) {

  const {emails, quick_search_value, page, total_pages} = props;

  const handlePagination = (event) => {
    props.paginateCommunicationHistory()
  }

  const handleShowBulkEmailModalClick = (event) => {
    const template_id = event.target.getAttribute('data-template-id')
    props.showBulkTemplate(template_id)
  }

  return (
      <div className='row section_container mb-4'>
        <Col xs={12} className="mb-3 p-0 communication_center_header_section">
          SENT EMAILS
        </Col>
        { props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <Col xs={12} className="p-0">
            <Row>
              <Col xs={12} className="communication_table_container">
                <Table striped className="tableFixHead custom_template_communications">
                  <thead>
                    <tr>
                      <th className='text-center' style={{width: "105px"}}>Date</th>
                      <th className='text-center' style={{width: "105px"}}>Time</th>
                      <th>Subject</th>
                      <th>Recipients</th>
                      <th className='text-center' style={{width: "105px"}}>Open Rate</th>
                      <th className='text-center' style={{width: "105px"}}>Click Rate</th>
                      <th className='text-center' style={{width: "75px"}}/>
                    </tr>
                  </thead>

                  <tbody>
                    {emails.map((email, index) => 
                      (!quick_search_value || (quick_search_value && (email.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                      <tr key={index}>
                        <td className='text-center'> {email.sent_date} </td>
                        <td className='text-center'> {email.nice_format_time} </td>
                        {email.entity_preview_link && <td> {<div dangerouslySetInnerHTML={{ __html: email.subject + ` - <a class="link" href="${email.entity_preview_link}" data-method="get">${email.entity_type == "OnlinePoForm" ? "View Online Form" : "View Survey"}</a>`}}/>} </td>}
                        {!email.entity_preview_link && <td> {email.subject} </td>}
                        <td> 
                          {email.recipients}
                        </td>
                        <td className='text-center' style={{width: "105px"}}> {email.open_rate} </td>
                        <td className='text-center' style={{width: "105px"}}> {email.click_rate} </td>
                        <td className='text-center'> 
                          <span 
                            className='link' 
                            onClick={handleShowBulkEmailModalClick} 
                            data-template-id={email.id}
                          > 
                            Preview 
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              {emails.length > 0 && page < total_pages && <Col xs={12}>
                <span 
                  className='link'
                  onClick={handlePagination}
                >
                  View More
                </span>
              </Col>}
              {emails.length <= 0 && <Col xs={12}>
                <span>
                  No sent emails
                </span>
              </Col>}
            </Row>
          </Col>
        }
      </div>
  )
}

export default SentEmailsSection
