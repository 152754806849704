import { ActionTypes } from '../actions'

const {
  CHANGE_CUSTOMER_AUTH_TYPE,
  LOGIN_MEMBER,
  CHANGE_GENERIC_MEMBER,
  CHANGE_SELECTED_DROP_SHIP,
  MARK_DROP_SHIP_DELETED,
  SET_DROP_SHIP_EDIT_MODE,
  CANCEL_DROP_SHIP_EDIT,
  SAVE_DROP_SHIP_EDIT,
  EDIT_DROP_SHIP_FIELD,
  SWITCH_DROP_SHIP_ENABLED,
  SAVE_NEW_DROP_SHIP_ADDRESS,
} = ActionTypes

const initialState = {
}
  
const customer = (state = initialState, action) => {
  var customer_details_copy;
  var drop_ship_addresses_copy;
  var drop_ship_copy;
  const drop_ship_fields = ['line_1', 'line_2', 'city', 'state', 'zip', 'description']
  switch (action.type) {
    case CHANGE_CUSTOMER_AUTH_TYPE:
      return {
        ...state,
        authentication_customer_type: action.data.type,
      }
    case LOGIN_MEMBER:
      return {
        ...state,
        invalid_credentials: action.data.invalid_credentials,
        customer_details: action.data.invalid_credentials ? null : action.data.customer_details,
        drop_ship_addresses: action.data.invalid_credentials ? [] : action.data.drop_ship_addresses,
      }
    case CHANGE_GENERIC_MEMBER:
      return {
        ...state,
        invalid_credentials: false,
        customer_details: null,
        drop_ship_addresses: [],
      }
    case SWITCH_DROP_SHIP_ENABLED:
      return {
        ...state,
        drop_ship_enabled: !state.drop_ship_enabled
      }
    case CHANGE_SELECTED_DROP_SHIP:
      drop_ship_addresses_copy = state.drop_ship_addresses
      drop_ship_addresses_copy.forEach((ds) => ds.selected = false)
      if (action.data.drop_ship) {
        drop_ship_addresses_copy = drop_ship_addresses_copy.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id)
        drop_ship_addresses_copy.push({...action.data.drop_ship, ...{selected: true}})
      }
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }
    case MARK_DROP_SHIP_DELETED:
      drop_ship_addresses_copy = state.drop_ship_addresses.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id)

      if (!action.data.drop_ship.profile_category_id.includes('new')) {
        drop_ship_addresses_copy.push({...action.data.drop_ship, ...{deleted: true, selected: false}})
      }

      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }

    case SET_DROP_SHIP_EDIT_MODE:
      drop_ship_addresses_copy = state.drop_ship_addresses
      drop_ship_addresses_copy = drop_ship_addresses_copy.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id)
      drop_ship_addresses_copy.push({...action.data.drop_ship, ...{edit: true}})
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }
    case CANCEL_DROP_SHIP_EDIT:
      drop_ship_addresses_copy = state.drop_ship_addresses.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id)
      let initial_drop_ship = action.data.drop_ship

      Object.entries(initial_drop_ship.address_fields).forEach(field => {
        const [field_key, field_data] = field;
        initial_drop_ship.address_fields[field_key]["new_value"] = initial_drop_ship.address_fields[field_key]["value"]
      });

      drop_ship_addresses_copy.push({...initial_drop_ship, ...{edit: false}})
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }
    case EDIT_DROP_SHIP_FIELD:
      drop_ship_addresses_copy = state.drop_ship_addresses.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id)
      drop_ship_copy = action.data.drop_ship
      let drop_ship_field_id = action.data.field_id
      let drop_ship_field_value = action.data.value
      drop_ship_copy.address_fields[drop_ship_field_id].new_value = drop_ship_field_value

      drop_ship_addresses_copy.push(drop_ship_copy)
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }
    case SAVE_DROP_SHIP_EDIT:
      drop_ship_addresses_copy = state.drop_ship_addresses.filter((ds, index) => ds.profile_category_id != action.data.drop_ship.profile_category_id);
      drop_ship_copy = action.data.drop_ship
      drop_ship_copy.edit = false
      drop_ship_copy.label = ''

      Object.entries(drop_ship_copy.address_fields).forEach(field => {
        const [field_key, field_data] = field;
        if (drop_ship_copy.address_fields[field_key].new_value !== undefined) {
          drop_ship_copy.address_fields[field_key].value = drop_ship_copy.address_fields[field_key].new_value
        }

        if (drop_ship_copy.address_fields[field_key].label.toLowerCase().includes('description'))
          drop_ship_copy.label = drop_ship_copy.address_fields[field_key].value
      });

      drop_ship_addresses_copy.push({...drop_ship_copy, ...{edit: false, saved_edit_changes: true}})
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy
      }

    case SAVE_NEW_DROP_SHIP_ADDRESS:
      let new_drop_ship_address = {
        profile_category_id: `new_${state.new_drop_ship_index}`,
        save: false,
        deleted: false,
        selected: true,
        order: state.drop_ship_addresses.length,
        address_fields: {...action.data.drop_ship},
        label: action.data.drop_ship["description"].value,
      }

      drop_ship_addresses_copy = state.drop_ship_addresses
      drop_ship_addresses_copy.forEach((ds) => ds.selected = false)
      drop_ship_addresses_copy.push(new_drop_ship_address)
      return {
        ...state,
        drop_ship_addresses: drop_ship_addresses_copy,
        new_drop_ship_index: state.new_drop_ship_index + 1,
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default customer