import { connect } from 'react-redux'
import Header from '../components/Header.jsx'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)