import React from 'react'
import {useState} from 'react';
import {Form} from 'react-bootstrap';
import {getShippingAddressErrorText} from "../actions";

function DropShipComponent (props){
  const {
    genericForm,
    dropShipType,
    fontColor,
    customer,
    dropShipError,
    dropShipEnabled,
    selectedDropShip,
    newDropShipIndex,
    editOrder,
    guestForm,
    memberForm,

    changeSelectedDropShip,
    markDropShipAsDeleted,
    setDropShipEditMode,
    cancelDropShipEdit,
    saveDropShipEdit,
    editDropShipField,
    changeDropShipEnabled,
    saveNewDropShip,
  } = props;


  const dropShipFieldsHash = [
    {label: "Address Line 1", id: "line_1", required: true},
    {label: "Address Line 2", id: "line_2", required: false},
    {label: "City", id: "city", required: true},
    {label: "State", id: "state", required: true},
    {label: "Zip Code", id: "zip", required: true},
    {label: "Description", id: "description", required: false}]

  let emptyDropShipNewFields = dropShipFieldsHash.reduce((hash, elem, index) => {
    hash[elem.id] = {
      label: elem.label,
      metadatum_id: elem.id,
      required: elem.required,
      value: '',
    }
    return hash;
  }, {})


  const initialState = {
    addNewShippingAddress: false,
    newDropShipFields: emptyDropShipNewFields
  }

  const [state, setState] = useState(initialState);

  const newShippingAddressErrorText = getShippingAddressErrorText(
    dropShipFieldsHash.filter((field) => !["line_2", "description"].includes(field.id))
      .reduce((array, field, index) => {
        array.push({label: field.label, value: state.newDropShipFields[field.id].value})
        return array;
      }, []))


  const handleDropShipCheckboxInputChange = (event) => {
    if (dropShipEnabled) {
      changeSelectedDropShip(null)
    }

    changeDropShipEnabled()
  }

  const handleChangeNewAddressField = (event, field_id) => {
    let fields = state.newDropShipFields

    fields[field_id]["value"] = event.target.value
    setState({...state, newDropShipFields: fields})

    if (guestForm) {
      let new_address_specific_fields = {
        profile_category_id: 'new_guest',
        address_fields: {...fields}
      }
      changeSelectedDropShip(new_address_specific_fields)
    }
  }

  const handleClickNewAddress = (event) => {
    setState({ ...state, newDropShipFields: {...emptyDropShipNewFields}, addNewShippingAddress: true})
  }

  const handleClickCancelNewAddress = (event) => {
    setState({ ...state, newDropShipFields: {...emptyDropShipNewFields}, addNewShippingAddress: false})
    changeSelectedDropShip(null)
  }

  const handleClickSaveNewAddress = (event) => {
    setState({ ...state, newDropShipFields: {...emptyDropShipNewFields}, addNewShippingAddress: false})
    saveNewDropShip(state.newDropShipFields)
  }

  const handleClickDelete = (e, drop_ship_address) => {
    markDropShipAsDeleted(drop_ship_address)
    e.stopPropagation()
  };

  const handleClickEdit = (e, drop_ship_address) => {
    setDropShipEditMode(drop_ship_address)
    e.stopPropagation()
  };

  const isFieldNameRequired = (name) => {
    return (name.toLowerCase().includes('address') && !name.toLowerCase().replace(/ /g,'').includes('line2'))|| name.toLowerCase().includes('city') || name.toLowerCase().includes('state') || name.toLowerCase().includes('zip');
  }

  return (
    <>
      <style>{`\
        .form-check-input[type="checkbox"]:checked, .form-check-input[type="radio"]:checked{\
          background-color: ${fontColor}!important;\
          border-color: ${fontColor}!important;\
        }\
        .form-check-input[type="checkbox"]:focus, .form-check-input[type="radio"]:focus{\
          border-color: ${fontColor}!important;\
        }\
      `}</style>

      <div id='cart-customer-drop-ship'>
        {dropShipType==='required' && <>
          <span>Your order will be shipped to the specified address.</span>
          { memberForm && <span>Please select a shipping address or add a new one!</span>}

        </>}

        {dropShipType==='optional' && <>

          { memberForm ?
            <span>You have the option to select shipping to your own address or pick it from the golf club.</span> :
            <>
              {editOrder && selectedDropShip ?
                <span>Your order will be shipped to the specified address:</span>
                : <span>You have the option to add your own address.</span>
              }
            </>
          }

        </>}

        { dropShipType !== 'required' && !(editOrder && guestForm && selectedDropShip) && <Form.Check
          name="drop_ship_checkbox"
          disabled={dropShipType==='required'}
          value={dropShipEnabled}
          defaultChecked={dropShipEnabled}
          type='checkbox'
          id="drop_ship_checkbox"
          label="Ship to my address"
          style={{gap: "8px"}}
          className='w-100 d-flex align-items-center ps-0'
          onChange={handleDropShipCheckboxInputChange}
        />}
        {dropShipEnabled && <>
          {
            memberForm && <>
              { !state.addNewShippingAddress && <>
                { customer.drop_ship_addresses && <span className='mt-2'>Your addresses:</span>}
                {(customer.drop_ship_addresses || []).sort((a, b) => a.order - b.order).map((drop_ship_address, index) => {
                  let fullAddressArray = []
                  let requiredFieldsPresent = false
                  Object.entries(drop_ship_address.address_fields).forEach(field => {
                    const [field_key, field_data] = field;
                    if (isFieldNameRequired(field_data.label))
                      requiredFieldsPresent = true
                    fullAddressArray.push(field_data.value)
                  });
                  let fullAddressText = fullAddressArray.filter(Boolean).join(", ")


                  let editShippingAddressErrorText = getShippingAddressErrorText(
                    Object.values(drop_ship_address.address_fields).filter((field) => isFieldNameRequired(field.label))
                      .reduce((array, field, index) => {
                        array.push({label: field.label, value: drop_ship_address.address_fields[field.metadatum_id].new_value !== undefined ? drop_ship_address.address_fields[field.metadatum_id].new_value : drop_ship_address.address_fields[field.metadatum_id].value})
                        return array;
                      }, []))

                  return (!drop_ship_address.deleted && <div key={index} className='d-flex flex-column shipping_elements_list w-100'>

                    {/* Shipping Address Container Element */}
                    {!drop_ship_address.edit && <div
                      className='drop_ship_address_element w-100'
                      style={selectedDropShip.profile_category_id === drop_ship_address.profile_category_id ? {borderColor: fontColor} : {}}
                      onClick={() => changeSelectedDropShip(drop_ship_address)}
                    >
                      <div className='d-flex align-items-center' style={{gap: "8px"}}>
                        <b className='flex-grow-1'
                           style={{color: fontColor}}>{drop_ship_address.label || `Address ${index + 1}`}</b>
                        <i className='fa fa-pencil color_blue' onClick={(e) => handleClickEdit(e, drop_ship_address)}/>
                        <i className='icon-delete-demo-club-from-list text-danger'
                           onClick={(e) => handleClickDelete(e, drop_ship_address)}/>
                      </div>
                      <hr/>
                      <div className='d-flex flex-column' style={{gap: "8px"}}>
                        <span>{fullAddressText}</span>
                      </div>
                    </div>}

                    {/* Edit Existing Shipping Address Form */}
                    {drop_ship_address.edit &&
                      <div className='drop_ship_address_element w-100' style={{gap: "8px"}}>
                        { memberForm && requiredFieldsPresent && <span className='fst-italic text-danger bold'>Fields marked with * are required.</span>}
                        {Object.values(drop_ship_address.address_fields).sort((a, b) => a.order - b.order).map((drop_ship_field, index) =>
                          <div className='cart-customer-credentials-row' key={index}>
                            <span style={{textTransform: "capitalize"}}>
                              <b>{drop_ship_field.label}</b>
                              {isFieldNameRequired(drop_ship_field.label) && <span className='text-danger'>*</span>}
                            </span>

                            <input
                              type='text'
                              id={`drop_ship_${drop_ship_field.metadatum_id}`}
                              name={`drop_ship_${drop_ship_field.metadatum_id}`}
                              autoComplete="off"
                              className="drop_ship_field form-control"
                              style={{height: "35px", textAlign: 'left'}}
                              onChange={(event) => editDropShipField(drop_ship_address, drop_ship_field.metadatum_id, event.target.value)}
                              value={drop_ship_field.new_value === undefined ? drop_ship_field.value : drop_ship_field.new_value}
                            />
                          </div>
                        )}

                        { !_.isEmpty(editShippingAddressErrorText) && <div className='cart-customer-credentials-error'>
                          The following fields are mandatory: {editShippingAddressErrorText}
                        </div>}

                        <div className='d-flex align-items-center justify-content-between'>
                          <button
                            className="btn gems_custom_button"
                            type="button"
                            onClick={() => cancelDropShipEdit(drop_ship_address)}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn gems_custom_button"
                            type="button"
                            style={{color: fontColor, borderColor: fontColor}}
                            onClick={() => saveDropShipEdit(drop_ship_address)}
                            disabled={!_.isEmpty(editShippingAddressErrorText)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    }
                  </div>)
                })}
              </>}

              { customer.drop_ship_addresses.filter((ds, index) => !ds.deleted).length < 4 &&
                <>
                  {/* New Shipping Address button */}
                  {!state.addNewShippingAddress &&
                    <div style={{color: fontColor}} className='d-flex align-items-center shipping_new_button'>
                      <span onClick={handleClickNewAddress} className='cursor_pointer'>
                        <i className='fa fa-plus-circle me-2'/>
                        <b>Add New Shipping Address</b>
                      </span>
                    </div>
                  }

                  {/* New Shipping Address form */}
                  {state.addNewShippingAddress &&
                    <div className='drop_ship_address_element' style={{gap: "8px"}}>
                      { memberForm && <span className='fst-italic text-danger bold'>Fields marked with * are required.</span>}
                      {dropShipFieldsHash.map((drop_ship_field, index) =>
                        <div className='cart-customer-credentials-row' key={index}>
                          <span style={{textTransform: "capitalize"}}>
                            <b>{drop_ship_field.label}</b>
                            {drop_ship_field.required && <span className='text-danger'>*</span>}
                          </span>

                          <input
                            type='text'
                            id={`drop_ship_${drop_ship_field.id}`}
                            name={`drop_ship_${drop_ship_field.id}`}
                            autoComplete="off"
                            className="drop_ship_field form-control"
                            style={{height: "35px", textAlign: 'left'}}
                            onChange={(event) => handleChangeNewAddressField(event, drop_ship_field.id)}
                            value={customer.customer_details[drop_ship_field.id]}
                          />
                        </div>
                      )}

                      {
                        !_.isEmpty(newShippingAddressErrorText) && <div className='cart-customer-credentials-error'>
                          The following fields are mandatory: {newShippingAddressErrorText}
                        </div>
                      }
                      <div className='d-flex align-items-center justify-content-between'>
                      <button
                          className="btn gems_custom_button"
                          type="button"
                          onClick={handleClickCancelNewAddress}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn gems_custom_button"
                          type="button"
                          style={{color: fontColor, borderColor: fontColor}}
                          onClick={handleClickSaveNewAddress}
                          disabled={!_.isEmpty(newShippingAddressErrorText)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  }
                </>
              }
            </>
          }

          {
            guestForm && <>
              { editOrder && selectedDropShip &&
                <div className='d-flex flex-column'>
                  <span>{selectedDropShip.label}</span>
                </div>

              }
              { !(editOrder && selectedDropShip) && dropShipFieldsHash.slice(0, dropShipFieldsHash.length - 1).map((drop_ship_field, index) =>
                <div className='cart-customer-credentials-row' key={index}>
                  <span style={{textTransform: "capitalize"}}>
                    <b>{drop_ship_field.label}</b>
                    {drop_ship_field.required && <span className='text-danger'>*</span>}
                  </span>

                  <input
                    type='text'
                    id={`drop_ship_${drop_ship_field.id}`}
                    name={`drop_ship_${drop_ship_field.id}`}
                    autoComplete="off"
                    className="drop_ship_field form-control"
                    style={{height: "35px", textAlign: 'left'}}
                    onChange={(event) => handleChangeNewAddressField(event, drop_ship_field.id)}
                    value={((selectedDropShip.address_fields || {})[drop_ship_field.id] || {}).value}
                  />
                </div>
              )}
            </>
          }
        </>}

      </div>
    </>

  )
}


export default DropShipComponent