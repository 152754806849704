import { ActionTypes } from '../actions'

const {
  SET_SETTINGS_ATTRIBUTE
} = ActionTypes

const general_settings = (state = {}, action) => {
  switch (action.type) {
    case SET_SETTINGS_ATTRIBUTE: {
      return {
        ...state,
        ...{ [action.attribute]: action.value}
      }
    }
    default:
      return state
  }
}

export default general_settings
