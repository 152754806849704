import { ActionTypes, DISPLAY_MODES } from '../actions'

const {
  SET_ACTIVE_TAB,
  DISPLAY_MODE,
  SET_ACTIVE_COLUMN,
  SET_ACTIVE_BLOCK,
  SET_ACTIVE_POSITION,
  SET_IFRAME_POINTER_OPTIONS,
  SET_DRAGGABLE_ELEMENT,
  SET_IFRAME_FORM_STATE,
  SET_DRAGGED_OVER_ELEMENT,
  SET_DRAGGED_OVER_POSITION,
  ADD_NEW_WIDGET,
  VIEW_INVENTORY,
  ADD_TO_WAITING_LIST,
  RESERVE_DEMO,
  GET_RESERVE_DEMO,
  CHOOSE_NOTIFICATION,
  REQUEST_REPAIR,
  UPDATE_MEMBER_PROFILE,
  UPDATE_INFO_MESSSAGE,
  SAVE_GENERAL_SETTINGS
} = ActionTypes

let inventory = []
let index = null
let updated_club_repairs = []
let updated_demo_clubs = []

const filters = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        active_tab: action.id,
      }
    }
    case DISPLAY_MODE: {
      if (action.display_mode === DISPLAY_MODES.DEFAULT) {
        $('#popup-element').modal('hide')
        return {
          ...state,
          demo_page: null,
          display_mode: action.display_mode,
          infoMessage: null,
          }
      } else {
        $('#popup-element').modal({ show: true, backdrop: 'static', keyboard: false })
        $('#popup-element').modal('show')
      }
      return {
        ...state,
        display_mode: action.display_mode,
      }
    }
    case SET_ACTIVE_COLUMN: {
      return {
        ...state,
        active_column: action.column_id,
      }
    }
    case SET_ACTIVE_BLOCK: {
      return {
        ...state,
        active_block: action.block_id,
      }
    }
    case SET_ACTIVE_POSITION: {
      return {
        ...state,
        position: action.position,
      }
    }
    case SET_IFRAME_POINTER_OPTIONS: {
      return {
        ...state,
        iframe_pointer_options: action.option,
      }
    }
    case SET_DRAGGABLE_ELEMENT: {
      return {
        ...state,
        draggable_element: action.element,
      }
    }
    case SET_DRAGGED_OVER_ELEMENT: {
      return {
        ...state,
        dragged_over_element: action.element,
      }
    }
    case SET_DRAGGED_OVER_POSITION: {
      return {
        ...state,
        dragged_over_position: action.position,
      }
    }
    case SET_IFRAME_FORM_STATE: {
      return {
        ...state,
        form_submitted: action.status,
      }
    }
    case ADD_NEW_WIDGET: {
      if (state.widgets.filter(section => section.title === action.widget.title).lenght > 0) {
        const newWidgets = state.widgets.map(section =>
          (section.title === action.widget.title)
            ? { ...section, pages: [ ...section.pages, action.widget.page ] }
            : section )

        return {
          ...state,
          widgets: newWidgets,
        }
      } else {
        return {
          ...state,
          widgets: [
            ...state.widgets,
            { title: action.widget.title, pages: [ action.widget.page ] },
          ],
        }
      }
    }
    case VIEW_INVENTORY: {
      return {
        ...state,
        inventory: action.data.inventory,
        inventory_select: action.data.inventory_select,
        demo_page: 'inventory'
        }
    }
    case ADD_TO_WAITING_LIST: {
      inventory = [...state.inventory];
      index = inventory.findIndex(club => club.id === action.data.item_id)
      inventory[index].notified_on = true 

      return {
        ...state,
        inventory: inventory,
        selected_club_id: null,
        }
    }
    case GET_RESERVE_DEMO: {
      return {
        ...state,
        selected_club_id: action.data.selected_club_id,
        inventory: action.data.inventory,
        inventory_select: action.data.inventory_select
        }
    }
    case CHOOSE_NOTIFICATION: {
      return {
        ...state,
        selected_club_id: action.data.selected_club_id,
        }
    }
    case REQUEST_REPAIR: {
      updated_club_repairs = state.clubRepairs
      updated_club_repairs.requested_repairs = [...state.clubRepairs.requested_repairs, action.data.item]

      return {
        ...state,
        clubRepairs: updated_club_repairs,
        infoMessage: action.data.infoMessage
      }
    }
    case RESERVE_DEMO: {
      updated_demo_clubs = state.demoClubs
      updated_demo_clubs.reserved_demos = [...state.demoClubs.reserved_demos, action.data.item]

      inventory = [...state.inventory];
      
      if (action.data.item.type == 'Full Club')
      {
        index = inventory.findIndex(club => club.id === action.data.item.club_tracker_id)
        inventory[index].status = "reserved"
      }
      else
      {
        index = inventory.findIndex(club => club.id === action.data.item.head_club_id)
        if(index > -1)
          inventory[index].status = "reserved"
        
        index = inventory.findIndex(club => club.id === action.data.item.shaft_club_id)
        if(index > -1)
          inventory[index].status = "reserved"
      }

      return {
        ...state,
        demoClubs: updated_demo_clubs,
        inventory: inventory,
        selected_club_id: null
      }
    }
    case UPDATE_MEMBER_PROFILE: {
      return {
        ...state,
        member_profile_values: action.data.member_profile_values,
        }
    }
    case UPDATE_INFO_MESSSAGE:{
      return {
        ...state,
        infoMessage: action.data.infoMessage
      }
    }
    case SAVE_GENERAL_SETTINGS:{
      return {
        ...state,
        have_settings: true
      }
    }
    default:
      return state
  }
}

export default filters
