import React, { Component } from 'react'

class DemoClubContent extends Component {
  constructor(props) {
    super(props)
  };
  
  render() {
    const {demo} = this.props

    return (
      <div className={`col-12 col-lg-${this.props.maxWidthChildren} mb-3`}>
        <div className="row d-flex flex-row cursor_pointer m-0 dropdown_page_caret" data-bs-target= {`#demo_`+demo.id} data-bs-toggle= "collapse" type = "button" id= {`demo_`+demo.id+'_btn'}>
          <div className='col p-0 float-start'>
            <div className='row item-box m-0'>
              <div className='col-11 p-0'>
                <b>Type: </b>
                <span className="searchable_field">
                  {demo.type}
                </span>
                <br/>
                <b>Tracking Id: </b>
                <span className="searchable_field">
                  {demo.tracking_id}
                </span>
                <br/>
                <b>{ demo.reserve_date ? "Pick-up Date" : demo.expected_return_date ? "Expected Return Date" : "Return Date"}: </b>
                <span className="searchable_field">
                  { demo.reserve_date ? demo.reserve_date : demo.expected_return_date ? demo.expected_return_date : demo.returned_date}
                </span>
                <br/>
              </div>
              <div className='col-1'>
                <i className="add-line-height-35 float-end fs-4 fa fa-caret-down icon_dropdown_search"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="row item-box m-0 panel custom_panel collapse" id= {`demo_`+demo.id} >
          {demo.details.map((cat, index) => (
            <div className='col-6' key={`${demo.id}_${cat[0]}_${index}`}>
              <b>{cat[0]}: </b>
              <span className="searchable_field">
                {cat[1]}
              </span>
            </div>
          ))}
        </div>
      </div>

    )
  }
}

export default DemoClubContent