import { ActionTypes } from "../actions";

const {
	FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST,
	ENABLE_DISABLE_AUTOMATED_TEMPLATE,
	UPDATE_DEMO_REMINDER_TEMPLATE_DAYS,
	UPDATE_TEMPLATE_ATTRIBUTE,
	RESET_TEMPLATES,
} = ActionTypes;

const initialState = {
	automated_text_message_templates: [],
	settings: {
		demo_tracker_reminder_after_days: 0,
		demo_tracker_reminder_before_days: 0,
		notification_options: {},
	},
	isLoading: true,
};

const automated_templates = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST:
			return {
				...state,
				automated_text_message_templates:
					action.data.automated_text_message_templates,
				settings: action.data.settings,
				isLoading: false,
			};

		case ENABLE_DISABLE_AUTOMATED_TEMPLATE:
			const automated_text_message_templates_list = [
				...state.automated_text_message_templates,
			];
			const template_category_index =
				automated_text_message_templates_list.findIndex(
					(template_category) =>
						template_category.key === action.data["template_category"]
				);
			const templates =
				automated_text_message_templates_list[template_category_index]
					.templates;

			templates[action.data["template_key"]].enabled = action.data["enabled"];
			automated_text_message_templates_list[template_category_index].templates =
				templates;

			return {
				...state,
				automated_text_message_templates: automated_text_message_templates_list,
			};
		case UPDATE_DEMO_REMINDER_TEMPLATE_DAYS:
			var settings = state.settings;
			if (action.data.template_key == "member_club_reminder_before")
				settings.demo_tracker_reminder_before_days = action.data.days;
			else settings.demo_tracker_reminder_after_days = action.data.days;

			return {
				...state,
				settings: settings,
			};

		case UPDATE_TEMPLATE_ATTRIBUTE:
			let notification_options = state.settings.notification_options;
			if (!notification_options[action.data.template_key])
				notification_options[action.data.template_key] = {};
			if (!notification_options[action.data.template_key]["sms"])
				notification_options[action.data.template_key]["sms"] = {};
			notification_options[action.data.template_key]["sms"][
				action.data.attribute_type
			] = action.data.value;
			var settings = state.settings;
			settings.notification_options = notification_options;
			return {
				...state,
				settings: settings,
			};
		case RESET_TEMPLATES:
			return {
				...state,
				automated_text_message_templates:
					action.data.automated_text_message_templates,
			};
		default:
			return {
				...initialState,
				...state,
			};
	}
};

export default automated_templates;
