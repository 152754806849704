import { connect } from 'react-redux'
import { closeManageTabsModal, deleteTab, startEditTab, endEditTab, saveTabTitle, swapOrder } from '../actions'
import ManageTabsModal from '../components/ManageTabsModal'

const mapStateToProps = (state) => ({
  modal_manage_tabs_open: state.profile_setup.modal_manage_tabs_open,
  customer_id: state.profile_setup.customer_id,
  all_tabs: state.profile_setup.all_tabs,
  modal_add_tab_open: state.profile_setup.modal_add_tab_open,
  edited_block: state.profile_setup.edited_block,
  edited_tab: state.profile_setup.edited_tab,
  hidden_tabs: state.profile_setup.hidden_tabs,
  current_tab: state.profile_setup.current_tab
})

const mapDispatchToProps = (dispatch) => ({
  closeManageTabsModal: () => dispatch(closeManageTabsModal()),
  deleteTab: (tab_id, is_profile_category) => dispatch(deleteTab(tab_id, is_profile_category)),
  startEditTab: (tab_id) => dispatch(startEditTab(tab_id)),
  endEditTab: () => dispatch(endEditTab()),
  saveTabTitle: (tab_id, title) => dispatch(saveTabTitle(tab_id, title)),
  swapOrder: (draggedId, hoveredId, type, blockId) => dispatch(swapOrder(draggedId, hoveredId, type, blockId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTabsModal)