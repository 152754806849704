import { connect } from 'react-redux'
import MergePage from '../components/MergePage'
import { checkMergingOptions, submitMergingOptions, openHandleDuplicatesModal, closeHandleDuplicatesModal} from '../actions'

const mapStateToProps = (state) => ({
  customer_id: state.merge_page.customer_id,
  spreadsheetfile_id: state.merge_page.spreadsheetfile_id,
  roster_options: state.merge_page.roster_options,
  spreadsheet_options: state.merge_page.spreadsheet_options,
  roster_duplicates_count: state.merge_page.roster_duplicates_count,
  soft_deleted_roster_duplicates_count: state.merge_page.soft_deleted_roster_duplicates_count,
  delete_m_count: state.merge_page.delete_m_count,
  update_m_count: state.merge_page.update_m_count,
  spreadsheet_duplicates_count: state.merge_page.spreadsheet_duplicates_count,
  new_m_count: state.merge_page.new_m_count,
  all_matched_values: state.merge_page.all_matched_values,
  handle_duplicates_modal_open: state.merge_page.handle_duplicates_modal_open,
  spreadsheet_duplicates_rows: state.merge_page.spreadsheet_duplicates_rows,
  spreadsheet_duplicates_data: state.merge_page.spreadsheet_duplicates_data,
  spreadsheet_keys: state.merge_page.spreadsheet_keys,
  loading_btn: state.merge_page.loading_btn,
  token: state.merge_page.token,
  all_spreadsheet_matched_rows: state.merge_page.all_spreadsheet_matched_rows,
  total_members: state.merge_page.total_members,
  total_rows: state.merge_page.total_rows,
  total_deleted_members: state.merge_page.total_deleted_members,
  all_spreadsheet_duplicates_count: state.merge_page.all_spreadsheet_duplicates_count,
  all_roster_duplicates_count: state.merge_page.all_roster_duplicates_count,
  no_remove_soft_deleted_count: state.merge_page.no_remove_soft_deleted_count,
  all_soft_deleted_roster_duplicates_count: state.merge_page.all_soft_deleted_roster_duplicates_count,
  soft_deleted_after_import: state.merge_page.soft_deleted_after_import,
  match_values_soft_deleted_ids: state.merge_page.match_values_soft_deleted_ids
})

const mapDispatchToProps = (dispatch) => ({
  checkMergingOptions: data => dispatch(checkMergingOptions(data)),
  submitMergingOptions: data => dispatch(submitMergingOptions(data)),
  openHandleDuplicatesModal: data => dispatch(openHandleDuplicatesModal(data)),
  closeHandleDuplicatesModal: () => dispatch(closeHandleDuplicatesModal())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergePage)
