import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Block from './block'
import { Col } from 'react-bootstrap'

class ListBlocks extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Col xs={12} className="blocks-list">
        {this.props.blocks.map(block =>
          <Block key={block.id} onRemove={ () => this.props.removeBlock(block.id) } onEdit={ () => this.props.editBlock(block.id) } editMode = {this.props.editMode} schemaColor={this.props.schemaColor} {...block} />
        )}
      </Col>
    )
  }
}

ListBlocks.propTypes = {
  blocks: PropTypes.array,
  removeBlock: PropTypes.func,
  editBlock: PropTypes.func,
}

export default ListBlocks
