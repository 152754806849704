import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class GsgSpan extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {this.props.name}:&nbsp;  
        <span className="searchable_field">{this.props.value}</span> 
        <br/>
      </div>
    )
  }
}

export default GsgSpan
