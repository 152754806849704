import { combineReducers } from 'redux'
import dashboard from './dashboard'
import automated_templates from './automated_templates'
import custom_templates from './custom_templates'
import scheduled_emails from './scheduled_emails'
import sent_emails_history from './sent_emails_history'


export default combineReducers({
  dashboard,
  automated_templates,
  custom_templates,
  scheduled_emails,
  sent_emails_history
})
