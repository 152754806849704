import { connect } from 'react-redux'
import ListBody from '../components/ListBody'
import {editStockOrder, deleteStockOrder, openSplitStockOrder,
       openPlaceStockOrder, openReceiveStockOrder, openFinalizeStockOrder, openCancelStockOrder, openShipDetailsStockOrder, restoreStockOrder, openListViewOptionalFieldsModal, updateListViewSorting} from '../actions'

const mapStateToProps = (state) => ({
  to_place: state.stock_order.to_place,
  placed: state.stock_order.placed,
  delivered: state.stock_order.delivered,
  list_view_stock_orders: state.stock_order.list_view,
  in_fields: state.stock_order.in_fields,
  filters_list: state.stock_order.filters_list.length > 0 ? state.stock_order.filters_list : "all",
  quick_search_value: state.stock_order.quick_search_value || null,
  condensed_tile_layout: state.stock_order.condensed_tile_layout || false,
  view_only_stock_orders: state.stock_order.view_only_stock_orders,
  can_place_stock_orders: state.stock_order.can_place_stock_orders,
  stock_order_id: state.stock_order.stock_order_id,
  to_place_sorted_by: state.stock_order.to_place_sorted_by,
  placed_sorted_by: state.stock_order.placed_sorted_by,
  delivered_sorted_by: state.stock_order.delivered_sorted_by,
  sort_column: state.stock_order.list_view_sort_column,
  sort_direction: state.stock_order.list_view_sort_direction
})

const mapDispatchToProps = (dispatch) => ({
  editStockOrder: id => dispatch(editStockOrder(id)),
  deleteStockOrder: id => dispatch(deleteStockOrder(id)),
  openCancelStockOrder: id => dispatch(openCancelStockOrder(id)),
  restoreStockOrder: id => dispatch(restoreStockOrder(id)),
  openSplitStockOrder: id => dispatch(openSplitStockOrder(id)),
  openPlaceStockOrder: id => dispatch(openPlaceStockOrder(id)),
  openReceiveStockOrder: id => dispatch(openReceiveStockOrder(id)),
  openFinalizeStockOrder: id => dispatch(openFinalizeStockOrder(id)),
  openShipDetailsStockOrder: id => dispatch(openShipDetailsStockOrder(id)),
  openListViewOptionalFieldsModal: id => dispatch(openListViewOptionalFieldsModal(id)),
  updateListViewSorting: data => dispatch(updateListViewSorting(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListBody)
