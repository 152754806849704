import { connect } from 'react-redux'
import { matchField, unmatchField, setMatchFieldDropdown, createNewFieldDropdown, ignoreFieldDropdown } from '../actions'
import MatchingFieldsTable from '../components/MatchingFieldsTable'

const mapStateToProps = (state) => ({
  callaway_fields: state.callaway_connect.callaway_fields,
  gs_fields: state.callaway_connect.gs_fields,
  matched_fields: state.callaway_connect.matched_fields,
  unmatched_fields: state.callaway_connect.unmatched_fields,
  ignored_fields: state.callaway_connect.ignored_fields,
  fields_to_create: state.callaway_connect.fields_to_create,
  admin_side: state.callaway_connect.admin_side,
})

const mapDispatchToProps = (dispatch) => ({
  matchField: (cc_field_id) => dispatch(matchField(cc_field_id)),
  unmatchField: (cc_field_id, matching_action) => dispatch(unmatchField(cc_field_id, matching_action)),
  setMatchFieldDropdown: (cc_field_id, field) => dispatch(setMatchFieldDropdown(cc_field_id, field)),
  createNewFieldDropdown: (cc_field_id) => dispatch(createNewFieldDropdown(cc_field_id)),
  ignoreFieldDropdown: (cc_field_id) => dispatch(ignoreFieldDropdown(cc_field_id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchingFieldsTable)
