import React, {useEffect, useRef} from 'react'
import missingImage from '../../../../assets/images/missing_product_image.png'
import Modal from "react-bootstrap/Modal";

function MobileCartConfirmation (props){
  const {
    fontColor,
    formType,
    currencySymbol,
    itemsInCart,
    cartProducts,
    totalCost,
    show,

    goToCartPopover,
    closeCartPopover,
    removeProductFromCart,
  } = props;




  return (
    <Modal show={true} onHide={closeCartPopover} backdrop={!(window.innerWidth > 992)} className='mobile_view' id='modal-mobile-cart-confirmation' style={{marginTop: "70px"}}>
      <Modal.Header style={{border: 0, fontSize: "18px"}}>
        <div className='d-flex justify-content-between align-items-center align-self-stretch w-100'>
          <span className='float-start bold'> Added to Cart </span>
          <span className='float-end bold' style={{color: `${fontColor}`}}> {itemsInCart} items </span>
        </div>
      </Modal.Header>
      <Modal.Body style={{
        paddingBottom: "0",
        paddingTop: "0",
      }}>
        <hr/>
        <div style={{
          maxHeight: "50vh",
          paddingRight: (itemsInCart > 2 ? "8px" : "0"),
          overflow: "auto",
          color: "black",
          fontSize: "14px",
          letterSpacing: "0.42px",
          lineHeight: "137%"
        }} className='d-flex align-items-start flex-wrap'>
          {cartProducts.sort((a, b) => a.insertedOrder - b.insertedOrder).map((product, index) => !product.deleted && !product.canceled_at &&
            <div key={index}
                 className={`w-100 ${index !== (cartProducts.length - 1) ? "border-bottom" : ""}`}
                 style={{display: "flex", padding: "16px 0px", alignItems: "flex-start", gap: "12px"}}>
              <img src={product.image ? product.image : missingImage}
                   style={{width: "98px", height: "98px", objectFit: "cover", objectPosition: "top"}}/>
              <div className='d-flex flex-column align-items-start align-self-stretch'
                   style={{gap: "16px", flex: "1 0 0"}}>
                <div
                  className='d-flex align-items-start align-self-stretch'
                  style={{gap: "9px"}}>
                  <div className='d-flex flex-column align-items-start align-self-stretch'
                       style={{gap: "6px", flex: "1 0 0"}}>
                    <span className='bold'>{product.name}</span>
                    {
                      product.configuration.map((field, index) => <span>
                        {field.name}: {field.value}
                        <br/>
                        </span>)
                    }

                  </div>
                  <i className='fa fa-trash color_red' onClick={() => removeProductFromCart(product)} style={{paddingRight: "8px"}}/>

                </div>
                <div className='d-flex justify-content-between w-100' style={{gap: "48px"}}>
                  <div className='d-flex align-items-center' style={{gap: "10px"}}>
                    <span>
                      Qty:
                    </span>
                      <span>{product.quantity}</span>
                  </div>
                  {formType !== 'no_cost' && <div style={{color: `${fontColor}`, paddingRight: "8px"}}>
                      <span className='bold'>
                        {
                          formType === 'points' ?
                            <>{product.price} Points</>
                            :
                            <>{currencySymbol === '$' ? `${currencySymbol}${product.price}` : `${product.price}${currencySymbol}`}</>
                        }
                      </span>
                  </div>}
                </div>
              </div>

            </div>
          )}
        </div>
        <hr/>
      </Modal.Body>
      <Modal.Footer style={{border: 0}}>
        <div className='d-flex justify-content-between align-items-center align-self-stretch w-100'>
          {formType !== 'no_cost' && <div className='d-flex align-items-center' style={{gap: "12px", fontSize: "18px"}}>
              <span className='bold'>
                TOTAL
              </span>
            <span style={{color: `${fontColor}`, fontWeight: "bold"}}>
                {
                  formType === 'points' ?
                    <>{totalCost} Points</>
                    :
                    <>{currencySymbol === '$' ? `${currencySymbol}${totalCost}` : `${totalCost}${currencySymbol}`}</>
                }
              </span>
          </div>}
          <div>
            <button type="button" className='btn btn-sm'
                    style={{backgroundColor: `${fontColor}`, color: "white"}}
                    onClick={() => goToCartPopover()}>Go to Cart
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}


export default MobileCartConfirmation