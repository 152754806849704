import { connect } from 'react-redux'
import CommunicationHistoryModal from '../../shared/components/CommunicationHistoryModal'
import { closeCommunicationTemplateModal } from '../actions'
                     
const mapStateToProps = (state) => ({
  modal_open: state.sent_text_messages_history.modal_show_communication_history_open,
  communication: state.sent_text_messages_history.template_to_display,
  communication_type: 'text_message',
  calendar_view: false
})

const mapDispatchToProps = (dispatch) => ({
  closeCommunicationTemplateModal: () => dispatch(closeCommunicationTemplateModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationHistoryModal)
