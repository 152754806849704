import { connect } from 'react-redux'
import CommunicationHistoryModal from '../../shared/components/CommunicationHistoryModal'
import { closeCommunicationTemplateModal, unscheduleCommunication, pauseScheduledCommunication, resumeScheduledCommunication } from '../actions'
                     
const mapStateToProps = (state) => ({
  modal_open: state.communication_center.modal_show_communication_history_open,
  communication: state.communication_center.template_to_display,
  communication_type: state.communication_center.template_type_to_display == "email" ? "email" : "text_message",
  calendar_view: true
})

const mapDispatchToProps = (dispatch) => ({
  closeCommunicationTemplateModal: () => dispatch(closeCommunicationTemplateModal()),
  unscheduleCommunication: (id, type) => dispatch(unscheduleCommunication(id, type)),
  pauseScheduledCommunication: (id, type) => dispatch(pauseScheduledCommunication(id, type)),
  resumeScheduledCommunication: (id) => dispatch(resumeScheduledCommunication(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationHistoryModal)
