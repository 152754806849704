import React from 'react'
import {useState, useEffect} from 'react';
import Select from "react-select";
import ProductAttribute from './ProductAttribute';
import ImageCarousel from './ImageCarousel';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Label } from 'recharts';
import ReadMoreComponent from './ReadMoreComponent';

function Product (props){
  const {
    version,
    product,
    formType,
    fontColor,
    totalCost,
    checkoutItemsLimit,
    itemsInCart,
    maxCredits,
  } = props;

  const initialState = {
    number_of_items_to_order: 1,
    field_configuration: {},
    out_of_stock: product.stock !== null && product.stock <= product.items_ordered_by_others,
  }

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let quantityInputs = document.getElementsByClassName('product-quantity-value')
    Array.from(quantityInputs).forEach(element => {
      inputAdjust(element)
    });
  }, []);


  const inputAdjust = (element) => {
    element.style.width = '32px'
    element.style.width = ((element.value.length + 1) * 13) + 13 + 'px'
  }

  const handleChangeQuantity = (event) => {
    let quantity;
    if (event.target.value)
      quantity = parseInt(event.target.value)
    else
      quantity = ''
    inputAdjust(event.target)
    setState({...state, number_of_items_to_order: quantity});
    
  }

  const handleChangeField = (field, value) => {
    let config = state.field_configuration
    if (value)
      config[field.id] = {
        name: field.name,
        metadatum_id: field.metadatum_id,
        value: value
      }
    else
      delete config[field.id]
    setState({...state, field_configuration: config});
    
  }

  const handleAddProduct = () => {
    // validate products limit
    if (checkoutItemsLimit !== 0 && state.number_of_items_to_order + itemsInCart > checkoutItemsLimit) {
      resetDefaultValues()
      props.openErrorModal(`<p>You cannot order more than ${checkoutItemsLimit} items.</p>`)
      return false
    } else {
      // validate credit limit
      if (['credit', 'points'].includes(formType) && product.price*state.number_of_items_to_order > Math.max(0, (maxCredits - totalCost))) {
        resetDefaultValues()
        props.openErrorModal(`<p>You do not have enough ${formType == 'credit' ? 'credits' : 'points'} to purchase this item. <br> Please select another item or check out.</p>`)
        return false
      }

      // validate field inputs && quantity
      let valid = true
      product.fields.map((field, index) => {
        if (field.required && !state.field_configuration[field.id])
          valid = false
      })

      const product_container = document.getElementById(`product_${product.id}`)
      let quantity_value = $(product_container).closest('.product_tile_container').find('.product-quantity-value').val()
      if (Number(quantity_value) == NaN || Number(quantity_value) == 0)
        valid = false

      if (!valid) {
        highlightRequiredFields()
        props.openErrorModal('<p>Please fill in the required details!</p>')
      } else {
        // validate credit
        if (formType == 'credit_and_purchase' && product.price*state.number_of_items_to_order > Math.max(0, (maxCredits - totalCost))) {
          props.openErrorModal(`<p>You do not have enough credits to purchase this item. <br> You can optionally apply your remaining credits (if any) to this item and pay the remaining balance.</p>`)
        }
        props.addProductToCart(product, state.field_configuration, state.number_of_items_to_order)
        resetDefaultValues()
      }
    }
  }

  const highlightRequiredFields = () => {
    product.fields.map((field, index) => {
      if (field.required && !state.field_configuration[field.id]) {
        let field_container = document.getElementById(`product_${product.id}_field_${field.id}`)
        $(document.getElementById(`product_${product.id}_field_${field.id}_select`)).css("border", "1px solid red")
        $(field_container).find('.text_free_value').css("border", "1px solid red")
      }
    })

    const product_container = document.getElementById(`product_${product.id}`)
    let quantity_value = $(product_container).closest('.product_tile_container').find('.product-quantity-value').val()
    if (Number(quantity_value) == NaN || Number(quantity_value) == 0)
      $(product_container).closest('.product_tile_container').find('.product-quantity-value').css("border", "1px solid red")
  }

  const resetDefaultValues = () => {
    const product_container = document.getElementById(`product_${product.id}`)
    $(product_container).find('.text_free_value').css("border", "")
    product.fields.map((field, index) => {
      $(document.getElementById(`product_${product.id}_field_${field.id}_select`)).css("border", "")
      $(document.getElementById(`product_${product.id}_field_${field.id}_select`)).val('').trigger('change')
    })

    $(product_container).closest('.product_tile_container').find('.product-quantity-value').val(1).css("border", "")

    setState({...state,
      field_configuration: {},
      fieldsReadMore: true,
      number_of_items_to_order: 1
    });
  }

  return (
    <div className={`border product_container position_relative ${version}_tile ${version}_view`} id={`product_${product.id}`}>
      <div className='h-100 d-flex flex-column justify-content-between align-itmes-center align-self-stretch' style={{gap: "16px"}}>
        <div className='product_tile_container'>
          <div className='d-flex justify-content-between align-items-start align-self-stretch' style={{color: `${fontColor}`, height: `${version == 'mobile' ? "45px" : "48px"}`, overflow: 'hidden'}}>
            <label className='title'> {product.name} </label>
            <b className='title' style={{minWidth: "75px", textAlign: "end"}}> {product.price_nice} </b>
          </div>

          <ImageCarousel

            product_id={product.id}
            images={product.images}
            sliderSide={version == 'mobile' ? 'bottom' : 'left'}
            fontColor={fontColor}
          />
          <ReadMoreComponent
            isParagraph={true}
            lines={3}
            height={null}
            readMoreText={'Read More'}
            readLessText={'Read Less'}
            component={product.description ? <span dangerouslySetInnerHTML={{ __html: product.description }} /> : null}
          />

          <ReadMoreComponent
            isParagraph={false}
            lines={null}
            height={'200px'}
            readMoreText={'See More Product Attributes'}
            readLessText={'See Less Product Attributes'}
            component={<>
              {product.fields.sort((a, b) => a.order - b.order).map((field, field_index) =>
                <ProductAttribute
                  key={field.id}
                  product={product}
                  field={field}
                  currentFieldValue={state.field_configuration[field.id] ? state.field_configuration[field.id].value : ''}
                  changeHandler={handleChangeField}
                />
              )}
            </>}
          />
        </div>

        <div className='product_buttons_container'>
        <div className={`float-start d-flex align-items-center ${product.stock !== null && (product.stock - product.items_ordered_by_others > 0) && (product.stock - product.items_ordered_by_customer - product.items_ordered_by_others) < state.number_of_items_to_order ? 'stock-exceeded' : ''}`}>
          {!state.out_of_stock &&  <>
            <b className='shop_item_qty_text me-2'>Qty</b>
              <input 
                type="number"
                name={`quantities_${product.id}`}
                id={`quantities_${product.id}`}
                value={state.number_of_items_to_order}
                onChange={(e) => handleChangeQuantity(e)}
                step="1"
                min="0"
                className={`form-control product-quantity-value`}
                style={{minWidth: "32px", maxWidth: "75px", height: "29px"}}
              />
              {
                product.stock !== null && (product.stock - product.items_ordered_by_others > 0) && (product.stock - product.items_ordered_by_customer - product.items_ordered_by_others) < state.number_of_items_to_order && 
                <>
                  <b
                    id={`not_enough_items_in_stock_${product.id}`}
                    className='color_red ms-2'
                  >
                    Stock Exceeded
                  </b>
                  <OverlayTrigger
                    key="background_opacity"
                    placement="top"
                    overlay={
                      <Tooltip id={`add_to_cart_info_${product.id}`}>
                        {`Available items: ${(product.stock-product.items_ordered_by_others)}`}
                      </Tooltip>
                    }
                  >
                    <i className='gga-info-circle mx-2 mb-1 fs-6 color_red'/>
                  </OverlayTrigger>
                </>
              }
            </>}
          </div>
          <div className='d-flex justify-content-center align-items-center align-self-stretch'>
            { product.stock !== null ? 
              <>
                {
                  state.out_of_stock ?
                  <>
                    <b
                      className='color_red'
                      id={`currently_unavailable_button_${product.id}`}
                    >
                      <i className='fa fa-shopping-cart add_padding_right_3'/>
                      Out of Stock
                    </b>
                  </>
                  :
                  <>
                    <button 
                      className={`btn btn-sm btn_add_to_order ${isNaN(Number(state.number_of_items_to_order)) || Number(state.number_of_items_to_order) === 0 || (product.stock !== null && (product.stock - product.items_ordered_by_customer - product.items_ordered_by_others) < state.number_of_items_to_order) ? 'disabled' : ''}`}
                      style={{backgroundColor: `${fontColor}`, color: "white"}}
                      id={`add_to_cart_${product.id}`}
                      onClick={handleAddProduct}
                      type='button'
                    >
                      <i className='fa fa-shopping-cart color_white me-2'/>
                      Add to Order
                    </button>
                  </>
                }
              </> 
              :
              <>
                <button 
                  className={`btn btn-sm btn_add_to_order ${isNaN(Number(state.number_of_items_to_order)) || Number(state.number_of_items_to_order) === 0 ? 'disabled' : ''}`}
                  style={{backgroundColor: `${fontColor}`, color: "white"}}
                  id={`add_to_cart_${product.id}`}
                  onClick={handleAddProduct}
                  type='button'
                >
                  <i className='fa fa-shopping-cart color_white me-2'/>
                  Add to Order
                </button>
              </>
            }            
          </div>


        </div>
      </div>
    </div>
  )
}


export default Product