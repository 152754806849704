import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'

import BodyContainer from '../containers/BodyContainer'
import FooterContainer from '../containers/FooterContainer'
import HeaderContainer from '../containers/HeaderContainer'
function Dashboard(props){
  
  useEffect(() => {
    props.fetchProfileSetupData()
  })

  const [memberInfo, setMemberInfo] = useState(props.member_info)

  return (
      <div className="col-12 h-100 d-flex flex-column">
        <HeaderContainer />
        <Row className="flex-grow-1" style={{height: "500px"}}>
          <BodyContainer memberInfo={memberInfo} setMemberInfo={setMemberInfo} />
        </Row>
        <FooterContainer memberInfo={memberInfo} setMemberInfo={setMemberInfo}/>
      </div>
  )
}


export default Dashboard
