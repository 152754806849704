import React from 'react'
import { Col } from 'react-bootstrap';
import AutomatedTemplatesContainer from '../containers/AutomatedTemplatesContainer';
import BulkEmailShowModalContainer from '../containers/BulkEmailShowModalContainer';
import CustomTemplatesContainer from '../containers/CustomTemplatesContainer';
import CustomTemplateShowModalContainer from '../containers/CustomTemplateShowModalContainer';
import UnsentEmailsContainer from '../containers/UnsentEmailsContainer';
import SentEmailsContainer from '../containers/SentEmailsContainer';

function Body(props) {

  const {modal_show_custom_template_open, modal_show_communication_history_open} = props;
  return (
    <div className="row flex-grow-1" id="container_email_center_dashboard">
      <Col xs={12} xl={8} className="offset-xl-2 pe-4" style={{overflowX: "hidden", overflowY: "auto" }}>
        <SentEmailsContainer />
        <UnsentEmailsContainer />
        <CustomTemplatesContainer />
        <AutomatedTemplatesContainer />

        {modal_show_custom_template_open && <CustomTemplateShowModalContainer/>}
        {modal_show_communication_history_open && <BulkEmailShowModalContainer/>}
      </Col>
    </div>
  )
}

export default Body
