import React, { Component } from 'react'


import BodyContainer from '../containers/BodyContainer'

class Dashboard extends Component {
  constructor(props){
    super(props)
  }
  
  componentDidMount(){
    this.props.fetchCommunicationCenterData()
  }

  render() {
    return (
      <div className="col-12 h-100 d-flex flex-column">
        <BodyContainer />
      </div>
    )
  }
}


export default Dashboard
