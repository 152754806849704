import React from 'react'
import {useState, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import CartTableElement from './CartTableElement';


function CartTable (props){
  const { 
    fontColor,
    formType,
    displaySpecialInstructions,
    currencySymbol,
    formClosed,
    cartProducts,
    products,
    itemsInCart,
    checkoutItemsLimit,
    maxCredits,
    totalCost,

    changeSpecialInstructions,
    removeProductFromCart,
    updateQuantity,
    openErrorModal,
  } = props;


  return (
    <>
      <table className='cart-table'>
        <thead className='cart-header'>
          <tr className='cart-row'>
            <th className='cart-header-section text-start'> Item </th>
            <th className='cart-header-section text-start' style={{minWidth: "200px"}}> Description </th>
            { formType != 'no_cost' && <th className='cart-header-section text-start'> Price </th> }
            <th className='cart-header-section text-start'> Qty </th>
            { formType != 'no_cost' && <th className='cart-header-section text-start'> Subtotal </th> }
            { displaySpecialInstructions && <th className='cart-header-section text-start'> Special Instructions </th> }
            <th className='cart-header-section text-start'/>
          </tr>
        </thead>

        <tbody className='cart-body'>
          {
            cartProducts.sort((a, b) => a.insertedOrder - b.insertedOrder).map((product, index) => !product.deleted && !product.canceled_at && <>
              <CartTableElement
                key={index}
                cartProduct={product}
                fontColor={fontColor}
                formType={formType}
                displaySpecialInstructions={displaySpecialInstructions}
                currencySymbol={currencySymbol}
                formClosed={formClosed}
                products={products}
                checkoutItemsLimit={checkoutItemsLimit}
                itemsInCart={itemsInCart}
                totalCost={totalCost}
                maxCredits={maxCredits}

                changeSpecialInstructions={changeSpecialInstructions}
                removeProductFromCart={removeProductFromCart}
                updateQuantity={updateQuantity}
                openErrorModal={openErrorModal}
              />
            </>)
          }
          { itemsInCart == 0 && <tr className='cart-row border-top'>
            <td colSpan="100%" className='cart-row-section'>
              <i className='fa fa-shopping-cart me-2'/>
              <span>Cart is empty</span>
            </td>
          </tr>}
        </tbody>
      </table>

      
    </>
    
  )
}


export default CartTable