import React, {useEffect} from "react";
import {Form} from "react-bootstrap";

const NotificationsTable = (props) => {
  const notifications = props.assingee_data ? ["Shop task has been assigned"] : ["Shop task has been created"]

  useEffect(() => {
    $('.gga-info-circle').tooltip("dispose").tooltip();
  }, [props])

  return (
    <table className="table table-bordered align-middle background_white customer_summary_table order_line_details">
      <thead></thead>
      <tbody>
      {props.assignee_type === "all"
        ?
        <tr>
          <td className="width_70">
          </td>
          <td id="name_info_container">
            <span className="contact_info_name_value">All Assignees</span>
          </td>
        </tr>
        :
        <>
          <tr>
            <td className="width_70">
              <b>Name</b>
            </td>
            <td id="name_info_container">
              <span className="contact_info_name_value">{(props.assingee_data || props.tag_customer)["name"]}</span>
            </td>
          </tr>
          <tr>
          <td className="width_70">
          <b>Email</b>
          </td>
          <td id="email_info_container">
          <div className="row preview_info">
          <div className="col-12">
                <span className="contact_info_email_value" data-email={(props.assingee_data || props.tag_customer)["email"]}>{(props.assingee_data || props.tag_customer)["email"]}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="width_70">
            <b>Cell Phone</b>
          </td>
          <td id="phone_info_container">
            <div className="row preview_info">
              <div className="col-12">
                <span className="contact_info_phone_value" data-phone={(props.assingee_data || props.tag_customer)["phone"]}>{(props.assingee_data || props.tag_customer)["phone"]}</span>
              </div>
            </div>
          </td>
        </tr>
      </>
      }
      <tr className="">
        <td className="width_70">
          <b>Notifications</b>
        </td>
        <td>
          <div className="col-12 container_notifications">
            {notifications.map((notification, index) => (<>
              <div id="email_notification">
                <div className="m-0 mb-2 member_in_transit row">
                  <div className="col-6 col-lg-9 notification_email p-0">
                    <span className="d-flex align-items-center">{notification + " email."}</span>
                  </div>
                  <div className="col-6 col-lg-3 p-0">
                    <i className="float-end gga-info-circle add_padding_top_5" data-toggle="tooltip" title={(props.assingee_data || props.tag_customer)["e_info"]}
                       data-bs-original-title={(props.assingee_data || props.tag_customer)["e_info"]}></i>
                    <div className="float-end form-check form-switch me-2 p-0">
                      <Form.Check
                        type="switch"
                        id="email-custom-switch"
                        disabled={true}
                        checked={(props.assingee_data || props.tag_customer)["email_notif"]}
                        className="p-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="sms_notification">
                <div className="m-0 mb-2 member_in_transit row">
                  <div className="col-6 col-lg-9 notification_sms p-0">
                    <span className="d-flex align-items-center">{notification + " text message."}</span>
                  </div>
                  <div className="col-6 col-lg-3 p-0">
                    <i className="float-end gga-info-circle add_padding_top_5" data-toggle="tooltip" title={(props.assingee_data || props.tag_customer)["s_info"]}
                       data-bs-original-title={(props.assingee_data || props.tag_customer)["s_info"]}></i>
                    <div className="float-end form-check form-switch me-2 p-0">
                      <Form.Check
                        type="switch"
                        id="email-custom-switch"
                        disabled={true}
                        checked={(props.assingee_data || props.tag_customer)["sms_notif"]}
                        className="p-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>))}
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  );
};

export default NotificationsTable;
