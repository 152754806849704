import React, { useId } from 'react';
import Modal, { ModalHeader, ModalBody, ModalFooter } from './Modal';
import { Form, Row, Col, FormLabel } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select'

function UploadStockOrderModal(props) {

  const initialState = {
  }

	const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);
  const [uploadIsStarted, setuploadIsStarted] = useState(false);

	const [vendorId, setVendorId] = useState(null);
  const [aiEngine, setAiEngine] = useState("Gemini")

  const [state, setState] = useState(initialState);
  const forceUpdate = React.useCallback(() => setState({}), []);

  const { modal_upload_open, closeUploadModal, title, customer_id, upload_finished_with_error, upload_error_description } = props;

  const handleClose = () => {
    closeUploadModal()
  }

	const changeHandler = (event) => {
    if (event.target.files[0]){
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
    }
	};

  const clickElement = () => {
    // To simulate a user focusing an input you should use the
    // built in .focus() method.
    myInput.current?.click();

    // To simulate a click on a button you can use the .click()
    // method.
    // myInput.current?.click();
  }

  const myInput = useRef(null);

  const handleVendorSelectChange = (event) => {
    setVendorId(event.value)
  }
  const handleAiEngineSelectChange = (event) => {
    setAiEngine(event.value)
  }

	const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', selectedFile);
    formData.append('customer_id', customer_id);
    formData.append('vendor_id', vendorId)
    formData.append('ai_engine', aiEngine)
    props.uploadStockOrderFile(formData)
    setuploadIsStarted(true)
    setSelectedFile(null)
    setIsSelected(false)
    document.getElementById('files_id').value= null;
	};

  const handleJobRetry = () => {
    props.retryUploadStockOrder(props.upload_stock_order_job_id)
  }

  return (
    <div className="">
      <Modal className="modal-xl" style={{height: "700px!important"}} isOpen={modal_upload_open}>
        <ModalHeader >
          <div className="row">
            <div className="col-7 order-1">
              <h4 className="modal-title form_new_edit no-margin">{title}</h4>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <p className='pb-5 fs-6' id="uploadInfoText">
            Optionally choose a Vendor and AI Engine from the dropdowns, then upload a PDF Order Confirmation. 
            Once the PDF is uploaded we will analyze the data and input it into a Stock Order Form.
            You will be brought to the Stock Order Form in order to review, edit and create.
          </p>

          <Form.Group as={Row} className="mb-3" controlId="formVendor" id="uploadVendorSelect">
            <Col sm="2" className="pt-2">
              Vendor
            </Col>
            <Col sm="6">
              <Select id="vendor_id"
                name='vendor_id'
                value={props.vendors_list.filter(function (option) {
                  return option.value === vendorId;
                })}
                options={props.vendors_list}
                onChange={handleVendorSelectChange} 
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 pt-3" controlId="formVendor" id="uploadAiEngineSelect">
            <Col sm="2" className="pt-2">
              AI Engine
            </Col>
            <Col sm="6">
              <Select id="ai_engine"
                name='ai_engine'
                value={{label: aiEngine, value: aiEngine}}
                options={[{label: "Gemini", value: "Gemini"}, {label: "OpenAI", value: "OpenAI"}]}
                onChange={handleAiEngineSelectChange} 
              />
            </Col>
          </Form.Group>

          {uploadIsStarted && !upload_finished_with_error &&
            <div>
              <div className='d-flex justify-content-center pb-3' style={{paddingTop: "50px"}}>
                <img style={ {width: 200}} src={ props.cdn_assets_urls.in_progress_gif_url }/>
              </div>
              <p id="job_status_text" className='pb-5 pt-3' style={{textAlign: "center"}}>
                Uploading your PDF
              </p>
            </div>
          }

          {upload_finished_with_error &&
            <div>
              <div className='d-flex justify-content-center' style={{paddingTop: "50px"}}>
                <img style={ {width: 200}} src={ props.cdn_assets_urls.error_gif_url }/>
              </div>
              <p className='pb-5' style={{textAlign: "center"}}>
                {upload_error_description}
              </p>
              <div className = 'd-flex justify-content-center'>
                <button
                    className={`btn gems_custom_button ps-3 pe-3 fs-6 errors_${props.upload_stock_order_job_id}`}
                    style={{width: "fit-content"}}
                    aria-label="manage_tabs"
                    onClick={handleJobRetry}
                    disabled={false}
                  >
                    <span>
                      Retry
                    </span>
                </button>
              </div>
            </div>
          }

          { !uploadIsStarted && !upload_finished_with_error && <Form.Group style={{paddingTop: "30px"}} className="mb-2" controlId="formUploadAttch">
              <input className = "d-none" type="file" name="file" id="files_id" onChange={changeHandler} ref={myInput} accept={".pdf, .png"}/>    
              {!isSelected && <button
                  className="btn gems_custom_button gems_orange_button ps-3 pe-3 fs-6"
                  style={{width: "fit-content"}}
                  aria-label="manage_tabs"
                  onClick={clickElement}
                  disabled={false}
                >
                  <span>
                    <i className="fa fa-cloud-upload fa-fw me-1 fs-6"></i>
                    Upload PDF
                  </span>
              </button>
              }
              {isSelected && <Form.Group as={Row} style={{paddingTop: "15px"}} className="mb-3" controlId="formVendor" id="uploadVendorSelect">
                  <Col sm="9">
                    <span className="ps-1 fs-6" style={{color: "#0098c4"}}>
                      {selectedFile.name}
                    </span>
                    <span onClick={clickElement} className="ps-2 cursor_pointer" style={{color: "#333f48"}}>
                      <i className="fa fa-pencil fa-fw"></i>
                    </span>
                  </Col>
                </Form.Group>              
              }
            </Form.Group>
          }
        </ModalBody>
        <ModalFooter>
          <button
            className="float-start btn gems_custom_button"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>

          { !uploadIsStarted && !upload_finished_with_error && <button
            className="float-end btn gems_custom_button gems_orange_button"
            aria-label="Close"
            onClick={handleSubmission}
            disabled={!isSelected}
          >
            Submit
          </button>
          }
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default UploadStockOrderModal