
import React from 'react';
import _ from 'lodash';
import { useState } from "react";

function ReadMoreReadLess(props) {
  const {limit, text, rows} = props

  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleLink = (e) => {
    setReadMoreShown( prevState => !prevState)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div className="read-more-read-less d-flex flex-column p-0">
      <span className={`${isReadMoreShown ? '' : 'read-more-text'}`} style={{flexGrow: 1, whiteSpace: "normal", WebkitLineClamp: rows}}>
        {
          isReadMoreShown ? text : text.substr(0, limit)
        }
      </span>
      

      {
        text.length > limit && <span onClick={(e) => toggleLink(e)} class="link">
          {
            isReadMoreShown ? 'Read Less' : 'Read More'
          }
        </span>
      }
      
    </div>
  )
} 
export default ReadMoreReadLess