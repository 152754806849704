import { connect } from 'react-redux'
import { closeEditValuesModal, setMetadatumValues } from '../actions'
import EditValuesModal from '../components/EditValuesModal'

const mapStateToProps = (state) => ({
  customer_id: state.profile_setup.customer_id,
  current_tab: state.profile_setup.current_tab,
  modal_edit_values_open: state.profile_setup.modal_edit_values_open,
  edited_block: state.profile_setup.edited_block,
  edited_metadatum: state.profile_setup.edited_metadatum,
  all_tabs: state.profile_setup.all_tabs,
  all_metadata: state.profile_setup.all_metadata,
  metadata_values: state.profile_setup.metadata_values
})

const mapDispatchToProps = (dispatch) => ({
  closeEditValuesModal: () => dispatch(closeEditValuesModal()),
  setMetadatumValues: (edited_metadatum_id, values_type, values) => dispatch(setMetadatumValues(edited_metadatum_id, values_type, values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditValuesModal)