import { connect } from 'react-redux'
import { closeShipDetailsModal, updateStockOrder, uploadFile} from '../actions'
import ShipDetailsStockOrderModal from '../components/ShipDetailsStockOrderModal'

const mapStateToProps = (state) => ({
  modal_ship_details_open: state.stock_order.modal_ship_details_open,
  item: state.stock_order.item,
  customer_id: state.stock_order.customer_id,
  carrier_options: state.stock_order.carrier_options || [],
  view_only_stock_orders: state.stock_order.view_only_stock_orders,
  can_place_stock_orders: state.stock_order.can_place_stock_orders,
  date_format: state.stock_order.date_format
})

const mapDispatchToProps = (dispatch) => ({
  closeShipDetailsModal: () => dispatch(closeShipDetailsModal()),
  updateStockOrder: data => dispatch(updateStockOrder(data)),
  uploadFile: data => dispatch(uploadFile(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipDetailsStockOrderModal)