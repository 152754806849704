import { ActionTypes } from '../actions'

const {
  FETCH_STOCK_ORDERS_DATA_REQUEST,
  CREATE_STOCK_ORDER,
  NEW_STOCK_ORDER,
  UPLOAD_STOCK_ORDER,
  EDIT_STOCK_ORDER,
  DELETE_STOCK_ORDER,
  UPDATE_STOCK_ORDER,
  SPLIT_STOCK_ORDER,
  CANCEL_STOCK_ORDER,
  OPEN_PLACE_STOCK_ORDER,
  OPEN_SHIP_DETAILS_STOCK_ORDER,
  OPEN_RECEIVE_STOCK_ORDER,
  OPEN_FINALIZE_STOCK_ORDER,
  OPEN_SPLIT_STOCK_ORDER,
  OPEN_CANCEL_STOCK_ORDER,
  OPEN_FILTER_MODAL,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  DISPLAY_UPLOAD_ERROR,
  UPDATE_UPLOAD_JOB_ID,
  RETRY_UPLOAD,
  OPEN_LOGOS_MODAL,
  CLOSE_LOGOS_MODAL,
  CLOSE_PLACE_MODAL,
  CLOSE_SHIP_DETAILS_MODAL,
  CLOSE_RECEIVE_MODAL,
  CLOSE_FINALIZED_MODAL,
  CLOSE_SPLIT_MODAL,
  CLOSE_CANCEL_MODAL,
  CLOSE_FILTER_MODAL,
  SWITCH_VIEW,
  APPLY_FILTERS,
  REMOVE_ALL_FILTERS,
  CHANGE_CALENDAR_FILTER_DATE,
  APPLY_FILES_TO_STOCK_ORDER,
  QUICK_SEARCH,
  CHANGE_TILE_LAYOUT,
  UPDATE_PIPEDRIVE_SORTING,
  OPEN_LIST_VIEW_ADDITIONAL_FIELDS,
  CLOSE_OPTIONAL_FIELDS_MODAL,
  UPDATE_LIST_VIEW_OPTIONAL_FILEDS,
  UPDATE_LIST_VIEW_SORTING,
  DELETE_LOGO
} = ActionTypes

const initialState = {
  to_place: [],
  placed: [],
  delivered: [],
  list_view: [],
  in_fields: [],
  to_place_sorted_by: { sort_column: 'created_at', sort_direction: 'desc' },
  placed_sorted_by: { sort_column: 'ordered_date', sort_direction: 'desc' },
  delivered_sorted_by: { sort_column: 'delivered_date', sort_direction: 'desc' },
  search_params: { finalized: false, canceled: false, deleted: false, status: '' },
  modal_open: false,
  modal_upload_open: false,
  modal_place_open: false,
  modal_ship_details_open: false,
  modal_delivered_open: false,
  modal_logos_open: false,
  modal_finalize_open: false,
  modal_split_open: false,
  modal_cancel_open: false,
  modal_filter_open: false,
  modal_list_view_optional_fields: false,
  optional_fields: [],
  vendors_list: [],
  categories_list: [],
  categories_attributes: {},
  sizing_table: [],
  categories_list_for_filter: [],
  stock_orders_vendor_email_not_opened: [],
  categories_by_id: {},
  view: "pipedrive",
  filters_list: [],
  calendar_filter_date: "end_date",
  "quick_search_value": null,
  "po": "",
  "condensed_tile_layout": false,
  list_view_sort_column: "order_number",
  list_view_sort_direction: "asc",
  upload_finished_with_error: false,
  upload_error_description: null,
  upload_stock_order_job_id: null
}

const item_initial_attrs = {
  "id": "", "vendor_id": "", "customer_id": "", "order_nickname": "",
  "order_number": "", "start_date": "", "cancellation_date": "", "vendor_cancel_date": "", "ordered_date": "",
  "end_date": "", "order_description": "", "expected_ship_date": "",
  "expected_delivery_date": "", "additional_description_to_vendor": "", "internal_notes": "",
  "delivered_date": "", "vendor_invoice_received": "", "received_into_inventory": "",
  "invoice_processed": "", "shipping_cost": "", "returned_date": "", "return_note": "",
  "created_at": "", "updated_at": "", "method_of_ordering": "",
  "vendor_confirmation_number": "", "shipping_company": "",
  "tracking_number": "", "vendor_invoice_number": "", "color": '#9B9B9B', "parent_childrens": [],
  "stock_order_categories": [], "files": [], activity_logs: [], "logos": [], logos_list: [], current_item: null, current_category_id: null
}
let index_id = null
let position = null
let list_view_index_id = null

let to_place = [];
let placed = [];
let delivered = [];
let list_view = [];
let logos = [];

const stock_order = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOCK_ORDERS_DATA_REQUEST:
      return {
        ...state,
        to_place: action.data.to_place,
        placed: action.data.placed,
        delivered: action.data.delivered,
        list_view: action.data.list_view,
        in_fields: action.data.in_fields,
        search_params: action.data.search_params || initialState["search_params"],
        list_view_sort_column: action.data.list_view_sort_column,
        list_view_sort_direction: action.data.list_view_sort_direction,
        vendors_list: action.data.vendors_list,
        categories_list_for_filter: action.data.categories_list_for_filter,
        stock_orders_vendor_email_not_opened: action.data.stock_orders_vendor_email_not_opened,
      }
    case CREATE_STOCK_ORDER:
      return {
        ...state,
        ...{
          to_place: [...state.to_place, action.data.item],
          modal_place_open: action.open_place_popup,
          item: action.data.item_params_for_place || initialState["search_params"],
          list_view: [...state.list_view, action.data.item]
        }

      }
    case UPDATE_STOCK_ORDER:
      index_id = null
      position = null
      list_view_index_id = null

      to_place = [...state.to_place];
      placed = [...state.placed];
      delivered = [...state.delivered];
      list_view = [...state.list_view];

      to_place.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_to_place";
        }
      })

      placed.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_placed";
        }
      })

      delivered.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_delivered";
        }
      })

      list_view.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          list_view_index_id = index;
        }
      })

      if (index_id || (index_id == 0)) {

        if (action.data.position && (action.data.position != position)) {
          position = action.data.position
          switch (action.data.position) {
            case "orders_to_place":
              {
                placed = placed.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                delivered = delivered.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                to_place = [...state.to_place, action.data]
                break;
              }
            case "orders_placed":
              {
                to_place = to_place.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                delivered = delivered.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                placed = [...state.placed, action.data]
                break;
              }
            case "orders_delivered":
              {
                to_place = to_place.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                placed = placed.filter((filter) => {
                  return filter.id.toString() !== action.data.id
                })
                delivered = [...state.delivered, action.data]
                break;
              }
          }
        }
        else {
          switch (position) {
            case "orders_to_place":
              {
                to_place[index_id] = action.data
                break;
              }
            case "orders_placed":
              {
                placed[index_id] = action.data
                break;
              }
            case "orders_delivered":
              {
                delivered[index_id] = action.data
                break;
              }
          }
        }

        list_view[list_view_index_id] = {
          ...list_view[list_view_index_id],
          delivered_date: action.data.delivered_date,
          early_cancel_date: action.data.early_cancel_date,
          end_date: action.data.end_date,
          expected_delivery_date: action.data.expected_delivery_date,
          expected_ship_date: action.data.expected_ship_date,
          ordered_date: action.data.ordered_date,
          start_date: action.data.start_date,
          status: action.data.position == 'orders_to_place' ? 'to_place' : action.data.position == 'orders_placed' ? 'placed' : 'delivered',
          vendor_cancel_date: action.data.vendor_cancel_date,
          vendor_confirmation_number: action.data.vendor_confirmation_number,
          shipping_company: action.data.shipping_company
        }

        return {
          ...state,
          item: item_initial_attrs,
          to_place: to_place,
          placed: placed,
          delivered: delivered,
          list_view: list_view
        }
      }
      else
        return {
          ...state,
          item: item_initial_attrs,
        }
    case SPLIT_STOCK_ORDER:
      index_id = null
      position = null
      list_view_index_id = null

      to_place = [...state.to_place];
      placed = [...state.placed];
      delivered = [...state.delivered];
      list_view = [...state.list_view];

      to_place.map((filter, index) => {
        if (filter.id.toString() == action.data.original_item.id) {
          index_id = index;
          position = "orders_to_place";
        }
      })

      placed.map((filter, index) => {
        if (filter.id.toString() == action.data.original_item.id) {
          index_id = index;
          position = "orders_placed";
        }
      })

      delivered.map((filter, index) => {
        if (filter.id.toString() == action.data.original_item.id) {
          index_id = index;
          position = "orders_delivered";
        }
      })

      list_view.map((filter, index) => {
        if (filter.id.toString() == action.data.original_item.id) {
          list_view_index_id = index;
        }
      })

      if (index_id || (index_id == 0)) {
        switch (position) {
          case "orders_to_place":
            {
              to_place[index_id] = action.data.original_item
              to_place = [...to_place, action.data.revised_item]
              break;
            }
          case "orders_placed":
            {
              placed[index_id] = action.data.original_item
              placed = [...placed, action.data.revised_item]
              break;
            }
          case "orders_delivered":
            {
              delivered[index_id] = action.data.original_item
              delivered = [...delivered, action.data.revised_item]
              break;
            }
        }

        let original_item = action.data.original_item
        let revised_item = action.data.revised_item

        list_view[list_view_index_id] = {
          ...list_view[list_view_index_id],
          delivered_date: original_item.delivered_date,
          early_cancel_date: original_item.early_cancel_date,
          end_date: original_item.end_date,
          expected_delivery_date: original_item.expected_delivery_date,
          expected_ship_date: original_item.expected_ship_date,
          order_no: original_item.order_no,
          order_number: original_item.order_no,
          order_nickname: original_item.order_nickname,
          ordered_date: original_item.ordered_date,
          start_date: original_item.start_date,
          status: original_item.position == 'orders_to_place' ? 'to_place' : original_item.position == 'orders_placed' ? 'placed' : 'delivered',
          vendor_cancel_date: original_item.vendor_cancel_date,
          vendor_confirmation_number: original_item.vendor_confirmation_number,
          shipping_company: original_item.shipping_company
        }

        list_view = [...list_view, {
          ...list_view[list_view_index_id],
          delivered_date: revised_item.delivered_date,
          early_cancel_date: revised_item.early_cancel_date,
          end_date: revised_item.end_date,
          expected_delivery_date: revised_item.expected_delivery_date,
          expected_ship_date: revised_item.expected_ship_date,
          order_no: revised_item.order_no,
          order_number: revised_item.order_no,
          order_nickname: revised_item.order_nickname,
          ordered_date: revised_item.ordered_date,
          start_date: revised_item.start_date,
          status: revised_item.position == 'orders_to_place' ? 'to_place' : revised_item.position == 'orders_placed' ? 'placed' : 'delivered',
          vendor_cancel_date: revised_item.vendor_cancel_date,
          vendor_confirmation_number: revised_item.vendor_confirmation_number,
          shipping_company: revised_item.shipping_company
        }]

        return {
          ...state,
          item: item_initial_attrs,
          to_place: to_place,
          placed: placed,
          delivered: delivered,
          list_view: list_view
        }
      }
      else
        return {
          ...state,
          item: item_initial_attrs,
        }
    case CANCEL_STOCK_ORDER:
      index_id = null
      position = null
      list_view_index_id

      to_place = [...state.to_place];
      placed = [...state.placed];
      delivered = [...state.delivered];
      list_view = [...state.list_view];

      to_place.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_to_place";
        }
      })

      placed.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_placed";
        }
      })

      delivered.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          index_id = index;
          position = "orders_delivered";
        }
      })

      list_view.map((filter, index) => {
        if (filter.id.toString() == action.data.id) {
          list_view_index_id = index;
        }
      })

      if (index_id || (index_id == 0)) {
        switch (position) {
          case "orders_to_place":
            {
              to_place[index_id] = action.data
              break;
            }
          case "orders_placed":
            {
              placed[index_id] = action.data
              break;
            }
          case "orders_delivered":
            {
              delivered[index_id] = action.data
              break;
            }
        }

        list_view[list_view_index_id] = {
          ...list_view[list_view_index_id],
          status: 'cancelled',
          total_quantity: action.data.total_items,
          vendor_cancel_date: action.data.vendor_cancel_date
        }

        return {
          ...state,
          item: item_initial_attrs,
          to_place: to_place,
          placed: placed,
          delivered: delivered,
          list_view: list_view
        }
      }
      else
        return {
          ...state,
          item: item_initial_attrs,
        }
    case NEW_STOCK_ORDER:
      return {
        ...state,
        modal_open: true,
        title: "New Stock Order",
        btn_name: "Create",
        vendors_list: action.data.vendors_list,
        categories_list: action.data.categories_list,
        categories_attributes: action.data.categories_attributes,
        sizing_table: action.data.sizing_table,
        po: action.data.po,
        item: action.data.stock_order_ai_upload ? {
          ...item_initial_attrs,
          files: [action.data.ai_upload_additional_data.attachment],
          internal_notes: action.data.ai_upload_additional_data.internal_notes
        } : item_initial_attrs,
        default_order_number: action.data.default_order_number,
        optional_fields: action.data.optional_fields,
        similar_stock_order: action.data.similar_stock_order
      }
    case UPLOAD_STOCK_ORDER:
      return {
        ...state,
        modal_upload_open: true,
        title: "AI Upload Stock Order",
      }

    case EDIT_STOCK_ORDER:

      return {
        ...state,
        modal_open: true,
        title: "Edit Stock Order",
        btn_name: "Update",
        item: action.data.item,
        vendors_list: action.data.vendors_list,
        categories_list: action.data.categories_list,
        categories_attributes: action.data.categories_attributes,
        sizing_table: action.data.sizing_table,
        stock_order_id: null,
        similar_stock_order: null,
        optional_fields: action.data.optional_fields,
      }
    case OPEN_PLACE_STOCK_ORDER:
      return {
        ...state,
        modal_place_open: true,
        item: action.data.item,
      }
    case UPDATE_PIPEDRIVE_SORTING:
      return {
        ...state,
        to_place_sorted_by: action.data.to_place_sorted_by,
        placed_sorted_by: action.data.placed_sorted_by,
        delivered_sorted_by: action.data.delivered_sorted_by
      }
    case UPDATE_LIST_VIEW_SORTING:
      return {
        ...state,
        list_view_sort_column: action.data.sort_column,
        list_view_sort_direction: action.data.sort_direction
      }
    case OPEN_SHIP_DETAILS_STOCK_ORDER:
      return {
        ...state,
        modal_ship_details_open: true,
        item: action.data.item,
      }
    case OPEN_RECEIVE_STOCK_ORDER:
      return {
        ...state,
        modal_delivered_open: true,
        item: action.data.item,
      }
    case OPEN_LOGOS_MODAL:
      return {
        ...state,
        modal_logos_open: true,
        current_item: action.data.item,
        current_category_id: action.data.category_id
      }
    case OPEN_FINALIZE_STOCK_ORDER:
      return {
        ...state,
        modal_finalize_open: true,
        item: action.data.item,
        finalized_fields: {
          vendor_invoice_received: action.data.finalized_fields.vendor_invoice_received,
          invoice_processed: action.data.finalized_fields.invoice_processed,
          received_into_inventory: action.data.finalized_fields.received_into_inventory
        }
      }
    case OPEN_SPLIT_STOCK_ORDER:
      return {
        ...state,
        modal_split_open: true,
        item: action.data.item,
        sizing_table: action.data.sizing_table
      }
    case OPEN_CANCEL_STOCK_ORDER:
      return {
        ...state,
        modal_cancel_open: true,
        item: action.data.item,
        sizing_table: action.data.sizing_table
      }
    case OPEN_FILTER_MODAL:
      return {
        ...state,
        modal_filter_open: true,
        categories_list_for_filter: action.data.categories_list_for_filter,
      }
    case DELETE_STOCK_ORDER:
      return {
        ...state,
        to_place: state.to_place.filter((filter) => {
          return filter.id.toString() !== action.data.deleted_id
        }),
        placed: state.placed.filter((filter) => {
          return filter.id.toString() !== action.data.deleted_id
        }),
        delivered: state.delivered.filter((filter) => {
          return filter.id.toString() !== action.data.deleted_id
        }),
        list_view: state.list_view.filter((filter) => {
          return filter.id.toString() !== action.data.deleted_id
        })
      }
    case OPEN_MODAL:
      return {
        ...state,
        modal_open: true
      }
    case CLOSE_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_open: false
      }
    case OPEN_UPLOAD_MODAL:
      return {
        ...state,
        modal_upload_open: true
      }
    case CLOSE_UPLOAD_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_upload_open: false,
        upload_finished_with_error: false
      }
    case UPDATE_UPLOAD_JOB_ID:
      return {
        ...state,
        upload_stock_order_job_id: action.data.job_id
      }
    case RETRY_UPLOAD:
      return {
        ...state,
        upload_finished_with_error: false
      }
    case DISPLAY_UPLOAD_ERROR:
      return {
        ...state,
        upload_finished_with_error: true,
        upload_error_description: action.data
      }
    case CLOSE_PLACE_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_place_open: false
      }
    case CLOSE_SHIP_DETAILS_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_ship_details_open: false
      }
    case CLOSE_RECEIVE_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_delivered_open: false
      }
    case CLOSE_LOGOS_MODAL:
      return {
        ...state,
        // item: item_initial_attrs,
        modal_logos_open: false
      }
    case CLOSE_FINALIZED_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_finalize_open: false
      }
    case CLOSE_SPLIT_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_split_open: false
      }
    case CLOSE_CANCEL_MODAL:
      return {
        ...state,
        item: item_initial_attrs,
        modal_cancel_open: false
      }
    case CLOSE_FILTER_MODAL:
      return {
        ...state,
        modal_filter_open: false
      }
    case SWITCH_VIEW:
      return {
        ...state,
        view: action.data
      }
    case CLOSE_OPTIONAL_FIELDS_MODAL:
      return {
        ...state,
        modal_list_view_optional_fields: false
      }
    case UPDATE_LIST_VIEW_OPTIONAL_FILEDS:
      return {
        ...state,
        modal_list_view_optional_fields: false,
        fields: JSON.parse(action.data.fields),
        in_fields: JSON.parse(action.data.in_fields)
      }

    case APPLY_FILTERS:
      return {
        ...state,
        filters_list: action.data.filters_list
      }
    case REMOVE_ALL_FILTERS:
      return {
        ...state,
        filters_list: []
      }
    case CHANGE_CALENDAR_FILTER_DATE:
      return {
        ...state,
        calendar_filter_date: action.data.calendar_filter_date
      }
    case CHANGE_TILE_LAYOUT:
      return {
        ...state,
        condensed_tile_layout: action.data.condensed_tile_layout
      }
    case QUICK_SEARCH:
      return {
        ...state,
        quick_search_value: action.data.quick_search_value
      }
    case OPEN_LIST_VIEW_ADDITIONAL_FIELDS:
      return {
        ...state,
        modal_list_view_optional_fields: true,
        fields: action.data.fields,
        in_fields: action.data.in_fields,
        mandatory_fields: action.data.mandatory_fields
      }
    case DELETE_LOGO:
      return {
        ...state,
        logos_list: action.data["logos_list"]
      }
    case APPLY_FILES_TO_STOCK_ORDER:
      let logos_list = action.data["logos_list"]
      let data = action.data
      delete data["logos_list"]

      if (action.data.valid_extension) {
        if (action.data.stock_order_id == "revised")
          return {
            ...state,
            item: {
              ...state.item,
              revised_files: [...state.item.revised_files, data]
            }
          }
        else
          if (action.data.stock_order_id)
            return {
              ...state,
              item: {
                ...state.item,
                files: [...state.item.files, data]
              }
            }
          else {
            logos = [...state.item.logos] || []
            logos.map((filter, index) => {
              if (filter.stock_order_item_id.toString() == action.data.stock_order_item_id) {
                index_id = index;
              }
            })

            if (index_id) {
              logos[index_id] = data
              return {
                ...state,
                item: {
                  ...state.item,
                  logos: logos
                },
                logos_list: logos_list
              }
            } else
              return {
                ...state,
                item: {
                  ...state.item,
                  logos: [...state.item.logos, data]
                },
                logos_list: logos_list
              }
          }
        }
      else {
        window.alert("Selected file format is not allowed!")
        return {
          ...state
        }
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default stock_order