import { connect } from 'react-redux'
import ReserveDemoForm from '../presentational/reserve_demo_form'
import {reserveDemo, displayMode, DISPLAY_MODES } from '../../actions'


const mapStateToProps = state => ({
  member_id: state.filters.member_id,
  customer_id: state.filters.customer_id,
  inventory: state.filters.inventory,
  inventory_select: state.filters.inventory_select,
  selected_club_id: state.filters.selected_club_id,
  demo_return_interval: state.general_settings.demo_return_interval || 7,
  column: state.columns.filter((filter) => {
    return filter.id == state.filters.active_column})[0],
  schemaColor: state.general_settings.color_schema,
  backgroundColor: state.general_settings.background_color,
  date_format: state.general_settings.date_format
})

const mapDispatchToProps = dispatch => ({
  cancel: () => {
    dispatch(displayMode(DISPLAY_MODES.DEFAULT))
  },
  
  reserveDemo: (data) => dispatch(reserveDemo(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReserveDemoForm)
