import React from 'react';

function TextMessageTemplateBody(props) {

  const {template} = props;

  return (
    <div className='text_message_body' style={{minHeight: "350px"}} dangerouslySetInnerHTML={{ __html: template.body.replace(/\n/g, "<br/>")}} />
  )
}

export default TextMessageTemplateBody