import React, { Fragment, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import moment from 'moment';
import { parse } from 'date-fns';

function GeneralTab(props) {

  const beautifyString = (string) => {
    const replacedString = string.replace('_', ' ')
    const uppercaseString = replacedString.charAt(0).toUpperCase() + replacedString.slice(1);
    return uppercaseString
  }

  const handleMemberInfoInput = (key, value) => {
    props.setMemberInfo(prevHash=> ({...prevHash, [key]: value}))
  }

  useEffect(() => {
    if (!props.manager_edit_profile)
      return

    if (props.memberInfo["membership_date"]){
      const membershipDateFormatted = parse(props.memberInfo["membership_date"], 'yyyy-MM-dd', new Date());    
      props.setMemberInfo(prevHash=> ({...prevHash, ["membership_date"]: membershipDateFormatted}))
    }

    if (props.memberInfo["birthday"]){
      const birthdayFormatted = parse(props.memberInfo["birthday"], 'yyyy-MM-dd', new Date());    
      props.setMemberInfo(prevHash=> ({...prevHash, ["birthday"]: birthdayFormatted}))
    }
  }, []);

  useEffect(() => {
    $('.select2').select2({})
    const dropdown_elements = $(".select_field_dropdown")
    for (let element of dropdown_elements) {
      element.onchange = function(){handleMemberInfoInput(this.getAttribute("memberInfoKey"), this.value)}
    }
  }, [props.memberInfo, props.view_mode]);

  return(
    <Fragment>
      {Object.keys(props.memberInfo).map((key) =>
        <div className="metadatum" key={key}>
          <Col>
            {beautifyString(key)}
          </Col>
          {props.manager_edit_profile && props.view_mode === "member_edit" && <div>
            {!(["suffix", "membership_date", "birthday", "gender"].includes(key)) && <Col>
                <input type="text"
                  placeholder=""
                  className="form-control p-1"
                  value={ props.memberInfo[key] }
                  onInput={ e => handleMemberInfoInput(key, e.target.value) }
                  />
            </Col>}

            {key === "suffix" && 
              <select className="select_field_dropdown form-control form-control-sm select2" memberInfoKey="suffix">
                <option selected={!props.memberInfo["suffix"]} disabled>Choose a suffix...</option>
                {["Sr.", "Jr.", "II.", "III", "IV", "V"].map(suffix =>
                  <option value={suffix} key={suffix} selected={props.memberInfo["suffix"] === suffix}>{suffix}</option>
                )}
              </select>
            }

            {key === "gender" && 
              <select className="select_field_dropdown form-control form-control-sm select2" memberInfoKey="gender">
                <option selected={!props.memberInfo["gender"]} disabled>Choose the gender...</option>
                {["Male", "Female"].map(gender =>
                  <option value={gender} key={gender} selected={props.memberInfo["gender"] === gender}>{gender}</option>
                )}
              </select>
            }

            {key === "membership_date" && 
              <div className="datepickerContainer">  
                <DatePicker className="form-control cursor_pointer"
                  name='membership_date'
                  id='membership_date'
                  maxDate={new Date()}
                  obj_type = "original"
                  selected = {Date.parse(moment(props.memberInfo["membership_date"], props.date_format.toUpperCase()))}
                  value =  {Date.parse(moment(props.memberInfo["membership_date"], props.date_format.toUpperCase()))}
                  dateFormat={ props.date_format.replaceAll('m', 'M')}
                  onChange={(event) => handleMemberInfoInput("membership_date", event)}
                  autoComplete = "off"
                />
              </div>
            }

            {key === "birthday" && 
              <div className="datepickerContainer">  
                <DatePicker className="form-control cursor_pointer"
                  name='birthday'
                  id='birthday'
                  maxDate={new Date()}
                  obj_type = "original"
                  selected = {Date.parse(moment(props.memberInfo["birthday"], props.date_format.toUpperCase()))}
                  value =  {Date.parse(moment(props.memberInfo["birthday"], props.date_format.toUpperCase()))}
                  dateFormat={ props.date_format.replaceAll('m', 'M')}
                  onChange={(event) => handleMemberInfoInput("birthday", event)}
                  autoComplete = "off"
                />
              </div>
            }
          </div>}

          {(!props.manager_edit_profile || props.view_mode !== "member_edit") &&  <Col className="fw-bold fs-5">
            {(["membership_date", "birthday"].includes(key)) ? (props.memberInfo[key] ? moment(props.memberInfo[key]).format(props.date_format.toUpperCase().replaceAll('%', '')) : '-') : (props.memberInfo[key] || "-")}
          </Col>}
        </div>
      )}
    </Fragment>
  )
}
export default GeneralTab