import React from 'react'

import rootReducer from '../reducers'

import configureStore from 'shared/configure_store'
import { GlobalProvider } from 'shared/global_context'

const ShopDashboardRoot = ({component_data}) => {
  const state = {
    dashboard: component_data
  }
  
  const store = configureStore(rootReducer, state)
  var componentName = component_data.component_name;

  const importedComponentModule = require("./" + componentName).default;

  return (
    <GlobalProvider store={ store }>
      {React.createElement(importedComponentModule)}
    </GlobalProvider>
  )
}


export default ShopDashboardRoot