import React, { PureComponent } from 'react'
import GsgButton from '../../shared/components/gsg_button'
import PropTypes from 'prop-types'

function Footer(props) {  

  const handleSaveChangesClick = (all_tabs) => {
    if(window.confirm(`Are you sure you want to save the changes made?`)){
      props.saveChanges(JSON.stringify(all_tabs), JSON.stringify(props.all_metadata), JSON.stringify(props.memberInfo), props.member_id)
    }
  }

  const handleSwitchViewClick = (view_mode) => {
    props.switchView(view_mode)
  }

  const handleCloseClick = () => {
    if(window.confirm(`Are you sure you want to discard all changes?`)){
      props.close(props.current_tab.id)
    }
  }

  return (
    <div className='row bottom_buttons_bar'> 
      <div className="col-12 py-2 px-3">
        { (props.view_mode == "manager") && !props.preview_member_profile && <GsgButton text="Save"
          className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right"
          disabled={false}
          onClick={() => handleSaveChangesClick(props.all_tabs)}
        />}
        { props.view_mode == "manager" && !props.preview_member_profile && <GsgButton text="Preview"
          className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right me-2"
          disabled={false}
          onClick={() => handleSwitchViewClick("preview")}
        />}
        {/* <GsgButton text="Member View"
          className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right me-2"
          disabled={false}
          onClick={() => handleSwitchViewClick("member_preview")}
        /> */}
        { (props.view_mode == "manager" || props.view_mode == "preview") && !props.preview_member_profile && <GsgButton text="Close"
          className="btn gems_custom_button btn-default add-margin-left-0 float-start me-2"
          disabled={false}
          onClick={() => handleCloseClick()}
        />}
        { props.view_mode == "preview" && !props.preview_member_profile && <GsgButton text="Edit"
          className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right me-2"
          disabled={false}
          onClick={() => handleSwitchViewClick("manager")}
        />}
        { props.preview_member_profile && <div className='btn gems_custom_button cursor_pointer me-1 my-1'>
          <a href={props.member_roster_url} data-method="get">Back</a>
        </div>}
      </div>
    </div>
  )
}


export default Footer