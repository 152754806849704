import { connect } from 'react-redux'
import SubmittedOnlineOrder from '../components/SubmittedOnlineOrder';
import {
	editOnlineOrder
} from "../actions";

const mapStateToProps = (state) => ({
  fontColor: state.general_settings.font_color,
  formLogo: state.general_settings.logo,
  formDisplayBanner: state.general_settings.display_banner,
  formBanner: state.general_settings.banner,
  orderSubmitted: state.form_state.orderSubmitted,
  formClosed: state.form_state.closed,
  genericForm: state.customer.generic_form,
  cartProducts: Object.values(state.cart.products)
})

const mapDispatchToProps = (dispatch) => ({
  editOnlineOrder: () => dispatch(editOnlineOrder()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmittedOnlineOrder)
