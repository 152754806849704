import React from 'react'
import { Col, Table, Spinner, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';

function UnsentEmailsSection(props) {

  const {emails, quick_search_value, page, total_pages} = props;

  const handlePagination = (event) => {
    props.paginateUnsentEmails()
  }

  const handleShowBulkEmailModalClick = (event) => {
    const template_id = event.target.getAttribute('data-template-id')
    props.showBulkTemplate(template_id)
  }

  const handleUnscheduleEmailClick = (event) => {
    if(window.confirm('Are you sure you want to cancel this scheduled email?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.unscheduleEmail(communication_history_id)
    }
  }

  const handleDeleteDraftEmailClick = (event) => {
    if(window.confirm('Are you sure you want to delete this draft email?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.deleteDraftEmail(communication_history_id)
    }
  }

  const handlePauseEmailClick = (event) => {
    if(window.confirm('Are you sure you want to pause this scheduled email?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.pauseScheduledEmail(communication_history_id)
    }
  }

  const handleResumeEmailClick = (event) => {
    if(window.confirm('Are you sure you want to resume this paused scheduled email?')){
      const communication_history_id = event.target.getAttribute('data-template-id')
      props.resumeScheduledEmail(communication_history_id)
    }
  }

  return (
      <div className='row section_container mb-4'>
        <Col xs={12} className="mb-3 p-0 communication_center_header_section">
          UNSENT EMAILS
        </Col>
        { props.isLoading 
          ? 
          <div className='spinner_class'>
            <Spinner className="d-block" animation="border" variant="warning" id="loading_spinner"/>
          </div>
          :
          <Col xs={12} className="p-0 actions-dropdown-container">
            <Row>
              <Col xs={12} className="communication_table_container">
                <Table striped className="tableFixHead custom_template_communications">
                  <thead>
                    <tr>
                      <th className='text-center' style={{width: "105px"}}>Date</th>
                      <th className='text-center' style={{width: "105px"}}>Time</th>
                      <th>Subject</th>
                      <th>Recipients</th>
                      <th className='text-center' style={{width: "75px"}}> Status </th>
                      <th className='text-center' style={{width: "75px"}}> Actions </th>
                    </tr>
                  </thead>

                  <tbody>
                    {emails.map((email, index) => 
                      (!quick_search_value || (quick_search_value && (email.searchable_fields.map((sf) => (sf.includes(quick_search_value.toLowerCase()))).indexOf(true) > -1))) &&
                        <tr key={index}>
                          <td className='text-center'> {email.sent_date} </td>
                          <td className='text-center'> {email.nice_format_time} </td>
                          {email.entity_preview_link && <td> {<div dangerouslySetInnerHTML={{ __html: email.subject + ` - <a class="link" href="${email.entity_preview_link}" data-method="get">${email.entity_type == "OnlinePoForm" ? "View Online Form" : "View Survey"}</a>`}}/>} </td>}
                          {!email.entity_preview_link && email.entity_deleted && <td> 
                            <span>{email.subject}</span>
                            <OverlayTrigger
                              key="entity_deleted_tooltip"
                              placement="top"
                              overlay={
                                <Tooltip id={`entity_deleted_tooltip`}>
                                  {(email.entity_type === "OnlinePoForm" ? 'Online Form' : 'Survey') + " associated with this email has been deleted."}
                                </Tooltip>
                                }>
                              <i className="ms-2 gga-info-circle tooltip_info color_red" />
                            </OverlayTrigger>
                            </td>
                          }
                          {!email.entity_preview_link && !email.entity_deleted && <td> {email.subject} </td>}
                          <td> 
                            {email.recipients}
                          </td>
                          {email.scheduled_communication && !email.paused && <td className='text-center color_green'> 
                            Scheduled
                          </td>}
                          {email.scheduled_communication && email.paused && <td className='text-center color_red'> 
                            Paused
                          </td>}
                          {email.is_draft && <td className='text-center'> 
                            Draft
                          </td>}
                          <td className='text-center actions-dropdown-cell'>
                            <div className="dropdown actions-dropdown">
                              <button className="btn dropdown-toggle lh-1 fs-5" aria-expanded = "false" data-bs-toggle = "dropdown" type = "button" id = {`dropdownContainerItemMenu_${email.id}`} />
                              <ul className="dropdown-menu dropdown-menu-left" aria-labelledby = {`dropdownContainerItemMenu_${email.id}`} >
                                <li className="dropdown-item"> 
                                  <span 
                                    className='link' 
                                    style={{fontSize: "14px"}}
                                    onClick={handleShowBulkEmailModalClick} 
                                    data-template-id={email.id}
                                  > 
                                    Preview 
                                  </span>
                                </li>
                                <li className="dropdown-item"> 
                                  <a 
                                    className="link"
                                    style={{fontSize: "14px"}}
                                    key={index}
                                    href={email.communication_template_edit_link}
                                    data-method="GET"
                                  >
                                    Edit
                                  </a>
                                </li>
                                {email.is_draft && <li className="dropdown-item"> 
                                  <span 
                                    className='link color_red'
                                    style={{fontSize: "14px"}}
                                    data-template-id={email.id}
                                    onClick={handleDeleteDraftEmailClick} 
                                  > 
                                    Delete
                                  </span>
                                </li>}
                                {email.scheduled_communication && email.paused && email.can_resume && <li className="dropdown-item"> 
                                  <span 
                                    className='link'
                                    style={{fontSize: "14px"}}
                                    data-template-id={email.id}
                                    onClick={handleResumeEmailClick} 
                                  > 
                                    Resume
                                  </span>
                                </li>}
                                {email.scheduled_communication && email.paused && !email.can_resume && email.entity_type == "OnlinePoForm" && <li className="dropdown-item"> 
                                <OverlayTrigger
                                  key="resume_email"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-resume_email`}>
                                      Cannot resume an email that is scheduled outside an online form's open/close time period.
                                    </Tooltip>
                                  }
                                >
                                  <span
                                  className="cursor_pointer color_gray disabled"
                                  style={{fontSize: "14px"}}
                                  >
                                    Resume 
                                  </span>
                                </OverlayTrigger>
                                </li>}
                                {email.scheduled_communication && email.paused && !email.can_resume && email.entity_type == "Survey" && <li className="dropdown-item"> 
                                <OverlayTrigger
                                  key="resume_email"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-resume_email`}>
                                      Cannot resume an email that is scheduled for a closed survey.
                                    </Tooltip>
                                  }
                                >
                                  <span
                                  className="cursor_pointer color_gray disabled"
                                  style={{fontSize: "14px"}}
                                  >
                                    Resume 
                                  </span>
                                </OverlayTrigger>
                                </li>}
                                {email.scheduled_communication && !email.paused && <li className="dropdown-item"> 
                                  <span 
                                    className='link'
                                    style={{fontSize: "14px"}}
                                    data-template-id={email.id}
                                    onClick={handlePauseEmailClick} 
                                  > 
                                    Pause
                                  </span>
                                </li>}
                                {email.scheduled_communication && <li className="dropdown-item"> 
                                  <span 
                                    className='link color_red'
                                    style={{fontSize: "14px"}}
                                    data-template-id={email.id}
                                    onClick={handleUnscheduleEmailClick} 
                                  > 
                                    Delete
                                  </span>
                                </li>}
                              </ul>
                            </div>
                          </td>
                        </tr>
                    )}
                  </tbody>
                  
                </Table>
              </Col>
              {emails.length > 0 && page < total_pages && <Col xs={12}>
                <span 
                  className='link'
                  onClick={handlePagination}
                >
                  View More
                </span>
              </Col>}
              {emails.length <= 0 && <Col xs={12}>
                <span>
                  No scheduled emails
                </span>
              </Col>}
            </Row>
          </Col>
        }
      </div>
  )
}

export default UnsentEmailsSection
