import React from 'react'
import CalendarBodyContainer from '../containers/CalendarBodyContainer'
import '!style-loader!css-loader!react-big-calendar/lib/css/react-big-calendar.css'
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css'

import CommunicationShowModalContainer from '../containers/CommunicationShowModalContainer'
import HeaderContainer from '../containers/HeaderContainer'

function Body(props) {

  const {modal_show_communication_history_open} = props;

  return (
    <div className="flex-grow-1 d-flex flex-column" id="container_view_dashboard_communication_center" style={{overflowX: "auto", overflowY: "hidden"}}>
      <HeaderContainer />
      <div className="flex-grow-1 p-3 bg-white calendar_body_wrapper"><CalendarBodyContainer /></div>
      
      {modal_show_communication_history_open && <CommunicationShowModalContainer/>}
    </div>
  )
}

export default Body
