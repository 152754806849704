import React, {useRef} from 'react'
import {useState, useEffect} from 'react';
import {Form, Col, Row} from 'react-bootstrap';
import Select from "react-select";
import ProductAttribute from './ProductAttribute';
import missingImage from '../../../../assets/images/missing_product_image.png'

function ImageCarousel (props){
  const {
    product_id,
    images,
    sliderSide,
    fontColor,
  } = props;

  const initialState = {
    imageIndex: 0,
    scale: 2.5,
    start: {
      x: 0,
      y: 0
    },
    zoomed: false,
  }


  const [state, setState] = useState(initialState);
  const currentImageRef = useRef();

  useEffect(() => {
  }, []);


  const handleSlideNext = () => {
    let current_image = document.getElementById(`current_image_${product_id}`)
    current_image.classList.add('slideInFromRight')
    setTimeout(() => {
      current_image.classList.remove('slideInFromRight')
    }, "500");

    let index = state.imageIndex
    if (state.imageIndex == images.length - 1)
      index = 0
    else
      index = state.imageIndex + 1
    setState({...state, imageIndex: index});
  }

  const handleSlidePrevious = () => {
    let current_image = document.getElementById(`current_image_${product_id}`)
    current_image.classList.add('slideInFromLeft')
    setTimeout(() => {
      current_image.classList.remove('slideInFromLeft')
    }, "500");

    let index = state.imageIndex
    if (state.imageIndex > 1)
      index = state.imageIndex - 1
    else
      index = images.length - 1
    setState({...state, imageIndex: index});
  }

  const handleSlideSelect = (index) => {
    let current_image = document.getElementById(`current_image_${product_id}`)
    current_image.classList.add('fadeIn')
    setTimeout(() => {
      current_image.classList.remove('fadeIn')
    }, "500");

    setState({...state, imageIndex: index});
  }

  const zoomScale = (scale) => {
    if (currentImageRef.current) {
      currentImageRef.current.style.transform = 'scale(' + scale + ')'
    }
  }

  const handleMouseMoveDesktop = (event) => {
    if (currentImageRef.current) {
      const container = $(currentImageRef.current).closest('.carousel-container')
      currentImageRef.current.style.transformOrigin = (event.pageX - $(container).offset().left) / $(container).width() * 100 + '% ' + (event.pageY - $(container).offset().top) / $(container).height() * 100 + '%'
    }
  }

  const handleMouseMoveMobile = (event) => {
    // if ($(currentImageRef.current).css("transform") !== 'matrix(2.5, 0, 0, 2.5, 0, 0)')
    //   return
    //
    // const offset = {}
    //
    // offset.x = state.start.x - event.touches[0].pageX;
    // offset.y = state.start.y - event.touches[0].pageY;
    // const left = parseInt(currentImageRef.current.style.left.replace(/[^-\d\.]/g, '')) || 0
    // const top = parseInt(currentImageRef.current.style.top.replace(/[^-\d\.]/g, '')) || 0
    // const width = parseInt(currentImageRef.current.style.width.replace(/[^-\d\.]/g, '')) || 100
    // const height = parseInt(currentImageRef.current.style.height.replace(/[^-\d\.]/g, '')) || 100
    //
    // currentImageRef.current.style = {left:  Math.min(Math.max(left - offset.x/10, -width*0.8), width*0.8), top: Math.max(Math.min(top  - offset.y/10, height*0.8), -height*0.8)}
  }

  return (
    <>
      {
        sliderSide == "left" && <Row style={{height: "210px"}}>
          { images.length > 1 && 
            <Col xs={2} className='h-100 p-0'>
              <div className='carousel-container thumbnails_list h-100'>
                <i className='fa fa-arrow-up text-center my-1 fs-5' style={{color: `${fontColor}`}} onClick={handleSlidePrevious}/>
                <ul className='next-list flex-grow-1'>
                  {images.map((image, index) => 
                    <li key={index} className='border' style={{width: "40px", height: "40px", borderColor: `${index == state.imageIndex ? `${fontColor}!important` : 'inherit'}`}}>
                      <img src={image ? image : missingImage} className={`image-of-list ${index == state.imageIndex ? 'current-image-list' : ''}`} style={{height: "100%", width: "100%", objectFit: "cover", objectPosition: 'top'}} onClick={() => handleSlideSelect(index)}/>
                    </li>
                  )}
                </ul>
                <i className='fa fa-arrow-down text-center my-1 fs-5' style={{color: `${fontColor}`}} onClick={handleSlideNext}/>
              </div>
            </Col>
          }

          <div className={`main-image h-100 p-0 ${images.length > 1 ? "col-9" : "col-10 offset-1"}`}>
            {
              images ? 
                <figure className='carousel-container current_carousel h-100 zoom' onMouseMove={handleMouseMoveDesktop} style={{height: "180px"}}>
                  <div
                    ref={currentImageRef}
                    className='current-image'
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                      background: "white",
                      backgroundImage: `url(${images[state.imageIndex] ? images[state.imageIndex] : missingImage})`,
                    }}
                    href={images[state.imageIndex] ? images[state.imageIndex] : missingImage}
                    target="_self" id={`current_image_${product_id}`}
                    onMouseOver={() => zoomScale(state.scale)}
                    onMouseOut={() => zoomScale(1)}
                  />
                  <i className='fa fa-search-plus text-center my-1 fs-5' style={{color: `${fontColor}`, position: "absolute", right: "15px", bottom: "10px"}}/>
                </figure> 
              : 
                <img src={missingImage} style={{alignSelf: "center", height: "100%", width: "100%", objectFit: "contain"}}/> 
            }
          </div>
        </Row>
      }

      

      

      {
        sliderSide == "bottom" && 
          <Col xs={12} className='main_image' style={{height: "270px"}}>
            {
              images ? 
                <div className='carousel-container current_carousel zoom-mobile' style={{height: "180px"}}>
                  <div
                    ref={currentImageRef}
                    className='current-image'
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                      background: "white",
                      backgroundImage: `url(${images[state.imageIndex] ? images[state.imageIndex] : missingImage})`,
                    }}
                    href={images[state.imageIndex] ? images[state.imageIndex] : missingImage}
                    target="_self" id={`current_image_${product_id}`}
                    onMouseOver={() => zoomScale(state.scale)}
                    onMouseOut={() => zoomScale(1)}
                    onTouchStart={(event) => setState({...state, start: {x: event.touches[0].pageX, y: event.touches[0].pageY}})}
                    onTouchMove={handleMouseMoveMobile}
                  />
                  <i className='fa fa-search-plus text-center my-1 fs-5' style={{color: `${fontColor}`, position: "absolute", right: "15px", bottom: "10px"}}/>
                </div> 
              : 
                <img src={missingImage} style={{alignSelf: "center", height: "100%", width: "100%", objectFit: "contain"}}/> 
            }
            
            { images.length > 1 && 
              <div className='carousel-container thumbnails_list mt-2 flex-row w-100' style={{width: "50px"}}>
                <i className='fa fa-arrow-left text-center me-2 fs-5' style={{color: `${fontColor}`}} onClick={handleSlidePrevious}/>
                <ul className='next-list flex-grow-1 flex-row'>
                  {images.map((image, index) => 
                    <li key={index} className='mx-1'>
                      <img src={image ? image : missingImage} className={`image-of-list ${index == state.imageIndex ? 'current-image-list' : ''}`} style={{height: "100%", width: "50px", objectFit: "contain"}} onClick={() => handleSlideSelect(index)}/>
                    </li>
                  )}
                </ul>
                <i className='fa fa-arrow-right ms-2 fs-5' style={{color: `${fontColor}`}} onClick={handleSlideNext}/>
              </div>
            }
          </Col>
      }
      
    </>
  )
}


export default ImageCarousel