import { ActionTypes } from '../actions'

const {
  ADD_PRODUCT_TO_CART,
  REMOVE_PRODUCT_FROM_CART,
  ADJUST_PRODUCTS_STOCK_ORDER,
  UPDATE_QUANTITY,
} = ActionTypes

const initialState = {
}
  
const products = (state = initialState, action) => {
  let products;
  let selected_product;
  switch (action.type) {
    case ADD_PRODUCT_TO_CART:
      selected_product = action.data.product
      products = state
      products[selected_product.id].items_ordered_by_customer = action.data.totalProductQuantityOrdered
      return {
        ...products
      }
    case REMOVE_PRODUCT_FROM_CART:
      selected_product = action.data.product
      products = state
      products[selected_product.id].items_ordered_by_customer = action.data.totalProductQuantityOrdered
      return {
        ...products
      }
    case UPDATE_QUANTITY:
      selected_product = action.data.product
      products = state
      products[selected_product.id].items_ordered_by_customer = action.data.totalProductQuantityOrdered
      return {
        ...products
      }
    case ADJUST_PRODUCTS_STOCK_ORDER:
      let refresed_products = action.data.products
      products = state
      Object.values(refresed_products).forEach((product) => {
        products[product.id].items_ordered_by_others = product.items_ordered_by_others
        products[product.id].available_items = product.available_items
      })
      return {
        ...products
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default products