import { ActionTypes } from '../actions'

const {
  FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST,
  QUICK_SEARCH,
  ENABLE_DISABLE_AUTOMATED_TEMPLATE,
  UPDATE_DEMO_REMINDER_TEMPLATE_DAYS
} = ActionTypes

const initialState = {
}
  
const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXT_MESSAGE_CENTER_DATA_REQUEST:
      return {
        ...state,
        custom_text_message_templates: action.data.custom_text_message_templates,
        automated_text_message_templates: action.data.automated_text_message_templates,
        create_text_message_url: action.data.create_text_message_url,
        create_template_url: action.data.create_template_url,
        settings: action.data.settings,
      }

    case QUICK_SEARCH:
      return {
        ...state,
        quick_search_value: action.data.quick_search_value
      }

    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default dashboard