import { ActionTypes } from '../actions'

const {
  MATCH_CATEGORY,
  UNMATCH_CATEGORY,
  UNDO_NEW_CATEGORY,
  MATCH_CATEGORY_DROPDOWN,
  MATCH_NEW_CATEGORY_DROPDOWN,
  CREATE_CATEGORY_DROPDOWN,
  SET_PARENT_NEW_CATEGORY_DROPDOWN,
  MATCH_FIELD,
  UNMATCH_FIELD,
  MATCH_FIELD_DROPDOWN,
  CREATE_FIELD_DROPDOWN,
  IGNORE_FIELD_DROPDOWN
} = ActionTypes

const initialState = {
  customer_id: "",
  admin_side: false,
  callaway_categories: [],
  gs_categories: [],
  matched_categories: [],
  unmatched_categories: [],
  categories_to_create: [],
  callaway_fields: [],
  gs_fields: [],
  matched_fields: [],
  unmatched_fields: [],
  fields_to_create: []
}
  
const callaway_connect = (state = initialState, action) => {

  switch (action.type) {
    case MATCH_CATEGORY_DROPDOWN:
      var unmatched_categories_list = [...state.unmatched_categories]
      var cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_match = unmatched_categories_list[cc_category_index]
      cc_category_to_match.matched_category = action.data['category']
      unmatched_categories_list[cc_category_index] = cc_category_to_match
      return {
        ...state,
        unmatched_categories: unmatched_categories_list
      }
    case MATCH_CATEGORY:
      var unmatched_categories_list = [...state.unmatched_categories]
      var matched_categories_list = [...state.matched_categories]
      var cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_match = unmatched_categories_list[cc_category_index]
      unmatched_categories_list = unmatched_categories_list.filter((c) => c.id != action.data['cc_category_id'])
      matched_categories_list.push(cc_category_to_match)
      matched_categories_list.sort((a, b) => a.full_name > b.full_name ? 1 : -1)
      return {
        ...state,
        matched_categories: matched_categories_list,
        unmatched_categories: unmatched_categories_list
      }

    case UNMATCH_CATEGORY:
      var unmatched_categories_list = [...state.unmatched_categories]

      if (action.data['matching_action'] == 'matched') {
        var matched_categories_list = [...state.matched_categories]
        var cc_category_index = matched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
        var cc_category_to_match = matched_categories_list[cc_category_index]
        cc_category_to_match.matched_category = null
        matched_categories_list = matched_categories_list.filter((c) => c.id != action.data['cc_category_id'])
      } else {
        var categories_to_create_list = [...state.categories_to_create]
        var cc_category_index = categories_to_create_list.findIndex(c => c.id === action.data['cc_category_id']);
        var cc_category_to_match = categories_to_create_list[cc_category_index]
        cc_category_to_match.parent_category_id = null
        cc_category_to_match.parent_category = null
        cc_category_to_match.new_category = false
        categories_to_create_list = categories_to_create_list.filter((c) => c.id != action.data['cc_category_id'])
      }

      unmatched_categories_list.push(cc_category_to_match)
      unmatched_categories_list.sort((a, b) => a.full_name > b.full_name ? 1 : -1)
      if (action.data['matching_action'] == 'matched')
        return {
          ...state,
          matched_categories: matched_categories_list,
          unmatched_categories: unmatched_categories_list
        }
      else
        return {
          ...state,
          categories_to_create: categories_to_create_list,
          unmatched_categories: unmatched_categories_list
        }

    case UNDO_NEW_CATEGORY:
      var unmatched_categories_list = [...state.unmatched_categories]

      var cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_unmatch = unmatched_categories_list[cc_category_index]
      cc_category_to_unmatch.parent_category_id = null
      cc_category_to_unmatch.parent_category = null
      cc_category_to_unmatch.new_category = false
      unmatched_categories_list[cc_category_index] = cc_category_to_unmatch

      return {
        ...state,
        unmatched_categories: unmatched_categories_list
      }
    case MATCH_NEW_CATEGORY_DROPDOWN:
      var unmatched_categories_list = [...state.unmatched_categories]
      var cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_create = unmatched_categories_list[cc_category_index]
      cc_category_to_create.new_category = true
      cc_category_to_create.parent_category = null
      cc_category_to_create.parent_category_id = null
      unmatched_categories_list[cc_category_index] = cc_category_to_create

      return {
        ...state,
        unmatched_categories: unmatched_categories_list
      }
    case CREATE_CATEGORY_DROPDOWN:
      var unmatched_categories_list = [...state.unmatched_categories]
      var categories_to_create_list = [...state.categories_to_create]
      var cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_create = unmatched_categories_list[cc_category_index]

      unmatched_categories_list = unmatched_categories_list.filter((c) => c.id != action.data['cc_category_id'])
      categories_to_create_list.push(cc_category_to_create)
      categories_to_create_list.sort((a, b) => a.full_name > b.full_name ? 1 : -1)

      return {
        ...state,
        categories_to_create: categories_to_create_list,
        unmatched_categories: unmatched_categories_list
      }

    case SET_PARENT_NEW_CATEGORY_DROPDOWN:
      var parent_category = action.data['parent_category']
      if (parent_category)
        var parent_category_id = parent_category.id
      else
        var parent_category_id = null
      var categories_to_create_list = [...state.categories_to_create]
      var unmatched_categories_list = [...state.unmatched_categories]
      var cc_category_index = categories_to_create_list.findIndex(c => c.id === action.data['cc_category_id']);
      var cc_category_to_update = null
      if (cc_category_index == -1)
      {
        cc_category_index = unmatched_categories_list.findIndex(c => c.id === action.data['cc_category_id']);
        cc_category_to_update = unmatched_categories_list[cc_category_index]
        cc_category_to_update.parent_category_id = parent_category_id
        cc_category_to_update.parent_category = parent_category
        unmatched_categories_list[cc_category_index] = cc_category_to_update
      } else {
        cc_category_to_update = categories_to_create_list[cc_category_index]
        cc_category_to_update.parent_category_id = parent_category_id
        cc_category_to_update.parent_category = parent_category
        categories_to_create_list[cc_category_index] = cc_category_to_update
      }
      
      return {
        ...state,
        categories_to_create: categories_to_create_list,
        unmatched_categories: unmatched_categories_list
      }

    case MATCH_FIELD_DROPDOWN:
      var unmatched_fields_list = [...state.unmatched_fields]
      var cc_field_index = unmatched_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
      var cc_field_to_match = unmatched_fields_list[cc_field_index]
      cc_field_to_match.matched_field = action.data['field']
      unmatched_fields_list[cc_field_index] = cc_field_to_match
      return {
        ...state,
        unmatched_fields: unmatched_fields_list
      }
    case MATCH_FIELD:
      var unmatched_fields_list = [...state.unmatched_fields]
      var matched_fields_list = [...state.matched_fields]
      var cc_field_index = unmatched_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
      var cc_field_to_match = unmatched_fields_list[cc_field_index]
      unmatched_fields_list = unmatched_fields_list.filter((c) => c.code != action.data['cc_field_code'])
      matched_fields_list.push(cc_field_to_match)
      matched_fields_list.sort((a, b) => a.name > b.name ? 1 : -1)
      return {
        ...state,
        matched_fields: matched_fields_list,
        unmatched_fields: unmatched_fields_list
      }

    case UNMATCH_FIELD:
      var unmatched_fields_list = [...state.unmatched_fields]

      if (action.data['matching_action'] == 'matched') {
        var matched_fields_list = [...state.matched_fields]
        var cc_field_index = matched_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
        var cc_field_to_match = matched_fields_list[cc_field_index]
        matched_fields_list = matched_fields_list.filter((c) => c.code != action.data['cc_field_code'])
      } else       
        if (action.data['matching_action'] == 'ignored') {
          var ignored_fields_list = [...state.ignored_fields]
          var cc_field_index = ignored_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
          var cc_field_to_match = ignored_fields_list[cc_field_index]
          ignored_fields_list = ignored_fields_list.filter((c) => c.code != action.data['cc_field_code'])
        }
        else{
          var fields_to_create_list = [...state.fields_to_create]
          var cc_field_index = fields_to_create_list.findIndex(c => c.code === action.data['cc_field_code']);
          var cc_field_to_match = fields_to_create_list[cc_field_index]
          fields_to_create_list = fields_to_create_list.filter((c) => c.code != action.data['cc_field_code'])
        }

      unmatched_fields_list.push(cc_field_to_match)
      unmatched_fields_list.sort((a, b) => a.name > b.name ? 1 : -1)
      if (action.data['matching_action'] == 'matched')
        return {
          ...state,
          matched_fields: matched_fields_list,
          unmatched_fields: unmatched_fields_list
        }
      else
        if (action.data['matching_action'] == 'ignored')
          return {
            ...state,
            ignored_fields: ignored_fields_list,
            unmatched_fields: unmatched_fields_list
          }
        else
          return {
            ...state,
            fields_to_create: fields_to_create_list,
            unmatched_fields: unmatched_fields_list
          }
    case CREATE_FIELD_DROPDOWN:
      var unmatched_fields_list = [...state.unmatched_fields]
      var fields_to_create_list = [...state.fields_to_create]
      var cc_field_index = unmatched_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
      var cc_field_to_create = unmatched_fields_list[cc_field_index]

      unmatched_fields_list = unmatched_fields_list.filter((c) => c.code != action.data['cc_field_code'])
      fields_to_create_list.push(cc_field_to_create)
      fields_to_create_list.sort((a, b) => a.name > b.name ? 1 : -1)

      return {
        ...state,
        fields_to_create: fields_to_create_list,
        unmatched_fields: unmatched_fields_list
      }
    case IGNORE_FIELD_DROPDOWN:
      var unmatched_fields_list = [...state.unmatched_fields]
      var ignored_fields_list = [...state.ignored_fields]
      var cc_field_index = unmatched_fields_list.findIndex(c => c.code === action.data['cc_field_code']);
      var cc_field_to_create = unmatched_fields_list[cc_field_index]

      unmatched_fields_list = unmatched_fields_list.filter((c) => c.code != action.data['cc_field_code'])
      ignored_fields_list.push(cc_field_to_create)
      ignored_fields_list.sort((a, b) => a.name > b.name ? 1 : -1)

      return {
        ...state,
        ignored_fields: ignored_fields_list,
        unmatched_fields: unmatched_fields_list
      }
    default:
      return {
        ...initialState,
        ...state,
      }
  }
}

export default callaway_connect