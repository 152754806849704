import React, {Component, useState} from 'react'
import GsgSpan from '../../shared/components/gsg_date'
import {TiArrowSortedDown, TiArrowSortedUp} from "react-icons/ti";
import QuickTask from "./QuickTask";
import moment from "moment";
import _ from "lodash";
function TaskListItem(props) {
  const {open, open_key, name, handleOpenClose, color, checkbox_color, tasks, editTask,
    setImportant, setCompleted, assignAndAccept, first_category_name, deleteTask, isRestricted, userId, taskId, applyFilters} = props;

  return (
    <div className="flex-column task-list-item-container d-flex">
        <div className="task-list-item-header d-flex justify-content-start">
          <div className="task-list-item-header-body">
            <div className="align-vertical-middle justify-content-start align-self-start">
              {!open ?
                <TiArrowSortedDown className="cursor_pointer fs-4 " onClick={(e) => handleOpenClose(open_key)}/> :
                <TiArrowSortedUp className="cursor_pointer fs-4 " onClick={(e) => handleOpenClose(open_key)}/>}
              <div className={"align-vertical-middle cursor_pointer"} onClick={(e) => handleOpenClose(open_key)}>
                <b className={"fs-6"}>{name}</b>
              </div>
            </div>
          </div>
        </div>
      {open &&
        <div className="d-flex task-list-item-body">
          {tasks.map((task, index) => (
            <QuickTask id={task["id"]}
                       name={task["name"]}
                       due_date={task["due_date"]}
                       completed_at_date={task["completed_at_date"]}
                       color={color}
                       checkbox_color={checkbox_color}
                       assigned_to={task["assigned_to"]}
                       is_important={task["is_important"]}
                       recurring={task["recurring"]}
                       alerts={task["alerts"]}
                       accepted_users={task["accepted_users"]}
                       email_read_users={task["email_read_users"]}
                        cadence={task["cadence"]}
                        cadenceInfo={task["cadence_info"]}
                        tags={task["tags"]}
                        editTask={editTask}
                        deleteTask={deleteTask}
                        isCompleted={(name == "Completed" || name == "Recently Completed") ? true : false}
                        setImportant={setImportant}
                        setCompleted={setCompleted}
                        assignAndAccept={assignAndAccept}
                        isRestricted={isRestricted}
                        taskId={taskId}
                        next_recurring_task_id={task["next_recurring_task_id"]}
                        userAssigned={!!_.find(task["assignees_ids"], (ai) => ai === userId)}
                        isAttachment={task["is_attachment"]}/>
            ))
            }
        </div>}
    </div>
  )
}

export default TaskListItem
